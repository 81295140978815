import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ArrayHelper } from "@calaosoft/osapp/helpers/arrayHelper";
import { IdHelper } from "@calaosoft/osapp/helpers/idHelper";
import { Loader } from "@calaosoft/osapp/modules/loading/Loader";
import { ObservableArray } from "@calaosoft/osapp/modules/observable/models/observable-array";
import { ObservableProperty } from "@calaosoft/osapp/modules/observable/models/observable-property";
import { DestroyableComponentBase } from "@calaosoft/osapp/modules/utils/components/destroyable-component-base";
import { Queue } from "@calaosoft/osapp/modules/utils/queue/decorators/queue.decorator";
import { secure } from "@calaosoft/osapp/modules/utils/rxjs/operators/secure";
import { LoadingService } from "@calaosoft/osapp/services/loading.service";
import { NetworkService } from "@calaosoft/osapp/services/network.service";
import { UiMessageService } from "@calaosoft/osapp/services/uiMessage.service";
import { plainToClass } from "class-transformer";
import { defer, Observable, of } from "rxjs";
import { filter, map, mergeMap, take, tap } from "rxjs/operators";
import { C_PREFIX_SECTORIZATION } from "../../../../app/app.constants";
import { Sectorization } from "../../models/sectorization";
import { ISector } from "../../sectors/models/isector";
import { Sector } from "../../sectors/models/sector";
import { SectorsService } from "../../sectors/services/sectors.service";
import { SectorizationsService } from "../../services/sectorizations.service";

interface IFrontSector extends ISector {
	readonly namePointOfContact: string | undefined;
	readonly mapButtonEnabled: boolean | undefined;
}
class FrontSector extends Sector implements IFrontSector {
	/** @implements */
	public readonly namePointOfContact: string | undefined;
	/** @implements */
	public readonly mapButtonEnabled: boolean | undefined;
}
@Component({
	selector: "sectorization",
	templateUrl: "./sectorization.page.html",
	styleUrls: ["./sectorization.page.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectorizationPage extends DestroyableComponentBase {

	//#region FIELDS

	private mbSectorsMapButtonEnabled = false;
	public get sectorsMapButtonEnabled(): boolean { return this.mbSectorsMapButtonEnabled; }

	//#endregion FIELDS

	//#region PROPERTIES

	public readonly isOnline$: Observable<boolean>;

	public readonly sectors: ObservableArray<FrontSector> = new ObservableArray([]);
	public readonly sctrzTitle: ObservableProperty<string> = new ObservableProperty("");

	public readonly sctrz = new ObservableProperty<Sectorization>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcSctrz: SectorizationsService,
		private readonly ioActivatedRoute: ActivatedRoute,
		private readonly isvcSectors: SectorsService,
		private readonly isvcLoading: LoadingService,
		private readonly ioRoute: ActivatedRoute,
		psvcNetwork: NetworkService
	) {
		super();

		isvcSctrz.getSectorization$(IdHelper.buildId(
			C_PREFIX_SECTORIZATION,
			this.ioActivatedRoute.snapshot.params.entityGuid
		))
			.pipe(
				filter((poSctrz?: Sectorization) => !!poSctrz),
				tap((poSctrz: Sectorization) => {
					this.sectors.clear();
					this.sctrz.value = poSctrz;
					if (poSctrz.title)
						this.sctrzTitle.value = poSctrz.title;
					if (poSctrz.linkedSectors) {
						const laFrontSectors: FrontSector[] = this.toFrontSectors(poSctrz.linkedSectors);

						for (let i = 0; i < laFrontSectors.length; ++i) {
							this.sectors.push(laFrontSectors[i]);
							if (laFrontSectors[i].mapButtonEnabled)
								this.mbSectorsMapButtonEnabled = true;
						}
					}
				}),
				secure(this)
			).subscribe();

		this.isOnline$ = psvcNetwork.hasNetwork$.pipe(secure(this));
	}

	private toFrontSectors(paSectors: Sector[]): FrontSector[] {
		return paSectors.map((poSector: Sector) => {
			const loFrontSector: IFrontSector = {
				...poSector,
				namePointOfContact: poSector.getNamePointOfContact(),
				mapButtonEnabled: poSector.canDisplayMap()
			};
			return plainToClass(FrontSector, loFrontSector);
		});
	}

	public navigateToSectorVisuModeAsync(poSector: FrontSector): Promise<boolean> {
		return this.isvcSectors.navigateToSectorAsync(poSector._id, this.ioRoute);
	}

	public navigateToSectorMapModeAsync(poSector: FrontSector): Promise<boolean> {
		return this.isvcSectors.navigateToSectorMapAsync(poSector._id, this.ioRoute);
	}

	public navigateToSectorEditModeAsync(poSector: FrontSector): Promise<boolean> {
		return this.isvcSectors.navigateToSectorEditAsync(poSector._id, this.ioRoute);
	}

	public compareSectorizationAsync(): Promise<boolean> {
		return this.isvcSctrz.getActiveSectorization$().pipe(
			map((poSctrz: Sectorization | undefined) => poSctrz?._id),
			filter((poSctrz: string | undefined) => !!poSctrz),
			mergeMap((poSctrz: string) => this.isvcSctrz.navigateToActiveSectorsComparisonAsync(
				IdHelper.getGuidFromId(poSctrz, C_PREFIX_SECTORIZATION), this.ioRoute)),
			take(1)
		).toPromise();
	}

	@Queue<SectorizationPage, Parameters<SectorizationPage["switchSectorization$"]>,
		ReturnType<SectorizationPage["switchSectorization$"]>>({ excludePendings: true })
	private switchSectorization$(): Observable<boolean> {
		if (this.sctrz.value) {
			let loLoader: Loader;
			return defer(() => this.isvcLoading.create("Sauvegarde en cours..."))
				.pipe(
					tap((poLoader: Loader) => loLoader = poLoader),
					mergeMap((poLoader: Loader) => poLoader.present()),
					mergeMap(_ => this.isvcSctrz.getActiveSectorization$()),
					filter((poSctrz: Sectorization | undefined) => !!poSctrz),
					take(1),
					mergeMap((poSctrz: Sectorization) => this.isvcSctrz.switchSectorizationAsync(poSctrz, this.sctrz.value!)),
					tap((pbSaveHasWork: boolean) => {
						loLoader?.dismiss();
						if (pbSaveHasWork) {
							this.showErrorPopup;
						}
					})
				);
		}
		else
			return of(true);
	}

	public navigateToSectorizationAsync(): Promise<boolean> {
		return this.isvcSctrz.getInactivesSectorizations$()
			.pipe(
				take(1),
				filter((paSctrz: Sectorization[]) => ArrayHelper.hasElements(paSctrz)),
				map((paSctrz: Sectorization[]) => ArrayHelper.getFirstElement(paSctrz)),
				mergeMap((poSctrz: Sectorization) =>
					this.isvcSectors.navigateToSectorizationAsync(IdHelper.getGuidFromId(poSctrz._id, C_PREFIX_SECTORIZATION))
				),
				secure(this)
			).toPromise();
	}

	public navigateToBusinessDistributionAsync(): Promise<boolean> {
		return this.isvcSctrz.navigateToBusinessDistributionAsync(this.ioRoute);
	}

	public navigateToBusinessDistributionWithOptimizationAsync(): Promise<boolean> {
		return this.isvcSctrz.navigateToBusinessDistributionWithOptimizationAsync(this.ioRoute);
	}

	private showErrorPopup() {
		return this.isvcUiMessage.showPopupMessage(
			{
				header: " Erreur",
				message: "La sauvegarde n'as pas fonctionnée",
				backdropDismiss: true,
				buttons: [],
				subHeader: "", cssClass: "", inputs: []
			});
	}

	public navigateToSectorCreateModeAsync(): Promise<boolean> {
		if (this.sctrz.value)
			return this.isvcSectors.navigateToSectorNewAsync(this.ioRoute, this.sctrz.value);
		else
			return Promise.resolve(false);
	}

	public switchSectorizationAsync(): Promise<boolean> {
		return this.switchSectorization$()
			.pipe(
				mergeMap(_ => this.navigateToSectorizationAsync())
			).toPromise();
	}

	public async onDeleteItemClickedAsync(poSector: FrontSector): Promise<boolean> {
		if (this.sctrz.value) {
			this.sctrz.value.linkedSectors =
				this.sctrz.value.linkedSectors?.filter(
					(poSect: Sector) => poSect._id !== poSector._id);

			await this.isvcSctrz.saveSectorizationAsync(this.sctrz.value);
		}

		return this.isvcSectors.deleteSectorAsync(poSector);
	}

	public navigateToSectorsMapAsync(): Promise<boolean> {
		return this.isvcSectors.navigateToSectorsMapAsync(this.ioRoute);
	}

	//#endregion METHODS

}