/**
 * Composant de démarrage de l'application
 * Initialisation de la navigation et du menu
 */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '@calaosoft/osapp/components/menu/menu.service';
import { DateHelper } from '@calaosoft/osapp/helpers/dateHelper';
import { ObjectHelper } from '@calaosoft/osapp/helpers/objectHelper';
import { IPopoverItem } from '@calaosoft/osapp/model/IPopoverItem';
import { IAppStartPauseLogData } from '@calaosoft/osapp/model/application/iapp-start-pause-log-data';
import { ConfigData } from '@calaosoft/osapp/model/config/ConfigData';
import { IConfig } from '@calaosoft/osapp/model/config/IConfig';
import { NotSupportedPlatformError } from '@calaosoft/osapp/model/errors/NotSupportedPlatformError';
import { ELinkAction } from '@calaosoft/osapp/model/link/ELinkAction';
import { ELinkTemplate } from '@calaosoft/osapp/model/link/ELinkTemplate';
import { IMailOptions } from '@calaosoft/osapp/model/mail/IMailOptions';
import { ActivePageManager } from '@calaosoft/osapp/model/navigation/ActivePageManager';
import { DeviceNotAuthorizedError } from '@calaosoft/osapp/model/store/error/device-not-authorized-error';
import { BatteryService } from '@calaosoft/osapp/modules/battery/services/battery.service';
import { BaseEventOccurrence } from '@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence';
import { ConflictsService } from '@calaosoft/osapp/modules/conflicts/services/conflicts.service';
import { DeeplinkService } from '@calaosoft/osapp/modules/deeplink/deeplink.service';
import { EFlag } from '@calaosoft/osapp/modules/flags/models/EFlag';
import { Loader } from '@calaosoft/osapp/modules/loading/Loader';
import { ELogActionId } from '@calaosoft/osapp/modules/logger/models/ELogActionId';
import { LoggerService } from '@calaosoft/osapp/modules/logger/services/logger.service';
import { ModalComponentsRegistry } from '@calaosoft/osapp/modules/modal/model/modal-components-registry';
import { PatchService } from '@calaosoft/osapp/modules/patch/services/patch.service';
import { PwaUpdateService } from '@calaosoft/osapp/modules/pwa/services/pwa-update.service';
import { PageManagerService } from '@calaosoft/osapp/modules/routing/services/pageManager.service';
import { AppComponentBase } from '@calaosoft/osapp/modules/utils/components/app-component-base';
import { ApplicationService } from '@calaosoft/osapp/services/application.service';
import { ConfigService } from '@calaosoft/osapp/services/config.service';
import { EntityLinkService } from '@calaosoft/osapp/services/entityLink.service';
import { FlagService } from '@calaosoft/osapp/services/flag.service';
import { GlobalDataService } from '@calaosoft/osapp/services/global-data.service';
import { ShowMessageParamsPopup } from '@calaosoft/osapp/services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '@calaosoft/osapp/services/loading.service';
import { MailService } from '@calaosoft/osapp/services/mail.service';
import { NotificationService } from '@calaosoft/osapp/services/notification.service';
import { PlatformService } from '@calaosoft/osapp/services/platform.service';
import { UiMessageService } from '@calaosoft/osapp/services/uiMessage.service';
import { MenuController } from '@ionic/angular';
import { AlertButton } from '@ionic/core';
import { EMPTY, Observable, defer } from 'rxjs';
import { catchError, finalize, mergeMap, switchMap, tap } from 'rxjs/operators';
import { WebViewModalComponent } from '../../../../libs/osapp/src/components/webview/webview-modal/webview-modal.component';
import packageJson from '../../package.json';
import * as constants from '../config';
import { TradeComponentRegister } from '../model/TradeComponentRegister';
import { ETradeEventType } from '../modules/trade-events/models/etrade-event-type';
import { TradeEventsService } from '../modules/trade-events/services/trade-events.service';

@Component({
	selector: "ion-app",
	templateUrl: 'app.component.html'
})
export class AppComponent extends AppComponentBase implements OnInit {

	//#region FIELDS

	private moHomeActivePageManager: ActivePageManager;
	private moGlobalActivePageManager: ActivePageManager;

	//#endregion

	//#region PROPERTIES

	public readonly logSourceId = "TRADE.APP.C::";

	//#endregion

	//#region METHODS

	constructor(
		private isvcLoading: LoadingService,
		private isvcConfig: ConfigService,
		private isvcMail: MailService,
		private isvcMenu: MenuService,
		/** Service de menu angular. */
		private ioMenu: MenuController,
		private isvcUiMessage: UiMessageService,
		private isvcDeeplink: DeeplinkService,
		private isvcEntityLink: EntityLinkService,
		private isvcLogger: LoggerService,
		private readonly isvcPatch: PatchService,
		private readonly isvcFlag: FlagService,
		private readonly isvcPlatform: PlatformService,
		private readonly isvcPwaUpdate: PwaUpdateService,
		private readonly isvcTradeEvent: TradeEventsService,
		private readonly isvcGlobalData: GlobalDataService,
		psvcBattery: BatteryService,
		psvcApplication: ApplicationService,
		psvcPageManager: PageManagerService,
		poRouter: Router,
		psvcConflicts: ConflictsService
	) {
		super(psvcApplication, isvcPlatform, psvcBattery);
		this.isvcDeeplink.init();
		psvcPageManager.addComponents(TradeComponentRegister.getTradeRouteComponents());
		psvcConflicts.initConflictsLogging();

		this.initNotificationCounter();

		this.moHomeActivePageManager = new ActivePageManager(this, poRouter, (psNewUrl: string, psPageUrl: string) => psNewUrl === "/home");
		this.moGlobalActivePageManager = new ActivePageManager(this, poRouter, () => true);
	}

	private initNotificationCounter(): void {
		this.isvcFlag.waitForFlag(EFlag.appAvailable, true).pipe(
			switchMap(() => this.isvcTradeEvent.getUserWaitingParticipationOccurrences$(
				this.moGlobalActivePageManager,
				ETradeEventType.standard,
				{ from: DateHelper.resetDay(new Date) }
			)),
			tap((paOccurrences: BaseEventOccurrence[]) => {
				this.isvcGlobalData.setData(NotificationService.C_NB_OF_NOTIF_DATA_KEY, paOccurrences.length);
			})
		).subscribe();
	}

	public ngOnInit(): void {
		let loLoader: Loader;
		console.log("TRADE.APP.C:: En attente d'initialisation de la plateforme...");

		this.ioMenu.swipeGesture(true);
		this.ioMenu.enable(false);

		this.addModalComponentRegistry();

		constants.environment.version = packageJson.version;

		defer(() => this.isvcPatch.applyPatchesAsync()) // Application des patchs.
			.pipe(
				mergeMap(_ => this.isvcLoading.create("Démarrage de l'application ...")),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => this.isvcConfig.init(constants as IConfig)),
				tap(
					_ => {
						this.initPopovers();
						loLoader.dismiss();
						this.isvcFlag.setFlagValue(EFlag.appInitialized, true);
						this.logAppInitialized();
					},
					poError => this.initError(poError)
				),
				mergeMap(() => this.initRouteSubscriptions()),
				finalize(() => {
					loLoader.dismiss();
				})
			)
			.subscribe();

		this.isvcPwaUpdate.initUpdateCheck();
	}

	/** Envoie à la lib les popovers qui sont disponible pour l'application Trade. */
	private initPopovers(): void {
		const laPopoverItems: Array<IPopoverItem> = [
			{
				id: "changePassword",
				label: "Changer de mot de passe",
				isPinned: true,
				templateId: ELinkTemplate.itemNoLine,
				action: ELinkAction.navigate,
				actionParams: [
					"password",
					"change-password"
				]
			}
		];

		if (!this.isvcPlatform.isIOS || !this.isvcPlatform.isMobile) {
			laPopoverItems.push({
				id: "remarks",
				label: "Vos remarques",
				isPinned: true,
				templateId: ELinkTemplate.itemNoLine,
				action: ELinkAction.callback,
				actionParams: {
					function: () => this.sendFeedback()
				}
			});
		}

		const laCustomPopoverItems = Array.from(laPopoverItems);
		this.isvcMenu.clearPopover();
		this.isvcMenu.setCustomPopoverItem(laCustomPopoverItems);
	}

	private sendFeedback(): void {
		this.isvcMail.sendMail(
			`Remarque au sujet de ${ConfigData.appInfo.appName}`,
			`Bonjour,\n\nJe souhaiterais vous faire part de mes remarques sur ${ConfigData.appInfo.appName}.\n\nCordialement.`,
			{
				needScreenshot: true,
				needLogFile: true,
				to: [ConfigData.appInfo.supportEmail]
			} as IMailOptions
		)
			.pipe(
				catchError(poError => {
					if (poError instanceof NotSupportedPlatformError) {
						this.isvcUiMessage.showMessage(
							new ShowMessageParamsPopup({ message: "L'envoi de mail n'est pas disponible sur cette plateforme.\nVeuillez contacter le support.", header: "Erreur" })
						);
					}
					else
						console.error("TRADE.APP.C:: Erreur envoi feedback : ", poError);

					return EMPTY;
				})
			)
			.subscribe();
	}

	private initError(poError: string | DeviceNotAuthorizedError | any): void {
		console.error(`TRADE.APP.C:: Application initialization error.`, poError);

		//! Nécessaire afin de supprimer le loader : si utilisation de l'instance du loader ou si cette ligne de code est appelée dans le finalize() alors le loader restera.
		this.isvcLoading.dismiss();

		/** Message d'erreur à afficher à l'utilisateur. */
		let lsMessage: string;

		// Si l'erreur reçu est de type `string`, elle est générée par OSApp, on l'affiche directement à l'utilisateur.
		if (typeof poError === "string")
			lsMessage = poError;
		// Si c'est un type d'erreur géré par OSApp, on l'affiche.
		else if (poError instanceof DeviceNotAuthorizedError)
			lsMessage = poError.message;
		else	// Type d'erreur non géré.
			lsMessage = "Une erreur critique s'est produite. Veuillez contacter le support technique.";

		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				message: lsMessage,
				header: "Erreur",
				buttons: [
					{ text: "Quitter", handler: () => ApplicationService.exitApp() } as AlertButton,
					{ text: "Réessayer", handler: () => { console.warn("TRADE.APP.C:: Application will be reloaded."); ApplicationService.reloadApp(); } } as AlertButton
				]
			})
		);
	}

	private initRouteSubscriptions(): Observable<boolean> {
		return this.moHomeActivePageManager.isActive$.pipe(
			mergeMap((pbIsActive: boolean) => {
				if (pbIsActive && !ObjectHelper.isNullOrEmpty(this.isvcEntityLink.currentEntity))
					return this.isvcEntityLink.clearCurrentEntity(this.isvcEntityLink.currentEntity._id);
				return EMPTY;
			})
		);
	}

	private logAppInitialized(): void {
		console.log("TRADE.APP.C::Application initialized");

		const loAppStartPauseLogData: IAppStartPauseLogData = {
			version: ConfigData.appInfo.appVersion!,
			environment: ConfigData.environment.id
		};

		this.isvcLogger.action(
			this.logSourceId,
			"Démarrage de l'application.",
			ELogActionId.appStart,
			loAppStartPauseLogData
		);
	}

	private addModalComponentRegistry(): void {
		ModalComponentsRegistry.add("webview-modal", WebViewModalComponent);
	}

	//#endregion

}