import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DateModule } from '../../components/date/date.module';
import { PipeModule } from '../../pipes/pipes.module';
import { CalaoAvatarModule } from '../avatar/calao-avatar.module';
import { BlankInformationModule } from '../blank-information/blank-information.module';
import { ErrorsModule } from '../errors/errors.module';
import { FilterModule } from '../filter/filter.module';
import { FormModule } from '../forms/form/form.module';
import { HeaderModule } from '../header/header.module';
import { ListsModule } from '../lists/lists.module';
import { ModalModule } from '../modal/modal.module';
import { PickerModule } from '../picker/picker.module';
import { SelectorModule } from '../selector/selector.module';
import { SortModule } from '../sort/sort.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { TabsModule } from '../tabs/tabs.module';
import { VirtualScrollModule } from '../virtual-scroll/virtual-scroll.module';
import { EntityEntriesListBaseComponent } from './components/entity-entries-list-base/entity-entries-list-base.component';
import { EntityEntriesListListItemComponent } from './components/entity-entries-list-list-item/entity-entries-list-list-item.component';
import { EntityEntriesListTabItemComponent } from './components/entity-entries-list-tab-item/entity-entries-list-tab-item.component';
import { EntityEntriesListComponent } from './components/entity-entries-list/entity-entries-list.component';
import { EntityFormComponent } from './components/entity-form/entity-form.component';
import { EntityHeaderComponent } from './components/entity-header/entity-header.component';
import { EntityModalComponent } from './components/entity-modal/entity-modal.component';
import { EntityPickerFilterBarComponentComponent } from './components/entity-picker-modal/components/entity-picker-filter-bar-component/entity-picker-filter-bar-component.component';
import { EntityPickerModalComponent } from './components/entity-picker-modal/entity-picker-modal.component';
import { LayoutComponent } from './components/layout/layout.component';
import { StackLayoutComponent } from './components/stack-layout/stack-layout.component';
import { TabLayoutComponent } from './components/tab-layout/tab-layout.component';

const components: Array<Type<any> | any[]> = [
	TabLayoutComponent,
	LayoutComponent,
	StackLayoutComponent,
	EntityEntriesListComponent,
	EntityFormComponent,
	EntityModalComponent,
	EntityHeaderComponent,
	EntityPickerModalComponent,
	EntityPickerFilterBarComponentComponent,
	EntityEntriesListTabItemComponent,
	EntityEntriesListListItemComponent,
	EntityEntriesListBaseComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		TabsModule,
		IonicModule,
		ListsModule,
		CalaoAvatarModule,
		PipeModule,
		FormModule,
		SpinnerModule,
		BlankInformationModule,
		HeaderModule,
		ErrorsModule,
		ModalModule,
		VirtualScrollModule,
		FilterModule,
		SortModule,
		DateModule,
		PickerModule,
		SelectorModule
	]
})
export class EntitiesModule { }
