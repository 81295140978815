import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { UserData } from 'libs/osapp/src/model/application/UserData';
import { IContact } from 'libs/osapp/src/model/contacts/IContact';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { FormComponent } from '../../../../components/forms/form/form.component';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { IdHelper } from '../../../../helpers/idHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { EPrefix } from '../../../../model/EPrefix';
import { ERouteUrlPart } from '../../../../model/route/ERouteUrlPart';
import { IWorkspace } from '../../../../model/workspaces/IWorkspace';
import { ShowMessageParamsPopup } from '../../../../services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { WorkspaceService } from '../../../../services/workspace.service';
import { Site } from '../../models/site';
import { SiteDetailsModel } from '../../models/site-details-model';
import { SitesService } from '../../services/sites.service';

@Component({
	selector: 'site-details',
	templateUrl: './site-details.component.html',
	styleUrls: ['./site-details.component.scss'],
})
export class SiteDetailsComponent extends ComponentBase implements OnInit {

	//#region PROPERTIES

	/** Modèle du site  */
	@Input() public model: SiteDetailsModel & { $cacheData?: { groupsIds?: string[] } };
	/** Indique si on doit définir le site en tant que site par défaut. */
	public setToDefaultSite: boolean;

	/** Identifiant du descripteur de formulaire des sites. */
	public readonly formDescriptorId = SitesService.C_DEFAULT_SITES_FORMDESC_ID;
	/** Identifiant de la définition de formulaire des sites à utiliser. */
	public formDefinitionId: string;

	public customSubmit: (poModel: SiteDetailsModel, poForm: FormComponent<SiteDetailsModel>) => Observable<any>;

	public get isReadOnly(): boolean {
		const loLastUrlSegment: UrlSegment = ArrayHelper.getLastElement(this.ioRoute.snapshot?.url);
		return loLastUrlSegment?.path !== ERouteUrlPart.edit && loLastUrlSegment?.path !== ERouteUrlPart.new;
	}

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcSites: SitesService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcWorkspace: WorkspaceService,
		private readonly ioRoute: ActivatedRoute,
		private readonly ioRouter: Router
	) {
		super();

		this.model = this.ioRoute.snapshot?.data?.model ?? this.ioRouter.getCurrentNavigation()?.extras.state;
		this.setToDefaultSite = coerceBooleanProperty(this.ioRoute.snapshot?.queryParams?.setToDefaultSite);
	}

	public ngOnInit(): void {
		if (!this.model) // Si pas de paramètre alors mode création.
			this.model = new SiteDetailsModel({ _id: "", name: "", contacts: [] });

		if (!this.model.contacts)
			this.model.contacts = [];

		this.customSubmit = (poModel: SiteDetailsModel, poForm: FormComponent<SiteDetailsModel>) => this.save();

		this.formDefinitionId = this.isReadOnly ? "site_visu" : "site_edit";
	}

	/** Vérifie qu'on peut enregistrer : le nom du site au minimum est renseigné. */
	private canSave(): boolean {
		if (StringHelper.isBlank(this.model.name)) {
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Le nom du site est obligatoire !", buttons: [{ text: "OK" }] }));
			this.model.name = "";
			return false;
		}
		else
			return true;
	}

	/** Enregistre un site (création ou mise à jour). */
	private save(): Observable<any> {

		if (this.canSave()) {
			const laContacts: Array<IContact> = this.model.contacts;
			delete this.model.contacts;

			if (StringHelper.isBlank(this.model._rev)) { // Si pas de révision alors mode création.

				if (!this.model._id.startsWith(EPrefix.site))
					this.model._id = IdHelper.buildId(EPrefix.site);
			};

			return this.updateSite(this.model)
				.pipe(
					tap(() => this.model.contacts = laContacts),
					mergeMap(() => {
						if (this.setToDefaultSite) {
							return this.isvcWorkspace.getWorkspaceDocument(ArrayHelper.getFirstElement(this.isvcWorkspace.getUserWorkspaceIds()))
								.pipe(
									mergeMap((poWorkspace: IWorkspace) => {
										poWorkspace.defaultSiteId = this.model._id;
										UserData.currentSite = this.model;
										return this.isvcWorkspace.saveWorkspace(poWorkspace);
									})
								);
						}
						else
							return of(null);
					})
				);
		}
		else
			return of(null);
	}

	/** Met à jour le site.
	 * @param poSite Site qu'on veut mettre à jour.
	 */
	private updateSite(poSite: Site): Observable<Site> {
		return this.isvcSites.saveSite(poSite)
			.pipe(
				catchError(poError => {
					console.error(`SITE-D.C:: Erreur mise à jour du site '${poSite._id}' : `, poError);
					this.isvcUiMessage.showMessage(
						new ShowMessageParamsPopup({ message: `La mise à jour du site ${poSite.name} a échoué. Veuillez réessayez ultérieurement.` })
					);
					return of(null);
				})
			);
	}

	//#endregion
}