import { EPrefix } from '@calaosoft/osapp/model/EPrefix';
import { EDatabaseRole } from '@calaosoft/osapp/model/store/EDatabaseRole';
import { EPermissionScopes } from "@calaosoft/osapp/modules/permissions/models/epermission-scopes";

//#region PRÉFIXES

/** Préfixe d'id d'un client : "biz_". */
export const C_PREFIX_BUSINESS: EPrefix = "biz_" as EPrefix;
/** Préfixe d'id d'une tournée : "tour_". */
export const C_PREFIX_TOURNEE: EPrefix = "tour_" as EPrefix;
/** Préfixe d'identifiant d'un planning RH : "plan_". */
export const C_PREFIX_PLANNING_RH: EPrefix = "plan_" as EPrefix;
/** Préfixe d'identifiant d'un relevé d'intervention : "inter_". */
export const C_PREFIX_INTER_STATE: EPrefix = "inter_" as EPrefix;
/** Préfixe d'id d'un secteur : "sect_". */
export const C_PREFIX_SECTOR: EPrefix = "sect_" as EPrefix;
/** Préfixe d'id d'une sectorization : "sctrz_". */
export const C_PREFIX_SECTORIZATION: EPrefix = "sctrz_" as EPrefix;

//#endregion

/*#region DATABASE ROLE */

export const C_PLANNING_DB_ROLE: EDatabaseRole = "plannings" as EDatabaseRole;

/*#endregion */

//#region RÔLES

export const C_INTERVENANTS_ROLE_ID = "intervenants";

//#endregion

//#region STYLES

export const C_ICON_TASK_DEFAULT = "task";
export const C_ICON_TASK_DONE = "checkmark-circle";
export const C_ICON_TASK_LATE = "stopwatch";

// Couleurs des items des événements
export const C_PRIMARY_COLOR_EVENT_ITEM = "var(--ion-color-event)";
export const C_SECONDARY_COLOR_EVENT_ITEM = "var(--ion-secondary-color-event)";

// Couleurs des items de tâches
export const C_COLOR_TASK_ITEM = "var(--ion-color-task-tint)";
export const C_COLOR_TASK_PRIORITY_1 = "var(--ion-color-danger-tint)";
export const C_COLOR_TASK_PRIORITY_2 = "var(--ion-color-danger-tint)";
export const C_COLOR_TASK_PRIORITY_3 = "var(--ion-color-task-tint)";
export const C_COLOR_TASK_PRIORITY_4 = "var(--ion-color-task-tint)";
export const C_COLOR_TASK_PRIORITY_5 = "var(--ion-color-success-tint)";

// Couleurs des icônes de tâche selon la priorité
export const C_COLOR_TASK_ICON_PRIORITY_1 = "var(--ion-color-danger)";
export const C_COLOR_TASK_ICON_PRIORITY_2 = "var(--ion-color-danger)";
export const C_COLOR_TASK_ICON_PRIORITY_3 = "var(--ion-color-task)";
export const C_COLOR_TASK_ICON_PRIORITY_4 = "var(--ion-color-task)";
export const C_COLOR_TASK_ICON_PRIORITY_5 = "var(--ion-color-success)";

// Couleurs des différents types d'évènements connus
export const C_COLOR_EVENT_DEFAULT = "var(--ion-color-event-tint)";
export const C_COLOR_EVENT_MEETING = "var(--ion-color-special-event-tint)";
export const C_COLOR_EVENT_CALL = "var(--ion-color-reminder-tint)";
export const C_COLOR_EVENT_APPOINTMENT = "var(--ion-color-reminder-tint)";

// Zone en-tête des items d'évènement
export const C_COLOR_EVENT_ITEM_START = "var(--ion-color-background)";

//#endregion

export const C_PARAMETERS_PERMISSION_ID = "parameters";
export const C_BUSINESS_PERMISSION_ID = "business" as EPermissionScopes;

/** Identifiant utilisé pour renseigner la propriété lastChange */
export const APPLICATION_CLIENT_ID = "trade";

export const C_SMALL_SCREEN_DISPLAY_MAX_WIDTH = 992;
/** Nombre de titre de tâches affichés dans les items Todo. */
export const C_NB_TODO_TASKS_IN_TODO_ITEMS = 3;
/** Nombre de titre d'évènements affichés dans les items Late. */
export const C_NB_LATE_EVENTS_IN_LATE_ITEMS = 3;
/** Nombre de jour à ajouter dans la plage de requêtage au clic sur le bouton "Charger plus". */
export const C_LOAD_MORE_NB_DAYS = 14;
/** Adresse mail du support. */
export const C_SUPPORT_MAIL = "support.trade@calaosoft.fr";
/** Sujet du mail de support. */
export const C_HELP_SUPPORT_MAIL_SUBJECT = "Demande d'aide";
/** Message par défaut du body du mail de demande d'aide. */
export const C_HELP_SUPPORT_MAIL_BODY = "J'ai besoin d'aide concernant l'application Trade\n\rMa demande est la suivante :";
