import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { FormsService } from '../../../../services/forms.service';

/** Champs contenant un lien de type calao-link. */
@Component({
	selector: 'calao-link-field',
	templateUrl: './linkField.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkFieldComponent extends FieldBase implements OnInit { //todo : typer + params.

	//#region METHODS

	constructor(psvcForms: FormsService, poChangeDetectorRef: ChangeDetectorRef) {
		super(psvcForms, poChangeDetectorRef);
	}

	/** Endroit où initialiser le composant après sa création. */
	public override ngOnInit(): void {
		super.ngOnInit();

		if (this.to.data.linkInfo) {

			if (this.to.data.linkInfo.actionParams)
				this.to.data.linkInfo.actionParams.bindingData = this.fieldValue;
			else
				this.to.data.linkInfo.actionParams = { bindingData: this.fieldValue };
		}
		else
			this.to.data.linkInfo = { actionParams: { bindingData: this.fieldValue } };

		this.detectChanges();
	}

	//#endregion
}