import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Type } from '@angular/core';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { register } from 'swiper/element/bundle';
import { ScrollTabsSegmentsComponent } from './components/scroll-tabs-segments/scroll-tabs-segments.component';
import { TabsSegmentComponent } from './components/tabs-segment/tabs-segment.component';
import { TabsSegmentsIconLabelComponent } from './components/tabs-segments-icon-label/tabs-segments-icon-label.component';
import { TabsSegmentsComponent } from './components/tabs-segments/tabs-segments.component';
import { TabsTabContainerComponent } from './components/tabs-tab-container/tabs-tab-container.component';
import { TabsTabComponent } from './components/tabs-tab/tabs-tab.component';
import { TabsComponent } from './components/tabs/tabs.component';

register();

const components: Array<Type<any>> = [
	TabsComponent,
	TabsTabComponent,
	TabsSegmentsComponent,
	ScrollTabsSegmentsComponent,
	TabsSegmentComponent,
	TabsTabContainerComponent,
	TabsSegmentsIconLabelComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		DragScrollComponent,
		DragScrollItemDirective
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TabsModule { }
