import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { DateHelper } from '../../../../../helpers/dateHelper';
import { ETimetablePattern } from '../../../../../model/date/ETimetablePattern';
import { FieldBase } from '../../../models/FieldBase';
import { IDateTimeSpinnerFieldParams } from '../../../models/IDateTimeSpinnerFieldParams';
import { FormsService } from '../../../services/forms.service';

@Component({
	selector: 'calao-date-time-spinner-field',
	templateUrl: './dateTimeSpinnerField.component.html'
})
export class DateTimeSpinnerFieldComponent extends FieldBase<Date> implements OnInit {

	//#region PROPERTIES

	/** Paramètres possibles pour le composant de formulaire DateTimeSpinner. */
	public params: IDateTimeSpinnerFieldParams;

	//#endregion

	//#region METHODS

	constructor(psvcForms: FormsService, poChangeDetector: ChangeDetectorRef) {
		super(psvcForms, poChangeDetector);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.params = this.to.data;

		if (!this.params.displayFormat)
			this.params.displayFormat = ETimetablePattern.dd_MM_yyyy_HH_mm_slash;

		// Application de la valeur par défaut si elle est renseignée.
		timer(0) //! Si pas d'asynchronisme alors une erreur "ExpressionChangedAfterItHasBeenCheckedError" survient.
			.pipe(
				filter(() => !this.fieldValue && DateHelper.isDate(this.params.defaultDate)),
				tap(() => this.fieldValue = new Date(this.params.defaultDate))
			)
			.subscribe();
	}

	public onDateChange(): void {
		this.markAsDirty();
	}

	//#endregion
}