import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { plainToClass } from "class-transformer";
import { Observable, combineLatest, firstValueFrom } from "rxjs";
import { auditTime, filter, map, startWith, take, takeUntil, tap } from "rxjs/operators";
import { ArrayHelper } from "../../../../../../helpers/arrayHelper";
import { DateHelper } from "../../../../../../helpers/dateHelper";
import { ObjectHelper } from "../../../../../../helpers/objectHelper";
import { UserHelper } from "../../../../../../helpers/user.helper";
import { EDateTimePickerMode } from "../../../../../../model/date/EDateTimePickerMode";
import { ETimetablePattern } from "../../../../../../model/date/ETimetablePattern";
import { IDateTimePickerParams } from "../../../../../../model/date/IDateTimePickerParams";
import { ICoordinates } from '../../../../../../model/navigation/ICoordinates';
import { ContactsService } from "../../../../../../services/contacts.service";
import { ShowMessageParamsPopup } from '../../../../../../services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '../../../../../../services/interfaces/ShowMessageParamsToast';
import { LoadingService } from "../../../../../../services/loading.service";
import { UiMessageService } from '../../../../../../services/uiMessage.service';
import { BaseEvent } from "../../../../../calendar-events/models/base-event";
import { BaseEventOccurrence } from "../../../../../calendar-events/models/base-event-occurrence";
import { EventDuration } from "../../../../../calendar-events/models/event-duration";
import { Recurrence } from "../../../../../calendar-events/models/recurrence";
import { CalendarEventsService } from "../../../../../calendar-events/services/calendar-events.service";
import { Contact } from '../../../../../contacts/models/contact';
import { CustomIonCheckboxEvent } from "../../../../../ionic/models/icustom-ion-input-event";
import { Loader } from "../../../../../loading/Loader";
import { EModalSize } from "../../../../../modal";
import { ModalService } from "../../../../../modal/services/modal.service";
import { ObserveProperty } from "../../../../../observable/decorators/observe-property.decorator";
import { ObservableProperty } from "../../../../../observable/models/observable-property";
import { EJobType } from '../../../../../tour-optimization/models/ejob-type';
import { EOptimizationStatusCode } from '../../../../../tour-optimization/models/eoptimization-status-code';
import { OptimizedTourDTO } from '../../../../../tour-optimization/models/optimized-tour-dto';
import { StepDTO } from "../../../../../tour-optimization/models/step-dto";
import { TourOptimizationRequestDTO } from "../../../../../tour-optimization/models/tour-optimization-request-dto";
import { TourOptimizationResultDTO } from "../../../../../tour-optimization/models/tour-optimization-result-dto";
import { TourOptimizationService } from "../../../../../tour-optimization/services/tour-optimization.service";
import { ModelResolver } from "../../../../../utils/models/model-resolver";
import { secure } from "../../../../../utils/rxjs/operators/secure";
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IInlineFieldLayoutParams } from "../../customFields/inline-field-layout/models/iinline-field-layout-params";
import { IInlineField } from "../../customFields/inline-field-layout/models/iinlineField";
import { EventOptmizedModalComponent } from '../../modals/event-optmized-input-modal.component';
import { IEventOptimizedInputModalResult } from '../../models/ievent-optimized-input-modal-result';

interface IPreparedEvent {
	tabEvent: ISimplifiedOptimizableEvent[];
	eventToSet: ISimplifiedOptimizableEvent;
}
interface ISimplifiedOptimizableEvent {
	_id: string,
	startDate: Date,
	endDate?: Date,
	durationMn: number,
	location?: ICoordinates
}
interface IEventDurationFieldComponentParams extends IInlineField {
	hasOptimizationButton: boolean;
	startDateLabel?: string;
	endDateLabel?: string;
	startDateKey?: string;
	readOnly?: boolean;
	fullDayKey?: string;
	latitudeKey: string; // longitude
	longitudeKey: string; // longitude
	placeKey: string;	// place
	/** Indique le modulo à utiliser pour arrondir les minutes par défaut. */
	roundDefaultDateToNextMinutes?: number;
}


@Component({
	selector: 'calao-event-duration-field',
	templateUrl: './event-duration-field.component.html',
	styleUrls: ['./event-duration-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventDurationFieldComponent extends FieldBase<EventDuration> implements OnInit, IInlineField {

	//#region FIELDS
	private static readonly C_LOG_ID = "EVTDURATION.C::";
	// TODO voir les horaires de travail
	private static readonly NAME_RESOURCE = "1";
	private static readonly WORK_HOUR_START = 8;
	private static readonly WORK_HOUR_END = 17;
	private static readonly NO_PLACE_MESSAGE = "Aucun lieu n'a été renseigné pour cet évènement, veuillez le compléter avant de lancer l'optimisation.";
	private static readonly NO_COORDINATES_MESSAGE = "Aucune adresse n'a été renseignée dans votre fiche contact.";

	private moStartDateControl: AbstractControl<Date>;
	private moFullDayControl: AbstractControl<boolean>;

	//#endregion

	//#region PROPERTIES

	public readonly observableStartDateTimePickerParams = new ObservableProperty<IDateTimePickerParams>({
		pickerMode: EDateTimePickerMode.date,
		displayFormat: ETimetablePattern.dd_MMM_yyyy,
		hideIcon: true
	});

	public readonly observableStartTimePickerParams = new ObservableProperty<IDateTimePickerParams>({
		pickerMode: EDateTimePickerMode.time,
		displayFormat: ETimetablePattern.HH_mm,
		hideIcon: true
	});

	public readonly observableEndDateTimePickerParams = new ObservableProperty<IDateTimePickerParams>({
		pickerMode: EDateTimePickerMode.date,
		displayFormat: ETimetablePattern.dd_MMM_yyyy,
		hideIcon: true
	});

	public readonly observableEndTimePickerParams = new ObservableProperty<IDateTimePickerParams>({
		pickerMode: EDateTimePickerMode.time,
		displayFormat: ETimetablePattern.HH_mm,
		hideIcon: true
	});

	public readonly observableStartDate = new ObservableProperty<Date | undefined>();
	public readonly observableEndDate = new ObservableProperty<Date | undefined>();

	public readonly observableDurationReadonlyLabel = new ObservableProperty<string>();

	public params: IEventDurationFieldComponentParams;
	public layout: "inline";
	public layoutParams: IInlineFieldLayoutParams;
	public hideLabelWhenFilled: boolean;

	public readonly observableHasPlace = new ObservableProperty<boolean>();
	public readonly observableUserCoordinates = new ObservableProperty<ICoordinates>();

	/** `true` si la tâche est programmée (timée) sinon `false`. */
	public readonly observableActivation = new ObservableProperty<boolean>().bind(
		this.observableStartDate.value$.pipe(map((pdDate: Date) => !!pdDate)),
		this
	);

	/** `true` si l'évènement est sur la journée complète. */
	public fullDay: boolean;
	@ObserveProperty<EventDurationFieldComponent>({ sourcePropertyKey: "fullDay" })
	public readonly observableFullDay = new ObservableProperty<boolean>();

	//#endregion

	//#region METHODS
	constructor(
		psvcForms: FormsService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcContacts: ContactsService,
		private readonly isvcModal: ModalService,
		private readonly isvcCalendarEvents: CalendarEventsService,
		private readonly isvcTourOptimization: TourOptimizationService,
		private readonly isvcLoading: LoadingService,
	) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.params = this.to.data ?? {};
		this.layout = this.params.layout;
		this.layoutParams = this.params.layoutParams;
		this.hideLabelWhenFilled = this.params.hideLabelWhenFilled;
		this.moStartDateControl = this.form.controls[this.params.startDateKey];
		this.moFullDayControl = this.form.controls[this.params.fullDayKey];

		if (this.moStartDateControl)
			this.initStartDateChanges$().pipe(secure(this)).subscribe();
		else
			console.error(`${EventDurationFieldComponent.C_LOG_ID}The field ${this.params.startDateKey} is not found.`);

		if (this.moFullDayControl)
			this.initFullDayChanges$().pipe(secure(this)).subscribe();
		else
			console.error(`${EventDurationFieldComponent.C_LOG_ID}The field ${this.params.fullDayKey} is not found.`);

		if (this.params.readOnly)
			this.observableDurationReadonlyLabel.bind(this.getReadonlyLabel$(), this);


		this.observableStartDateTimePickerParams.value.label = this.params.startDateLabel;
		this.observableEndDateTimePickerParams.value.label = this.params.endDateLabel;

		this.initObservablePlace();

		this.initObservableUserContact();

	}

	private initObservableUserContact(): void {

		this.isvcContacts.getContact(UserHelper.getUserContactId())
			.pipe(
				tap((poContact: Contact) => this.observableUserCoordinates.value = { latitude: poContact.latitude, longitude: poContact.longitude }
				))
			.subscribe();
	}

	private initObservablePlace(): void {
		const loNameField: AbstractControl<any, any> | undefined = this.form.get(this.params.placeKey);
		if (loNameField) {
			loNameField.valueChanges
				.pipe(
					startWith(!!this.model[this.params.latitudeKey]),
					auditTime(200),
					tap((_) => this.observableHasPlace.value = !!this.model[this.params.latitudeKey]),
					takeUntil(this.destroyed$))
				.subscribe();
		}
	}

	private getReadonlyLabel$(): Observable<string> {
		return combineLatest([this.observableStartDate.value$, this.observableFullDay.value$, this.observableEndDate.value$]).pipe(
			map(([pdStartDate, pbFullDay, pdEndDate]: [Date | undefined, boolean, Date | undefined]) => {
				const leStartPattern: ETimetablePattern = pbFullDay ? ETimetablePattern.EEE_dd_MMMM_yyyy : ETimetablePattern.EEE_dd_MMMM_yyyyy_HH_mm;
				if (!pdStartDate && !pdEndDate)
					return "";
				else {
					if (DateHelper.areDayEqual(pdStartDate, pdEndDate)) {
						if (pbFullDay)
							return `${DateHelper.transform(pdStartDate, leStartPattern)}`;
						return `${DateHelper.transform(pdStartDate, leStartPattern)} à ${DateHelper.transform(pdEndDate, ETimetablePattern.HH_mm)}`;
					}
					else
						return `${DateHelper.transform(pdStartDate, leStartPattern)} au ${DateHelper.transform(pdEndDate, leStartPattern)}`;
				}
			}));
	}

	private initFullDayChanges$(): Observable<boolean> {
		return this.moFullDayControl.valueChanges.pipe(
			startWith(this.moFullDayControl.value),
			tap((pbFullDay?: boolean) => this.fullDay = !!pbFullDay)
		);
	}

	private initStartDateChanges$(): Observable<Date> {
		return this.moStartDateControl.valueChanges.pipe(
			startWith(this.moStartDateControl.value),
			map((pdValue?: Date) => pdValue ? new Date(pdValue) : undefined),
			tap((pdStartDate?: Date) => {
				if (this.required && !pdStartDate) {
					const ldNow = new Date();
					const ldDate: Date = this.params.roundDefaultDateToNextMinutes ? DateHelper.roundMinutes(ldNow, this.params.roundDefaultDateToNextMinutes) : ldNow;
					this.moStartDateControl.setValue(pdStartDate = ldDate);
				}

				if (pdStartDate && !this.fieldValue)
					pdStartDate = DateHelper.roundMinutes(pdStartDate, this.params.roundDefaultDateToNextMinutes);

				if (!DateHelper.isDate(pdStartDate))
					this.fieldValue = undefined;
				else if (!this.fieldValue)
					this.fieldValue = this.getDefaultDuration();


				this.observableStartDate.value = pdStartDate;
				this.observableEndDate.value = pdStartDate ? this.fieldValue.addDurationToDate(new Date(pdStartDate)) : undefined;

				if (pdStartDate) {
					this.observableEndDateTimePickerParams.value = {
						...this.observableEndDateTimePickerParams.value,
						min: pdStartDate.toISOString()
					};
				}
			})
		);
	}

	private getDefaultDuration(): EventDuration {
		return ModelResolver.toClass(EventDuration, this.fieldValue ?? { hours: 1 });
	}

	public onEndDateChanged(pdEndDate: Date): void {
		this.fieldValue = new EventDuration({
			minutes: DateHelper.diffMinutes(pdEndDate, this.observableStartDate.value)
		});
		this.observableEndDate.value = pdEndDate;
	}

	public onStartDateChanged(pdStartDate: Date): void {
		this.moStartDateControl.setValue(pdStartDate);
		this.fieldValue = new EventDuration({
			minutes: DateHelper.diffMinutes(this.observableEndDate.value, pdStartDate)
		});
	}

	public onActivationChanged(pbChecked: boolean) {
		if (!pbChecked) {
			if (this.moStartDateControl)
				this.moStartDateControl.setValue(null);

			// Le modèle ne se met pas à jour si on fait fieldValue = undefined. Il faut donc le faire explicitement pour ne pas enregistrer quelque chose dans duration.
			this.model[this.fieldKey] = undefined;
		}
		else
			this.moStartDateControl.setValue(new Date());
	}

	public onFullDayChanged(poEvent: Event): void {
		const lbFullDay: boolean = (poEvent as CustomIonCheckboxEvent).detail.checked;
		this.fullDay = lbFullDay;
		this.moFullDayControl.patchValue(lbFullDay);
	}

	public async optimizeAsync(): Promise<void> {
		const laOptimizeRequirementsErrorMessages: string[] = [];
		if (!this.observableHasPlace.value)
			laOptimizeRequirementsErrorMessages.push(EventDurationFieldComponent.NO_PLACE_MESSAGE);

		if (ObjectHelper.isEmpty(this.observableUserCoordinates.value))
			laOptimizeRequirementsErrorMessages.push(EventDurationFieldComponent.NO_COORDINATES_MESSAGE);

		if (ArrayHelper.hasElements(laOptimizeRequirementsErrorMessages)) {
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
				message: `<ul>\
${laOptimizeRequirementsErrorMessages.map((psMessage: string) => `<li>${psMessage}</li>`).join("<br/>")}\
</ul>`
			}));
		}
		else {
			// Modal durée & sous combien de temps placer l'event.
			const loModalResult: IEventOptimizedInputModalResult | undefined = await firstValueFrom(this.isvcModal.open({
				component: EventOptmizedModalComponent
			}, EModalSize.medium)
				.pipe(
					filter((poModalResult?: IEventOptimizedInputModalResult) => !!poModalResult),
				));

			return loModalResult ? this.optimizeEventPlacementAsync(loModalResult) : undefined;
		}
	}

	public async optimizeEventPlacementAsync(poInputModalResult: IEventOptimizedInputModalResult): Promise<void> {

		// Loader
		const loLoader: Loader = await this.isvcLoading.create("Optimisation en cours..");
		await loLoader.present();

		// Get events
		const laEvents: BaseEvent<BaseEventOccurrence>[] = await this.getEvt(poInputModalResult.endTw);

		// Create Input
		const loTourInput: TourOptimizationRequestDTO = await this.createInputAsync(laEvents, poInputModalResult);

		// Request TourOptimization
		const loOptimizationResult: TourOptimizationResultDTO = await this.isvcTourOptimization.optimize(loTourInput, "trade").toPromise();

		await loLoader.dismiss();

		// Manage Optimization Result
		return this.manageOptimizationResult(loOptimizationResult, poInputModalResult, laEvents);
	}

	private async createInputAsync(paEvent: BaseEvent<BaseEventOccurrence>[],
		poInput: IEventOptimizedInputModalResult)
		: Promise<TourOptimizationRequestDTO> {

		const loSimplifiedEvents: IPreparedEvent = await this.prepareEventsAsync(paEvent, poInput);

		// faire l'input
		return this.createTourInput(poInput, loSimplifiedEvents);
	}

	private createTourInput(poInput: IEventOptimizedInputModalResult,
		poSimplifiedEvents: IPreparedEvent): TourOptimizationRequestDTO {
		// création de l'input de la tournée avec les evt fixe
		const loTourInput: TourOptimizationRequestDTO = this.createTourInputFromEvent(poInput, poSimplifiedEvents);
		// l'event à placer
		const loPlacedStep: StepDTO = plainToClass(StepDTO, {
			durationMn: poSimplifiedEvents.eventToSet.durationMn,
			id: poSimplifiedEvents.eventToSet._id,
			timeWindows: [],
			coordinates: poSimplifiedEvents.eventToSet.location ? { latitude: poSimplifiedEvents.eventToSet.location.latitude, longitude: poSimplifiedEvents.eventToSet.location.longitude } : { latitude: 46.1318557, longitude: 3.4048566 }
		});
		for (let lnINdex = 0; lnINdex < DateHelper.diffDays(poInput.endTw, new Date()); ++lnINdex) {
			loPlacedStep.timeWindows.push({
				start: DateHelper.addHours(DateHelper.addDays(DateHelper.resetDay(new Date()), lnINdex), EventDurationFieldComponent.WORK_HOUR_START),
				end: DateHelper.addMinutes(DateHelper.addDays(DateHelper.resetDay(new Date()), lnINdex), (EventDurationFieldComponent.WORK_HOUR_END * 60) - (poSimplifiedEvents.eventToSet.durationMn))
			});
		}

		loTourInput.steps.push(loPlacedStep);
		return loTourInput;
	}

	private createTourInputFromEvent(
		poInput: IEventOptimizedInputModalResult,
		poSimplifiedEvents: IPreparedEvent)
		: TourOptimizationRequestDTO {
		const loTourInput: TourOptimizationRequestDTO = this.createTourInputSkeleton();

		// gestion plage de travail
		this.addUserProfileForOptimization(poInput, loTourInput);

		// les events fixe
		this.addFixedEventToTourInput(poSimplifiedEvents, loTourInput);
		return loTourInput;
	}

	private addFixedEventToTourInput(
		poSimplifiedEvents: IPreparedEvent,
		poTourInput: TourOptimizationRequestDTO)
		: void {
		poSimplifiedEvents.tabEvent.forEach(poSimplifiedEvent => {
			// event non-localisé
			if (!poSimplifiedEvent.location || !poSimplifiedEvent.location.latitude) {
				poTourInput.resources[0].breaks.push({
					durationMn: poSimplifiedEvent.durationMn,
					id: poSimplifiedEvent._id,
					timeWindows: [{ start: poSimplifiedEvent.startDate, end: poSimplifiedEvent.startDate }] // c'est normal
				});
			}

			// event localisé
			else {
				poTourInput.steps.push(
					{
						durationMn: poSimplifiedEvent.durationMn,
						id: poSimplifiedEvent._id,
						timeWindows: [{ start: poSimplifiedEvent.startDate, end: poSimplifiedEvent.startDate }], // c'est normal
						coordinates: { latitude: poSimplifiedEvent.location.latitude, longitude: poSimplifiedEvent.location.longitude }
					});
			}
		});
	}

	private createTourInputSkeleton(): TourOptimizationRequestDTO {
		return plainToClass(TourOptimizationRequestDTO, {
			options: {
				distance: true,
				duration: true
			},
			resources: [{
				id: EventDurationFieldComponent.NAME_RESOURCE,
				breaks: [],
				workingRanges: [],
				// TODO il doit y avoir un profil utilisateur ou choisir comment on fait!
				profile: {
					useHighways: true,
					useTolls: true
				}
			}],
			steps: []
		});
	}

	private addUserProfileForOptimization(
		poInput: IEventOptimizedInputModalResult,
		loTourInput: TourOptimizationRequestDTO)
		: void {
		const ldTodayAtMidNight = DateHelper.resetDay(new Date());

		for (let lnINdex = 0; lnINdex <= DateHelper.diffDays(poInput.endTw, new Date()); ++lnINdex) {
			// [0] est normal opti non-multi personne
			loTourInput.resources[0].workingRanges.push({
				// On pourrait mettre un GUUID mais c'est plus clair ainsi
				dayId: `date+${lnINdex}`,
				timeWindow: {
					start: DateHelper.getMax([DateHelper.addHours(DateHelper.addDays(ldTodayAtMidNight, lnINdex), 8), new Date]),
					end: DateHelper.getMax([DateHelper.addHours(DateHelper.addDays(ldTodayAtMidNight, lnINdex), 17), new Date])
				},
				// TODO Domicile/Bureau de l'utilisateur ! => pas de nuit à l'hotel actuellement
				startCoord: { latitude: this.observableUserCoordinates.value.latitude, longitude: this.observableUserCoordinates.value.longitude },
				// On peux préciser des coordonnées de fin de journée ici
				endCoord: { latitude: this.observableUserCoordinates.value.latitude, longitude: this.observableUserCoordinates.value.longitude },
			});
		}
	}

	private generateOccurence(pdStartDate: Date, poReccur: Recurrence, pdEndDate: Date): Date[] {
		return poReccur.generate({ from: pdStartDate, to: pdEndDate });
	};

	private async prepareEventsAsync(
		paEvent: BaseEvent<BaseEventOccurrence>[],
		poInput: IEventOptimizedInputModalResult)
		: Promise<IPreparedEvent> {
		const ldNow: Date = new Date();
		const laOptimizableEvent: ISimplifiedOptimizableEvent[] = await this.simplifyEventsAsync(paEvent, poInput);
		// L'évènement qu'on optimise.
		const loSimplifiedEventToOptimize: ISimplifiedOptimizableEvent = {
			durationMn: poInput.dureeMn,
			endDate: poInput.endTw,
			startDate: ldNow,
			_id: this.model._id,
			location: {
				longitude: this.model[this.params.longitudeKey], latitude: this.model[this.params.latitudeKey]
			}
		};

		const loSimplifiedEventsAndMovingOne: IPreparedEvent = {
			tabEvent: this.getEventsWithoutSuperposition(laOptimizableEvent),
			eventToSet: loSimplifiedEventToOptimize
		};

		return loSimplifiedEventsAndMovingOne;
	}

	private async simplifyEventsAsync(paEvent: BaseEvent<BaseEventOccurrence>[], poInput: IEventOptimizedInputModalResult)
		: Promise<ISimplifiedOptimizableEvent[]> {
		const ldNow: Date = new Date();
		const laOptimizableEvent: ISimplifiedOptimizableEvent[] = [];
		for (let pnIndex = 0; pnIndex < paEvent.length; ++pnIndex) {
			const loEvent = paEvent[pnIndex];

			// Cas recurrences
			if (loEvent.recurrences && loEvent.recurrences.length) {
				// Un event avec pls rec n'est pas gérer!
				// mais impossible dans Trade
				this.generateOccurence(ldNow, loEvent.recurrences[0], poInput.endTw)
					.filter((poEvt: Date) => poEvt > ldNow)
					.forEach((poEvt: Date) => {
						laOptimizableEvent.push({
							_id: loEvent._id,
							startDate: poEvt,
							durationMn: (loEvent.duration.days * 24 * 60) + (loEvent.duration.hours * 60) + loEvent.duration.minutes,
							location: loEvent.latitude ? { latitude: loEvent.latitude, longitude: loEvent.longitude } : undefined
						});
					});
			}

			// Cas sans recurrences
			else {
				const loOcur: BaseEventOccurrence = await this.isvcCalendarEvents.getEventOccurrenceAsync(loEvent._id, ldNow);
				if (loOcur && loOcur.startDate > ldNow) {
					laOptimizableEvent.push({
						_id: loOcur.eventId,
						startDate: loOcur.startDate,
						durationMn: (loEvent.duration.days * 24 * 60) + (loEvent.duration.hours * 60) + loEvent.duration.minutes,
						location: loEvent.latitude ? { latitude: loEvent.latitude, longitude: loEvent.longitude } : undefined
					});
				}
			}
		};
		return laOptimizableEvent;
	}

	private isSuperpositioningEvent(poSourceEvent: ISimplifiedOptimizableEvent, poTargetEvent: ISimplifiedOptimizableEvent): boolean {
		return poSourceEvent._id !== poTargetEvent._id &&
			DateHelper.addMinutes(poSourceEvent.startDate, poSourceEvent.durationMn) > DateHelper.addMinutes(poTargetEvent.startDate, poTargetEvent.durationMn) &&
			poTargetEvent.startDate > poSourceEvent.startDate;
	}

	private getEventsWithoutSuperposition(paOptimizableEvents: ISimplifiedOptimizableEvent[]): ISimplifiedOptimizableEvent[] {
		const laToDeleteEvents: ISimplifiedOptimizableEvent[] = [];

		paOptimizableEvents.forEach((poEvent1: ISimplifiedOptimizableEvent) => {
			paOptimizableEvents.forEach((poEvent2: ISimplifiedOptimizableEvent) => {
				if (this.isSuperpositioningEvent(poEvent1, poEvent2))
					laToDeleteEvents.push(poEvent2);
			});
		});

		return paOptimizableEvents.filter((poEvent: ISimplifiedOptimizableEvent) => !laToDeleteEvents.includes(poEvent));
	}

	private async getEvt(pdEndTw: Date): Promise<BaseEvent<BaseEventOccurrence>[]> {
		const lsUserContactId: string = UserHelper.getUserContactId();
		return this.isvcCalendarEvents.getEvents$({ dateRange: { from: new Date(), to: pdEndTw } })
			.pipe(take(1))
			.toPromise()
			.then(paEvents => paEvents.filter(
				(poEvent: BaseEvent<BaseEventOccurrence>) =>
					poEvent.participantIds.includes(lsUserContactId)
			));
	}

	private manageOptimizationResult(
		poOptimizationResult: TourOptimizationResultDTO,
		poInput: IEventOptimizedInputModalResult,
		paEvents: BaseEvent<BaseEventOccurrence>[]): void {
		// Cas où l'evt n'as pas la place sur la plage
		if (poOptimizationResult.code === EOptimizationStatusCode.invalid) {
			this.isvcUiMessage.showMessage(
				new ShowMessageParamsPopup({
					header: "Optimisation Impossible",
					message: "Vous n'avez pas de place dans votre emploi du temps ou certaines de vos coordonnées GPS ne sont pas valides.",
				}));
		}
		else {
			// Set
			this.setOptimizationResult(poOptimizationResult, poInput, paEvents);
		}
	}

	private setOptimizationResult(
		poOptimizationResult: TourOptimizationResultDTO,
		poModalInput: IEventOptimizedInputModalResult,
		paEvents: BaseEvent<BaseEventOccurrence>[]): void {
		const lsEventId: string = this.model._id;
		const loResultJob: OptimizedTourDTO = poOptimizationResult.resourcesTours[EventDurationFieldComponent.NAME_RESOURCE].find((poStep: OptimizedTourDTO) => poStep.stepId === lsEventId && poStep.type === EJobType.job);
		const loResultJobHour: Date = DateHelper.addMinutes(loResultJob.date, loResultJob.waitingDurationMn);


		this.observableStartDate.value = loResultJobHour;
		this.onStartDateChanged(loResultJobHour);

		const ldEndDate: Date = DateHelper.addMinutes(loResultJobHour, poModalInput.dureeMn);
		this.observableEndDate.value = ldEndDate;
		this.onEndDateChanged(ldEndDate);


		this.presentToastOptimizationResult(poOptimizationResult, paEvents, loResultJob.index);
	}

	private presentToastOptimizationResult(
		poOptimizationResult: TourOptimizationResultDTO,
		paEvent: BaseEvent<BaseEventOccurrence>[],
		pnPlacedJobIndex: number): void {
		const loJobs: OptimizedTourDTO[] = poOptimizationResult.resourcesTours[EventDurationFieldComponent.NAME_RESOURCE];

		const lePreviousJobTtype: EJobType | undefined = loJobs[pnPlacedJobIndex - 1]?.type;
		const leNextJobTtype: EJobType | undefined = loJobs[pnPlacedJobIndex + 1]?.type;

		const loPreviousJob: OptimizedTourDTO | undefined =
			lePreviousJobTtype === EJobType.job || lePreviousJobTtype === EJobType.break ?
				loJobs[pnPlacedJobIndex - 1] : undefined;

		const loNextJob: OptimizedTourDTO | undefined =
			leNextJobTtype === EJobType.job || leNextJobTtype === EJobType.break ?
				loJobs[pnPlacedJobIndex + 1] : undefined;

		let lsMessage: string;
		if (loPreviousJob && loNextJob) {
			lsMessage = `L'évènement a été placé entre "${paEvent.find((poEvent: BaseEvent<BaseEventOccurrence>) => poEvent._id === loPreviousJob.stepId)?.title}" et "${paEvent.find((poEvent: BaseEvent<BaseEventOccurrence>) => poEvent._id === loNextJob.stepId)?.title}."`;
		}
		else if (!loPreviousJob && !loNextJob) {
			lsMessage = `L'évènement a été placé le plus tôt possible`;
		}
		else if (!loPreviousJob && loNextJob) {
			lsMessage = `L'évènement a été placé avant "${paEvent.find((poEvent: BaseEvent<BaseEventOccurrence>) => poEvent._id === loNextJob.stepId)?.title}."`;
		}
		else {
			lsMessage = `L'évènement a été placé après "${paEvent.find((poEvent: BaseEvent<BaseEventOccurrence>) => poEvent._id === loPreviousJob.stepId)?.title}."`;
		}

		this.isvcUiMessage.showToastMessage(new ShowMessageParamsToast(
			{
				message: lsMessage,
				position: "bottom",
				duration: 3000
			}
		));
	}

	public onFieldClicked(poEvent: Event): void {
		if (!this.observableActivation.value && (poEvent.currentTarget as HTMLElement).parentNode.nodeName === "CALAO-INLINE-FIELD-LAYOUT" &&
			(poEvent.target as HTMLElement).nodeName !== "ION-TOGGLE") {
			this.moStartDateControl.setValue(new Date());
			this.observableActivation.value = true;
		}
	}

	//#endregion
}
