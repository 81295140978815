import { Inject, Injectable, InjectionToken, Injector, Optional, Type } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { DateHelper } from '../../../helpers/dateHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { EPrefix } from '../../../model/EPrefix';
import { EntityLinkService } from '../../../services/entityLink.service';
import { WorkspaceService } from '../../../services/workspace.service';
import { Entity } from '../../entities/models/entity';
import { EntityLink } from '../../entities/models/entity-link';
import { EntityLinkEntity } from '../../entities/models/entity-link-entity';
import { EntitiesService } from '../../entities/services/entities.service';
import { IDmsMeta } from './IDmsMeta';
import { IMetaEntityBuilderBase } from './IMetaEntityBuilderBase';
import { MetaEntityBuilderBase } from './MetaEntityBuilderBase';

export const CONV_META_CONFIG = new InjectionToken<Type<IMetaEntityBuilderBase>[]>("CONV_META_CONFIG");

@Injectable()
export class MetaConversationBuilder extends MetaEntityBuilderBase {

	//#region FIELDS

	private maBuilders?: IMetaEntityBuilderBase[];

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		private readonly ioInjector: Injector,
		psvcEntities: EntitiesService,
		psvcWorkspace: WorkspaceService,
		// On injecte directement les types car on ne peut pas appeler le service dms-meta sans créer de cycle.
		@Inject(CONV_META_CONFIG) @Optional() private readonly iaBuildersTypes?: Type<IMetaEntityBuilderBase>[]
	) {
		super(psvcWorkspace, psvcEntities);
	}

	/** Déclenche l'injection des builders. */
	private initBuilders(): void {
		if (this.iaBuildersTypes) {
			// On supprime les valeurs undefined du tableau pour fonctionner même si il manque des providers.
			this.maBuilders = ArrayHelper.getValidValues(this.iaBuildersTypes.map((poType: Type<IMetaEntityBuilderBase>) => {
				try {
					return this.ioInjector.get(poType);
				}
				catch (poError) {
					console.error("CONV.META.BUILDER::Erreur lors de l'initialisation du DmsMetaService. Des providers sont manquants pour ce builder.", poType, poError);
					return undefined;
				}
			}));
		}
		else
			this.maBuilders = [];
	}

	public override match(psDocumentId: string): boolean {
		return IdHelper.hasPrefixId(psDocumentId, EPrefix.conversation);
	}

	public override async prepareMeta(poEntity: Entity, poMeta: IDmsMeta): Promise<IDmsMeta> {
		if (!this.maBuilders)
			this.initBuilders();

		const laEntityLinks: EntityLink[] = await firstValueFrom(this.isvcEntityLink.getEntityLinks(poEntity._id));

		laEntityLinks.sort((poEntityA: EntityLink, poEntityB: EntityLink) =>
			DateHelper.compareTwoDates(poEntityA.creationDate, poEntityB.creationDate)
		);

		const loFirstLink: EntityLink | undefined = ArrayHelper.getFirstElement(laEntityLinks);

		if (loFirstLink) {
			// On ne gère qu'un seul élèment pour rétrocompat
			const loTargetEntity: EntityLinkEntity | undefined =
				ArrayHelper.getFirstElement(loFirstLink.getTargetEntitiesBySourceId(poEntity._id));
			if (loTargetEntity) {
				const loLinkedEntity: Entity | undefined = await this.isvcEntities.getModelAsync(loTargetEntity.id, loTargetEntity.databaseId);
				let loMeta: IDmsMeta;

				if (loLinkedEntity) {
					const loBuilder: IMetaEntityBuilderBase | undefined = this.maBuilders?.find((poBuilder: IMetaEntityBuilderBase) => poBuilder.match(loLinkedEntity._id));

					if (loBuilder)
						loMeta = await loBuilder.prepareMeta(loLinkedEntity, poMeta);
					else
						loMeta = await super.prepareMeta(loLinkedEntity, poMeta);

					if (loMeta.attributes)
						loMeta.attributes.push({ name: "convId", value: poEntity._id });

					return loMeta;
				}
			}


		}
		return super.prepareMeta(poEntity, poMeta);
	}

	//#endregion

}
