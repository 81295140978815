export abstract class PathHelper {

	//#region FIELDS

	public static readonly C_PATH_SEPARATOR = "/";
	public static readonly C_DATABASE_PATH_SEPARATOR = "\\";
	public static readonly C_PARSE_REGEX = new RegExp(`${this.C_DATABASE_PATH_SEPARATOR}${this.C_DATABASE_PATH_SEPARATOR}`, "g"); // On le double car un des \ sert à échapper dans le cas de la regex
	public static readonly C_PREPARE_REGEX = new RegExp(this.C_PATH_SEPARATOR, "g");


	//#endregion FIELDS

	//#region METHODS

	/** Traduit un chemin sauvegardé pour pouvoir le manipuler plus facilement.
	 * @param psPath
	 */
	public static parsePath(psPath: string): string {
		return psPath.replace(PathHelper.C_PARSE_REGEX, PathHelper.C_PATH_SEPARATOR);
	}

	/** Met en forme un chemin avant sauvegarde.
	 * @param psPath
	 */
	public static preparePath(psPath: string): string {
		return psPath.replace(PathHelper.C_PREPARE_REGEX, PathHelper.C_DATABASE_PATH_SEPARATOR);
	}

	/** Met en forme un chemin type base de données pour une regex.
	 * @param psPath
	 */
	public static preparePathForRegex(psPath: string): string {
		return psPath.replace(
			PathHelper.C_PREPARE_REGEX,
			`${PathHelper.C_DATABASE_PATH_SEPARATOR}${PathHelper.C_DATABASE_PATH_SEPARATOR}`
		);
	}

	//#endregion

}
