import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { defer, firstValueFrom, Observable } from "rxjs";
import { ConfigData } from "../../../../../model/config/ConfigData";
import { PlatformService } from "../../../../../services/platform.service";
import { UiMessageService } from "../../../../../services/uiMessage.service";
import { ModalComponentBase } from "../../../../modal";
import { ObservableProperty } from "../../../../observable/models/observable-property";
import { Queue } from "../../../../utils/queue/decorators/queue.decorator";
import { EJitsiButton } from "../../models/Ejitsi-button";
import { JitsiOptions } from "../../models/IJitsi-options";

declare const JitsiMeetExternalAPI: any;

@Component({
	selector: "jitsi",
	templateUrl: 'jitsi-modal.component.html',
	styleUrls: ["jitsi-modal.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class JitsiModalComponent extends ModalComponentBase<void> implements OnInit {

	//#region PROPERTIES

	private readonly msDomain = ConfigData.conversation?.visioApiUrl;

	private readonly moObservableJitsiApi = new ObservableProperty<any>();

	//#endregion PROPERTIES

	@Input() public name: string;
	@Input() public room: string;

	//#region METHODS

	constructor(
		private readonly isvcUiMessage: UiMessageService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
	) {
		super(poModalCtrl, psvcPlatform);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		const loElement: Element | null = document.querySelector('#meeting');

		if (loElement)
			this.moObservableJitsiApi.value = new JitsiMeetExternalAPI(this.msDomain, this.getOptions(loElement));
	}

	public onHangupAsync(): Promise<boolean> {
		return firstValueFrom(this.hangup$());
	}

	@Queue<JitsiModalComponent, Parameters<JitsiModalComponent["hangup$"]>, ReturnType<JitsiModalComponent["hangup$"]>>({
		excludePendings: true
	})
	private hangup$(): Observable<boolean> {
		if (this.moObservableJitsiApi.value) {
			this.moObservableJitsiApi.value.executeCommand('hangup');
			this.moObservableJitsiApi.value.dispose();
		}

		return defer(() => this.close());
	}

	public getOptions(poElement: Element): JitsiOptions {
		return {
			parentNode: poElement,
			roomName: this.room,
			// On met à 100% pour laisser la logique de taille au css du parent
			width: "100%",
			height: "100%",
			configOverwrite: {
				prejoinPageEnabled: false,
				prejoinConfig: {
					enabled: false,
				},

				// Specify the settings for video quality optimizations on the client.
				videoQuality: {
					minHeightForQualityLvl: {
						150: 'standard',
						300: 'high',
					},
				},
				maxFullResolutionParticipants: 3,
				enableLobbyChat: false,
				LANG_DETECTION: false,
				defaultLanguage: 'fr',
				hideAddRoomButton: true,
				enableWelcomePage: false,
				disableShortcuts: true,
				enableClosePage: false,
				defaultLocalDisplayName: 'Moi',
				toolbarButtons: [
					EJitsiButton.camera,
					EJitsiButton.microphone,
					EJitsiButton.togglecamera,
				],
				rtcstatsEnabled: true,
				rtcstatsStoreLogs: true,
				hideLogo: true,
				// Sets the conference subject
				subject: 'CalaoLive',

				// Sets the conference local subject
				// localSubject: 'Conference Local Subject',
				disableDeepLinking: true,
				SHOW_JITSI_WATERMARK: false,
			},
			interfaceConfigOverwrite: {
				DEFAULT_BACKGROUND: '#141414',
				DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
				DISABLE_FOCUS_INDICATOR: true,
				defaultLanguage: 'fr',
				LANG_DETECTION: false,
				prejoinPageEnabled: false,
				ENFORCE_NOTIFICATION_AUTO_DISMISS_TIMEOUT: 1,
				disableDeepLinking: true,
				MOBILE_APP_PROMO: false,
				SHOW_JITSI_WATERMARK: false,
				HIDE_DEEP_LINKING_LOGO: true,
				SHOW_BRAND_WATERMARK: false,
				SHOW_WATERMARK_FOR_GUESTS: false
			},
			disabledSounds: [],
			userInfo: {
				displayName: this.name,
			},
		};
	}

	//#endregion METHODS

}
