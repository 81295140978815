import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { StringHelper } from "../../../helpers/stringHelper";
import { PageInfo } from "../../../model/PageInfo";
import { ModalComponentBase } from "../../../modules/modal";
import { PlatformService } from "../../../services/platform.service";

@Component({
	selector: 'webViewModal',
	templateUrl: './webview-modal.component.html',
	styleUrls: ['./webview-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebViewModalComponent extends ModalComponentBase<undefined> implements OnInit {

	//#region PROPERTIES

	private readonly C_LOG_ID = "WVM.C::";

	//#endregion PROPERTIES

	//#region PROPERTIES

	@Input("title")
	public title: string = "";

	@Input("source")
	public source?: string;

	@Input("sandboxOptions")
	public sandboxOptions: string[] = [];

	@Input("cssClass")
	public cssClass?: string;

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioRoute: ActivatedRoute,
		isvcPlatform: PlatformService,
		poModalCtrl: ModalController,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, isvcPlatform, poChangeDetector);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		const loPageInfo = new PageInfo({
			title: this.title,
			componentName: "webview",
			isClosable: true,
			isModal: true,
			cssId: "contact-selector-modal",
			params: {
				source: this.source,
				cssClass: this.cssClass ?? "full-size",
				sandboxOptions: this.sandboxOptions
			},
		});

		if (!StringHelper.isValid(this.source)) {
			console.error(`${this.C_LOG_ID} Impossible de générer un la webview. Il lui faut une source.`);
		}

		this.ioRoute.snapshot.data.pageInfo = loPageInfo;
	}

	//#endregion

}