<div *ngIf="!params.displayType || params.displayType === 'normal'" lines="none" class="contacts-list">
	<ion-grid class="pad-contacts">
		<ion-row class="dis-flex flex-col">
			<ion-col *ngIf="params.title" class="ion-no-padding txt-left" size="4">
				<div class="title-group">{{ params.title }}</div>
			</ion-col>
			<ion-col size="12" class="ion-no-padding">
				<ion-list *ngIf="!params.useTags" class="ion-no-padding dis-flex flex-col">
					<ng-container *ngFor="let item of contactsWithAvailabilityAndAvatar">
						<ng-container *ngIf="item.contact; else notAvailable">
							<ion-item lines="none" *ngIf="contactDisplayProperty.picture && item.avatar">
								<avatar [src]="item.avatar"></avatar>
							</ion-item>

							<ion-item class="ion-no-padding member-height"
								[lines]="params.disbaleRouteToContact || params.readOnly ? 'none' : ''">
								<ion-icon *ngIf="contactDisplayIconName.name" [name]="contactDisplayIconName.name"></ion-icon>
								<ion-label [class]="params.disbaleRouteToContact ? '' : 'txt-contacts-list pointer'"
									data-cy="contact-identity" (click)="route(item.contact)">
									{{ item.contact | contactName }}
								</ion-label>
								<ion-button slot="end" *ngIf="!params.readOnly && !params.hideRemoveBtn"
									(click)="remove(item.contact._id)" color="danger">
									<ion-icon name="close" shape="round"></ion-icon>
								</ion-button>
							</ion-item>

							<ion-item lines="none" *ngIf="contactDisplayProperty.address" class="ion-no-padding">
								<ion-icon *ngIf="contactDisplayIconName.address" [name]="contactDisplayIconName.address"></ion-icon>
								<ion-label>
									{{ item.contact | contactAddress }}
								</ion-label>
							</ion-item>

							<ion-item lines="none" *ngIf="contactDisplayProperty.birthDate" class="ion-no-padding">
								<ion-label>
									<ion-icon *ngIf="contactDisplayIconName.birthDate" [name]="contactDisplayIconName.birthDate">
									</ion-icon>
									{{ item.contact.birthDate | dateWithLocale: "fr-FR" : "dd MMM yyyy" }}
								</ion-label>
							</ion-item>
 
							<ion-item lines="none" *ngIf="contactDisplayProperty.email" class="ion-no-padding">
								<ion-label>
									<ion-icon *ngIf="contactDisplayIconName.email" [name]="contactDisplayIconName.email"></ion-icon>
									<a href="mailto:{{ item.contact.email }}">{{ item.contact.email }}</a>
								</ion-label>
							</ion-item>

							<ion-item lines="none" *ngIf="contactDisplayProperty.phone" class="ion-no-padding">
								<ion-label>
									<ion-icon *ngIf="contactDisplayIconName.phone" [name]="contactDisplayIconName.phone"></ion-icon>
									<a href="tel:{{ item.contact.phone }}"> {{ item.contact.phone }}</a>
								</ion-label>
							</ion-item>

							<ion-item lines="none" *ngIf="contactDisplayProperty.profession" class="ion-no-padding">
								<ion-label>
									<ion-icon *ngIf="contactDisplayIconName.profession" [name]="contactDisplayIconName.profession">
									</ion-icon>
									{{ item.contact.profession }}
								</ion-label>
							</ion-item>
						</ng-container>
						<ng-template #notAvailable>
							<ion-item lines="none" *ngIf="!item.isAvailable">
								<ion-label>
									Vous n'avez pas accès à cette information.
								</ion-label>
							</ion-item>
						</ng-template>
					</ng-container>


					<ion-button slot="end" *ngIf="addButtonVisible" (click)="selectContacts()" size="default"
						[color]="params.addButtonColor" class="button-fit-conten" data-cy="contact-selector-button">
						<ion-icon *ngIf="params.addButtonIcon" [name]="params.addButtonIcon" slot="start">
						</ion-icon>
						{{ params.addButtonText }}
					</ion-button>
				</ion-list>


				<div *ngIf="params.useTags">

					<ion-label *ngIf="contactsWithAvailabilityAndAvatar.length === 1" slot="end" class="user-selector"
						(click)="selectContacts()" size="default" [color]="params.addButtonColor">
						{{ contactsWithAvailabilityAndAvatar[0].contact | contactName }}
					</ion-label>

					<ion-label *ngIf="!(contactsWithAvailabilityAndAvatar.length === 1)" slot="end" class="user-selector"
						(click)="selectContacts()" size="default" [color]="params.addButtonColor">
						{{ params.addButtonText }}
					</ion-label>

					<div *ngIf="!(contactsWithAvailabilityAndAvatar.length === 1)" class="tags">
						<ng-container *ngFor="let item of contactsWithAvailabilityAndAvatar">
							<ng-container *ngIf="item.contact; else notAvailable">

								<ion-button shape="round" size="small" *ngIf="!params.readOnly && !params.hideRemoveBtn"
									(click)="remove(item.contact._id)" class="tag">
									<ion-label [class]="params.disbaleRouteToContact ? '' : 'txt-contacts-list pointer'"
										data-cy="contact-identity" (click)="route(item.contact)">
										{{ item.contact | contactName }}
									</ion-label>
									<ion-icon name="close"></ion-icon>
								</ion-button>

							</ng-container>
							<ng-template #notAvailable>
								<ion-item lines="none" *ngIf="!item.isAvailable">
									<ion-label>
										Vous n'avez pas accès à cette information.
									</ion-label>
								</ion-item>
							</ng-template>
						</ng-container>
					</div>



				</div>

				<ion-label *ngIf="params.emptyText && params.readOnly && !contactsWithAvailabilityAndAvatar.length"
					class="no-contacts">{{ params.emptyText }}</ion-label>
			</ion-col>
		</ion-row>
	</ion-grid>
</div>