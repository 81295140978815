import { ChangeDetectorRef, Component } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Observable, of } from "rxjs";
import { finalize, switchMap, take } from "rxjs/operators";
import { EntityLinkService } from "../../../services/entityLink.service";
import { PlatformService } from "../../../services/platform.service";
import { ModalComponentBase } from "../../modal";
import { ObservableProperty } from "../../observable/models/observable-property";
import { Queue } from "../../utils/queue/decorators/queue.decorator";
import { secure } from "../../utils/rxjs/operators/secure";
import { Entity } from "./entity";

@Component({ template: "" })
export class CurrentEntityTrackerModal extends ModalComponentBase<Entity | undefined> {

	//#region FIELDS

	protected readonly moObservableCurrentEntitySet = new ObservableProperty<boolean>();
	protected readonly moObservableEntity = new ObservableProperty<Entity | undefined>();

	//#endregion FIELDS

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public ionViewDidEnter(): void {
		this.setCurrentEntity$().subscribe();
	}

	public ionViewWillLeave(): void {
		this.clearCurrentEntity$().subscribe();
	}

	private setCurrentEntity$(): Observable<boolean> {
		return this.manipulateCurrentEntity$("set").pipe(
			finalize(() => this.moObservableCurrentEntitySet.value = true),
			secure(this)
		);
	}

	private clearCurrentEntity$(): Observable<boolean> {
		return this.manipulateCurrentEntity$("clear").pipe(
			finalize(() => this.moObservableCurrentEntitySet.value = false),
			secure(this)
		);
	}

	@Queue<
		CurrentEntityTrackerModal,
		Parameters<CurrentEntityTrackerModal["manipulateCurrentEntity$"]>,
		ReturnType<CurrentEntityTrackerModal["manipulateCurrentEntity$"]>
	>()
	private manipulateCurrentEntity$(psAction: "set" | "clear"): Observable<boolean> {
		return this.moObservableEntity.value$.pipe(
			take(1),
			switchMap((poEntity?: Entity) => {
				if (poEntity) {
					switch (psAction) {
						case "clear":
							return this.isvcEntityLink.clearCurrentEntity(poEntity._id);

						case "set":
							return this.isvcEntityLink.trySetCurrentEntity(poEntity);
					}
				}
				return of(true);
			})
		);
	}

	//#endregion METHODS

}
