/* eslint-disable max-lines */
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { classToPlain } from 'class-transformer';
import { EMPTY, Observable, Subject, combineLatest, defer, forkJoin, from, fromEvent, merge, of, throwError } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, finalize, map, mapTo, mergeMap, reduce, retryWhen, startWith, switchMap, take, tap, toArray } from 'rxjs/operators';
import { ConversationHelper } from '../helpers/ConversationHelper';
import { ArrayHelper } from '../helpers/arrayHelper';
import { ContactHelper } from '../helpers/contactHelper';
import { DateHelper } from '../helpers/dateHelper';
import { GuidHelper } from '../helpers/guidHelper';
import { IdHelper } from '../helpers/idHelper';
import { MapHelper } from '../helpers/mapHelper';
import { ObjectHelper } from '../helpers/objectHelper';
import { StoreDocumentHelper } from '../helpers/storeDocumentHelper';
import { StoreHelper } from '../helpers/storeHelper';
import { StringHelper } from '../helpers/stringHelper';
import { UserHelper } from '../helpers/user.helper';
import { EPrefix } from '../model/EPrefix';
import { IIndexedArray } from '../model/IIndexedArray';
import { EApplicationEventType } from '../model/application/EApplicationEventType';
import { ENetworkFlag } from '../model/application/ENetworkFlag';
import { UserData } from '../model/application/UserData';
import { ConfigData } from '../model/config/ConfigData';
import { EContactsType } from '../model/contacts/EContactsType';
import { IContact } from '../model/contacts/IContact';
import { IGroup } from '../model/contacts/IGroup';
import { IGroupMember } from '../model/contacts/IGroupMember';
import { EActivityStatus } from '../model/conversation/EActivityStatus';
import { EConversationError } from '../model/conversation/EConversationError';
import { EConversationEvent } from '../model/conversation/EConversationEvent';
import { EConversationType } from '../model/conversation/EConversationType';
import { IConversation } from '../model/conversation/IConversation';
import { IConversationActivity } from '../model/conversation/IConversationActivity';
import { IConversationCacheData } from '../model/conversation/IConversationCacheData';
import { IConversationEvent } from '../model/conversation/IConversationEvent';
import { IConversationUiEvent } from '../model/conversation/IConversationUiEvent';
import { IGetConversationOptions } from '../model/conversation/IGetConversationOptions';
import { ILocalConversation } from '../model/conversation/ILocalConversation';
import { IMessage } from '../model/conversation/IMessage';
import { IOpenConversationOptions } from '../model/conversation/IOpenConversationOptions';
import { IParticipant } from '../model/conversation/IParticipant';
import { IParticipantIndicator } from '../model/conversation/IParticipantIndicator';
import { IReadIndicator } from '../model/conversation/IReadIndicator';
import { IActionButtonFieldMeetingParams } from '../model/forms/actionButtonFields/IActionButtonFielMeetingParams';
import { EAvatarSize } from '../model/picture/EAvatarSize';
import { IAvatar } from '../model/picture/IAvatar';
import { ERouteUrlPart } from '../model/route/ERouteUrlPart';
import { ESecurityFlag } from '../model/security/ESecurityFlag';
import { Database } from '../model/store/Database';
import { EDatabaseRole } from '../model/store/EDatabaseRole';
import { EStoreFlag } from '../model/store/EStoreFlag';
import { IChangeEvent } from '../model/store/IChangeEvent';
import { IDataSource } from '../model/store/IDataSource';
import { IStoreDataResponse } from '../model/store/IStoreDataResponse';
import { IStoreDocument } from '../model/store/IStoreDocument';
import { IStoreReplicationOptions } from '../model/store/IStoreReplicationOptions';
import { IStoreReplicationResponse } from '../model/store/IStoreReplicationResponse';
import { IStoreReplicationToLocalResponse } from '../model/store/IStoreReplicationToLocalResponse';
import { IStoreReplicationToServerResponse } from '../model/store/IStoreReplicationToServerResponse';
import { EReplicationStyle } from '../model/store/ereplication-style.enum';
import { IWorkspaceInfo } from '../model/workspaces/IWorkspaceInfo';
import { IContactsPickerParams } from '../modules/contacts/models/contacts-picker/icontacts-picker-params';
import { EConversationSelectorModalAction } from '../modules/conversations/components/createConversation/models/econversation-selector-modal-action';
import { ICreateConversationModalResponse } from '../modules/conversations/components/createConversation/models/icreate-conversation-selector-modal-response';
import { CreateConversationSelectorModalOpenerService } from '../modules/conversations/components/createConversation/services/create-conversation-selector-modal-opener.service';
import { Conversation } from '../modules/conversations/model/conversation';
import { Message } from '../modules/conversations/model/message';
import { MessageAttachment } from '../modules/conversations/model/message-attachment';
import { MessageEntityAttachment } from '../modules/conversations/model/message-entity-attachment';
import { MessageFileAttachment } from '../modules/conversations/model/message-file-attachment';
import { Entity } from '../modules/entities/models/entity';
import { EntityLink } from '../modules/entities/models/entity-link';
import { EntityLinkEntity } from '../modules/entities/models/entity-link-entity';
import { IEntity } from '../modules/entities/models/ientity';
import { IActionButtonFieldParams } from '../modules/forms/models/actionButtonFields/IActionButtonFieldParams';
import { Loader } from '../modules/loading/Loader';
import { ELogActionId } from '../modules/logger/models/ELogActionId';
import { LoggerService } from '../modules/logger/services/logger.service';
import { PerformanceManager } from '../modules/performance/PerformanceManager';
import { HasPermissions } from '../modules/permissions/decorators/has-permissions.decorator';
import { EPermissionsFlag } from '../modules/permissions/models/EPermissionsFlag';
import { EPermissionScopes } from '../modules/permissions/models/epermission-scopes';
import { IHasPermission, PermissionsService } from '../modules/permissions/services/permissions.service';
import { DestroyableServiceBase } from '../modules/services/models/destroyable-service-base';
import { IDataSourceRemoteChanges } from '../modules/store/model/IDataSourceRemoteChanges';
import { ModelResolver } from '../modules/utils/models/model-resolver';
import { Queue } from '../modules/utils/queue/decorators/queue.decorator';
import { ContactsService } from './contacts.service';
import { EntityLinkService } from './entityLink.service';
import { FlagService } from './flag.service';
import { GalleryService } from './gallery.service';
import { GlobalDataService } from './global-data.service';
import { GroupsService } from './groups.service';
import { ShowMessageParamsPopup } from './interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from './interfaces/ShowMessageParamsToast';
import { ICreateConversationOptions } from './interfaces/icreate-conversation-options';
import { LoadingService } from './loading.service';
import { NetworkService } from './network.service';
import { Store } from './store.service';
import { UiMessageService } from './uiMessage.service';

interface IDeleteResults {
	code?: EConversationError;
	message: string;
}

interface IDeleteError {
	error: IDeleteResults;
	canContinue: boolean;
}

interface IParticipantDetails {
	/** Avatar du participant. */
	avatar?: IAvatar;
	/** Activité du participant. */
	activity?: IConversationActivity;
	/** Modèle du participant. */
	model?: IContact | IGroup;
}
interface IUpdateConversationDetails {
	/** Tableau d'objets permettant de retrouver l'avatar et l'activité d'un participant. */
	participantDetails: IParticipantDetails[];
	/** Nombre de participants dans la conversation avant l'ajout des nouveaux (évolue avec des suppressions). */
	participantsLengthBeforeAdd: number;
}
/** Objet qui correspond aux informations d'un nouvel invité d'une conversation. */
interface IParticipantInfo {
	member: IContact | IGroup;
	participant: IParticipant;
}
interface IContactPathsAndGroupIds {
	contactPaths: string[];
	groupIds: string[];
}
interface IAddConversationParticipantsResult {
	readonly newParticipantInfos: IParticipantInfo[];
	readonly participantsDetails: IParticipantDetails[];
}

/** Service de gestion des conversations. */
@Injectable({ providedIn: "root" })
export class ConversationService extends DestroyableServiceBase implements IHasPermission, OnDestroy {

	//#region FIELDS

	/** Id de la liste des conversations. */
	private static readonly C_CONVERSATIONS = "conversations";
	private static readonly C_LOG_ID = "CONV.S::";

	/** Sujet pour l'envoi d'événement d'application. */
	private moAppEventSubject: Subject<IConversationEvent> = new Subject();
	/** Sujet pour notifier que des changements d'ui sont nécessaires. */
	private moConversationUiSubject: Subject<IConversationUiEvent> = new Subject();

	//#endregion

	//#region PROPERTIES

	public static readonly C_NB_OF_UNREAD_CONV_DATA_KEY = "nbOfUnreadConv";

	/** Base de données de conversation. */
	private moConversationDatabase: Database;
	/** Base de données de conversation. */
	public get conversationDatabase(): Database {
		if (!this.moConversationDatabase || this.moConversationDatabase.isClosed) // Singleton
			this.moConversationDatabase = this.isvcStore.getDatabaseById(this.databaseId);

		return this.moConversationDatabase;
	}

	private msDatabaseId: string;
	/** Id de la base de données de conversation. */
	public get databaseId(): string {
		return !StringHelper.isBlank(this.msDatabaseId) ?
			this.msDatabaseId : this.msDatabaseId = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.conversations));
	}

	@HasPermissions({ permission: "delete" })
	/** Indique si l'utilisateur peut supprimer des conversations. */
	public get canDelete(): boolean { return true; }

	public readonly permissionScope: EPermissionScopes = EPermissionScopes.conversations;

	//#endregion

	//#region METHODS

	constructor(
		/** Service pour les requêtes sur base de données. */
		private readonly isvcStore: Store,
		/** Service lié au réseau. */
		private readonly ioNetwork: NetworkService,
		/** Service de gestion des popups et toasts. */
		private readonly isvcUiMessage: UiMessageService,
		/** Service de gestion des entités liées. */
		private readonly isvcEntityLink: EntityLinkService,
		/** Service de gestion des contacts. */
		private readonly isvcContacts: ContactsService,
		private readonly isvcGroups: GroupsService,
		private readonly ioRouter: Router,
		private readonly isvcGlobalData: GlobalDataService,
		private readonly isvcFlag: FlagService,
		private readonly isvcLoading: LoadingService,
		public readonly isvcPermissions: PermissionsService,
		private readonly isvcCreateConversationSelectorModalOpenerService: CreateConversationSelectorModalOpenerService,
		public readonly isvcLogger: LoggerService,
		private readonly isvcGallery: GalleryService
	) {
		super();
		this.initNumberOfUnreadConversations();
	}

	public override ngOnDestroy(): void {
		this.moAppEventSubject.complete();
		this.moConversationUiSubject.complete();
		super.ngOnDestroy();
	}

	/** Crée une nouvelle conversation.
	 * @param poConversationOptions Options pour la création de la conversation.
	 */
	private create(poConversationOptions: ICreateConversationOptions): Observable<IConversation> {
		console.debug(`${ConversationService.C_LOG_ID}Creating new conversation.`);

		if (!ArrayHelper.hasElements(poConversationOptions.members))
			return this.raiseCreateError("La conversation n'a pu être créée car aucun contact n'a été sélectionné.", "no contact selected");

		else {
			return this.buildConversation$(poConversationOptions)
				.pipe(
					catchError((poError) => this.raiseCreateError(
						`La création de la conversation ${poConversationOptions.options.title ?? ""} a échoué. Veuillez réessayez ultérieurement.`,
						"creating template for new conversation failed", poError
					)),
					mergeMap((poConversation: IConversation) => this.isvcStore.put(poConversation, this.databaseId)
						.pipe(
							catchError(poError => this.raiseCreateError(
								`La création de la conversation ${poConversation.title} a échoué. Veuillez réessayez ultérieurement.`,
								"put conversation failed", poError
							)),
							mapTo(poConversation),
							tap((_: IConversation) => this.requestReplicationToServer())
						)),
					mergeMap((poConversation: IConversation) => {
						const loParticipant: IParticipant | undefined = this.findParticipant(poConversation.participants, poConversationOptions.userContactId);
						poConversationOptions.options.currentContactId = loParticipant?.participantId;
						this.isvcEntityLink.cacheLinkToAdd(poConversation, poConversationOptions.options.linkedEntities!); // Le tableau est censé être renseigné.
						return ArrayHelper.hasElements(poConversationOptions.options.linkedEntities) ? this.saveConversationLinks(poConversation) : of(poConversation);
					})
				);
		}
	}

	/** Crée le modèle d'une nouvelle conversation.
	 * @param poConversationOptions Options pour la création de la conversation.
	 */
	private buildConversation$(poConversationOptions: ICreateConversationOptions): Observable<IConversation> {
		if (!UserData.current)
			return throwError(() => new Error(`Error creating template for new conversation: no current user.`));

		let lsConvId: string = IdHelper.buildId(EPrefix.conversation);

		if (poConversationOptions._id) {
			lsConvId = poConversationOptions._id;
		}
		const loConversation: IConversation = {
			_id: lsConvId,
			_rev: undefined,
			createUserId: UserData.current.name,
			createDate: new Date(),
			participants: this.innerBuildConversation_getParticipantsFromContactsAndGroups(lsConvId, poConversationOptions.members),
			title: poConversationOptions.options.title,
			triggers: [],
			type: EConversationType.conversation
		};
		let loAddUserParticipantToConversation$: Observable<void>;

		this.initConversationDefaultTitle(loConversation);

		// Si le contact utilisateur n'est pas dans les participants, il faut le récupérer pour l'ajouter.
		if (!poConversationOptions.members.some((poMember: IGroupMember) => poMember._id === poConversationOptions.userContactId)) {
			loAddUserParticipantToConversation$ = this.isvcContacts.getContact(poConversationOptions.userContactId)
				.pipe(
					map((poContact?: IContact) => {
						if (poContact) {
							const lsCurrentUserContactPath: string | undefined = this.getCurrentUserContactPath();
							if (lsCurrentUserContactPath) {
								const loUserParticipant: IParticipant<IContact> = this.createParticipant(loConversation, lsCurrentUserContactPath, poContact);
								this.prepareParticipantForSave(loUserParticipant);
								loConversation.participants.push(loUserParticipant); // On ajoute le créateur en tant que participant.
							}
							else {
								console.error(`${ConversationService.C_LOG_ID}Path to user contact '${poConversationOptions.userContactId}' is missing !`);
								throw new Error(`Error creating template for new conversation: path to current user missing.`);
							}
						}
						else {
							console.error(`${ConversationService.C_LOG_ID}User contact '${poConversationOptions.userContactId}' not exist !`);
							throw new Error(`Error, contact '${poConversationOptions.userContactId}' not exist.`);
						}
					})
				);
		}
		else
			loAddUserParticipantToConversation$ = of(undefined);

		return loAddUserParticipantToConversation$.pipe(mapTo(loConversation));
	}

	/** Retourne un tableau de participants à partir des contacts/groupes à ajouter à la conversation.
	 * @param psConvId Identifiant de la conversation.
	 * @param paMembers Tableau des contacts/groupes à ajouter dans la conversation.
	 */
	private innerBuildConversation_getParticipantsFromContactsAndGroups(psConvId: string, paMembers: Array<IContact | IGroup>): IParticipant[] {
		return paMembers.map((poContact: IContact | IGroup) => {
			const loContactClone: IContact | IGroup = ObjectHelper.clone(poContact);
			const loParticipant: IParticipant = this.createParticipant(psConvId, Store.getDocumentPath(loContactClone), loContactClone);
			this.prepareParticipantForSave(loParticipant);
			return loParticipant;
		});
	}

	/** Récupère le chemin vers le contact de l'utilisateur. */
	public getCurrentUserContactPath(): string | undefined {
		if (!UserData.current) {
			console.error(`${ConversationService.C_LOG_ID}Error retrieving path to current user's contact: no current user.`);
			return undefined;
		}

		let loWorkspaceInfo: IWorkspaceInfo | undefined = UserData.current.workspaceInfos.find((poWorkspaceInfo: IWorkspaceInfo) =>
			poWorkspaceInfo.shared && poWorkspaceInfo.appId === ConfigData.appInfo.appId
		);

		if (!loWorkspaceInfo) {
			loWorkspaceInfo = UserData.current.workspaceInfos.find((poWorkspaceInfo: IWorkspaceInfo) =>
				!poWorkspaceInfo.shared && poWorkspaceInfo.appId === ConfigData.appInfo.appId
			);
		}

		if (loWorkspaceInfo) {
			const lsDefaultDatabaseId: string = this.isvcStore.getDatabaseIdByFragmentIdAndRole(loWorkspaceInfo.id, EDatabaseRole.contacts);

			return Store.getDocumentPath({ _id: ContactsService.getContactIdFromUserId(UserData.current.name) }, lsDefaultDatabaseId);
		}
		else
			throw new Error("No user information found about the current user.");
	}

	/** Crée les liens de conversation avec les différentes entités.
	 * @param poConversation Conversation en cours de création.
	 */
	public saveConversationLinks(poConversation: IConversation): Observable<IConversation> {
		console.debug(`${ConversationService.C_LOG_ID}Creating conversation links.`);

		return this.isvcEntityLink.saveEntityLinks(poConversation)
			.pipe(
				catchError(poError => {
					this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Les liens de la conversation n'ont pas pu être créés.", header: "Erreur de création" }));
					console.error(`${ConversationService.C_LOG_ID}Conversation '${poConversation._id}' links couldn't be created.`, poError);
					return throwError(() => poError);
				}),
				mapTo(poConversation)
			);
	}

	/** Crée une nouvelle conversation ou ouvre une conversation existante en présentant la sélection de contacts et route directement vers celle-ci ou non.
	 * @param psUserContactId Id du contact de l'utilisateur de l'application.
	 * @param poOptions Paramètres d'ouverture de la conversation.
	 */
	public createOrOpenConversation(psUserContactId: string, poOptions?: IOpenConversationOptions): Observable<IConversation | undefined> {
		console.debug(`${ConversationService.C_LOG_ID}Opening conversation.`);
		const loOptions: IOpenConversationOptions = this.getOpenConversationOptions(poOptions);

		const lsCurrentUserId: string = ContactsService.getUserContactId();
		const lfIsNotCurrentUser: (poItem: IGroupMember) => boolean = (poItem: IGroupMember) => poItem._id !== lsCurrentUserId;
		const lbHasPrefilledParticipants: boolean = ArrayHelper.hasElements(loOptions.participants) &&
			loOptions.participants.some((poParticipant: IContact) => lfIsNotCurrentUser(poParticipant));

		return this.innerCreateOrOpenConversation(lbHasPrefilledParticipants, loOptions, lfIsNotCurrentUser)
			.pipe(
				filter((paMembers: Array<IContact | IGroup>) => !!paMembers),
				mergeMap((paMembers: Array<IContact | IGroup>) => this.searchOrCreateConversation(psUserContactId, paMembers, loOptions)),
				tap((poMatchingConversation?: IConversation) => {
					if (loOptions.routeToConversationAfterCreation && poMatchingConversation)
						this.routeToConversation(poMatchingConversation, { openVisio: loOptions.openVisio }, { sharedDocuments: ModelResolver.toPlain(poOptions.sharedDocuments) });
				}),
				// Permet de retenter si la sélection de conversation a été annulée.
				retryWhen((poErrors: Observable<any>) => poErrors
					.pipe(mergeMap(poError => !lbHasPrefilledParticipants && poError.canceled ? of(null) : throwError(() => poError)))
				)
			);
	}

	private innerCreateOrOpenConversation(pbHasPrefilledParticipants: boolean, poOptions: IOpenConversationOptions,
		lfIsNotCurrentUser: (poItem: IGroupMember) => boolean): Observable<Array<IContact | IGroup>> {

		// Si des participants sont renseignés, on les utilise (hors utilisateur courant).
		if (pbHasPrefilledParticipants)
			return of((poOptions.participants ?? []).filter((poParticipant: IContact) => lfIsNotCurrentUser(poParticipant)));
		// Sinon, si des membres sont renseignés, on les utilise (hors utilisateur courant).
		else if (ArrayHelper.hasElements(poOptions.members) && poOptions.members.some((poMember: IGroupMember) => lfIsNotCurrentUser(poMember)))
			return of(poOptions.members.filter((poMember: IGroupMember) => lfIsNotCurrentUser(poMember)));
		// Sinon, il faut ouvrir un sélecteur pour choisir qui sera participant de la conversation.
		else {
			const loContactSelectorParams: IContactsPickerParams = {
				...poOptions?.contactSelectorParams ?? {},
				excludeCurrentUser: true,
				hasSearchbox: true,
				type: EContactsType.contactsAndGroups,
				min: 1,
				disableItemFunction: (poContact: IContact) => !ConversationHelper.isParticipantEligible(poContact),
				onGroupClicked: (poGroup: IGroup, pbSelected?: boolean, paMembers?: IContact[], paDisabledMembers?: IContact[]) => {
					if (pbSelected && ArrayHelper.hasElements(paDisabledMembers)) {
						this.isvcUiMessage.showMessage(
							new ShowMessageParamsToast({
								message: "Des contacts de ce groupe n'ont ni compte ni email. Ils ne pourront pas intervenir dans la conversation.",
								position: "middle"
							})
						);
					}
				},
				hideAllSelectionButton: ConfigData.conversation?.hideAllSelectionButton,
				defaultTab: ConfigData.conversation?.defaultConversationTab ?? 0
			};

			return this.isvcContacts.openContactsSelectorAsModal(loContactSelectorParams, "Nouvelle conversation");
		}
	}

	/** Renvoie un objet paramètres pour ouvrir une conversation avec des valeurs par défaut celles du paramètre.
	 * @param poOptions Objet paramètres pour ouvrir une conversation.
	 */
	private getOpenConversationOptions(poOptions?: IOpenConversationOptions): IOpenConversationOptions {
		const loOptions: IOpenConversationOptions = poOptions ?? {};

		if (typeof loOptions.routeToConversationAfterCreation !== "boolean")
			loOptions.routeToConversationAfterCreation = true;

		if (!ArrayHelper.hasElements(loOptions.participants))
			loOptions.participants = [];

		if (typeof loOptions.searchForMatchingConversation !== "boolean")
			loOptions.searchForMatchingConversation = true;

		if (!ArrayHelper.hasElements(loOptions.linkedEntities))
			loOptions.linkedEntities = [];

		if (StringHelper.isBlank(loOptions.title))
			loOptions.title = ArrayHelper.hasElements(loOptions.linkedEntities) ? `À propos de ${ArrayHelper.getFirstElement(loOptions.linkedEntities)?.name}` : "";

		return loOptions;
	}

	/** Renvoie la conversation correspondant aux critères de recherche (affichage d'un sélecteur préalable si nécessaire),
	 * en crée une nouvelle et la retourne ou renvoie `undefined` si aucune action ne doit être appliquée.
	 * @param psUserContactId Identifiant du contact de l'utilisateur.
	 * @param paMembers Tableau des contacts/groupes associés aux conversations à rechercher ou qui seront ajoutés à la nouvelle conversation.
	 * @param poOptions Paramètres de la conversation à rechercher ou créer.
	 */
	private searchOrCreateConversation(psUserContactId: string, paMembers: Array<IContact | IGroup>, poOptions: IOpenConversationOptions): Observable<IConversation | undefined> {
		if (!ArrayHelper.hasElements(paMembers))
			return this.innerOpenConversation_noContactSelected();

		else { // Recherche de conversations correspondantes ou pas de recherche.
			let loMatchingConversations$: Observable<IConversation[]>;

			if (poOptions.searchForMatchingConversation) {
				const loOptions: IGetConversationOptions = {
					participantIds: paMembers.map((poContact: IContact) => poContact._id),
					links: ArrayHelper.hasElements(poOptions.linkedEntities) ? poOptions.linkedEntities.map((poEntity: IEntity) => poEntity._id) : undefined
				};
				loMatchingConversations$ = this.getConversations(psUserContactId, loOptions);
			}
			else
				loMatchingConversations$ = of([]);

			return loMatchingConversations$
				.pipe(
					mergeMap((paMatchingConversations: IConversation[]) => {
						const loConversationOptions: ICreateConversationOptions = {
							members: paMembers,
							userContactId: psUserContactId,
							options: poOptions
						};
						// Plusieurs conversations correspondantes ou aucune (création d'une nouvelle).
						if (ArrayHelper.hasElements(paMatchingConversations))
							return this.openConversationSelectorAsync(paMatchingConversations, loConversationOptions);
						else
							return this.create(loConversationOptions);
					})
				);
		}
	}

	/** Ouvre une popup d'erreur si l'utilisateur n'a sélectionné aucun contact et retourne un observable vide. */
	private innerOpenConversation_noContactSelected(): Observable<never> {
		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({ header: "Erreur", message: "La conversation n'a pu être créée car aucun contact n'a été sélectionné." })
		);
		return EMPTY;
	}

	/** Retourne `true` si la conversation peut être supprimée, `false` sinon.
	 * ### Si la suppression est impossible, une popup informative est affichée.
	 * @param poConversation Conversation dont il faut vérifier la possibilité de suppression.
	 */
	public isDeletable(poConversation: IConversation): Observable<boolean> {
		return this.isvcEntityLink.ensureIsDeletableEntity(poConversation);
	}

	/** Permet de supprimer une conversation et tous ses documents liés.
	 * @param poConversation Conversation à supprimer.
	 */
	public delete(poConversation: IConversation): Observable<boolean> {
		return this.isvcEntityLink.ensureIsDeletableEntity(poConversation)
			.pipe(
				filter((pbResult: boolean) => pbResult),
				mergeMap(_ => {
					if (this.canDelete)
						return of(undefined);
					else {
						this.isvcUiMessage.showMessage(
							new ShowMessageParamsPopup({ message: "Seuls les administrateurs peuvent supprimer une conversation.", header: "Suppression interdite" })
						);
						return EMPTY;
					}
				}),
				mergeMap(_ => {
					console.debug(`${ConversationService.C_LOG_ID}Deleting conversation '${poConversation._id}'.`, poConversation);

					return forkJoin([
						this.isvcEntityLink.deleteEntityLinksById(poConversation._id),
						this.deleteMessages(poConversation._id),
						this.deleteActivities(poConversation._id),
						this.deleteConversationDetailsLastSeq(poConversation._id)
					]);
				}),
				concatMap((paResults: boolean[]) => {
					const loCheckError: IDeleteError = this.innerDelete_getError(paResults);
					return loCheckError.canContinue ? this.isvcStore.delete(poConversation, this.databaseId, true) : throwError(() => loCheckError.error);
				}),
				catchError(poError => poError.code ? this.raiseDeleteError(poError.message, poError.code) : this.raiseDeleteError(poError, EConversationError.conversation)),
				tap((poResult: IStoreDataResponse) => {
					console.debug(`${ConversationService.C_LOG_ID}Conversation '${poConversation._id}' deleted.`, poResult);
					this.requestReplicationToServer();
				}),
				map((poResult: IStoreDataResponse) => poResult.ok)
			);
	}

	/** Vérifie si toutes les suppressions se sont bien passées ou non et retourne un objet contenant une erreur et un état.
	 * @param paResults Tableau des résultats des suppressions des entités liés, messages, activités et numéro de séquence de réplication.
	 */
	private innerDelete_getError(paResults: boolean[]): IDeleteError {
		const loError: IDeleteResults = { code: undefined, message: "Error removing " };
		let lbCanContinue = false;

		if (!paResults[0]) {
			loError.code = EConversationError.entityLinks;
			loError.message += "entityLinks";
		}
		else if (!paResults[1]) {
			loError.code = EConversationError.messages;
			loError.message += "messages";
		}
		else if (!paResults[2]) {
			loError.code = EConversationError.activities;
			loError.message += "activities";
		}
		else if (!paResults[3]) {
			loError.code = EConversationError.lastSeq;
			loError.message += "last replication sequence";
		}
		else
			lbCanContinue = true;

		return { error: loError, canContinue: lbCanContinue };
	}

	private requestReplicationToServer(): void {
		this.raiseAppConversationEvent({
			type: EApplicationEventType.ConversationEvent,
			createDate: new Date(),
			data: {
				eventType: EConversationEvent.synchro
			}
		});
	}

	/** Lève un événement d'erreur lors de la suppression d'une conversation.
	 * @param poSubscriber Abonné qui veut supprimer une conversation.
	 * @param poError Erreur survenue lors de la chaîne de suppression (conversation, activités, messages, entités liées).
	 */
	private raiseDeleteError(poError: any, peConvError: EConversationError): Observable<never> {
		let lsMessage = "Une erreur est survenue lors de la suppression ";
		let lsErrorMessage = `Error when deleting `;
		const lsEndMessage = "de la conversation.";
		const lsEndErrorMessage = "of conversation.";

		switch (peConvError) {

			case EConversationError.activities:
				lsMessage += `des activités ${lsEndMessage}`;
				lsErrorMessage += `activities ${lsEndMessage}`;
				break;

			case EConversationError.entityLinks:
				lsMessage += `des liens ${lsEndMessage}`;
				lsErrorMessage += `links ${lsEndMessage}`;
				break;

			case EConversationError.messages:
				lsMessage += `des messages ${lsEndMessage}`;
				lsErrorMessage += `messages ${lsEndMessage}`;
				break;

			default:
				lsMessage += lsEndMessage;
				lsErrorMessage += lsEndErrorMessage;
				break;
		}
		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: lsMessage, header: "Erreur" }));

		console.error(`${ConversationService.C_LOG_ID}${lsErrorMessage}`, poError);
		return throwError(() => poError);
	}

	/** Supprime les messages liés à la conversation.
	 * @param psConvId Identifiant de la conversation dont les messages doivent être supprimés.
	 */
	private deleteMessages(psConvId: string): Observable<boolean> {
		const lsStartKey: string = psConvId.substring(psConvId.indexOf(EPrefix.conversation) + EPrefix.conversation.length);
		const loParams: IDataSource = {
			databaseId: this.databaseId,
			viewParams: {
				startkey: EPrefix.message + lsStartKey,
				endkey: EPrefix.message + lsStartKey + Store.C_ANYTHING_CODE_ASCII,
				include_docs: true
			}
		};

		return defer(() => {
			console.debug(`${ConversationService.C_LOG_ID}Deleting messages for conversation '${psConvId}'.`);
			return this.deleteMultiple(loParams, EConversationError.messages);
		})
			.pipe(finalize(() => console.debug(`${ConversationService.C_LOG_ID}Messages deleted for conversation '${psConvId}'.`)));
	}

	/** Supprime les activités liées à la conversation.
	 * @param psConvId Identifiant de la conversation dont les activités doivent être supprimées.
	 */
	private deleteActivities(psConvId: string): Observable<boolean> {
		const lsStartKey: string = IdHelper.buildId(EPrefix.activity, GuidHelper.extractGuid(psConvId));
		const loParams: IDataSource = {
			databaseId: this.databaseId,
			viewParams: {
				startkey: lsStartKey,
				endkey: lsStartKey + Store.C_ANYTHING_CODE_ASCII,
				include_docs: true
			}
		};

		return defer(() => {
			console.debug(`${ConversationService.C_LOG_ID}Deleting activities for conversation '${psConvId}'.`);
			return this.deleteMultiple(loParams, EConversationError.activities);
		})
			.pipe(finalize(() => console.debug(`${ConversationService.C_LOG_ID}Activities deleted for conversation '${psConvId}'.`)));
	}

	private deleteMultiple(loParams: IDataSource, peConversationError: EConversationError): Observable<boolean> {
		return this.isvcStore.get(loParams)
			.pipe(
				mergeMap((paResults: IConversationActivity[]) => this.isvcStore.deleteMultipleDocuments(paResults)
					.pipe(catchError(poError => this.raiseDeleteError(poError, peConversationError)))
				)
			);
	}

	private execPendingSynchro(): void {
		const loParams: IDataSource = {
			role: EDatabaseRole.applicationStorage,
			viewParams: {
				startkey: EPrefix.pending,
				endkey: EPrefix.pending + Store.C_ANYTHING_CODE_ASCII,
				include_docs: true
			}
		};

		this.isvcStore.get(loParams)
			.pipe(
				tap(
					(paResults: IStoreDocument[]) => ArrayHelper.hasElements(paResults) ?
						this.requestReplicationToServer() : console.debug(`${ConversationService.C_LOG_ID}No sync waiting.`),
					poError => console.error(`${ConversationService.C_LOG_ID}Error while getting pending synchro documents`, poError)
				)
			)
			.subscribe();
	}

	/** Récupération de la conversation, `undefined` si non trouvée.
	 * @param psConvId Chaîne de caractères correspondant à l'id de la conversation à récupérer.
	 * @param pbLive Indique si la récupération de la conversation doit être live, `false` par défaut.
	 */
	public getConversation(psConvId: string, pbLive?: boolean): Observable<Conversation | undefined> {
		const loDataSource: IDataSource = {
			databaseId: this.databaseId,
			viewParams: {
				include_docs: true,
				key: psConvId
			},
			live: pbLive,
			baseClass: Conversation
		};

		return this.isvcStore.getOne<Conversation>(loDataSource, false).pipe(
			tap((poConversation?: Conversation) => {
				// Des conversations contiennent des attributs de IHydratedConversation qui ne doivent pas être sérialisés en base de données.
				// Si c'est attributs sont présents, ils perturbent le fonctionnement de l'application (des conversations sont considérées comme lues).
				// #18019
				if (poConversation)
					this.removeNonPersistedAttributes(poConversation);
			})
		);
	}

	/** Hydrate les données d'une conversation.
	 * @param poConversation Conversation à hydrater.
	 * @param psUserContactId Identifiant du contact qui utilise l'app (identifiant du contact utilisateur par défaut).
	 */
	public hydrateConversation(poConversation: IConversation, psUserContactId: string = UserHelper.getUserContactId()): Observable<IConversation | undefined> {
		if (poConversation) {
			return this.loadParticipants(poConversation, psUserContactId).pipe(
				catchError((poError) => {
					console.error(`${ConversationService.C_LOG_ID}The ${poConversation.title ?? ""} conversation hydrate failed.`, poError);
					return of(undefined);
				})
			);
		}
		else
			return of(undefined);
	}

	/** Récupère les participants de la conversation. */
	private loadParticipants(poConversation: IConversation, psUserContactId: string): Observable<IConversation> {
		const loOtherParticipantsMap: Map<string, IParticipant<IContact>> = new Map();
		const laParticipantIndicators: IParticipantIndicator[] = [];
		const loPerformance = new PerformanceManager();
		loPerformance.markStart();

		return of(poConversation.participants.filter((poParticipant: IParticipant) => this.isValidParticipant(poParticipant)))
			.pipe(
				mergeMap((paParticipants: IParticipant[]) => this.initParticipants(poConversation, psUserContactId, paParticipants, loOtherParticipantsMap)),
				mergeMap((paParticipants: IParticipant<IContact>[]) =>
					this.getParticipantsActivity(poConversation, psUserContactId, paParticipants, laParticipantIndicators)
				),
				mergeMap(async (paParticipants: IParticipant<IContact>[]) => {
					let loUserParticipant: IParticipant<IContact> | undefined;

					paParticipants.forEach((poParticipant: IParticipant<IContact>) => {
						if (poParticipant.participantId === psUserContactId) // Si le participant est celui de l'utilisateur courant, on le met de côté.
							loUserParticipant = poParticipant;
						else // Sinon on ajoute dans la map le participant.
							loOtherParticipantsMap.set(poParticipant.participantPath, poParticipant);
					});

					if (!loUserParticipant) { // Si l'utilisateur courant n'est pas renseigné, on log un warning et on le récupère.
						console.warn(`${ConversationService.C_LOG_ID}User participant '${psUserContactId}' not found in participants [${paParticipants.join(", ")}], adding it manually.`);
						if (UserData.current?._id)
							loUserParticipant = this.createParticipant(poConversation, await this.isvcContacts.getContactFromUserId(UserData.current?._id).toPromise());
						else {
							this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
								message: "L'utilisateur courant n'a pas été trouvé, impossible de charger les participants.",
								header: "Erreur chargement"
							}));
							throw new Error("Current user not found, unable to load participants.");
						}
					}

					const loConvCacheData: IConversationCacheData = {
						userParticipant: loUserParticipant,
						otherParticipantsMap: loOtherParticipantsMap,
						participantIndicators: this.getUniqueParticipantIndicators(laParticipantIndicators)
					};

					StoreHelper.updateDocumentCacheData(poConversation, loConvCacheData);

					return poConversation;
				}),
				tap(_ => console.debug(`${ConversationService.C_LOG_ID}Participants loaded in ${loPerformance.markEnd().measure()}ms.`))
			);
	}

	private isValidParticipant(poParticipant: IParticipant): boolean {
		return !StringHelper.isBlank(poParticipant.participantPath) &&
			!StringHelper.isBlank(poParticipant.participantId) &&
			!StringHelper.isBlank(poParticipant.label);
	}

	/** Récupère l'activité du participant lié à une conversation.
	 * @param poConversation Conversation dans laquelle récupérer le participant.
	 * @param psUserContactId Identifiant du contact utilisateur.
	 * @param paParticipants Participant.
	 * @param paParticipantIndicators Tableau des indicateurs de participant présents dans la conversation.
	 */
	private getParticipantsActivity(poConversation: IConversation, psUserContactId: string, paParticipants: IParticipant<IContact>[],
		paParticipantIndicators: IParticipantIndicator[]): Observable<IParticipant[]> {

		const lsConvGuid: string = IdHelper.getGuidFromId(poConversation._id, EPrefix.conversation);
		// Sauvegarde de l'id des activités de cette conversation, sans l'id du contact.
		const lsActivityWithoutContact = `${IdHelper.buildId(EPrefix.activity, lsConvGuid)}_`;
		const laKeys: string[] = paParticipants.map((poParticipant: IParticipant) => lsActivityWithoutContact + poParticipant.participantId);
		const loDataSource: IDataSource = {
			databaseId: this.databaseId,
			viewParams: {
				include_docs: true,
				keys: laKeys
			}
		};

		const loParticipantByIds = ArrayHelper.groupByUnique(paParticipants, (poParticipant: IParticipant<IContact>) => poParticipant.participantId);
		const loPerformance = new PerformanceManager();
		loPerformance.markStart();

		return this.isvcStore.get(loDataSource)
			.pipe(
				map((paActivities: IConversationActivity[]) => {
					paActivities.forEach((poActivity: IConversationActivity) => {
						const lsParticipantId: string = ConversationService.getContactIdFromActivity(poActivity);
						const loParticipant: IParticipant<IContact> | undefined = loParticipantByIds.get(lsParticipantId);
						if (loParticipant) {
							loParticipant.activity = poActivity;
							this.onGetActivity(psUserContactId, loParticipant, paParticipantIndicators);
						}
					});

					return paParticipants;
				}),
				tap(_ => console.debug(`${ConversationService.C_LOG_ID}Participants activities got in ${loPerformance.markEnd().measure()}ms.`)),
				catchError(poError => {
					console.error(`${ConversationService.C_LOG_ID}Error while getting participants's activities of conversation '${poConversation}'.`, poError);
					return throwError(() => `Une erreur est survenue lors de la récupération des activités des participants de la conversation '${poConversation._id}' sur la base de données.`);
				})
			);
	}

	/** Suite d'actions à effectuer lors de l'ajout d'une activité pour un participant.
	 * @param psUserContactId Identifiant du contact utilisateur.
	 * @param poParticipant Participant actuel.
	 * @param paParticipantIndicators Tableau des indicateurs de participant présents dans la conversation.
	 */
	private onGetActivity(psUserContactId: string, poParticipant: IParticipant<IContact>, paParticipantIndicators: IParticipantIndicator[]): void {
		const lsLastMessageReadId: string | undefined = poParticipant.activity?.lastReadMessageId;

		if (poParticipant.participantId === psUserContactId && poParticipant.activity)  // Si le participant est l'utilisateur courant.
			poParticipant.activity.activity = EActivityStatus.online;

		else if (!StringHelper.isBlank(lsLastMessageReadId)) {
			const loReadIndicator: IReadIndicator | undefined = this.createReadIndicator(poParticipant);

			if (loReadIndicator) {
				const loParticipantIndicator: IParticipantIndicator = {
					indicator: loReadIndicator,
					id: poParticipant.participantId,
					lastReadMessageId: lsLastMessageReadId
				};

				paParticipantIndicators.push(loParticipantIndicator);
			}
			else
				console.error(`${ConversationService.C_LOG_ID}Reading indicator for participant '${poParticipant.model?._id}' is missing.`);
		}
	}

	/** Crée l'indicateur de lecture d'un participant.
	 * @param poParticipant Participant dont on veut créer l'indicateur de lecture.
	 */
	private createReadIndicator(poParticipant: IParticipant<IContact>): IReadIndicator | undefined {
		if (!poParticipant.activity) {
			if (!poParticipant.model) {
				console.error(`${ConversationService.C_LOG_ID}Current user and its activity document are missing.`);
				return undefined;
			}
			else {
				console.error(`${ConversationService.C_LOG_ID}Current user's activity document is missing`);
				return undefined;
			}
		}
		else if (!poParticipant.model) {
			console.error(`${ConversationService.C_LOG_ID}Current user missing.`);
			return undefined;
		}

		return {
			activityId: poParticipant.activity._id,
			avatar: ContactsService.createContactAvatar(poParticipant.model, EAvatarSize.small)
		};
	}

	/** Initialise le participant correspondant au chemin de contact passé en paramètre.
	 * @param poConversation Conversation à ouvrir.
	 * @param psUserContactId Identifiant du contact utilisateur.
	 * @param paParticipants Tableau des participants de la conversation.
	 * @param poOtherParticipantByMap Map des participants autre que l'utilisateur.
	 */
	private initParticipants(poConversation: IConversation, psUserContactId: string, paParticipants: IParticipant[], poOtherParticipantByMap: Map<string, IParticipant>)
		: Observable<IParticipant[]> {

		const loPerformance = new PerformanceManager().markStart();

		return this.createParticipants$(poConversation, paParticipants.map((poParticipant: IParticipant) => poParticipant.participantPath), psUserContactId)
			.pipe(
				map((paResults: IParticipant[]) => {
					return paResults.filter((poResult: IParticipant) => {
						if (poResult.participantId !== psUserContactId) { // Si ce n'est pas le participant associé à l'utilisateur.
							const lbParticipantExists: boolean = poOtherParticipantByMap.has(poResult.participantPath);
							// Version plus récente du participant.
							return !lbParticipantExists ||
								(lbParticipantExists &&
									StoreDocumentHelper.getRevisionNumber(poOtherParticipantByMap.get(poResult.participantPath)!.model) < StoreDocumentHelper.getRevisionNumber(poResult.model)
								);
						}
						else
							return true;
					});
				}),
				tap(_ => console.debug(`${ConversationService.C_LOG_ID}Participants created in ${loPerformance.markEnd().measure()}ms.`))
			);
	}

	/** Retourne un tableau des indicateurs de participants de façon unique, si un même indicateur est présent plusieurs fois on garde le plus récent.
	 * @param paParticipantIndicators Tableau des indicateurs de participant existants.
	 */
	private getUniqueParticipantIndicators(paParticipantIndicators: IParticipantIndicator[]): IParticipantIndicator[] {
		const loParticipantIndicatorByContactId = new Map<string, IParticipantIndicator>();

		paParticipantIndicators.forEach((poItem: IParticipantIndicator) => {
			if (loParticipantIndicatorByContactId.has(poItem.id)) { // Si l'indicateur existe déjà.
				// On garde l'indicateur dont l'identifiant de message est le plus récent (identifiant basé sur un timestamp).
				if (poItem.lastReadMessageId > loParticipantIndicatorByContactId.get(poItem.id)!.lastReadMessageId)
					loParticipantIndicatorByContactId.set(poItem.id, poItem);
			}
			else
				loParticipantIndicatorByContactId.set(poItem.id, poItem);
		});

		return MapHelper.valuesToArray(loParticipantIndicatorByContactId);
	}

	/** Récupère les conversations qui respectent des paramètres de filtrage donnés, par défaut si aucun filtre n'est passé, on trie les conversations obtenues.
	 * @param psUserContactId Identifiant du contact de l'utilisateur de l'application avec un compte "_user".
	 * @param poOptions Objet correspondant aux paramètres de récupération des conversations, réalise un tri par défaut.
	*/
	public getConversations(psUserContactId: string, poOptions: IGetConversationOptions = { sortRequired: true }): Observable<IConversation[]> {
		const loDataSource: IDataSource<IConversation> = {
			databaseId: this.databaseId,
			live: poOptions.live,
			viewParams: { include_docs: true }
		};

		if (poOptions.activePageManager) {
			(loDataSource as IDataSourceRemoteChanges<IConversation>).remoteChanges = true;
			(loDataSource as IDataSourceRemoteChanges<IConversation>).activePageManager = poOptions.activePageManager;
		}

		if (poOptions.conversationIds) { // Récupération simple par identifiants de conversations fournis si le champ existe sinon filtre complexe.
			loDataSource.viewParams!.keys = poOptions.conversationIds;
			return this.isvcStore.get(loDataSource);
		}
		else { // Récupération complexe avec plusieurs filtres possibles.
			loDataSource.viewParams!.startkey = EPrefix.conversation;
			loDataSource.viewParams!.endkey = EPrefix.conversation + Store.C_ANYTHING_CODE_ASCII;

			return this.isvcStore.get(loDataSource)
				.pipe(
					tap((paConversations: IConversation[]) => {
						// Des conversations contiennent des attributs de IHydratedConversation qui ne doivent pas être sérialisés en base de données.
						// Si c'est attributs sont présents, ils perturbent le fonctionnement de l'application (des conversations sont considérées comme lues).
						// #18019
						paConversations.forEach((poConversation: IConversation) => this.removeNonPersistedAttributes(poConversation));
					}),
					mergeMap((paResults: IConversation[]) => this.isvcGroups.getUserGroupsPaths()
						.pipe(
							map((paUserGroupsPaths: string[]) => {
								return paResults.filter((poConversation: IConversation) =>
									poConversation.participants?.some((poParticipant: IParticipant) =>
										poParticipant.participantId === psUserContactId ||	// L'utilisateur actif fait partie des participants (quel que soit son workspace)
										paUserGroupsPaths.includes(poParticipant.participantPath)) // L'utilisateur appartient à un groupe figurant parmi les participants
								);
							})
						)),
					mergeMap((paConversations: IConversation[]) => ArrayHelper.hasElements(paConversations) ?
						this.filterConversations(psUserContactId, paConversations, poOptions) : of(paConversations)
					)
				);
		}
	}

	/** Récupère les conversations qui respectent des paramètres de filtrage donnés, par défaut si aucun filtre n'est passé, on trie les conversations obtenues.
	 * @param psUserContactId Identifiant du contact de l'utilisateur de l'application avec un compte "_user".
	 * @param poOptions Objet correspondant aux paramètres de récupération des conversations, réalise un tri par défaut.
	*/
	private getConversationsLocalChanges(): Observable<IChangeEvent<IConversation>> {
		const loDataSource: IDataSource<IConversation> = {
			databaseId: this.databaseId,
			viewParams: {
				include_docs: true,
				startkey: EPrefix.conversation,
				endkey: EPrefix.conversation + Store.C_ANYTHING_CODE_ASCII
			}
		};

		return this.isvcStore.localChanges(loDataSource);
	}

	/** Récupère les conversations qui correspondent à un filtre de recherche donné en fonction d'entités liées ou non.
	 * @param psUserContactId Id du contact de l'utilisateur de l'application.
	 * @param paConversations Tableau des conversations.
	 * @param poFilter Filtre de recherche de conversation.
	 */
	private filterConversations(psUserContactId: string, paConversations: Array<IConversation>, poFilter: IGetConversationOptions): Observable<Array<IConversation>> {
		const laFilteredConversations: Array<IConversation> = this.innerFilterConversations_sync(psUserContactId, paConversations, poFilter);

		if (!ArrayHelper.hasElements(laFilteredConversations))
			return of([]);
		else
			return this.innerFilterConversation_async(laFilteredConversations, poFilter);
	}

	/** Filtre les conversations avec les différents filtres synchrones possibles.
	 * @param psUserContactId Id du contact de l'utilisateur de l'application.
	 * @param paConversations Tableau des conversations.
	 * @param poFilter Filtre de recherche de conversation.
	 * NB : La recherche par participant ne tient pas compte de la base de donnée contenante
	 */
	private innerFilterConversations_sync(psUserContactId: string, paConversations: Array<IConversation>, poFilter: IGetConversationOptions): Array<IConversation> {
		let laFilteredConversations: Array<IConversation>;

		if (ArrayHelper.hasElements(poFilter.participantIds)) {

			if (!poFilter.participantIds.some((psParticipant: string) => psParticipant === psUserContactId))
				poFilter.participantIds.push(psUserContactId); // Ajoute l'utilisateur actif aux participants des conversations recherchées.

			// On parcourt toutes les conversations pour supprimer celles qui ne respectent pas le filtre des participants présents.
			laFilteredConversations = paConversations.filter((poConversation: IConversation) => {
				const laConversationParticipantIds: string[] = poConversation.participants.map(
					(poParticipant: IParticipant) => poParticipant.participantId);

				// Si la conversation ne contient pas les mêmes participants (même nombre et mêmes ID indépendamment de la base de donnée contenante).
				return ArrayHelper.areArraysEqual(poFilter.participantIds ?? [], laConversationParticipantIds);
			});
		}
		else
			laFilteredConversations = paConversations;

		if (poFilter.sortRequired)
			laFilteredConversations.sort((poConvA, poConvB) => this.getTimestampFromConversation(poConvB) - this.getTimestampFromConversation(poConvA));

		if (!ArrayHelper.hasElements(laFilteredConversations))
			console.debug(`${ConversationService.C_LOG_ID}No conversation matching the filter.`);

		return laFilteredConversations;
	}

	/** Filtre les conversations avec les filtres asynchrones possibles :
	 * - Liens : si aucun lien n'est indiqué, ne retient que les conversations liées à aucune entité,
	 * sinon uniquement celles liées au mêmes entités que celles indiquées.
	 * - (potentiellement d'autres critères de sélection ultérieurement).
	 * @param paConversations Tableau des conversations.
	 * @param poFilter Paramètres de filtrage des conversations candidates.
	 */
	private innerFilterConversation_async(paConversations: Array<IConversation>, poFilter: IGetConversationOptions): Observable<IConversation[]> {
		if (!poFilter.links) // Si pas de paramètre, pas de filtrage avec entités liées.
			return of(paConversations);
		else {
			return this.isvcEntityLink.getLinkedEntityIds(paConversations.map((poConversation: IConversation) => poConversation._id))
				.pipe(
					map((poLinkedEntityIdsByConvIds: Map<string, string[]>) =>
						paConversations.filter((poConversation: IConversation) =>
							this.innerFilterConversation_isMatchingFromEntityLinks(poConversation, poFilter.links ?? [], poLinkedEntityIdsByConvIds.get(poConversation._id) ?? [])
						))
				);
		}
	}

	/** Retourne un booléen indiquant si la conversation vérifie la correspondance des entités qui lui sont liées ou non.
	 * @param poConversation Conversation qu'on veut tester si elle correspond au filtre ou non.
	 * @param paFilterLinks Fragments d'identifiants de lien de conversation liée avec une entité.
	 * @param paConversationLinks Tableau des liens d'entité de la conversation.
	 */
	private innerFilterConversation_isMatchingFromEntityLinks(poConversation: IConversation, paFilterLinks: string[], paConversationLinkedIds: string[]): boolean {
		let lbMatches: boolean;

		/** Si le tableau des entités liées à filtrer n'est pas renseigné ou qu'il est vide ainsi que le tableau des entitées liées à la conversation,
		alors correspondance (on ne filtre pas). */
		if (!paFilterLinks || (!ArrayHelper.hasElements(paFilterLinks) && !ArrayHelper.hasElements(paConversationLinkedIds)))
			lbMatches = true;

		else // Sinon il faut filtrer la conversation en fonction des liens.
			lbMatches = ArrayHelper.areArraysEqual(paConversationLinkedIds, paFilterLinks);

		console.debug(`${ConversationService.C_LOG_ID}Conversation '${poConversation._id}' ${lbMatches ? "matches" : "does not match"} the links filter.`);
		return lbMatches;
	}

	/** Récupère le dernier message d'une conversation.
	 * @param psConvId Identifiant de la conversation dont il faut récupérer le dernier message.
	 */
	public getLastMessageFromConvId(psConvId: string): Observable<Message> {
		const lsId: string = psConvId.replace(EPrefix.conversation, "");
		const loParams: IDataSource<Message> = {
			databaseId: this.databaseId,
			viewParams: {
				startkey: `${lsId}_${Store.C_ANYTHING_CODE_ASCII}`,
				endkey: `${lsId}_`,
				include_docs: true,
				limit: 1,
				descending: true
			},
			baseClass: Message
		};

		return this.isvcStore.get(loParams)
			.pipe(map((paMessages: Message[]) => ArrayHelper.getLastElement(paMessages)));
	}

	/** Récupère les derniers messages de la conversation.
	 * @param poParams Objet correspondant aux paramètres de récupération des messages.
	 */
	public getMessages(poParams: IDataSource<Message>): Observable<Array<Message>> {
		return this.isvcStore.get(poParams)
			.pipe(map((paMessageResults: Message[]) => this.sortMessages(paMessageResults)));
	}

	/** Tri les messages par timestamp (plus récent au plus vieux) d'un tableau et retourne le tableau trié.
	 * @param paMessages Tableau des messages à trier.
	 */
	public sortMessages(paMessages: Array<Message>): Array<Message> {
		return paMessages.sort((poMessageA: Message, poMessageB: Message) => {
			const loTimestampA: number = IdHelper.extractTimestampFromId(poMessageA._id);
			const loTimestampB: number = IdHelper.extractTimestampFromId(poMessageB._id);

			return loTimestampA === loTimestampB ?
				0 : loTimestampA > loTimestampB ?
					-1 : 1;
		});
	}

	/** Récupération des messages qui n'ont pas été envoyés sur le serveur.
	 * @param psConvGuid Chaîne de caractères correspondant à l'id de la conversation dont il faut prendre le document associé.
	 * @param pbLive Indique si la récupération est continue ou non, `false` par défaut.
	 */
	public getPendingMessages(psConvGuid?: string, pbLive: boolean = false): Observable<IStoreDocument[]> {
		const lsStartPendingMessageId: string = EPrefix.pending + EPrefix.message;
		const loDataSource: IDataSource = {
			databasesIds: [...this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage), ...this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.conversations)], // TODO supprimer role conv pour rétrocompat
			viewParams: {
				include_docs: true,
				startkey: lsStartPendingMessageId,
				endkey: lsStartPendingMessageId + Store.C_ANYTHING_CODE_ASCII
			},
			filter: StringHelper.isBlank(psConvGuid) ? undefined : (poDocument: IStoreDocument) => poDocument._id.includes(psConvGuid),
			live: pbLive
		};

		return this.isvcStore.get<IStoreDocument>(loDataSource);
	}

	/** Retourne un Observable<booléen> indiquant si une connexion internet est présente ou non. */
	public asyncHasNetworkConnection(): Observable<boolean> {
		return this.ioNetwork.asyncIsNetworkReliable();
	}

	/** Crée l'activité de l'utilisateur pour une conversation donnée et l'envoie sur la base de données.
	 * @param poActivity Activité à créer.
	 */
	public createActivity(poActivity: IConversationActivity): Observable<IConversationActivity> {
		return this.saveActivity(poActivity)
			.pipe(catchError((poError) => throwError(() => ({ error: poError, reason: "Une erreur est survenue lors de la création de l'activité de l'utilisateur." }))));
	}

	/** Affiche une popup d'erreur de création de la conversation et met fin à l'abonnement.
	 * @param psMessage Message d'erreur survenu lors de la création de la conversation.
	 * @param psReason Raison de l'erreur de création de la conversation.
	 */
	private raiseCreateError(psMessage: string, psReason: string, poError: any = ""): Observable<IConversation> {
		console.error(`${ConversationService.C_LOG_ID}Error while creating conversation because of ${psReason}`, poError);

		this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: psMessage, header: "Erreur de création" }));

		return throwError(() => psReason);
	}

	/** Affiche un message d'erreur pour avertir l'utilisateur qu'une erreur est survenue (à partir du champ `message` ou `reason`).
	 * @param poError Objet correspondant à l'erreur survenue.
	 * @param psMessage Chaîne de caractères correspondant à la raison de l'erreur, affichage de l'erreur contenue dnas l'objet d'erreur par défaut.
	 */
	public onError(poError: { message?: string, reason?: string }, psMessage?: string): Observable<never> {
		let lsMessage: string | undefined = psMessage || poError.message || poError.reason;
		console.error(`${ConversationService.C_LOG_ID}${psMessage}`, poError);

		if (StringHelper.isBlank(lsMessage))
			lsMessage = "Une erreur inconnue est survenue.";

		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({ message: lsMessage, header: "Erreur", buttons: [{ text: "OK", cssClass: "button-assertive" }] })
		);

		return throwError(() => poError);
	}

	/** Ouvre un sélecteur de conversations afin de choisir laquelle ouvrir.
	 * @param paConversations Tableau des différentes conversations qu'on peut ouvrir.
	 * @param poConversationOptions Options pour la création de la conversation.
	 * @returns La conversation sélectionnée ou undefined si l'utilisateur n'en a choisi aucune (annulation).
	 */
	private async openConversationSelectorAsync(paConversations: Array<IConversation>, poConversationOptions: ICreateConversationOptions): Promise<IConversation | undefined> {
		const laSortedConversations: IConversation[] = this.sortConversationByLastMessage(paConversations);
		const loResponse: ICreateConversationModalResponse | undefined = await this.isvcCreateConversationSelectorModalOpenerService.openAsync(laSortedConversations);

		if (loResponse) {
			switch (loResponse.action) {
				case EConversationSelectorModalAction.canceled:
					return undefined;

				case EConversationSelectorModalAction.open:
					return loResponse.conversation;

				case EConversationSelectorModalAction.creation:
					return this.create(poConversationOptions).toPromise();

				default:
					console.error(`${ConversationService.C_LOG_ID}Conversation selector modal action '${loResponse.action}' unknown ${loResponse.conversation ? `for conversation '${loResponse.conversation._id}'` : ""}.`);
					return undefined;
			}
		}
		else {
			console.error(`${ConversationService.C_LOG_ID}Conversation selection modal opening error.`);
			return undefined;
		}
	}

	/** Retourne le tableau des conversations d'origine triées par date du dernier message envoyé, du plus récent au plus ancien.
	 * @param paConversations Tableau des conversations à trier.
	 */
	private sortConversationByLastMessage(paConversations: IConversation[]): IConversation[] {
		return paConversations.sort((poConversationA: IConversation, poConversationB: IConversation) => {
			const loDateA: Date | string = poConversationA.lastMessage ? poConversationA.lastMessage.createDate : poConversationA.createDate;
			const loDateB: Date | string = poConversationB.lastMessage ? poConversationB.lastMessage.createDate : poConversationB.createDate;

			return DateHelper.compareTwoDates(loDateB, loDateA);
		});
	}

	public static getConversationTitle(poConv: IConversation): string {
		return StringHelper.isBlank(poConv.title) ? poConv.defaultTitle ?? "" : poConv.title;
	}

	/** Réplique les documents des conversations vers le serveur.
	 * @param pbLive
	*/
	public replicateConversationsToServer$(pbLive?: boolean): Observable<IStoreReplicationToServerResponse> {
		const loOptions: PouchDB.Replication.ReplicateOptions = { live: pbLive };

		return this.isvcFlag.waitForFlag(ENetworkFlag.isOnlineReliable, true)
			.pipe(
				mergeMap(_ => this.getPendingMessages()),
				mergeMap((paPendingMessages: IStoreDocument[]) => this.isvcStore.replicateToServer(this.databaseId, loOptions)
					.pipe(mergeMap((poResult: IStoreReplicationToServerResponse) => this.deletePendingMessages(paPendingMessages).pipe(mapTo(poResult))))
				),
				tap((poResult: IStoreReplicationToServerResponse) => this.onConversationReplicated(poResult)),
				catchError(poError => this.onConversationReplicationError(poError)),
			);
	}

	/** Réplique les documents de conversations du serveur vers le local.
	 * @param pbLive
	*/
	public replicateConversationsToLocal$(pbLive?: boolean): Observable<IStoreReplicationToLocalResponse> {
		const loOptions: PouchDB.Replication.ReplicateOptions = { live: pbLive };

		return this.isvcFlag.waitForFlag(ENetworkFlag.isOnlineReliable, true)
			.pipe(
				mergeMap(_ => {
					const lsUserContactId: string = ContactsService.getUserContactId();
					return this.getLastSeq(ConversationService.C_CONVERSATIONS).pipe(
						tap((psLastSeq: string) => loOptions.since = psLastSeq),
						mergeMap(__ => this.isvcGroups.getUserGroupsPaths()),
						mergeMap((paUserGroupsPaths: string[]) => {
							loOptions.selector = this.createConversationsReplicationSelector(lsUserContactId, paUserGroupsPaths);

							return this.isvcStore.replicateToLocal(this.databaseId, loOptions).pipe(tap((poReplicationResult: IStoreReplicationToLocalResponse) => {
								poReplicationResult.docs?.forEach((poDoc: IStoreDocument) => {
									// On log si on réplique une donnée qui devrait rester sur le serveur.
									if (poDoc._id.startsWith(EPrefix.conversation) &&
										!(poDoc as IConversation).participants?.some((poParticipant: IParticipant) =>
											poParticipant.participantId === lsUserContactId || paUserGroupsPaths.includes(poParticipant.participantPath))
									)
										console.warn(`${ConversationService.C_LOG_ID}Conversation ${poDoc._id} replicated but the user is not a participant.`, poDoc, loOptions);
								});
							}));
						}),
						mergeMap((poReplicationResult: IStoreReplicationToLocalResponse) =>
							this.saveLastSeq(ConversationService.C_CONVERSATIONS, loOptions.since = poReplicationResult.last_seq).pipe(mapTo(poReplicationResult)
							)
						)
					);
				}),
				tap((poResult: IStoreReplicationToLocalResponse) => this.onConversationReplicated(poResult)),
				catchError(poError => this.onConversationReplicationError(poError))
			);
	}

	private onConversationReplicationError(poError: any): Observable<never> {
		return throwError(() => `La réplication des bases de conversations s'est terminée en erreur: ${poError}`);
	}

	private onConversationReplicated(poResult: IStoreReplicationResponse): void {
		console.debug(`${ConversationService.C_LOG_ID}Conversations replicated.`, poResult);
		this.raiseConversationUiEvent({ type: EConversationEvent.synchro } as IConversationUiEvent);
	}

	/** Permet de router vers une conversation.
	 * @param poConversation Conversation vers laquelle router.
	 * @param poOptions Options pour l'ouverture de la conversation.
	 */
	public routeToConversation(poConversation: IConversation, poQueryParams = {}, poState?: any): void {
		let loLoader: Loader;
		from(this.isvcLoading.create("Ouverture de la conversation en cours")).pipe(
			tap((poLoader: Loader) => loLoader = poLoader),
			mergeMap((poLoader: Loader) => poLoader.present()),
			mergeMap(() => this.ioRouter.navigate([ConversationService.C_CONVERSATIONS, poConversation._id], { queryParams: poQueryParams, state: poState })),
			tap(() => loLoader.dismiss()),
			finalize(() => loLoader.dismiss())
		)
			.subscribe();
	}

	/** Redirige l'utilisateur vers la page de création/modification d'une conversation.
	 * @param poConversation Conversation vers laquelle aller en mode édition, optionnel.
	 * @param paContacts Tableau des contacts participant à la conversation.
	 */
	public routeToConversationEdit(poConversation?: IConversation, paContacts?: Array<IContact>, paGroups?: Array<IGroup>): void {
		const loNavigationExtras: NavigationExtras = {
			state: {
				contacts: paContacts,
				groups: paGroups
			}
		};
		const laUrlParts: string[] = [ConversationService.C_CONVERSATIONS];

		if (poConversation)
			laUrlParts.push(poConversation._id, ERouteUrlPart.edit);
		else
			laUrlParts.push(ERouteUrlPart.new);

		this.ioRouter.navigate(laUrlParts, classToPlain(loNavigationExtras)); // Évite l'erreur de clonage de l'objet dans le state.
	}

	/** Envoi le message sur la base de données.
	 * @param poConversation Conversation dans laquelle le message est envoyé.
	 * @param poMessage Message qu'il faut envoyer.
	 */
	public sendMessage$(poConversation: IConversation, poMessage: Message): Observable<IStoreDataResponse[]>;
	/** Envoi les messages sur la base de données.
	 * @param poConversation Conversation dans laquelle le message est envoyé.
	 * @param paMessages Messages qu'il faut envoyer.
	 */
	public sendMessage$(poConversation: IConversation, paMessages: Message[]): Observable<IStoreDataResponse[]>;
	public sendMessage$(poConversation: IConversation, paMessages: Message[] | Message): Observable<IStoreDataResponse[]> {
		const laMessageDocs: Message[] = [];

		if (paMessages instanceof Array)
			laMessageDocs.push(...paMessages);
		else
			laMessageDocs.push(paMessages);

		this.removeNonPersistedAttributes(poConversation);
		const ldCreateDate = new Date;

		const laPendingMessageIds: IStoreDocument[] = [];
		laMessageDocs.forEach((poMessage: Message, pnIndex: number) => {
			poMessage.createDate = ldCreateDate;
			// On utilise l'index pour ajouter des millisecondes au getTime sinon dans la boucle les id seront identiques et génèreront des conflits.
			poMessage._id = `${IdHelper.buildId(EPrefix.message, IdHelper.getGuidFromId(poConversation._id, EPrefix.conversation))}_${DateHelper.addMilliseconds(ldCreateDate, pnIndex).getTime()}`;
			laPendingMessageIds.push({ _id: IdHelper.buildId(EPrefix.pending, poMessage._id) });
		});

		// Clonage du dernier message envoyé pour ne pas modifier sa référence dans l'objet de conversation s'il est sérialisé avant ledit objet.
		if (ArrayHelper.hasElements(laMessageDocs))
			poConversation.lastMessage = ObjectHelper.clone(ArrayHelper.getLastElement(laMessageDocs));

		return this.saveMessagesFileAttachments$(laMessageDocs).pipe(
			switchMap(() => this.saveMessagesEntityAttachments$(laMessageDocs, poConversation)),
			switchMap(() => combineLatest([
				this.isvcStore.putMultipleDocuments(
					[poConversation, ...laMessageDocs],
					this.databaseId,
					true
				),
				this.isvcStore.putMultipleDocuments(
					laPendingMessageIds,
					ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage)),
					true
				)
			])),
			tap(_ => this.execPendingSynchro()),
			map((paResults: IStoreDataResponse[][]) => paResults.flat())
		);
	}

	private saveMessagesFileAttachments$(laMessageDocs: Message[]): Observable<boolean> {
		return this.isvcGallery.save$(
			laMessageDocs.map(
				(poMessage: Message) => (poMessage.attachments?.filter((poAttachment: MessageAttachment) => poAttachment instanceof MessageFileAttachment) ?? [])
					.map((poAttachment: MessageFileAttachment) => poAttachment.dmsData)
			)
				.flat()
		);
	}

	private saveMessagesEntityAttachments$(laMessageDocs: Message[], poConversation: IConversation): Observable<boolean> {
		const laAttachments: MessageEntityAttachment[] = laMessageDocs.map(
			(poMessage: Message) =>
			(
				poMessage.attachments?.filter(
					(poAttachment: MessageAttachment) => poAttachment instanceof MessageEntityAttachment && !!poAttachment.entity
				) ??
				[]
			)
		)
			.flat() as MessageEntityAttachment[];

		const laEntities: Entity[] = laAttachments.map((poAttachment: MessageEntityAttachment) => poAttachment.entity);

		this.isvcEntityLink.cacheLinkToAdd(poConversation, laEntities);

		return this.isvcEntityLink.saveEntityLinks(poConversation);
	}

	/** Récupère un template de message qu'on peut remplir
	 * @param psMessage Texte du message.
	 * @param psConvGuid GUID de la conversation depuis laquelle le message doit être envoyé.
	 * @param peMessageType Type de message à récupérer.
	 * @param psParticipantPath Chemin vers le participant.
	 */
	public createMessage(psMessage: string): Message {
		return new Message({
			_id: "", // On délégue la création de l'id à la méthode d'envoi du message
			body: psMessage.trim(),
			senderContactPath: this.getCurrentUserContactPath()
		});
	}

	/** Met à jour la conversation sur la base de données.
	 * @param poActivity objet correspondant à une activité.
	 */
	public updateActivity(poActivity: IConversationActivity): Observable<IConversationActivity> {
		return this.saveActivity(poActivity)
			.pipe(catchError((poError) => throwError(() => ({ error: poError, reason: "Une erreur est survenue lors de la mise à jour de l'activité de l'utilisateur." }))));
	}

	/** Enregistre/Met à jour dans la base de données locale des conversations l'activité de l'utilisateur, puis la réplique sur le serveur.
	 * @param poActivity Activité de l'utilisateur qu'il faut enregistrer et synchroniser.
	 */
	private saveActivity(poActivity: IConversationActivity): Observable<IConversationActivity> {
		poActivity.lastActivityDate = new Date(); // Mise à jour de la date de dernière activité.
		poActivity.activityExpirationDate = DateHelper.addSeconds(poActivity.lastActivityDate, ConfigData.conversation?.conversationActivityExpirationDelaySeconds ?? 0);

		return this.isvcStore.put(poActivity, this.databaseId, true)
			.pipe(
				tap(_ => this.requestReplicationToServer()),
				mapTo(poActivity)
			);
	}

	/** Retourne les groupes des participants d'une conversation. */
	public getGroups(paParticipants?: IParticipant<IGroupMember>[]): IParticipant<IGroup>[] {
		return paParticipants
			?.filter((poParticipant: IParticipant<IGroupMember>) =>
				GroupsService.isGroup(poParticipant.participantId)
			) as IParticipant<IGroup>[] ?? []; // Récupère les groupes.
	}

	/** Retourne les contacts des participants d'une conversation. */
	public getContacts(paParticipants?: IParticipant<IGroupMember>[]): IParticipant<IContact>[] {
		return paParticipants
			?.filter((poParticipant: IParticipant<IGroupMember>) => ContactsService.isContact(poParticipant.participantId)
			) ?? []; // Récupère les contacts.
	}

	/** Démarre la réplication de la base de données des conversations pour écouter les changements opérés dessus.
	 * @param pfOnChange Méthode à exécuter lors d'un changement sur la base de données.
	 * @param psConvGuid Guid de la conversation que l'on veut répliquer.
	 */
	public startConversationDetailsLiveReplication(pfOnChange: (poEvent: IConversationEvent) => void, psConvGuid: string): Observable<IStoreDocument[]> {

		return this.getLastSeq(psConvGuid)
			.pipe(
				mergeMap((psResult: string) => this.isvcFlag.waitForFlag(ENetworkFlag.isOnlineReliable, true).pipe(mapTo(psResult))),
				mergeMap((psResult: string) => this.startConversationDetailsLiveReplicationSince(psResult, psConvGuid, pfOnChange))
			);
	}

	/** Démarre la réplication de la base de données des conversations à partir d'un numéro de séquence pour écouter les changements opérés dessus.
	 * @param pnSince Séquence à partir de laquelle on doit répliquer.
	 * @param psConvGuid Guid de la conversation que l'on veut répliquer.
	 * @param pfOnChange Fonction à appeler lors d'un changement sur la base.
	 */
	private startConversationDetailsLiveReplicationSince(pnSince: string, psConvGuid: string,
		pfOnChange: (poEvent: IConversationEvent) => void): Observable<IStoreDocument[]> {

		const loOptions: IStoreReplicationOptions = {
			live: true,
			retry: true,
			since: pnSince,
			selector: this.createConversationDetailsSelector(psConvGuid),
			style: EReplicationStyle.mainOnly
		};

		const loDatabaseReplication: PouchDB.Replication.Replication<any> =
			this.conversationDatabase.getRemoteInstance().replicate.to(this.conversationDatabase.getLocalInstance(), loOptions);

		const loChange$: Observable<IStoreDocument[]> = fromEvent(loDatabaseReplication, "change")
			.pipe(
				tap((poChange: PouchDB.Replication.ReplicationResult<IStoreDocument>) => {
					console.debug(`${ConversationService.C_LOG_ID}Processing replication change event for conversation '${psConvGuid}'.`, poChange);
					if (poChange && ArrayHelper.hasElements(poChange.docs))
						poChange.docs.forEach((poDoc: IStoreDocument) => this.onDocumentChange(poDoc, this.conversationDatabase.id, pfOnChange));
				}),
				mergeMap((poChange: PouchDB.Replication.ReplicationResult<IStoreDocument>) =>
					// On sauvegarde le dernier numéro de séquence pour la conversation (string mais typé number...).
					this.isvcStore.markSynchro(this.conversationDatabase, this.conversationDatabase.getRemoteInstance().name, this.conversationDatabase.getLocalInstance().name, poChange as any as IStoreReplicationResponse)
						.pipe(mapTo(poChange))
				),
				concatMap((poChange: PouchDB.Replication.ReplicationResult<IStoreDocument>) =>
					// On sauvegarde le dernier numéro de séquence pour la conversation (string mais typé number...).
					this.saveLastSeq(psConvGuid, loOptions.since = poChange.last_seq.toString())
						.pipe(
							catchError(poError => this.onError(poError, "Erreur lors de la sauvegarde de la dernière mise à jour de la conversation.")),
							mapTo(poChange.docs)
						)
				)
			);
		const loError$: Observable<never> = fromEvent(loDatabaseReplication, "error")
			.pipe(mergeMap(poError => { console.error(`${ConversationService.C_LOG_ID}Error occured on conversation changes feed for conversation '${psConvGuid}'.`, poError); return throwError(() => poError); }));

		return merge(loChange$, loError$).pipe(finalize(() => loDatabaseReplication.cancel()));
	}

	/** Crée un selecteur pour filtrer le détail d'une converation.
	 * @param psConvGuid Guid de la conversation.
	 */
	private createConversationDetailsSelector(psConvGuid: string): PouchDB.Find.Selector {
		return {
			$or: [
				{
					_id: {
						$eq: IdHelper.buildId(EPrefix.conversation, psConvGuid)
					}
				},
				{
					_id: {
						$gte: `${EPrefix.message}${psConvGuid}_`,
						$lte: `${EPrefix.message}${psConvGuid}_${Store.C_ANYTHING_CODE_ASCII}`
					}
				},
				{
					_id: {
						$gte: `${EPrefix.activity}${psConvGuid}_`,
						$lte: `${EPrefix.activity}${psConvGuid}_${Store.C_ANYTHING_CODE_ASCII}`
					}
				},
			]
		};
	}

	/**
	 * @param poDoc Document modifié.
	 * @param psDatabaseId Identifiant de la base de données dont est issu le changement.
	 * @param pfOnChange Fonction à exécuter lors d'un changement.
	 */
	private onDocumentChange(poDoc: IStoreDocument, psDatabaseId: string, pfOnChange: (poEvent: IConversationEvent) => void): void {
		const loConversationEvent: IConversationEvent = {
			type: EApplicationEventType.ConversationEvent,
			createDate: new Date(),
			data: { eventType: EConversationEvent.default, document: poDoc }
		};

		if (loConversationEvent.data.document)
			StoreHelper.updateDocumentCacheData(loConversationEvent.data.document, { databaseId: psDatabaseId });

		pfOnChange(loConversationEvent);
	}

	/** Permet de télécharger les conversations de l'utilisateur depuis le serveur et de les retourner.
	 * @param psUserContactId Id du contact de l'utilisateur.
	 * @param poOptions Objet correspondant aux paramètres de récupération des conversations, réalise un tri par défaut.
	 */
	public downloadConversations(psUserContactId: string, poOptions?: IGetConversationOptions, pbLive?: boolean): Observable<IConversation[]> {
		return this.isvcFlag.waitForFlag(ENetworkFlag.isOnlineReliable, true)
			.pipe(
				mergeMap(() => this.replicateConversationsToLocal$(pbLive)),
				switchMap(_ => this.getConversations(psUserContactId, { ...poOptions, live: pbLive }))
			);
	}

	public async optimizeDatabasesAsync(): Promise<Database> {
		const lsLoaderBaseText = "Optimisation des conversations :\n";
		let loLoader: Loader | undefined;

		try {
			loLoader = await this.isvcLoading.create(`${lsLoaderBaseText}Envoi des données`);
			await loLoader.present();
			await this.replicateConversationsToServer$(false).toPromise();
			loLoader.text = `${lsLoaderBaseText}Nettoyage des conversations locales`;
			await this.isvcStore.destroyAndCreateLocalInstanceDatabaseAsync(this.conversationDatabase);
			loLoader.text = `${lsLoaderBaseText}Téléchargement des conversations`;
			await this.replicateConversationsToLocal$(false).toPromise();
			return this.conversationDatabase;
		}
		finally {
			loLoader?.dismiss();
		}
	}

	/** Crée un selecteur pour filtrer les conversations d'un utilisateur.
	 * ### ATTENTION : Typage en any pour les champs spécifiques.
	 * @param psUserContactId Identifiant de l'utilisateur.
	 * @param paUserGroupsPaths Chemins des groupes auxquels appartient l'utilisateur.
	 */
	private createConversationsReplicationSelector(psUserContactId: string, paUserGroupsPaths: string[] = []): PouchDB.Find.Selector {
		return {
			$or: [
				{
					_id: {
						$gte: EPrefix.conversation,
						$lte: EPrefix.conversation + Store.C_ANYTHING_CODE_ASCII
					},
					participants: {
						$elemMatch: {
							$or: [
								{
									participantId: {
										$eq: psUserContactId
									}
								},
								{
									participantPath: {
										$in: paUserGroupsPaths
									}
								}
							]
						}
					}
				},
				{
					_id: {
						$gte: EPrefix.activity,
						$lte: EPrefix.activity + Store.C_ANYTHING_CODE_ASCII,
						$regex: `${EPrefix.activity}.*${ContactsService.getUserContactId()}`
					}
				}
			]
		};
	}

	/** Télécharge une conversation, `undefined` si non trouvée.
	 * @param psConvId Id de la conversation.
	 */
	public downloadConversation(psConvId: string): Observable<IConversation | undefined> {
		const loOptions: PouchDB.Replication.ReplicateOptions = {
			live: false,
			selector: {
				_id: {
					$eq: psConvId
				}
			}
		};

		return merge(this.getLastSeq(psConvId), this.getLastSeq(ConversationService.C_CONVERSATIONS))
			.pipe(
				reduce((psMaxSeq: string, psCurrent: string) => {
					if (StringHelper.isBlank(psMaxSeq) || StoreHelper.getSequenceNumber(psCurrent) > StoreHelper.getSequenceNumber(psMaxSeq))
						psMaxSeq = psCurrent;
					return psMaxSeq;
				}),
				mergeMap((psLastSeq: string) => {
					loOptions.since = psLastSeq;
					return this.isvcStore.replicateToLocal(this.databaseId, loOptions);
				}),
				mergeMap((poReplicationResult: IStoreReplicationToLocalResponse) => this.saveLastSeq(psConvId, loOptions.since = poReplicationResult.last_seq)),
				mergeMap(_ => this.getConversation(psConvId))
			);
	}

	/** Permet de télécharger les activités et messages d'une conversation de l'utilisateur depuis le serveur.
	 * @param psConvGuid Id de la conversation.
	 */
	public downloadConversationDetails(psConvGuid: string): Observable<IStoreReplicationToLocalResponse> {
		const loOptions: PouchDB.Replication.ReplicateOptions = {
			live: false,
			selector: this.createConversationDetailsSelector(psConvGuid)
		};

		const loPerfManager = new PerformanceManager;

		return defer(() => {
			loPerfManager.markStart();
			return this.getLastSeq(psConvGuid);
		})
			.pipe(
				mergeMap((psResult: string) => {
					loOptions.since = psResult;
					return this.isvcStore.replicateToLocal(this.databaseId, loOptions);
				}),
				mergeMap((poResult: IStoreReplicationToLocalResponse) => this.saveLastSeq(psConvGuid, loOptions.since = poResult.last_seq).pipe(mapTo(poResult))),
				tap(() => console.debug(`${ConversationService.C_LOG_ID}Conversation '${psConvGuid}' details download time : ${loPerfManager.markEnd().measure()}ms`))
			);
	}

	private deletePendingMessages(paPendingDocs: IStoreDocument[]): Observable<boolean> {
		return this.isvcStore.deleteMultipleDocuments(paPendingDocs)
			.pipe(
				catchError(poError => this.raiseDeleteError(poError, EConversationError.messages))
			);
	}

	/** Permet de récupérer l'id d'un contact depuis une activité.
	 * @param poActivity Activité.
	 */
	public static getContactIdFromActivity(poActivity: IConversationActivity): string {
		return IdHelper.buildId(EPrefix.contact, IdHelper.getGuidFromId(poActivity._id, EPrefix.contact));
	}

	public subscribe(pfNext: Function, pfError?: Function, pfComplete?: Function): void {
		this.moAppEventSubject.asObservable()
			.subscribe(
				(poResult: IConversationEvent) => pfNext(poResult),
				poError => pfError ? pfError(poError) : undefined,
				() => pfComplete ? pfComplete() : undefined
			);
	}

	private raiseAppConversationEvent(poEvent: IConversationEvent): void {
		this.moAppEventSubject.next(poEvent);
	}

	/** Sauvegarde le dernier numéro de séquence de réplication.
	 * @param psId Id de la ressource pour laquelle on veut sauvegarder le dernier numéro de séquence.
	 * @param psLastSeq Séquence de réplication.
	 */
	@Queue<ConversationService, Parameters<ConversationService["saveLastSeq"]>, ReturnType<ConversationService["saveLastSeq"]>>()
	private saveLastSeq(psId: string, psLastSeq: string): Observable<IStoreDataResponse> {
		const lsId: string = this.getLastSeqDocumentId(psId);

		if (!StringHelper.isBlank(psLastSeq)) { // Si on a un numéro de séquence valide on l'enregistre en base.
			return this.isvcStore.getLocal<ILocalConversation>(lsId, this.databaseId)
				.pipe(
					mergeMap((poResult?: ILocalConversation) => {
						if (poResult?.lastSeq === psLastSeq) // Même dernier numéro de séquence, on ne fait rien.
							return of({} as IStoreDataResponse);
						else {
							const loLocalConv: ILocalConversation = {
								_id: lsId,
								_rev: poResult?._rev,
								lastSeq: psLastSeq
							};

							return this.isvcStore.putLocal(loLocalConv, this.databaseId);
						}
					})
				);
		}
		else // Sinon on ne fait rien.
			return of({} as IStoreDataResponse);
	}

	/** Récupère le dernier numéro de séquence de réplication.
	 * @param psId Id de la ressource pour laquelle on veut le dernier numéro de séquence.
	 */
	private getLastSeq(psId: string): Observable<string | undefined> {
		return this.isvcStore.getLocal<ILocalConversation>(this.getLastSeqDocumentId(psId), this.databaseId)
			.pipe(map((poResult?: ILocalConversation) => poResult?.lastSeq));
	}

	private getLastSeqDocumentId(psId: string): string {
		return `${EPrefix.conversationMeta}${ContactsService.getUserContactId()}-${psId}`;
	}

	/** Supprime le dernier numéro de séquence de réplication pour une conversation.
	 * @param psConvId Identifiant de la conversation dont il faut supprimer le dernier numéro de séquence.
	 */
	private deleteConversationDetailsLastSeq(psConvId: string): Observable<boolean> {
		return defer(() => {
			console.debug(`${ConversationService.C_LOG_ID}Deleting last sequence for conversation '${psConvId}'.`);
			return this.isvcStore.getLocal(this.getLastSeqDocumentId(psConvId), this.databaseId);
		})
			.pipe(
				mergeMap((poResult?: IStoreDocument) => poResult ? this.isvcStore.deleteLocal(poResult) : of(null)),
				map((poResult: IStoreDataResponse | null) => poResult ? poResult.ok : true),
				finalize(() => console.debug(`${ConversationService.C_LOG_ID}Last sequence deleted for conversation '${psConvId}'.`))
			);
	}

	public getMessageIdFromPendingId(psPendingId: string): string {
		return ArrayHelper.getLastElement(psPendingId.split(EPrefix.pending));
	}

	/** Met à jour la conversation dans la base de données.
	 * @param poConversation Conversation à mettre à jour.
	 * @param paNewMembers Tableau du nouveau jeu de contacts/groupes de la conversation.
	 * @param paOldMembers Tableau de l'ancien jeu de contacts/groupes de la conversation.
	 */
	public updateConversation(poConversation: IConversation, paNewMembers: Array<IContact | IGroup> = [], paOldMembers: Array<IContact | IGroup> = [])
		: Observable<boolean> {
		// Suppression des détails des participants pour ne pas les sérialiser.
		const loParticipantDetailsById: Map<string, IParticipantDetails> = this.removeParticipantDetailsAndGetThemById(poConversation);
		let loUpdateConvDetails: IUpdateConversationDetails;
		let loConversationDetails$: Observable<IUpdateConversationDetails>;

		this.removeNonPersistedAttributes(poConversation);

		if (ArrayHelper.hasElements(paNewMembers))
			loConversationDetails$ = this.updateParticipants$(poConversation, paNewMembers, paOldMembers);
		else
			loConversationDetails$ = of({ participantDetails: [], participantsLengthBeforeAdd: poConversation.participants.length } as IUpdateConversationDetails);

		return loConversationDetails$
			.pipe(
				tap((poUpdateConvDetails: IUpdateConversationDetails) => loUpdateConvDetails = poUpdateConvDetails),
				tap(_ => this.initConversationDefaultTitle(poConversation)),
				mergeMap(_ => this.isvcStore.put(poConversation, this.databaseId)),
				tap(_ => this.restoreParticipantDetails(poConversation, loParticipantDetailsById, loUpdateConvDetails)),
				map((poResponse: IStoreDataResponse) => poResponse.ok),
				finalize(() => this.requestReplicationToServer())
			);
	}

	/** Supprime le détail de chaque participant car on ne veut pas les enregistrer en base de données
	 * et les récupère sous forme d'une map indexée par identifiant de participant.
	 * @param poConversation Conversation à mettre à jour.
	 */
	private removeParticipantDetailsAndGetThemById(poConversation: IConversation): Map<string, IParticipantDetails> {
		const loParticipantDetailsById = new Map<string, IParticipantDetails>();

		poConversation.participants.forEach((poParticipant: IParticipant) => {
			// Si le participant possède des champs facultatifs, on les stocke sinon pas la peine.
			if (poParticipant.activity || poParticipant.avatar || poParticipant.model) {
				loParticipantDetailsById.set(
					poParticipant.participantId,
					{ activity: poParticipant.activity, avatar: poParticipant.avatar, model: poParticipant.model } as IParticipantDetails
				);

				this.prepareParticipantForSave(poParticipant);
			}
		});

		return loParticipantDetailsById;
	}

	/** Crée le titre de la conversation par défaut en concaténant tous les participants de celle-ci.
	 * @param poConversation Conversation dont il faut construire le titre.
	 */
	private initConversationDefaultTitle(poConversation: IConversation): void {
		poConversation.defaultTitle = this.getDefaultTitle(poConversation);
	}

	/** Retourne le titre par défaut d'une conversation. */
	public getDefaultTitle(poConversation: IConversation): string {
		const lsUserContactId: string = ContactsService.getUserContactId();
		const lsTitle: string = poConversation.participants
			// Ne prend pas en compte l'utilisateur courant.
			.filter((poContactOrGroupe: IParticipant<IContact | IGroup>): boolean => poContactOrGroupe.participantId !== lsUserContactId)
			// Tri pour mettre les groupes en tête, puis les contacts.
			.sort((poParticipant1: IParticipant<IContact | IGroup>, poParticipant2: IParticipant<IContact | IGroup>): number => {
				const lsPrefix1: string = IdHelper.getPrefixFromId(poParticipant1.participantId);
				const lsPrefix2: string = IdHelper.getPrefixFromId(poParticipant2.participantId);

				return lsPrefix2.localeCompare(lsPrefix1);
			})
			// Ne récupère que le label.
			.map((poParticipant: IParticipant<IContact | IGroup>) => {
				return (poParticipant.model as IContact)?.firstName ? ContactHelper.getCompleteFormattedName(poParticipant.model as IContact) : poParticipant.label;
			})
			// Concatène les labels des participants.
			.join(", ");

		return StringHelper.isBlank(lsTitle) ? "Conversation avec moi même." : lsTitle;
	}

	/** Met à jour les participants de la conversation en supprimant ceux qui ne sont plus présents et en ajoutant les nouveaux.
	 * @param poConversation Conversation courante à mettre à jour.
	 * @param paNewParticipants Tableau du nouveau jeu de contacts/groupes de la conversation.
	 * @param paOldParticipants Tableau de l'ancien jeu de contacts/groupes de la conversation.
	 * @returns Le tableau des avatars et des activités des participants qu'il faut ajouter à la conversation mais pas en base de données.
	 */
	private updateParticipants$(poConversation: IConversation, paNewParticipants: Array<IContact | IGroup>, paOldParticipants: Array<IContact | IGroup>)
		: Observable<IUpdateConversationDetails> {

		const loUpdateConvDetails: IUpdateConversationDetails = {
			participantsLengthBeforeAdd: poConversation.participants.length - this.removeConversationParticipants(poConversation, paNewParticipants, paOldParticipants),
			participantDetails: []
		};
		let loManageUserParticipant$: Observable<IParticipantDetails[] | undefined>;

		if (!this.isUserContactParticipant(poConversation)) { // Si l'utilisateur n'est pas un participant contact de la conversation.
			loManageUserParticipant$ = this.isUserInGroupParticipant$(poConversation)
				.pipe(
					mergeMap((pbIsInGroup: boolean) => pbIsInGroup ? of([]) : this.addUserContactInParticipants$(poConversation)),
					tap((paDetails: IParticipantDetails[]) => loUpdateConvDetails.participantDetails.push(...paDetails))
				);
		}
		else
			loManageUserParticipant$ = of(undefined);

		return loManageUserParticipant$
			.pipe(
				mergeMap(_ => this.execUpdateParticipants$(poConversation, paNewParticipants, paOldParticipants, loUpdateConvDetails)),
				tap(() => this.logUpdatedParticipants(poConversation, paNewParticipants, paOldParticipants))
			);
	}

	/** Log les modifications des participants d'une conversation.
	 * @param poConversation Conversation mis à jour.
	 * @param paNewParticipants Tableau du nouveau jeu de contacts/groupes de la conversation.
	 * @param paOldParticipants Tableau de l'ancien jeu de contacts/groupes de la conversation.
	 */
	private logUpdatedParticipants(poConversation: IConversation, paNewParticipants: Array<IContact | IGroup>, paOldParticipants: Array<IContact | IGroup>): void {
		const lsLogMessage = `Participants in conversation '${poConversation._id}' have been modified.`;
		const loUpdateParticipants = {
			oldParticipants: paOldParticipants.map((poOldParticipant: IContact | IGroup) => poOldParticipant._id),
			newParticipants: paNewParticipants.map((poNewParticipants: IContact | IGroup) => poNewParticipants._id),
		};
		this.isvcLogger.action(ConversationService.C_LOG_ID, lsLogMessage, ELogActionId.updateConversationParticipants, loUpdateParticipants);
	}

	private execUpdateParticipants$(poConversation: IConversation, paNewParticipants: Array<IContact | IGroup>,
		paOldParticipants: Array<IContact | IGroup>, poUpdateConvDetails: IUpdateConversationDetails): Observable<IUpdateConversationDetails> {

		return this.getParticipantsToSendMessage$(paNewParticipants, paOldParticipants)
			.pipe(
				// Envoie des messages d'invitation pour les participants en paramètre.
				mergeMap((paParticipantsToSendMessage: (IContact | IGroup)[]) => {
					const loAddResult: IAddConversationParticipantsResult = this.addConversationParticipants(poConversation, paNewParticipants, paOldParticipants);
					return this.sendInvitationMessages$(poConversation, loAddResult.newParticipantInfos, paParticipantsToSendMessage).pipe(mapTo(loAddResult.participantsDetails));
				}),
				tap((paResults: IParticipantDetails[]) => poUpdateConvDetails.participantDetails.push(...paResults)),
				mapTo(poUpdateConvDetails)
			);
	}

	private isUserContactParticipant(poConversation: IConversation): boolean {
		const lsUserContactId: string = UserHelper.getUserContactId();

		return poConversation.participants.some((poParticipant: IParticipant) => poParticipant.participantId === lsUserContactId);
	}

	private isUserInGroupParticipant$(poConversation: IConversation): Observable<boolean> {
		const lsUserContactId: string = UserHelper.getUserContactId();
		const laParticipantGroups: IParticipant<IGroup>[] =
			poConversation.participants.filter((poParticipant: IParticipant) => GroupsService.isGroup(poParticipant.participantId)) as IParticipant<IGroup>[];

		if (ArrayHelper.hasElements(laParticipantGroups)) { // S'il y a des groupes participants, il faut récupérer leurs contacts associés.
			const laParticipantGroupIds: string[] = laParticipantGroups.map((poParticipant: IParticipant<IGroup>) => poParticipant.participantId);

			return this.isvcGroups.getGroupContacts(laParticipantGroupIds)
				.pipe(
					map((poContactsByGroupId: Map<string, IContact[]>) =>
						!!ArrayHelper.flat(MapHelper.valuesToArray(poContactsByGroupId)).find((poContact: IContact) => poContact._id === lsUserContactId)
					)
				);
		}
		else // Aucun groupe participant, utilisateur non présent dans un groupe.
			return of(false);
	}

	/** Ajout le contact courant comme participant à la conversation s'il n'y est pas déjà.
	 * @param poConversation La conversation dans laquelle l'ajouter.
	 * @returns Les détails de l'utilisateur courant.
	 */
	private addUserContactInParticipants$(poConversation: IConversation): Observable<IParticipantDetails[]> {
		return this.isvcContacts.getContact(UserHelper.getUserContactId()).pipe(
			map((poUserContact?: IContact) => {
				if (poUserContact)
					return this.addConversationParticipants(poConversation, [poUserContact], []).participantsDetails;
				else {
					console.error(`${ConversationService.C_LOG_ID}User contact '${UserHelper.getUserContactId()}' not found.`);
					return [];
				}
			})
		);
	}

	/** Supprime les participants qui ne doivent plus faire parti de la conversation et retourne combien il y a eu de suppressions.
	 * @param poConversation Converrsation qu'il faut mettre à jour.
	 * @param paNewParticipants Tableau du nouveau jeu de contacts/groupes de la conversation.
	 * @param paOldParticipants Tableau de l'ancien jeu de contacts/groupes de la conversation.
	 */
	private removeConversationParticipants(poConversation: IConversation, paNewParticipants: Array<IContact | IGroup>, paOldParticipants: Array<IContact | IGroup>)
		: number {

		const laParticipantsToRemove: Array<IContact | IGroup> =
			paOldParticipants.filter((poOld: IContact | IGroup) => paNewParticipants.every((poNew: IContact | IGroup) => poOld._id !== poNew._id));

		laParticipantsToRemove.forEach((poParticipantToRemove: IContact | IGroup) =>
			ArrayHelper.removeElementByFinder(poConversation.participants, (poParticipant: IParticipant) => poParticipant.participantId === poParticipantToRemove._id)
		);

		return laParticipantsToRemove.length;
	}

	private addConversationParticipants(poConversation: IConversation, paNewParticipants: Array<IContact | IGroup>, paOldParticipants: Array<IContact | IGroup>)
		: IAddConversationParticipantsResult {

		const laParticipantsToAdd: Array<IContact | IGroup> =
			paNewParticipants.filter((poNew: IContact | IGroup) => paOldParticipants.every((poOld: IContact | IGroup) => poOld._id !== poNew._id));

		const loResult: IAddConversationParticipantsResult = { newParticipantInfos: [], participantsDetails: [] };

		if (ArrayHelper.hasElements(laParticipantsToAdd)) {
			laParticipantsToAdd.forEach((poNewParticipant: IContact | IGroup) => {
				const loParticipant: IParticipant = this.createParticipant(poConversation, poNewParticipant);

				// On enregistre l'avatar et l'activité du participant dans un tableau avant de les supprimer du participant (pour ne pas être présent en bdd).
				loResult.participantsDetails.push({ avatar: loParticipant.avatar, activity: loParticipant.activity, model: loParticipant.model });
				this.prepareParticipantForSave(loParticipant);

				poConversation.participants.push(loParticipant);
				loResult.newParticipantInfos.push({ member: poNewParticipant, participant: loParticipant } as IParticipantInfo);
			});
		}

		return loResult;
	}

	/** Retourne un tableau des participants pour lesquels on doit envoyer un message d'ajout.
	 * @param paNewParticipants Tableau du nouveau jeu de contacts/groupes de la conversation.
	 * @param paOldParticipants Tableau de l'ancien jeu de contacts/groupes de la conversation.
	 */
	private getParticipantsToSendMessage$(paNewParticipants: Array<IContact | IGroup>, paOldParticipants: Array<IContact | IGroup>): Observable<(IContact | IGroup)[]> {
		const laOldGroups: IGroup[] = paOldParticipants.filter((poParticipant: IContact | IGroup) => GroupsService.isGroup(poParticipant._id)) as IGroup[];
		const laOldContacts: IContact[] = paOldParticipants.filter((poParticipant: IContact | IGroup) => ContactsService.isContact(poParticipant._id)) as IContact[];
		// On récupère la liste des membres des anciens contacts pour après l'ajouter à la liste des anciens contacts.
		return this.isvcGroups.getGroupContactsArray(laOldGroups.map((poOldGroup: IGroup) => poOldGroup._id))
			.pipe(
				map((paContacts: IContact[]) => {
					// On ajoute les membres des anciens groupes dans la liste des anciens contacts.
					const laOldContactsWithMembers: IContact[] = ArrayHelper.unique([...paContacts, ...laOldContacts], (poContact: IContact) => poContact._id);
					const laNewGroups: IGroup[] = paNewParticipants.filter((poParticipant: IContact | IGroup) => GroupsService.isGroup(poParticipant._id)) as IGroup[];
					const laNewContacts: IContact[] = paNewParticipants.filter((poParticipant: IContact | IGroup) => ContactsService.isContact(poParticipant._id)) as IContact[];
					const laGroupsToSendMessage: Array<IGroup> =
						laNewGroups.filter((poNewGroup: IGroup) => laOldGroups.every((poOldGroup: IGroup) => poOldGroup._id !== poNewGroup._id));
					const laContactsToSendMessage: Array<IContact> =
						laNewContacts.filter((poNewContact: IContact) => laOldContactsWithMembers.every((poOldContact: IContact) => poOldContact._id !== poNewContact._id));

					return [...laGroupsToSendMessage, ...laContactsToSendMessage];
				})
			);
	}

	/** Prépare le participant à la sauvegarde en supprimant les champs inutiles.
	 * @param loParticipant Participant à préparer.
	 */
	private prepareParticipantForSave(loParticipant: IParticipant<IContact | IGroup>): void {
		delete loParticipant.avatar;
		delete loParticipant.activity;
		delete loParticipant.model;
	}

	/** Supprime des attributs qui ne doivent pas être présents en base de données. Un bug ajoute des attributs qui ont un effet de bord. #18019 */
	private removeNonPersistedAttributes(poConversation: IConversation): void {
		// Cette fonction ne devrait pas être nécessaire mais suite au bug #18019 et à une mauvaise utilisation du typage, il faut supprimer des attributs.
		if (poConversation) {
			delete poConversation["userActivity"];
			delete poConversation["lastMessageAvatar"];
			delete poConversation["conversationAvatar"];
			delete poConversation["subtitle"];
		}
	}

	/** Remet les détails de chaque participant (anciens et nouveaux) dans la conversation.
	 * @param poConversation Conversation à mettre à jour.
	 * @param poParticipantDetailsById Map du détail de chaque participant indexé par identifiant de participant.
	 * @param poUpdateConvDetails Détail de la mise à jour de la conversation.
	 */
	private restoreParticipantDetails(poConversation: IConversation, poParticipantDetailsById: Map<string, IParticipantDetails>,
		poUpdateConvDetails: IUpdateConversationDetails): void {

		// Réajout des détails dans les participants (hors participants ajoutés).
		poConversation.participants.forEach((poParticipant: IParticipant, pnIndex: number) => {
			if (poParticipantDetailsById.has(poParticipant.participantId))
				poConversation.participants[pnIndex] = { ...poParticipant, ...poParticipantDetailsById.get(poParticipant.participantId) };
		});

		// Ajout des détails dans les nouveaux participants.
		poUpdateConvDetails.participantDetails.forEach((poItem: IParticipantDetails, pnIndex: number) => {
			// L'index initial où remettre l'avatar et l'activité est l'ancienne longueur du tableau de participants.
			const loCurrentParticipant: IParticipant = poConversation.participants[poUpdateConvDetails.participantsLengthBeforeAdd + pnIndex];

			loCurrentParticipant.avatar = poItem.avatar;
			loCurrentParticipant.activity = poItem.activity;
			loCurrentParticipant.model = poItem.model;
		});
	}

	/** Envoie les messages qui indiquent qu'un participant a été ajouté.
	 * @param poConversation Conversation dans laquelle il faut envoyer des messages d'invitation.
	 * @param paNewParticipantsInfo  Tableau des informations des nouveaux participants.
	 * @param paParticipantsToSendMessage Tableau des nouveaux participants pour lesquels ont doit envoyer un message.
	 */
	private sendInvitationMessages$(poConversation: IConversation, paNewParticipantsInfo: IParticipantInfo[], paParticipantsToSendMessage: (IContact | IGroup)[]): Observable<IStoreDataResponse[]> {
		const lsUserName: string = ContactHelper.getCompleteFormattedName(UserData.current?.firstName ?? "", UserData.current?.lastName ?? "");
		const laMessagesToSend: Message[] = [];

		paNewParticipantsInfo.forEach((poNewParticipantInfo: IParticipantInfo, pnIndex: number) => {
			// Si 'poNewParticipantInfo' fait parti des nouveaux participants, on envoie un message le concernant.
			if (paParticipantsToSendMessage.some((poParticipant: (IContact | IGroup)) => poParticipant._id === poNewParticipantInfo.member._id))
				laMessagesToSend.push(this.getInvitationMessage(poConversation, poNewParticipantInfo, lsUserName, pnIndex));
		});

		const loEvent: IConversationUiEvent = {
			convId: poConversation._id,
			newMessages: laMessagesToSend,
			type: EConversationEvent.update
		};

		this.raiseConversationUiEvent(loEvent);

		return this.sendMessage$(poConversation, laMessagesToSend);
	}

	private getInvitationMessage(poConversation: IConversation, poParticipantInfo: IParticipantInfo, lsUserName: string, pnIndex: number): Message {
		const loMember: IContact | IGroup = poParticipantInfo.member;
		const lbIsContact: boolean = ContactsService.isContact(loMember);
		const lsParticipantName: string = lbIsContact ?
			ContactHelper.getCompleteFormattedName(loMember as IContact) : (loMember as IGroup).name;
		const loMessage: Message = this.createMessage(
			`${lsUserName} a invité ${lbIsContact ? "" : "le groupe "}${lsParticipantName} à suivre la conversation.`
		);

		loMessage.isAutoGenerated = true;
		loMessage.participant = poParticipantInfo.participant;

		loMessage._id += ((loMessage.createDate ?? new Date).getTime() + pnIndex);

		return loMessage;
	}

	/** Crée un participant à partir du contact (avec cacheData) de l'identifiant de la conversation.
	 * @param poConvData Conversation ou identifiant de la conversation.
	 * @param poModel Modèle à partir duquel on veut créer le participant.
	 */
	private createParticipant<T extends IGroupMember>(poConvData: IConversation | string, poModel?: T): IParticipant<T>;
	/** Crée un participant à partir du contact (avec cacheData) de l'identifiant de la conversation.
	 * @param poConvData Conversation ou identifiant de la conversation.
	 * @param psPath Chemin du participant.
	 * @param poModel Modèle à partir duquel on veut créer le participant.
	 */
	private createParticipant<T extends IGroupMember>(poConvData: IConversation | string, psPath: string, poModel: T): IParticipant<T>;
	private createParticipant<T extends IGroupMember>(poConvData: IConversation | string, poModelOrPath?: string | T, poModel?: T): IParticipant<T> {
		let loModel: T | undefined;
		let lsPath: string;

		if (typeof poModelOrPath === "string") {
			loModel = poModel!; // Si on a un chemin, c'est qu'on a un contact/groupe dans les paramètres.
			lsPath = poModelOrPath as string;
		}
		else if (poModelOrPath) {
			loModel = poModelOrPath;
			try { lsPath = Store.getDocumentPath(loModel); } // Si le contact n'a pas de cacheData, ce n'est pas normal, on lève une erreur.
			catch (poError) { throw new Error(`Le contact "${JSON.stringify(loModel)}"devrait avoir un cacheData !`); }
		}

		const lbIsContact: boolean = ContactsService.isContact(loModel);
		const loParticipant: IParticipant<T> = {
			model: loModel,
			label: lbIsContact ? ContactHelper.getCompleteFormattedName(loModel as unknown as IContact, true) : (loModel as unknown as IGroup)?.name,
			participantPath: lsPath,
			participantId: loModel?._id,
			activity: this.createDefaultActivity(IdHelper.getGuidFromId(typeof poConvData === "string" ? poConvData : poConvData._id, EPrefix.conversation), lsPath),
			avatar: lbIsContact ? ContactsService.createContactAvatar(loModel as unknown as IContact) : GroupsService.createGroupAvatar(loModel as unknown as IGroup)
		};

		return loParticipant;
	}

	/** Crée un participant à partir du chemin vers celui-ci et de l'identifiant de la conversation.
	 * @param poConversation Conversation dont il faut créer les paticipants.
	 * @param paParticipantPaths Tableau des chemins vers les participants.
	 * @param psUserContactId
	 */
	private createParticipants$(poConversation: IConversation, paParticipantPaths: string[], psUserContactId: string): Observable<IParticipant<IContact>[]> {
		const loContactPathsAndGroupIds: IContactPathsAndGroupIds = this.getContactPathsAndGroupIds(paParticipantPaths, psUserContactId);

		return this.innerCreateParticipants_getContacts$(loContactPathsAndGroupIds.contactPaths, poConversation)
			.pipe(
				mergeMap((paContacts: IContact[]) => {
					return this.innerCreateParticipants_getGroupContacts$(loContactPathsAndGroupIds.groupIds, poConversation)
						.pipe(map((paGroupContacts: IContact[]) => paContacts.concat(paGroupContacts)));
				}),
				map((paContacts: IContact[]) => {
					const laParticipants: IParticipant<IContact>[] = [];

					paContacts.forEach((poContact: IContact) => {
						if (poContact)
							laParticipants.push(this.createParticipant(poConversation, Store.getDocumentPath(poContact), poContact));
					});

					return laParticipants;
				})
			);
	}

	/** Récupère un objet contenant le tableau des chemins des contacts participants et le tableau des identifiants des groupes participants d'une conversation.
	 * @param paParticipantPaths Tableau des chemis des participants d'une conversation.
	 * @param psUserContactId
	 */
	private getContactPathsAndGroupIds(paParticipantPaths: string[], psUserContactId: string): IContactPathsAndGroupIds {
		const loResult: IContactPathsAndGroupIds = { contactPaths: [], groupIds: [] };

		paParticipantPaths.forEach((psPath: string) => {
			const lsDocumentId: string = Store.getDocumentIdFromPath(psPath);

			if (ContactsService.isContact(lsDocumentId)) {
				if (lsDocumentId === psUserContactId)
					loResult.contactPaths.push(psUserContactId);
				else
					loResult.contactPaths.push(psPath);
			}
			else if (GroupsService.isGroup(lsDocumentId))
				loResult.groupIds.push(lsDocumentId);
		});

		return loResult;
	}

	/** Récupère les contacts d'une conversation en vue de créer les participants de celle-ci.
	 * @param paContactPaths Tableau des chemins des contacts à récupérer.
	 * @param poConversation Conversation associée aux contacts à récupérer.
	 */
	private innerCreateParticipants_getContacts$(paContactPaths: string[], poConversation: IConversation): Observable<IContact[]> {
		return this.isvcContacts.getContactsByPaths(paContactPaths)
			.pipe(
				tap((paContactResults: IContact[]) => {
					if (paContactPaths.length !== paContactResults.length)
						console.warn(`${ConversationService.C_LOG_ID}${paContactPaths.length} expected contacts but ${paContactResults.length} got for conversation '${poConversation._id}'.`);
				})
			);
	}

	/** Récupère les contacts des groupes d'une conversation en vue de créer les participants de celle-ci.
	 * @param paGroupIds Tableau des identifiants des groupes des contacts à récupérer.
	 * @param poConversation Conversation associée aux groupes des contacts à récupérer.
	 */
	private innerCreateParticipants_getGroupContacts$(paGroupIds: string[], poConversation: IConversation): Observable<IContact[]> {
		return from(paGroupIds)
			.pipe(
				concatMap((psGroupId: string) => this.isvcGroups.getGroupContacts(psGroupId)),
				toArray(),
				tap((paGroupContacts: IContact[][]) => {
					if (paGroupIds.length !== paGroupContacts.length)
						console.warn(`${ConversationService.C_LOG_ID}${paGroupIds.length} expected groups but ${paGroupContacts.length} got for conversation '${poConversation._id}'.`);
				}),
				map((paGroupContacts: IContact[][]) => paGroupContacts.flat())
			);
	}

	/** Récupère les participant lié à la conversation.
	 * @param poConversation
	 */
	public getParticipants(poConversation: IConversation): Observable<IParticipant[]> {
		const loParams: IDataSource = {
			databasesIds: ArrayHelper.unique(poConversation.participants.map((poParticipant: IParticipant) => Store.getDatabaseIdFromDocumentPath(poParticipant.participantPath))),
			viewParams: {
				include_docs: true,
				keys: poConversation.participants.map((poParticipant: IParticipant) => poParticipant.participantId)
			},
			handleUnknownDatabases: true
		};

		return this.isvcStore.get(loParams)
			.pipe(
				mergeMap((paResults: IContact[]) => paResults),
				map((poContact: IContact | IGroup) => this.createParticipant(poConversation, Store.getDocumentPath(poContact), poContact)),
				toArray(),
				catchError(poError => this.onError(poError,
					`Une erreur est survenue lors de la récupération des contacts correspondant aux participants de la conversation '${poConversation._id}' sur la base de données.`)
				)
			);
	}

	/** Construit l'activité par défaut pour un participant.
	 * @param psGuid Guid de la conversation.
	 * @param psContactPath Chemin jusqu'au contact du participant.
	 */
	public createDefaultActivity(psGuid: string, psContactPath: string): IConversationActivity {
		return {
			_id: `${IdHelper.buildId(EPrefix.activity, psGuid)}_${Store.getDocumentIdFromPath(psContactPath)}`,
			activity: EActivityStatus.quit,
			type: EConversationType.activity
		};
	}

	/** Récupère le timestamp de la date du dernier message ou de la date de création de la conversation s'il n'y a pas de message.
	 * @param poConversation Conversation et son dernier message.
	 */
	private getTimestampFromConversation(poConversation: IConversation): number {
		if (poConversation.lastMessage)
			return IdHelper.extractTimestampFromId(poConversation.lastMessage._id);

		else if (!poConversation.createDate)
			return new Date().getTime();

		else if (poConversation.createDate instanceof Date)
			return poConversation.createDate.getTime();

		else
			return new Date(poConversation.createDate).getTime();
	}

	/** Supprime ou restaure un message de la conversation (met à jour le champs deleted, donc pas de suppression du document).
	 * @param poMessage
	 */
	public deleteOrRestoreMessage(poMessage: IMessage): Observable<IStoreDataResponse> {
		poMessage.deleted = !poMessage.deleted;
		return this.isvcStore.put(poMessage);
	}

	/** ### Supprime un message définitivement sans possibilité de restauration du message.
	 * Préférer la méthode deleteOrRestoreMessage si besoin de restaurer le message.
	 */
	public deletePermanentlyMessage$(poMessage: IMessage): Observable<IStoreDataResponse> {
		return this.isvcStore.delete(poMessage._id, this.databaseId)
			.pipe(catchError(poError => this.raiseDeleteError(poError, EConversationError.messages)));
	}

	/** Récupère la dernière version du contact lié au participant, `undefined` si non trouvé.
	 * @param poParticipant
	 */
	public getContactFromParticipantAsync(poParticipant: IParticipant): Promise<IContact | undefined> {
		return this.isvcContacts.getContact(
			poParticipant.participantId,
			Store.getDatabaseIdFromDocumentPath(poParticipant.participantPath)
		)
			.pipe(take(1))
			.toPromise();
	}

	/** Notifie les abonnés que des changements au niveau de l'ui doivent avoir lieu (synchro, ajout de participants, ...).
	 * @param poEvent Événement à lever.
	 */
	public raiseConversationUiEvent(poEvent: IConversationUiEvent): void {
		this.moConversationUiSubject.next(poEvent);
	}

	/** Retourne l'observable permettant de s'abonner aux ajouts de participants. */
	public getConversationUiObservable(): Observable<IConversationUiEvent> {
		return this.moConversationUiSubject.asObservable();
	}

	/** Retrouve un participant depuis un id (cont_{GUID} ou depuis un contactPath), `undefined` si non trouvé.
	 * @param paParticipants Tableau des participants dans lequel on recherche.
	 * @param psContactId Id ou chemin du contact.
	 */
	public findParticipant<T extends IGroupMember>(paParticipants?: IParticipant<T>[], psContactId?: string): IParticipant<T> | undefined {
		return paParticipants?.find((poParticipant: IParticipant<T>) => poParticipant.participantId === psContactId);
	}

	/** Permet de récupérer les participants des conversations passées en paramètre en resolvant les groupes.
	 * @param paConversations
	 */
	public getConversationsParticipants(paConversations: IConversation[]): Observable<IIndexedArray<IParticipant<IGroupMember>[]>> {
		const laContactsIds: string[] = this.extractIdsFromConversationsParticipants(paConversations, EPrefix.contact);
		const laGroupsIds: string[] = this.extractIdsFromConversationsParticipants(paConversations, EPrefix.group);

		return this.isvcGroups.getGroupContactsIds(laGroupsIds)
			.pipe(
				mergeMap((poGroupsContactsIds: IIndexedArray<string[]>) => {
					// Ajoute tous les contacts des groupes dans le tableau `laContactsIds` pour ne faire qu'une seule requête.
					for (const lsKey in poGroupsContactsIds) {
						laContactsIds.push(...poGroupsContactsIds[lsKey]);
					}

					// Retourne tous les groupes et les contacts (y compris les contacts des groupes).
					return merge(this.isvcGroups.getDisplayableGroups(laGroupsIds), this.isvcContacts.getContactsByIds(laContactsIds))
						.pipe(
							reduce((paAcc: IGroupMember[], paCurr: IGroupMember[]) => paAcc.concat(paCurr), []),
							map((paContacts: IGroupMember[]) => {
								const loContactsMap: Map<string, IGroupMember> = new Map();
								paContacts.forEach((poContact: IGroupMember) => loContactsMap.set(poContact._id, poContact));
								return loContactsMap;
							}),
							map((poParticipantsMap: Map<string, IGroupMember>) => this.indexParticipantsByConversation(paConversations, poParticipantsMap, poGroupsContactsIds))
						);
				})
			);
	}

	/** Permet d'indexer les contacts par conversation.
	 * @param paConversations
	 * @param poContactsMap Dictionnaire de contacts par identifiant.
	 * @param poGroupsContactsIds Index des identifiants de contacts par groupe.
	 */
	private indexParticipantsByConversation(paConversations: IConversation[],
		poContactsMap: Map<string, IGroupMember>,
		poGroupsContactsIds: IIndexedArray<string[]>): IIndexedArray<IParticipant<IGroupMember>[]> {

		const loParticipants: IIndexedArray<IParticipant<IGroupMember>[]> = {};

		paConversations.forEach((poConversation: IConversation) => {
			poConversation.participants.forEach((poParticipant: IParticipant) => {
				const loGroupMember: IGroupMember | undefined = poContactsMap.get(poParticipant.participantId);

				if (loGroupMember) {
					this.addParticipant(loGroupMember, loParticipants, poConversation);
					if (poParticipant.participantId.indexOf(EPrefix.group) >= 0) {
						if (ArrayHelper.hasElements(poGroupsContactsIds[poParticipant.participantId])) { // Si on a accès au groupe (si le groupe existe et si il est dans notre espace de travail).
							poGroupsContactsIds[poParticipant.participantId].forEach(
								(psContactId: string) => {
									const loParticipant: IGroupMember | undefined = poContactsMap.get(psContactId);
									if (loParticipant)
										this.addParticipant(loParticipant, loParticipants, poConversation);
								}
							);
						}
					}
				}
			});
		});

		return loParticipants;
	}

	/** Permet d'extraire les identifiants des participants des conversations filtrés par préfixe.
	 * @param paConversations
	 * @param pePrefix
	 */
	private extractIdsFromConversationsParticipants(paConversations: IConversation[], pePrefix: EPrefix): string[] {
		const laParticipantsIds: string[] = [];

		paConversations.forEach((poConversation: IConversation) => {
			const laConvParticipantsIds: string[] = poConversation.participants
				.filter((poParticipant: IParticipant) => poParticipant.participantId.indexOf(pePrefix) >= 0)
				.map((poParticipant: IParticipant) => poParticipant.participantId);
			laParticipantsIds.push(...laConvParticipantsIds);
		});

		return laParticipantsIds;
	}

	private addParticipant(poParticipant: IGroupMember, paParticipants: IIndexedArray<IParticipant<IGroupMember>[]>, poConversation: IConversation): void {
		if (poParticipant) {
			if (!paParticipants[poConversation._id])
				paParticipants[poConversation._id] = [];

			paParticipants[poConversation._id].push(this.createParticipant(poConversation, poParticipant));
		}
	}

	/** Récupère l'activité de l'utilisateur en continue pour chaque conversation et les indexe par identifiant de conversation.
	 * @param paConversations
	 */
	public getConversationsUserActivities(paConversations: IConversation[]): Observable<IIndexedArray<IConversationActivity>> {
		const laActivityIds: string[] = paConversations.map((poConversation: IConversation) => this.getConversationUserActivityId(poConversation));

		return this.isvcStore.get<IConversationActivity>({
			databaseId: this.databaseId,
			live: true,
			viewParams: { include_docs: true, keys: laActivityIds }
		})
			.pipe(
				map((paGetResults: IConversationActivity[]) => {
					const loResult: IIndexedArray<IConversationActivity> = {};
					paGetResults.forEach((poActivity: IConversationActivity) => loResult[this.getConversationId(poActivity)] = poActivity);
					return loResult;
				})
			);
	}

	/** Retourne le document d'activité correspondant à l'identifiant.
	 * @param psActivityId L'identifiant du document d'activité à récupérer.
	 */
	public getConversationUserActivity(psActivityId: string): Observable<IConversationActivity> {
		return this.isvcStore.getOne<IConversationActivity>(
			{
				databaseId: this.databaseId,
				viewParams: { include_docs: true, key: psActivityId }
			} as IDataSource, true);
	}

	/** Retourne l'identifiant d'une activité d'un utilisateur.
	 * @param poConversation La conversation en rapport avec l'activité.
	 */
	public getConversationUserActivityId(poConversation: IConversation): string {
		const lsConversationId = IdHelper.extractIdWithoutPrefix(poConversation._id, EPrefix.conversation);
		return `${EPrefix.activity}${lsConversationId}_${ContactsService.getUserContactId()}`;
	}

	/** Permet de récupérer l'identifiant d'une conversation depuis une activité.
	 * @param poActivity
	 */
	private getConversationId(poActivity: IConversationActivity): string {
		const loResult: RegExpExecArray | null = new RegExp(`${EPrefix.activity}(.*)_${EPrefix.contact}.*`).exec(poActivity._id);

		if (loResult)
			return IdHelper.buildId(EPrefix.conversation, ArrayHelper.getLastElement(loResult));
		else
			throw new Error("Le document d'activité n'est pas valide.");
	}

	public isRead(poConversation: IConversation, poUserJoinDateByGroupId: Map<string, Date>, poUserActivity?: IConversationActivity): boolean {
		if (!poConversation || !poConversation.lastMessage) // Pas de conversation ou de dernier message dans la conv.
			return true;
		else {
			if (poUserActivity?.lastReadMessageId === poConversation.lastMessage._id) // Si l'utilisateur a lu le dernier message.
				return true;
			else if (poConversation.lastMessage.senderContactPath?.endsWith(ContactsService.getUserContactId()))
				return true; // Le dernier message de la conv a été envoyé par l'utilisateur.
			else {
				const laUserParticipantGroupIds: string[] = [];
				poConversation.participants?.forEach((poParticipant: IParticipant) => {
					if (GroupsService.isGroup(poParticipant.participantId) && poUserJoinDateByGroupId.has(poParticipant.participantId))
						laUserParticipantGroupIds.push(poParticipant.participantId);
				});

				if (ArrayHelper.hasElements(laUserParticipantGroupIds)) { // S'il y a des groupes dont fait parti l'utilisateur dans la conversation.
					return laUserParticipantGroupIds.every((psGroupId: string) => {
						// "Lu" si l'utilisateur a rejoint tous les groupes après la date du dernier message.
						return DateHelper.compareTwoDates(poUserJoinDateByGroupId.get(psGroupId), poConversation.lastMessage.createDate) > 0;
					});
				}
				else
					return false;
			}
		}
	}

	/** Récupère une conversation liée à une entité, `undefined` si aucune conversation liée à cette entité.
	 * @param poDocument Document dont on veut récupérer une conversation liée.
	 */
	public getConversationFromEntity<T extends IStoreDocument>(poDocument: T): Observable<IConversation | undefined> {
		return this.isvcEntityLink.getEntityLinks(poDocument._id, [EPrefix.conversation])
			.pipe(
				map((paLinks: EntityLink[]) => paLinks
					.map((poEntityLink: EntityLink) =>
						poEntityLink.getTargetEntitiesByTargetPrefix(EPrefix.conversation).map((poEntity: EntityLinkEntity) => poEntity.id)
					).flat()
				),
				mergeMap((paConversationIds: string[]) => {
					if (ArrayHelper.hasElements(paConversationIds)) {
						if (paConversationIds.length > 1)
							console.warn(`CONV.S:: Plusieurs conversations liées au document '${poDocument._id}' : ${paConversationIds.join(", ")}.\nLa première sera utilisée.`);

						return this.getConversation(ArrayHelper.getFirstElement(paConversationIds));
					}
					else
						return of(undefined);
				})
			);
	}

	/** Récupère le nombre de conversation non lues.
	 * @param pbLive Indique si la requête doit être live.
	 */
	private getNumberOfUnreadConversations(paConversations: IConversation[], pbLive?: boolean): Observable<number> {
		return defer(() => {
			if (ArrayHelper.hasElements(paConversations)) {
				return this.getConversationsLocalChanges().pipe(
					tap((poChange: IChangeEvent<IConversation>) => this.isvcStore.handleChangeEvent(poChange, paConversations, {})),
					mapTo(paConversations),
					startWith(paConversations)
				);
			}
			else
				return this.getConversations(ContactsService.getUserContactId(), { live: pbLive, sortRequired: false });
		})
			.pipe(
				switchMap((paResults: IConversation[]) => {
					if (ArrayHelper.hasElements(paResults)) {
						return combineLatest([this.getConversationsUserActivities(paResults), this.isvcGroups.getUserJoinDateByGroupId$()])
							.pipe(
								map(([poActivitiesByConvId, poUserJoinDateByGroupId]: [IIndexedArray<IConversationActivity>, Map<string, Date>]) => {
									return paResults.filter((poConversation: IConversation) => !this.isRead(poConversation, poUserJoinDateByGroupId, poActivitiesByConvId[poConversation._id])).length;
								})
							);
					}
					else
						return of(0);
				})
			);
	}

	private initNumberOfUnreadConversations(): void {
		this.isvcFlag.waitForFlags([ESecurityFlag.authenticated, EStoreFlag.DBInitialized, EPermissionsFlag.isLoaded], true)
			.pipe(
				mergeMap(_ => {
					if (ConfigData.conversation) {
						return merge(
							this.downloadConversations(ContactsService.getUserContactId(), undefined, true)
								.pipe(retryWhen((poErrors$: Observable<any>) => this.isvcStore.getRequestRetryStrategy(poErrors$))),
							of(null)
						);
					}
					else
						return EMPTY;
				}),
				switchMap((paConversations: IConversation[]) => this.getNumberOfUnreadConversations(paConversations, true)),
				debounceTime(1000),
				tap((pnNumberOfUnreadConversation: number) => this.isvcGlobalData.setData(ConversationService.C_NB_OF_UNREAD_CONV_DATA_KEY, pnNumberOfUnreadConversation))
			)
			.subscribe();
	}

	public createOrJoinLinkedMeetingConversation(poInputFromEvent: IActionButtonFieldParams<IActionButtonFieldMeetingParams>)
		: Observable<IUpdateConversationDetails> {
		const lsConv_id: string = poInputFromEvent.specParams.convId;
		const lsConvTitle: string = poInputFromEvent.specParams.title;
		//  Ces champs vont avec le linkedEntities => TODO
		// let lsevtId: string = inputFromEvent.specParams.eventId
		// let loOccur = inputFromEvent.specParams.databaseId

		const locontactParticipants: IContact[] = [...poInputFromEvent.specParams.participants];

		return this.getConversation(lsConv_id)
			.pipe(
				// Create conversation if not exist
				concatMap((poConversation: IConversation) => {
					if (!poConversation) {
						return this.create({
							userContactId: UserHelper.getUserContactId(),
							members: locontactParticipants,
							options: {
								// TODO lié l'évènement à la conversation à la création.
								// linkedEntities: [{
								// 	id: lsevtId,
								// 	name: loOccur.event.title,
								// 	databaseName: loOccur.event.$cacheData.databaseId,
								// 	model: { _id: lsevtId },
								// 	route: `database/${loOccur.event.$cacheData.databaseId}/${lsevtId}`,
								// 	documentsPaths: [],
								// }],
								title: lsConvTitle,
								openVisio: true,
								routeToConversationAfterCreation: false,
							},
							_id: lsConv_id
						});
					}
					else { return of(poConversation); }
				}),
				// Ajustement du nombre de gens
				mergeMap((poConversation: IConversation) => {
					const loOldContactParticipants: IGroupMember[] = [];
					poConversation.participants.forEach((poParticipant: IParticipant) => {
						// Contournement pour créer des IGroupMember à partir des participantId
						poParticipant.model = { "_id": poParticipant.participantId };
						loOldContactParticipants.push(poParticipant.model as IGroupMember);
					});

					return this.updateParticipants$(poConversation, locontactParticipants, loOldContactParticipants)
						.pipe(
							tap(() => this.routeToConversation(poConversation, { openVisio: true }))
						);
				}),
			);
	}
	//#endregion
}