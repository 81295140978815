import { coerceArray } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';
import { AlertOptions } from '@ionic/core';
import { defer, EMPTY, from, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { distinctUntilChanged, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { UserHelper } from '../../../helpers/user.helper';
import { EApplicationEventType } from '../../../model/application/EApplicationEventType';
import { UserData } from '../../../model/application/UserData';
import { ConfigData } from '../../../model/config/ConfigData';
import { Group } from '../../../model/contacts/group';
import { IGroup } from '../../../model/contacts/IGroup';
import { EPrefix } from '../../../model/EPrefix';
import { ESortOrder } from '../../../model/ESortOrder';
import { IFlag } from '../../../model/flag/IFlag';
import { PermissionMissingError } from '../../../model/security/errors/PermissionMissingError';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { EStoreFlag } from '../../../model/store/EStoreFlag';
import { IDataSource } from '../../../model/store/IDataSource';
import { IStoreDataResponse } from '../../../model/store/IStoreDataResponse';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { EntityLinkService } from '../../../services/entityLink.service';
import { FlagService } from '../../../services/flag.service';
import { InjectorService } from '../../../services/injector.service';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '../../../services/loading.service';
import { IApplicationRole } from '../../../services/security/IApplicationRole';
import { IApplicationRoles } from '../../../services/security/IApplicationRoles';
import { Store } from '../../../services/store.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { Loader } from '../../loading/Loader';
import { ObservableProperty } from '../../observable/models/observable-property';
import { DestroyableServiceBase } from '../../services/models/destroyable-service-base';
import { IDestroyable } from '../../utils/lifecycle/models/IDestroyable';
import { EContextualPermission } from '../models/econtextual-permission';
import { EPermissionScopes } from '../models/epermission-scopes';
import { EPermissionsFlag } from '../models/EPermissionsFlag';
import { IPermissionContext } from '../models/ipermission-context';
import { IPermissionScope } from '../models/ipermission-scope';
import { IPermissionEvent } from '../models/IPermissionEvent';
import { IPermissionSet } from '../models/IPermissionSet';
import { IRole } from '../models/irole';
import { TCRUDPermissions } from '../models/tcrud-permissions';
import { TPermission } from '../models/tpermission';
import { PermissionRulesService } from './permission-rules.service';

export const C_ADMINISTRATOR_TAG = "administrator";
export const C_DEFAULT_TAG = "default";

/** Cache de permissions, susceptible d'être renouvelé durant la vie de l'application. */
interface IPermissionsCache {
	permissions: IPermissionSet;
}

//#region DECORATORS

type TPermissionsAccessorDescriptor = TypedPropertyDescriptor<boolean>;

export function Roles(...paRoles: string[]):
	(poTarget: any, psMethodName: string, poDescriptor: TPermissionsAccessorDescriptor) => TPermissionsAccessorDescriptor {

	return function (poTarget: any, psMethodName: string, poDescriptor: TPermissionsAccessorDescriptor): TPermissionsAccessorDescriptor {
		const lfOriginalMethod: (() => boolean) | undefined = poDescriptor.get; // On sauvegarde l'ancienne implémentation du getter.

		poDescriptor.get = function (): boolean {
			const loTarget: any = this; // Représente la classe qui appelle le décorateur.
			const lsvcPermission: PermissionsService = InjectorService.instance?.get(PermissionsService);

			return (lfOriginalMethod?.apply(loTarget, arguments) ?? true) && // On appelle l'ancien getter en le mettant dans le contexte de l'appelant.
				paRoles.some((psRole: string) => lsvcPermission?.hasRole(psRole));
		};

		return poDescriptor;
	};
};

//#endregion

export interface IHasPermission extends IDestroyable {
	permissionScope?: EPermissionScopes | EPermissionScopes[];
	isvcPermissions: PermissionsService;
}

export const C_ADMINISTRATORS_ROLE_ID = "administrators";
export const C_SUPER_ADMIN_ROLE_ID = "superAdmin";
export const C_ADMIN_ROLE_ID = "admin";
export const C_WS_ADMINISTRATORS_ROLE_ID = "workspaceAdministrators";
export const C_WS_USERS_ROLE_ID = "workspaceUsers";
export const C_SECTORS_ROLE_ID = "sectors";

/** Gestion des permissions de l'application en fonction de l'utilisateur actif. */
@Injectable({ providedIn: "root" })
export class PermissionsService extends DestroyableServiceBase {

	//#region FIELDS

	private static readonly C_LOG_ID = "PERM.S::";

	private readonly moEventSubject = new Subject<IPermissionEvent>();
	private readonly permissionsCache: IPermissionsCache = { permissions: null };
	private moPermissionsByRole: Map<string, IPermissionSet> = new Map<string, IPermissionSet>();

	private maUserRoles: IApplicationRole[] = [];
	/** Indique si les permissions par défaut sont en cours d'utilisation ou non. */
	private mbAreDefaultPermissions = true;

	private moApplicationRoles: IApplicationRoles;

	//#endregion

	//#region PROPERTIES

	/** Détermine si le contrôle de permissions est activé pour l'application. */
	public get hasPermissions(): boolean {
		return !!ConfigData.security || !!this.moApplicationRoles;
	}

	public get permissionsEvent$(): Observable<IPermissionEvent> {
		return this.moEventSubject.asObservable();
	}

	private readonly moRolesSubject = new ReplaySubject<IRole[]>(1);
	public get roles$(): Observable<IRole[]> { return this.moRolesSubject.asObservable(); }

	/** Donne la date actuelle à chaque fois que les permissions sont modifiées en bdd. Prend actuellement seulement en compte les PermissionsRules. */
	public readonly observableUpdatePermissions = new ObservableProperty<number>(0).bind(this.isvcPermissionRules.observableUpdatePermissionRule.value$.pipe(map((pnValue) => pnValue ?? 0)), this);

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcStore: Store,
		private readonly isvcUiMessageService: UiMessageService,
		private readonly isvcFlag: FlagService,
		private readonly isvcPermissionRules: PermissionRulesService
	) {

		super();

		// Initialisation des permissions utilisateurs pour surcharger celles par défaut.
		isvcFlag.observeFlag(EStoreFlag.DBInitialized)
			.pipe(
				distinctUntilChanged(),
				mergeMap((poFlag: IFlag) => {
					if (poFlag.value)
						return defer(() => this.initRoles()).pipe(
							mergeMap(() => {
								if (!ConfigData.security?.permissions && this.moPermissionsByRole.size === 0) {
									const loParams = new ShowMessageParamsPopup({
										header: "Erreur",
										message: "Impossible d'accéder aux permissions de l'espace de travail.<br/>Veuillez contacter votre administrateur."
									} as AlertOptions);
									this.isvcUiMessageService.showMessage(loParams);
									return EMPTY;
								}
								else
									return of(true);
							}),
							mergeMap(() => this.initPermissions())
						);
					else
						return this.resetPermissions();
				})
			)
			.subscribe();

		this.moRolesSubject.asObservable().pipe(
			tap((paRoles: IRole[]) => {
				paRoles.forEach((poRole: IRole) => {
					this.initApplicationsRoles(poRole);
					const lsId: string = IdHelper.extractIdWithoutPrefix(poRole._id, EPrefix.role);
					this.moPermissionsByRole.set(lsId, poRole.permissions);
				});
			})
		).subscribe();
	}

	private initApplicationsRoles(poRole: IRole): void {
		if (!this.moApplicationRoles)
			this.moApplicationRoles = {} as unknown as IApplicationRoles;

		const lsKey: string = IdHelper.extractIdWithoutPrefix(poRole._id, EPrefix.role);
		const loApplicationRole: IApplicationRole = {
			id: lsKey,
			label: poRole.label,
			attributors: poRole.attributors
		};

		this.moApplicationRoles[lsKey] = loApplicationRole;
	}

	private initRoles(): Observable<boolean> {
		const loSource: IDataSource<IRole> = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				include_docs: true,
				startkey: EPrefix.role,
				endkey: EPrefix.role + Store.C_ANYTHING_CODE_ASCII
			},
			live: true
		};

		return this.isvcStore.get(loSource).pipe(
			tap((paRoles: IRole[]) => {
				const laOrderedRoles = ArrayHelper.dynamicSort<IRole>(paRoles, "index", ESortOrder.ascending);
				this.moRolesSubject.next(laOrderedRoles);
			}),
			map(() => true)
		);
	}

	/** Initialise les permissions par défaut de l'app. */
	private initDefaultPermissions(): void {
		let loDefaultPermissionFromWorkspace: IPermissionSet;
		if (this.moPermissionsByRole.has(C_DEFAULT_TAG)) {
			loDefaultPermissionFromWorkspace = this.moPermissionsByRole.get(C_DEFAULT_TAG);
		}
		this.permissionsCache.permissions = ObjectHelper.clone(ConfigData.security?.permissions?.default || loDefaultPermissionFromWorkspace);
		this.mbAreDefaultPermissions = true;
	}

	/** Réinitialise les permissions. */
	private resetPermissions(): Observable<boolean> {
		this.permissionsCache.permissions = null;
		this.raisePermissionsLoadedEvent(false);
		return of(true);
	}

	private initPermissions(): Observable<boolean> {
		let loLoader: Loader;

		return from(this.isvcLoading.create("Chargement des permissions"))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				tap(() => this.initDefaultPermissions()),
				mergeMap(_ => this.loadUserPermissionsCache()),
				mergeMap((pbResult: boolean) => {
					if (!loLoader.isDismissed)
						return loLoader.dismiss();
					return of(pbResult);
				}),
				tap(_ => this.raisePermissionsLoadedEvent(true)),
				finalize(() => {
					if (!loLoader.isDismissed)
						loLoader.dismiss();
				})
			);
	}

	private raisePermissionsLoadedEvent(pbIsLoaded: boolean): void {
		this.moEventSubject.next({
			createDate: new Date,
			type: EApplicationEventType.permissionsEvent,
			data: {},
			alteredFlags: [{ key: EPermissionsFlag.isLoaded, value: pbIsLoaded }]
		});

		this.isvcFlag.setFlagValue(EPermissionsFlag.isLoaded, pbIsLoaded);
	}

	/** Charge le jeu de permissions de l'utilisateur actif en fonction du paramétrage de sécurité.
	 * @returns `true` si les permissions de l'utilisateur ont été chargées, `false` si le contrôle de permissions est désactivé.
	 */
	private loadUserPermissionsCache(): Observable<boolean> {
		if (!this.hasPermissions)
			return of(false);
		else
			return this.getUserPermissionSet(true).pipe(map((poPermissionSet: IPermissionSet) => !!poPermissionSet));
	}

	/** Renvoie le jeu de permissions de l'utilisateur actif (chargé au 1er appel, puis mis en cache pour les appels suivants).
	 * @param pbLive
	 * @returns Le jeu de permissions de l'utilisateur.
	 * @throws {PermissionMissingError} Si le contrôle des permissions n'est pas activé pour l'application, une erreur est levée :
	 * un appel préalable à `hasPermissions` est recommandé.
	 */
	private getUserPermissionSet(pbLive?: boolean): Observable<IPermissionSet> {
		if (!this.hasPermissions)
			return throwError(() => new PermissionMissingError("Permission check was requested but no permissions settings were provided for current application."));
		else {
			return this.permissionsCache.permissions && !this.mbAreDefaultPermissions ?
				of(this.permissionsCache.permissions) : this.innerLoadUserPermissionsCache(pbLive);
		}
	}

	/** Charge le jeu de permissions de l'utilisateur actif en fonction du paramétrage de sécurité. */
	private innerLoadUserPermissionsCache(pbLive?: boolean): Observable<IPermissionSet> {
		return this.isvcEntityLink.getLinkedEntities<Group>(UserHelper.getUserContactId(), [EPrefix.group], undefined, pbLive)
			.pipe(
				mergeMap((paResults: Group[]) => {
					let loDefaultPermissionsFromWorkspace: IPermissionSet;
					const laTags: string[] = ArrayHelper.unique(paResults.map((poGroup: IGroup) => poGroup.roles ?? []).flat());
					//Applique les permissions par défaut
					this.permissionsCache.permissions = { contacts: null };
					if (this.moPermissionsByRole.has(C_DEFAULT_TAG))
						loDefaultPermissionsFromWorkspace = this.moPermissionsByRole.get(C_DEFAULT_TAG);
					const laPermissions: IPermissionSet[] = [ObjectHelper.clone(ConfigData.security?.permissions?.default || loDefaultPermissionsFromWorkspace)];

					laTags.forEach((psTag: string) => {
						let loWorkspacePermissions: IPermissionSet;
						if (this.moPermissionsByRole.has(psTag))
							loWorkspacePermissions = this.moPermissionsByRole.get(psTag);
						const loPermission: IPermissionSet = (ConfigData.security?.permissions) ? ConfigData.security?.permissions[psTag] : loWorkspacePermissions;
						if (loPermission)
							laPermissions.push(loPermission);
					});

					Object.assign(this.permissionsCache.permissions, this.mergePermissions(laPermissions));

					const laUserTags: IApplicationRole[] = [];
					const loApplicationRoles: IApplicationRoles = this.getApplicationRoles();

					for (const lsKey in loApplicationRoles) {
						const loTag: IApplicationRole = loApplicationRoles[lsKey];
						if (loTag && laTags.includes(loTag.id))
							laUserTags.push(loTag);
					}

					this.maUserRoles = laUserTags;

					return of(this.permissionsCache.permissions);
				}),
				tap((poPermissionsSet: IPermissionSet) => this.isvcPermissionRules.init(poPermissionsSet)),
				tap(_ => this.mbAreDefaultPermissions = false)
			);
	}

	/** Retourne `true` si la permission passée en paramètre est valide. C'est-à-dire si elle est settée, que sa valeur n'est pas `false` et, si c'est un `string`,
	 * si sa valeur est bien présente dans l'arborescence des permissions.
	 * @param poPermissionData Permission ou tableau de permissions (si tableau il y a, parcours en profondeur il y aura).
	 * @param psPermissionType Type de permission comme `create` | `delete` | `edit` | `read`.
	 * @throws Erreur en cas de cache non rempli alors qu'on est censé avoir des permissions.
	 */
	public evaluatePermission(poPermissionData?: EPermissionScopes | EPermissionScopes[], psPermissionType?: TCRUDPermissions, poContext?: IPermissionContext): boolean;
	/** Retourne `true` si la permission passée en paramètre est valide. C'est-à-dire si elle est settée, que sa valeur n'est pas `false` et, si c'est un `string`,
	 * si sa valeur est bien présente dans l'arborescence des permissions.
	 * @param poPermissionData Permission ou tableau de permissions (si tableau il y a, parcours en profondeur il y aura).
	 * @param psPermissionType Type de permission comme `create` | `delete` | `edit` | `read`.
	 * @throws Erreur en cas de cache non rempli alors qu'on est censé avoir des permissions.
	 */
	public evaluatePermission(poPermissionData?: EPermissionScopes | EPermissionScopes[], psPermissionType?: string, poContext?: IPermissionContext): boolean;
	public evaluatePermission(poPermissionData?: EPermissionScopes | EPermissionScopes[], psPermissionType?: TCRUDPermissions | string, poContext?: IPermissionContext): boolean {
		if (!this.hasPermissions || StringHelper.isBlank(psPermissionType) || !poPermissionData)
			return true;
		else if (!this.permissionsCache.permissions) // Si les permissions ne sont pas en caches mais qu'elles le devraient.
			throw new Error(`PERM.S::Permission check from cache has been called without having loaded the permissions cache.`);
		else {
			const laPermissionKeys: EPermissionScopes[] = coerceArray(poPermissionData);
			const leFirstPermission: EPermissionScopes = ArrayHelper.getFirstElement(laPermissionKeys);
			let loParentPermission: IPermissionScope;
			const loChildPermission: IPermissionScope = this.permissionsCache.permissions[leFirstPermission];
			let lbResult: boolean;

			if (laPermissionKeys.length === 1) // Un seul élément, pas de parcours à faire on renvoie directement le résultat.
				lbResult = this.innerEvaluatePermission(leFirstPermission, psPermissionType, loChildPermission, undefined, poContext);
			else
				lbResult = this.pathEvaluatePermission(laPermissionKeys, loChildPermission, loParentPermission, psPermissionType, poContext);

			// Si on utilise les permissions par défaut et qu'on n'est pas en mode invité, log d'avertissement.
			if (this.mbAreDefaultPermissions && !UserData.current?.isGuest)
				console.warn(`${PermissionsService.C_LOG_ID}Utilisation permissions par défaut !`);

			return lbResult;
		}
	}

	/** Parcourt récursivement les permissions pour évaluer celle qui nous intéresse et retourne le résultat de l'évaluation de cette permission.
	 * @param paPermissionKeys Tableau des clés de permission.
	 * @param poChildPermission Permission enfant.
	 * @param poParentPermission Permission parente.
	 * @param psPermissionType Type de permission comme `create` | `delete` | `edit` | `read`.
	 */
	private pathEvaluatePermission(paPermissionKeys: EPermissionScopes[], poChildPermission: IPermissionScope, poParentPermission: IPermissionScope,
		psPermissionType: TCRUDPermissions | string, poContext?: IPermissionContext): boolean {

		for (let lnIndex = 1; lnIndex < paPermissionKeys.length; ++lnIndex) {
			// Si le fils est défini et est un objet, on peut le parcourir pour obtenir un nouveau fils.
			if (poChildPermission && typeof poChildPermission === "object") {
				poParentPermission = poChildPermission; // Le fils actuel devient parent.
				poChildPermission = poParentPermission[paPermissionKeys[lnIndex]] as IPermissionScope; // Parcours pour obtenir le nouveau fils.
			}
			else if (poParentPermission) { // Sinon si le parent est défini, on récupère la permission associée du parent à défaut de celle de l'enfant.
				const leDefaultPermission: EPermissionScopes = paPermissionKeys[lnIndex - 1];
				console.info(`PERM.S::Permission '${psPermissionType} pour '${paPermissionKeys.join("/")}' impossible à atteindre, utilisation de la permission '${leDefaultPermission}'.`);
				return this.innerEvaluatePermission(leDefaultPermission, psPermissionType, poParentPermission, undefined, poContext);
			}
			else { // Sinon, fils non défini ou non parcourable -> parcours impossible.
				console.error(`PERM.S::Permission '${psPermissionType}' pour '${paPermissionKeys.join("/")}' non trouvée dans le cache`, this.permissionsCache.permissions);
				return this.innerEvaluatePermission(paPermissionKeys[lnIndex - 1], psPermissionType, poChildPermission, undefined, poContext);
			}
		}

		return this.innerEvaluatePermission(ArrayHelper.getLastElement(paPermissionKeys), psPermissionType, poChildPermission, poParentPermission, poContext);
	}

	private innerEvaluatePermission(pePermission: EPermissionScopes, psPermissionType: TCRUDPermissions | string, poChildPermission: IPermissionScope,
		poParentPermission?: IPermissionScope, poContext?: IPermissionContext): boolean {

		if (!poChildPermission) // Si la permission n'existe pas, elle peut être `undefined`.
			return poParentPermission ? this.innerEvaluatePermission(pePermission, psPermissionType, poParentPermission, undefined, poContext) : false;
		else {
			const loPermission: TPermission | IPermissionScope = poChildPermission[psPermissionType];

			if (typeof loPermission === "boolean") // Permission normale.
				return loPermission;
			if (typeof loPermission === "string") // Permission avec contexte.
				return this.evaluateContextualPermission(loPermission, poContext);
			else if (loPermission) { // Obtention d'une imbrication de permission et non une permission.
				console.error(`PERM.S::Permission souhaitée : '${pePermission}' pour le type de permission '${psPermissionType}' mais obtenu : `, poChildPermission);
				return false;
			}
			else if (poParentPermission) // Si un parent est renseigné, on évalue la permission du parent à défaut de l'enfant.
				return this.innerEvaluatePermission(pePermission, psPermissionType, poParentPermission, undefined, poContext);
			else { // La permission n'existe pas dans le PermissionSet => refusée.
				console.debug(`PERM.S::Permission '${psPermissionType}' of '${pePermission} not found in `, this.permissionsCache.permissions);
				return false;
			}
		}
	}

	private evaluateContextualPermission(pePermission: EContextualPermission, poContext?: IPermissionContext): boolean {
		if (pePermission.startsWith(EContextualPermission.rule))
			return this.isvcPermissionRules.evaluateRule(pePermission, poContext);
		if (!poContext)
			return false

		switch (pePermission) {
			case EContextualPermission.mine:
				return poContext.authorId === UserHelper.getUserContactId();
			case EContextualPermission.others:
				return poContext.authorId !== UserHelper.getUserContactId();
			case EContextualPermission.me:
				return poContext._id === UserHelper.getUserContactId();

			default:
				return false;
		}
	}

	public mergePermissions(paPermissions: IPermissionSet[]): IPermissionSet;
	public mergePermissions(poPermissionA: IPermissionSet, poPermissionB: IPermissionSet): IPermissionSet;
	public mergePermissions(poData: IPermissionSet[] | IPermissionSet, poPermissionB?: IPermissionSet): IPermissionSet {
		if (poData instanceof Array) {
			return poData.reduce((poPreviousPermission: IPermissionSet, poCurrentPermission: IPermissionSet) =>
				this.mergePermissions(poPreviousPermission, poCurrentPermission)
			);
		}
		else if (!poData)
			return poPermissionB!; // Surcharge 2.
		else {
			if (poPermissionB)
				Object.keys(poPermissionB).forEach((psKey: string) => poData[psKey] = this.pathPermissionsMerge(poData[psKey], poPermissionB[psKey]));

			return poData;
		}
	}

	/** Parcourt les permissions à fusionner pour une portée donnée de façon récursive.
	 * @param poPermissionScopeA Portée de la permission A.
	 * @param poPermissionScopeB Portée de la permission B.
	 */
	private pathPermissionsMerge(poPermissionScopeA: IPermissionScope, poPermissionScopeB: IPermissionScope): IPermissionScope {
		if (poPermissionScopeB) { // Si des permissions B sont présentes, on peut fusionner.
			if (poPermissionScopeA) { // Si des permissions A sont également présentes, on doit fusionner les permissions A et B.

				Object.keys(poPermissionScopeB).forEach((psScopeKey: string) => {
					const loPermissionScopeA: TPermission | IPermissionScope = poPermissionScopeA[psScopeKey];
					const loPermissionScopeB: TPermission | IPermissionScope = poPermissionScopeB[psScopeKey];
					// Pas de typage car on veut profiter du `loPermission is IScopePermission`.
					const lbIsObjectPermissionA = this.isScopePermission(loPermissionScopeA);
					const lbIsObjectPermissionB = this.isScopePermission(loPermissionScopeB);
					const lbIsStringPermissionA = this.isString(loPermissionScopeA);
					const lbIsStringPermissionB = this.isString(loPermissionScopeB);

					if (lbIsObjectPermissionA && lbIsObjectPermissionB) // Permissions A et B sont des objets, on doit les parcourir pour les fusionner.
						poPermissionScopeA[psScopeKey] = this.pathPermissionsMerge(loPermissionScopeA, loPermissionScopeB);
					else if (!lbIsStringPermissionA && !lbIsObjectPermissionA) // Permission A est un booléen.
						poPermissionScopeA[psScopeKey] = loPermissionScopeA || loPermissionScopeB;
					else if (!lbIsStringPermissionB && !lbIsObjectPermissionB) // Permission B est un booléen.
						poPermissionScopeA[psScopeKey] = loPermissionScopeB || loPermissionScopeA;
					else if (lbIsStringPermissionA) // Permission A est une chaîne de caractères.
						poPermissionScopeA[psScopeKey] = loPermissionScopeB ?? loPermissionScopeA; // TODO Gérer le cas de 2 chaînes (ex: "mine" et "others"). Gérer un tableau?
					else if (lbIsStringPermissionB) // Permission B est une chaîne de caractères.
						poPermissionScopeA[psScopeKey] = loPermissionScopeA ?? loPermissionScopeB; // TODO Gérer le cas de 2 chaînes (ex: "mine" et "others"). Gérer un tableau?
					else if (lbIsObjectPermissionB && !lbIsObjectPermissionA)
						poPermissionScopeA[psScopeKey] = loPermissionScopeB;

					// Dernier cas : Permission A est un objet mais permission B est un booléen (ou non défini).
					// => rien à faire parce que priorité sur les objets de permissions plutôt que les booléens car plus précis.
				});
			}
			else // Pas de permission A, on retourne les permissions B.
				return poPermissionScopeB;
		}

		return poPermissionScopeA; // Pas de permission B donc on retourne permissions A OU permissions fusionnées dans A.
	}

	private isScopePermission(poPermission: TPermission | IPermissionScope): poPermission is IPermissionScope {
		return typeof poPermission === "object";
	}

	private isString(poPermission: TPermission | IPermissionScope): poPermission is EContextualPermission {
		return typeof poPermission === "string";
	}

	/** Retourne les différents tags de permission que l'utilisateur peut assigner. */
	public getPermissionRolesThatUserCanAssign(): IApplicationRole[] {
		const laTags: IApplicationRole[] = [];
		const laRoles: IApplicationRoles = this.getApplicationRoles();

		for (const lsKey in laRoles) {
			const loTag: IApplicationRole = laRoles[lsKey];
			if (loTag && (loTag.attributors ?? []).concat(C_ADMINISTRATORS_ROLE_ID, C_SUPER_ADMIN_ROLE_ID).some((psTag: string) => this.maUserRoles.some((poTag: IApplicationRole) => poTag.id === psTag)))
				laTags.push(loTag);
		}

		return laTags;
	}

	public canApplyRoles(...paRoles: string[]): boolean {
		const laRolesThatUserCanApply: IApplicationRole[] = this.getPermissionRolesThatUserCanAssign();

		return paRoles?.every((psRole: string) =>
			StringHelper.isBlank(psRole) || laRolesThatUserCanApply.some((poRole: IApplicationRole) => poRole.id === psRole)
		) ?? true;
	}

	/** Retourne tous les rôles de l'application (y compris ceux dont ne fait pas parti l'utilisateur).
	 * @param paIds Tableau des identifiants de rôle qu'on veut garder (permet de filtrer si le rôle associé existe).
	 * @param paExcludeIds Tableau des identifiants de rôle qu'on ne veut pas garder.
	 */
	public getPermissionRoles(paIds?: string[], paExcludeIds: string[] = []): IApplicationRole[] {
		const laTags: IApplicationRole[] = [];
		const laRoles: IApplicationRoles = this.getApplicationRoles();

		for (const lsKey in laRoles) {
			const loRole: IApplicationRole = laRoles[lsKey];
			if ((!ArrayHelper.hasElements(paIds) || paIds.includes(loRole.id)) && !paExcludeIds.includes(loRole.id))
				laTags.push(loRole);
		}

		return laTags;
	}

	/** Retourne `true` si l'utilisateur possède le rôle demandé, `false` sinon.
	 * @param psRole Rôle dont l'utilisateur doit faire parti.
	 */
	public hasRole(psRole: string): boolean {
		return this.maUserRoles.some((poRole: IApplicationRole) => poRole.id === psRole);
	}

	/** Retourne `true` si l'utilisateur possède au moins l'un des rôles passés en paramètres, `false` sinon.
	 * @param paRoles Tableau des rôles dont l'utilisateur doit en faire parti d'au moins un.
	 */
	public hasRoleOf(paRoles: string[]): boolean {
		return this.maUserRoles.some((poRole: IApplicationRole) => paRoles.some((psRole: string) => psRole === poRole.id));
	}

	private getApplicationRoles(): IApplicationRoles {
		return (ConfigData.security) ? ConfigData.security.builtInRoles : this.moApplicationRoles;
	}

	public updateRoles<T extends IStoreDocument>(paRoles: T[]): Observable<IStoreDataResponse[]> {
		const lsDatabaseId: string = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace));
		return this.isvcStore.putMultipleDocuments(paRoles, lsDatabaseId, true, false);
	}

	/** Attend l'initialisation des permissions. */
	public async waitPermissionsAsync(): Promise<void> {
		await this.isvcFlag.waitForFlagAsync(EPermissionsFlag.isLoaded, true);
	}

	public evaluatePermission$(
		poPermissionData?: EPermissionScopes | EPermissionScopes[],
		psPermissionType?: TCRUDPermissions,
		poContext?: IPermissionContext
	): Observable<boolean>;
	public evaluatePermission$(
		poPermissionData?: EPermissionScopes | EPermissionScopes[],
		psPermissionType?: string,
		poContext?: IPermissionContext
	): Observable<boolean>;
	public evaluatePermission$(
		poPermissionData?: EPermissionScopes | EPermissionScopes[],
		psPermissionType?: TCRUDPermissions | string,
		poContext?: IPermissionContext
	): Observable<boolean> {
		return this.isvcFlag.observeFlag(EPermissionsFlag.isLoaded).pipe(
			map((poFlag: IFlag) => poFlag.value && this.evaluatePermission(poPermissionData, psPermissionType, poContext))
		);
	}

	//#endregion

}