<div class="separator" [ngClass]="observableSeparator.value$ | async">
	<div [ngClass]="observableSeparator.value$ | async"></div>
</div>

<div class="content">
	<div class="field-label">
		<ion-label>{{ observableLabel.value$ | async }}</ion-label>
	</div>
	<div class="inline-content">
		<ng-content select="calao-inline-readonly-field-layout-content"></ng-content>
	</div>
</div>