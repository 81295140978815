import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { switchMap, tap } from 'rxjs';
import { ArrayHelper } from '../../../../../../helpers/arrayHelper';
import { IdHelper } from '../../../../../../helpers/idHelper';
import { EntityLinkService } from '../../../../../../services/entityLink.service';
import { Entity } from '../../../../../entities/models/entity';
import { IEntityDescriptor } from '../../../../../entities/models/ientity-descriptor';
import { IEntityLinkRelationData } from '../../../../../entities/models/ientity-link-relation-data';
import { IEntitySelectorParamsBase } from '../../../../../entities/models/ientity-selector-params-base';
import { EntitiesService } from '../../../../../entities/services/entities.service';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { secure } from '../../../../../utils/rxjs/operators/secure';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IEntitySelectorFieldParams } from './models/ientity-selector-field-params';

@Component({
	selector: 'calao-entity-selector-field',
	templateUrl: './entity-selector-field.component.html',
	styleUrls: ['./entity-selector-field.component.scss'],
})
export class EntitySelectorFieldComponent extends FieldBase<string> implements OnInit {

	//#region PROPERTIES

	public readonly observableParams = new ObservableProperty<IEntitySelectorFieldParams>();
	public readonly observableSelectorParams = new ObservableProperty<IEntitySelectorParamsBase>();

	public readonly observableModel = new ObservableProperty<Entity | undefined>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		private readonly isvcEntities: EntitiesService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.initParams();

		this.observableParams.value$.pipe(
			switchMap((poParams: IEntitySelectorFieldParams) => this.isvcEntities.getDescriptor$(poParams.entityDescId).pipe(
				switchMap((poDesc: IEntityDescriptor) => this.isvcEntityLink.getLinkedEntities(
					this.isvcEntityLink.currentEntity,
					IdHelper.getPrefixFromId(poDesc.idPattern),
					poParams.linkTypes.target,
					true
				)),
				tap((paLinkedEntities: Entity[]) => {
					const loLinkedEntity: Entity | undefined = ArrayHelper.getFirstElement(paLinkedEntities);
					this.observableModel.value = loLinkedEntity;
					this.observableSelectorParams.value = {
						...poParams,
						preselectedEntityId: this.fieldValue = loLinkedEntity?._id ?? poParams.preselectedEntityId
					};
				})
			)),
			secure(this)
		).subscribe();
	}

	private initParams(): void {
		const loParams: IEntitySelectorFieldParams = this.props.data;

		if (loParams.readOnly === undefined)
			loParams.readOnly = this.to.readonly;

		this.observableParams.value = loParams;
	}

	public onSelectedEntityChanged(poEntity?: Entity): void {
		const loRelationData: Map<string, IEntityLinkRelationData> | undefined = poEntity ? new Map([
			[poEntity._id, {
				[this.model._id]: this.observableParams.value.linkTypes.current,
				[poEntity._id]: this.observableParams.value.linkTypes.target
			}]
		]) : undefined;

		this.isvcEntityLink.updateCachedEntityLinks(
			this.model,
			this.observableModel.value ? [this.observableModel.value] : [],
			poEntity ? [poEntity] : [],
			loRelationData
		);

		this.observableModel.value = poEntity;
		this.fieldValue = poEntity._id;
	}

	//#endregion METHODS

}
