import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { ConfigData } from "../../../../model/config/ConfigData";
import { OsappApiHelper } from "../../../osapp-api/helpers/osapp-api.helper";
import { SqlDataSource } from "../../models/sql-data-source";

@Injectable()
export abstract class SqlRemoteProvider {

	//#region FIELDS

	protected abstract readonly C_LOG_ID: string;

	//#endregion FIELDS

	//#region METHODS

	public constructor(protected readonly ioHttpClient: HttpClient) { }

	/** Il peut exister plusieurs remote providers pour une même application (ex: Merchapp: Stock, Catalog).
	 * `canProcess` permet de ségréguer les types de base de données qui sont gérés par ce provider en fonction de l'identifiant de base de données.
	 */
	public abstract canProcess(psDatabaseId: string): boolean;

	/** Retourne l'url de la dernière version d'une base de données. */
	public abstract getLastUrl(psDatabaseId: string): string;

	/** Retourne l'url de la base de données avec une version. */
	public abstract getUrl(psDatabaseId: string, pnVersion: number): string;

	/** Retourne la base de données la plus récente.
	 * @param psDatabaseId Identifiant de la base de données.
	 */
	public abstract getLastDatabaseAsync(psDatabaseId: string): Promise<SqlDataSource>;

	protected getVersionResponseAsync<T extends { version: string }>(psDatabaseId: string): Promise<T> {
		if (!ConfigData.authentication.token)
			console.error(`${this.C_LOG_ID}Token not defined !`);
		if (!ConfigData.environment.API_KEY)
			console.error(`${this.C_LOG_ID}ApiKey not defined !`);

		return lastValueFrom(
			this.ioHttpClient.get(
				this.getLastUrl(psDatabaseId),
				{
					headers: new HttpHeaders({
						appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
						token: ConfigData.authentication.token ?? "",
						"api-key": ConfigData.environment.API_KEY ?? "",
						accept: "application/json"
					}),
					observe: "body",
					responseType: "json"
				}
			)
		) as Promise<T>;
	}

	//#endregion METHODS

}