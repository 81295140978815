import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ArrayHelper } from '../../../../../../helpers/arrayHelper';
import { DateHelper } from '../../../../../../helpers/dateHelper';
import { EPrefix } from '../../../../../../model/EPrefix';
import { BaseEvent } from '../../../../../calendar-events/models/base-event';
import { EEntityLinkType } from '../../../../../entities/models/eentity-link-type';
import { Entity } from '../../../../../entities/models/entity';
import { ObserveProperty } from '../../../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IInlineFieldLayoutParams } from '../inline-field-layout/models/iinline-field-layout-params';
import { IInlineField } from '../inline-field-layout/models/iinlineField';
import { EEventDateQueryCriteria } from './models/eevent-date-query-criteria';
import { IEventDateParams } from './models/ievent-date-params';
import { IEventDateQuery } from './models/ievent-date-query';

@Component({
	selector: 'calao-event-date',
	templateUrl: './event-date.component.html',
	styleUrls: ['./event-date.component.scss'],
})
export class EventDateComponent extends FieldBase<Date> implements OnInit, IInlineField {

	//#region PROPERTIES

	/** `true` si readOnly, sinon `false`. Est `false` par défaut. */
	public readonly observableIsReadOnly = new ObservableProperty<boolean>(false);

	/** Style de layout à afficher (undefined par défaut). */
	public layout: "inline";
	@ObserveProperty<EventDateComponent>({ sourcePropertyKey: "layout" })
	public readonly observableLayout = new ObservableProperty<"inline">();

	/** Paramètres d'affichage à passer au layout. */
	public layoutParams: IInlineFieldLayoutParams;
	@ObserveProperty<EventDateComponent>({ sourcePropertyKey: "layoutParams" })
	public readonly observableLayoutParams = new ObservableProperty<IInlineFieldLayoutParams>();

	/** Masque le libellé du champs dans le layout si `true` et que le champs est renseigné. */
	public hideLabelWhenFilled: boolean;
	@ObserveProperty<EventDateComponent>({ sourcePropertyKey: "hideLabelWhenFilled" })
	public readonly observableHideWhenFilled = new ObservableProperty<boolean>();

	/** Format d'affichage de la date. */
	public readonly observableDateLabelFormat = new ObservableProperty<string>("EEE dd MMMM yyyy");

	/** Chaine complète du libellé à afficher dans le champs. */
	public readonly observableLabel = new ObservableProperty<string>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit() {
		super.ngOnInit();

		const loParams = this.props as IEventDateParams;

		this.observableIsReadOnly.value = !!loParams.readOnly;
		this.observableLayout.value = loParams.layout;
		this.observableLayoutParams.value = loParams.layoutParams;
		this.observableHideWhenFilled.value = loParams.hideLabelWhenFilled;
		this.observableDateLabelFormat.value = loParams.dateFormat;
		this.observableLabel.value = this.getLabel(loParams.query.criteria);

		this.fieldValue = this.getEventDate(loParams.query);
	}

	private getLabel(peCritaria: EEventDateQueryCriteria): string {
		return `Date du ${peCritaria === EEventDateQueryCriteria.last ? "dernier" : "prochain"} RDV`;
	}

	private getEventDate(poQuery: IEventDateQuery): Date | undefined {
		const laEvents: BaseEvent[] = (this.model as Entity).getLinkedEntities(EEntityLinkType.related, EPrefix.event) as BaseEvent[];
		const loEvent: BaseEvent | undefined = poQuery.criteria === EEventDateQueryCriteria.last ? this.getLastEvent(laEvents, poQuery) : this.getNextEvent(laEvents, poQuery);
		return loEvent ? new Date(loEvent.startDate) : undefined;
	}

	private getLastEvent(paEvents: BaseEvent[], poQuery: IEventDateQuery): BaseEvent | undefined {
		const laFilteredEvents: BaseEvent[] = paEvents.filter((poEvent: BaseEvent) => {
			const lbIsBefore: boolean = DateHelper.compareTwoDates(poEvent.startDate, new Date()) <= 0;
			const lbIsSameType: boolean = poEvent.eventType === poQuery.type;
			const lbIsSameSubType: boolean = poEvent.eventSubtype === poQuery.subtype;

			return lbIsBefore && lbIsSameType && lbIsSameSubType;
		})

		return ArrayHelper.getLastElement(laFilteredEvents);
	}

	private getNextEvent(paEvents: BaseEvent[], poQuery: IEventDateQuery): BaseEvent | undefined {
		const laFilteredEvents: BaseEvent[] = paEvents.filter((poEvent: BaseEvent) => {
			const lbIsAfter: boolean = DateHelper.compareTwoDates(poEvent.startDate, new Date()) >= 0;
			const lbIsSameType: boolean = poEvent.eventType === poQuery.type;
			const lbIsSameSubType: boolean = poEvent.eventSubtype === poQuery.subtype;

			return lbIsAfter && lbIsSameType && lbIsSameSubType;
		});

		return ArrayHelper.getFirstElement(laFilteredEvents);
	}

	//#endregion METHODS

}
