import { Component, Input, TemplateRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlatformService } from '../../../../../../../services/platform.service';
import { ModalComponentBase } from '../../../../../../modal';
import { ObserveProperty } from '../../../../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../../../../observable/models/observable-property';
import { IRecurrenceFieldModal } from '../models/irecurrence-field-modal';

@Component({
	selector: 'calao-recurrences-field-modal-content',
	templateUrl: './recurrences-field-modal-content.component.html',
	styleUrls: ['./recurrences-field-modal-content.component.scss', '../recurrences-field.component.scss'],
})
export class RecurrencesFieldModalContentComponent extends ModalComponentBase<IRecurrenceFieldModal> {

	/** Template à afficher dans la popup. */
	@Input() public template?: TemplateRef<any>;
	@ObserveProperty<RecurrencesFieldModalContentComponent>({ sourcePropertyKey: "template" })
	public readonly observableTemplate = new ObservableProperty<TemplateRef<any>>();

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
	) {
		super(poModalCtrl, psvcPlatform)
	}

	public async closeAsync(): Promise<boolean> {
		return this.close();
	}

}
