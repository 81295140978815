import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

/** Exécute une fonction dans les différents cas de `tap` possible (`next`, `error`, `complete`),\
 * pratique pour gérer les loaders.
 * @param pfCallback Fonction à exécuter dans les différents `tap` (`next`, `error`, `complete`).
 */
export function tapAll<T>(pfCallback: (poData?: any) => void): (poSource: Observable<T>) => Observable<T> {
	return (poSource: Observable<T>) => poSource.pipe(
		tap({
			next: (poResult: any) => pfCallback(poResult),
			error: (poError: any) => pfCallback(poError),
			complete: () => pfCallback()
		})
	);
}