<div [ngSwitch]="templateId" class="full-height">

	<ng-template [ngSwitchCase]="'side'">
		<div class="side">
			<ion-item-group *ngFor="let s of sections">
				<calao-section [sectionInfo]="s" [isClosed]="areSectionsClosed"></calao-section>
			</ion-item-group>
		</div>
	</ng-template>

	<ng-template [ngSwitchCase]="'home'">
		<ion-item-group *ngFor="let s of sections" class="marg-tuiles">
			<calao-section [sectionInfo]="s" [isClosed]="areSectionsClosed"></calao-section>
		</ion-item-group>
	</ng-template>

	<ng-template *ngSwitchDefault>Pas de template nommé {{templateId}}</ng-template>
</div>