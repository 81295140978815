<div class="container">

	<div *ngIf="observableLabel.value$ | async as label" class="label">
		<ion-label>{{ label }}</ion-label>
	</div>

	<ng-container *ngIf="observableRadioOptions.changes$ | async as radioOptions">
		<div *ngIf="radioOptions.length > 0">
			<ion-radio-group [value]="observableRadioValue.value$ | async" (ionChange)="onRadioChange($event)"
				class="radio-group">
				<ng-container *ngFor="let radioOption of radioOptions">
					<ion-radio [value]="radioOption.value">{{ radioOption.label }}</ion-radio>
				</ng-container>
			</ion-radio-group>
		</div>
	</ng-container>

	<ng-container *ngIf="observableCheckboxOptions.changes$ | async as checkboxOptions">
		<div class="separator"></div>

		<div class="checkbox">
			<ng-container *ngFor="let checkboxOption of checkboxOptions">
				<ion-checkbox labelPlacement="start" [value]="checkboxOption.value" [checked]="isChecked(checkboxOption.value)"
					(ionChange)="onCheckboxChanged($event)">
					{{ checkboxOption.label }}
				</ion-checkbox>
			</ng-container>
		</div>
	</ng-container>

	<div class="buttons">
		<ion-button (click)="cancelAsync()" class="button cancel">Annuler</ion-button>
		<ion-button (click)="validateAsync()" class="button">Enregistrer</ion-button>
	</div>
</div>