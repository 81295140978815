<ng-container *ngTemplateOutlet="layout === 'inline' ? inline : normal"></ng-container>

<ng-template #normal>
	<ng-container *ngTemplateOutlet="content"></ng-container>
</ng-template>

<ng-template #inline>
	<calao-inline-field-layout [hideIcon]="true" [label]="layoutParams.label" [separator]="layoutParams.separator">
		<calao-inline-field-layout-content>
			<ng-container *ngTemplateOutlet="content"></ng-container>
		</calao-inline-field-layout-content>
	</calao-inline-field-layout>
</ng-template>

<ng-template #content>
	<ion-item *ngIf="!(observableIsOpen.value$ | async) && (observableConsent.value$ | async)"
		class="presentation-ai-card" lines="none" color="ai">

		<ion-grid>
			<ion-row>
				<ion-col>
					<ion-text class="ion-text-wrap txt14px"><strong>Reformulez votre rapport</strong> grâce à l'IA générative
						!</ion-text>
					<br />
					<ion-text class="label-grey">Quota restant : {{(observableUserUsage.value$ |
						async)?.percentageLeft}}%</ion-text>
				</ion-col>
				<ion-col size="auto">
					<ion-button *ngIf="!isFirstOpen" color="ai-button" (click)="openProposalField()" [shape]="'round'"
						[disabled]="(observableIsLoading.value$ | async)">
						<ion-icon name="color-wand" slot="start"></ion-icon>
						<ion-text>Ouvrir</ion-text>
					</ion-button>

					<div (click)="toastIfQuotaExcedeed()" *ngIf="isFirstOpen">
						<ion-button color="ai-button" (click)="openProposalField()" [shape]="'round'"
							[disabled]="(observableIsLoading.value$ | async) || (observableUserUsage.value$ |	async)?.isQuotaExcedeed">
							<ion-icon name="color-wand" slot="start"></ion-icon>
							<ion-text *ngIf="!(observableIsLoading.value$ | async)">Générer</ion-text>
							<ion-spinner *ngIf="(observableIsLoading.value$ | async)"></ion-spinner>
						</ion-button>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-item>

	<ion-item *ngIf="!(observableConsent.value$ | async)" class="presentation-ai-card" lines="none" color="ai">
		<ion-grid>
			<ion-row>
				<ion-col>
					<ion-text class="ion-text-wrap txt14px">Avis d'utilisation de service externe.
						<br />
						En utilisant ce service, vous accédez à une intelligence artificielle générative externe. Vous êtes
						responsable du contenu que vous soumettez et de l'utilisation que vous faites des résultats générés.
						Veuillez utiliser ce service de manière responsable et conforme à nos conditions d'utilisation.
						<br />
						Pour en savoir plus, veuillez consulter nos CGU. <a
							href="https://www.calaotrade.fr/conditions-generales-utilisation/"
							target="_blank">https://www.calaotrade.fr/conditions-generales-utilisation/</a></ion-text>
				</ion-col>
				<ion-col size="auto">
					<ion-button (click)="consent()">Je consens</ion-button>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-item>

	<input type="hidden" [ngModel]="userText" [formControl]="$any(formControl)" />

	<ion-list *ngIf="(observableIsOpen.value$ | async)" lines="none" class="presentation-ai-card">
		<ion-item class="ai-button-bar">
			<div (click)="toastIfQuotaExcedeed()">
				<ion-button (click)="newProposal()" [disabled]="(observableIsLoading.value$ | async) || (observableUserUsage.value$ |
			async)?.isQuotaExcedeed" color="ai-button">
					<ion-icon name="color-wand" slot="start"></ion-icon>
					<ion-spinner *ngIf="(observableIsLoading.value$ | async)"></ion-spinner>
					<ion-label *ngIf="!(observableIsLoading.value$ | async)">Régénérer</ion-label>
				</ion-button>
			</div>

			<ion-button (click)="acceptProposal()">
				<ion-label>Enregistrer</ion-label>
			</ion-button>

			<ion-button (click)="refuseProposal()">
				<ion-label>Fermer</ion-label>
			</ion-button>
		</ion-item>

		<ion-item>
			<ion-label class="label-grey">{{generatedTextPosition === -1 ? "Texte initial" : "Texte généré"}}</ion-label>
		</ion-item>

		<ion-item lines="none">
			<ion-textarea class="proposal-field" [readonly]="true" [autoGrow]="true"
				[value]="observableProposalText.value$ | async"></ion-textarea>
		</ion-item>

		<ion-item>
			<ion-text class="label-grey" slot="start">Quota restant : {{(observableUserUsage.value$ |
				async)?.percentageLeft}}%</ion-text>
			<ion-item slot="end">
				<ion-icon name="chevron-back-circle" (click)="downTextPosition()" color="ai-button"
					[style.visibility]="(observableProposalCanGoDown.value$ | async)? 'hidden' : 'visible'"></ion-icon>
				<ion-text class="label-grey padding-arround"
					[style.visibility]="(observableProposalCanGoUp.value$ | async) && (observableProposalCanGoDown.value$ | async)? 'hidden' : 'visible'">
					{{
					generatedTextPosition + 1 }}/{{ generatedTexts.length }} </ion-text>
				<ion-icon name="chevron-forward-circle" (click)="upTextPosition()" color="ai-button"
					[style.visibility]="(observableProposalCanGoUp.value$ | async)? 'hidden' : 'visible'"></ion-icon>
			</ion-item>
		</ion-item>
	</ion-list>
</ng-template>