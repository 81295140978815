<ng-container *ngIf="!to.data.readOnly">
	<ng-container *ngTemplateOutlet="layout === 'inline' ? inline : normal"></ng-container>

	<ng-template #inline>
		<calao-inline-field-layout class="inline-textarea" [icon]="layoutParams.icon" [hideLabel]="true"
			[separator]="layoutParams.separator">
			<calao-inline-field-layout-content>
				<div #htmlContainer contenteditable="true" [(innerHTML)]="bufferValue" (keyup)="onKeyUp()"
					[attr.data-placeholder]="layoutParams.label" class="input html-editor-style"
					style="margin: 0 !important; padding-top: 10px; padding-bottom: 10px;">
				</div>
			</calao-inline-field-layout-content>
		</calao-inline-field-layout>
	</ng-template>

	<ng-template #normal>
		<div #htmlContainer contenteditable="true" [(innerHTML)]="bufferValue" (keyup)="onKeyUp()"
			class="input html-editor-style">
		</div>
	</ng-template>
</ng-container>

<ng-container *ngIf="to.data.readOnly">
	<div #htmlContainer [innerHTML]="fieldValue" class="input html-editor-style">
	</div>
</ng-container>