
export class DocumentsCounters {

	//#region PROPERTIES

	/** Nombre total des documents. */
	public total = 0;
	/** Nombre de documents non lus. */
	public notRead = 0;

	public get notReadLabel() { return this.notRead > 1 ? "non lus" : "non lu"; };

	//#endregion PROPERTIES

	//#region METHODS

	constructor(pnReadDocs: number, pnTotal: number) {
		this.total = pnTotal;
		this.notRead = pnTotal - pnReadDocs;
	}

	//#endregion METHODS

}