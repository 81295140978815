<div class="content-wrapper" *ngIf="observableActivitiesListItems.changes$ | async as items">
	<trade-events-filter-bar [minDate]="observableMinDate.value$ | async" [maxDate]="observableMaxDate.value$ | async"
		[filterValues]="observableFilterValues.value$ | async" [nbTmpResults]="observableNbTmpResults.value$ | async"
		[sortOptions]="observableSortOptions.changes$ | async" [hideCustomerSelector]="true"
		[defaultBy]="observableDefaultSort.value$ | async"
		[typeSelectorParams]="observableTypeSelectorParams.value$ | async"
		(onFilterValuesChange)="onFilterValuesChange($event)" (onTmpFilterValuesChange)="onTmpFilterValuesChange($event)">
	</trade-events-filter-bar>

	<div class="activities">
		<trade-activities-list (onLoadMoreClicked)="onLoadMorePendingClicked()" [items]="items"
			[blankInformationSubtitle]="getNoContentSubtitle()" [useDelegatedItem]="true"
			[virtualScrollStartTemplate]="virtualScrollStartTemplate"
			[hideBlankInformation]="observableActivitiesListItems.length === 0"></trade-activities-list>
	</div>
</div>

<ng-template #loading>
	<ng-container *ngIf="observableIsLoading.value$ | async">
		<osapp-spinner></osapp-spinner>
	</ng-container>
</ng-template>

<ion-fab *ngIf="observableCanCreate.value$ | async" vertical="bottom" horizontal="end" slot="fixed"
	class="fab-margin fab-list-text">
	<ion-fab-button class="fab-shadow">
		<ion-icon name="add"></ion-icon>
	</ion-fab-button>
	<ion-fab-list side="top">
		<ion-fab-button size="small" color="primary" class="fab-label" (click)="routeToNewTask()">
			<ion-label>Tâche</ion-label>
		</ion-fab-button>
		<ion-fab-button size="small" color="primary" class="fab-label" (click)="routeToNewEvent()">
			<ion-label>Évènement</ion-label>
		</ion-fab-button>
	</ion-fab-list>
</ion-fab>

<ng-template #virtualScrollStartTemplate>
	<ng-container *ngIf="!(observableHideLateItem.value$ | async)">
		<ng-container
			*ngIf="(observableCanDisplayLateItem.value$ | async) && (observableLateTasks.changes$ | async) as lateTasks">
			<ng-container *ngIf="lateTasks.length > 0">
				<div class="late-item">
					<trade-late-item [totalCount]="observableLateTasksCount.value$ | async"
						[diffCount]="observableLateNbDiff.value$ | async" [events]="lateTasks"></trade-late-item>
				</div>
			</ng-container>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="!(observableHideTodoItem.value$ | async)">
		<ng-container
			*ngIf="(observableCanDisplayTodoItem.value$ | async) && (observableUndatedActivitiesListItems.changes$ | async) as undatedTasks">
			<ng-container *ngIf="undatedTasks.length > 0">
				<div class="todo-item">
					<trade-todo-item [totalCount]="observableUndatedNbResults.value$ | async"
						[diffCount]="observableUndatedNbDiff.value$ | async" [tasks]="undatedTasks"></trade-todo-item>
				</div>
			</ng-container>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="observableActivitiesListItems.length === 0">
		<div class="blank-information">
			<calao-blank-information icon="task"
				subtitle="Aucune activité à venir ne correspond aux critères de filtrage pour ce Business.">
			</calao-blank-information>
		</div>
	</ng-container>
</ng-template>