import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ObserveProperty } from '../../../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { EInlineFieldLayoutSeparator } from '../inline-field-layout/models/einline-field-layout-separator';

@Component({
	selector: 'calao-inline-readonly-field-layout',
	templateUrl: './inline-readonly-field-layout.component.html',
	styleUrls: ['./inline-readonly-field-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineReadonlyFieldLayoutComponent {

	//#region PROPERTIES

	/** Style de séparateur à appliquer au champs. Par défaut "none". */
	@Input() public separator?: EInlineFieldLayoutSeparator;
	@ObserveProperty<InlineReadonlyFieldLayoutComponent>({ sourcePropertyKey: "separator" })
	public readonly observableSeparator = new ObservableProperty<EInlineFieldLayoutSeparator>(EInlineFieldLayoutSeparator.none);

	/** Libellé du champs. */
	@Input() public label?: string;
	@ObserveProperty<InlineReadonlyFieldLayoutComponent>({ sourcePropertyKey: "label" })
	public readonly observableLabel = new ObservableProperty<string>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor() { }

	//#endregion METHODS


}
