import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { IContact } from '../../../../model/contacts/IContact';
import { EFormEventType } from '../../../../model/forms/EFormEventType';
import { FieldBase } from '../../../../model/forms/FieldBase';
import { IContactsListFieldParams } from '../../../../model/forms/IContactsListFieldParams';
import { IFormEvent } from '../../../../model/forms/IFormEvent';
import { EntityLinkService } from '../../../../services/entityLink.service';
import { FormsService } from '../../../../services/forms.service';

/** Champs contenant une liste de contacts. */
@Component({
	selector: 'calao-contacts-list-field',
	templateUrl: './contactsListField.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsListFieldComponent extends FieldBase<Array<IContact> | Array<string>> implements OnInit {

	//#region FIELDS

	/** Indique si c'est le premier changement de modèle opéré ou non, permettant de déterminer s'il faut marquer le champ comme dirty ou non. */
	private mbIsFirstModelChanged = true;

	//#endregion

	//#region PROPERTIES

	/** Objet contenant les différents paramètres que le composant peut gérer. */
	public params: IContactsListFieldParams;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcEntityLink: EntityLinkService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.initParams();

		if (!ArrayHelper.hasElements(this.fieldValue))
			this.fieldValue = [];

		// Si l'on passe du mode edit au mode visu avec enregistrement, le contactsList est mis à jour.
		this.isvcForms.waitFormEvent(this.model._id, EFormEventType.afterSubmit)
			.pipe(
				tap((poEvent: IFormEvent) => this.onModelChanged(poEvent.data.model[this.fieldKey])),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	private initParams(): void {
		this.params = this.to.data;

		if (this.params.readOnly === undefined)
			this.params.readOnly = this.to.readonly;

		this.params.contactsContainer = this.model;
	}

	public onModelChanged(paValues: string[] | IContact[]): void {
		if (this.canSetModelAfterChange(paValues)) {
			// Si le modèle a des éléments et que c'est le premier changement, il s'agit du remplissage du composant de formulaire, pas une modification.
			if (ArrayHelper.hasElements(this.fieldValue) && this.mbIsFirstModelChanged)
				this.mbIsFirstModelChanged = false;
			else
				this.markAsDirty();

			this.fieldValue = paValues;
			this.detectChanges();
		}
	}

	private canSetModelAfterChange(paValues: Array<IContact> | Array<string>): boolean {
		return (typeof ArrayHelper.getFirstElement(paValues as string[]) === "string" && !ArrayHelper.areArraysEqual(paValues as string[], this.fieldValue as string[])) ||
			!ArrayHelper.areArraysFromDatabaseEqual(paValues as IContact[], this.fieldValue as IContact[]);
	}

	//#endregion
}