import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, defer, Observable, of, throwError } from 'rxjs';
import { filter, map, mapTo, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { StoreDocumentHelper } from '../../../../helpers/storeDocumentHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { ERouteUrlPart } from '../../../../model/route/ERouteUrlPart';
import { EListItemOption } from '../../../doc-explorer/models/elist-item-option';
import { IFormParams } from '../../../forms/models/IFormParams';
import { FormsService } from '../../../forms/services/forms.service';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { EPermissionScopes } from '../../../permissions/models/epermission-scopes';
import { TCRUDPermissions } from '../../../permissions/models/tcrud-permissions';
import { PermissionsService } from '../../../permissions/services/permissions.service';
import { PageManagerService } from '../../../routing/services/pageManager.service';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { Queue } from '../../../utils/queue/decorators/queue.decorator';
import { secure } from '../../../utils/rxjs/operators/secure';
import { IEntity } from '../../models/ientity';
import { IEntityDescriptor } from '../../models/ientity-descriptor';
import { ILayoutParams } from '../../models/ilayout-params';
import { EntitiesUpdateService } from '../../services/entities-update.service';
import { EntitiesService } from '../../services/entities.service';

@Component({
	selector: 'calao-entity-header',
	templateUrl: './entity-header.component.html',
	styleUrls: ['./entity-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.width]": "'100%'"
	}
})
export class EntityHeaderComponent<T extends IEntity> extends DestroyableComponentBase implements OnInit {

	//#region FIELDS

	private readonly moObservablePermissionScope = new ObservableProperty<string>();
	private readonly moObservableDescriptor = new ObservableProperty<IEntityDescriptor>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Paramètres du composant. */
	@Input() public params?: IFormParams<T>;
	@ObserveProperty<EntityHeaderComponent<T>>({ sourcePropertyKey: "params" })
	public readonly observableParams = new ObservableProperty<IFormParams<T>>();

	/** Paramètres du layout. */
	@Input() public layoutParams?: ILayoutParams;
	@ObserveProperty<EntityHeaderComponent<T>>({ sourcePropertyKey: "layoutParams" })
	public readonly observableLayoutParams = new ObservableProperty<ILayoutParams>();

	public readonly observableModel = new ObservableProperty<T | undefined>();

	public readonly observableTitle = new ObservableProperty<string>("").bind(this.getTitle$(), this);
	public readonly observableDisplayActionButtons = new ObservableProperty<boolean>(false).bind(this.getDisplayActionButtons$(), this);

	public readonly observableCanEdit = new ObservableProperty<boolean>(false).bind(this.getPermission$("edit").pipe(secure(this)), this);
	public readonly observableCanDelete = new ObservableProperty<boolean>(false).bind(this.getPermission$("delete").pipe(secure(this)), this);

	public readonly displayHeader$: Observable<boolean> = combineLatest([
		this.observableTitle.value$.pipe(map((psTitle: string) => !StringHelper.isBlank(psTitle))),
		this.observableDisplayActionButtons.value$
	]).pipe(
		map(ArrayHelper.some),
		secure(this)
	);

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcEntities: EntitiesService,
		private readonly isvcPermissions: PermissionsService,
		private readonly isvcForms: FormsService,
		private readonly isvcEntitiesUpdate: EntitiesUpdateService,
		private readonly ioRouter: Router,
		private readonly ioRoute: ActivatedRoute,
		protected readonly isvcPageManager: PageManagerService
	) {
		super();
	}

	public ngOnInit(): void {
		this.observableParams.value$.pipe(
			filter((poFormParams?: IFormParams<T>) => !!poFormParams),
			mergeMap((poFormParams: IFormParams<T>) =>
				defer(() =>
					!poFormParams.entityDescriptor && !StringHelper.isBlank(poFormParams.entityDescriptorId) ?
						this.isvcEntities.getDescriptor$(poFormParams.entityDescriptorId) :
						of(poFormParams.entityDescriptor)
				).pipe(
					map((poEntityDescriptor: IEntityDescriptor) => {
						this.moObservableDescriptor.value = poEntityDescriptor;

						if (!StringHelper.isBlank(poEntityDescriptor.permissionScope))
							this.moObservablePermissionScope.value = poEntityDescriptor.permissionScope;

						return poFormParams.model;
					}),
					mergeMap((poModel?: T) =>
						poModel ?
							of(poModel) :
							this.isvcForms.getModel$(poFormParams.modelId)
					),
					tap((poModel: T) => this.observableModel.value = poModel)
				)
			),
			secure(this)
		).subscribe();
	}

	private getTitle$(): Observable<string> {
		return combineLatest([this.observableModel.value$, this.observableParams.value$, this.observableLayoutParams.value$]).pipe(
			map(([poModel, poParams, poLayoutParams]: [T | undefined, IFormParams<T>, ILayoutParams]) =>
				!StringHelper.isBlank(poLayoutParams.title) ? poLayoutParams.title : (StoreDocumentHelper.hasRevision(poModel) ? poLayoutParams.editTitle : poLayoutParams.createTitle) ?? ""
			)
		);
	}

	protected getDisplayActionButtons$(): Observable<boolean> {
		return this.observableParams.value$.pipe(
			mergeMap((poFormParams?: IFormParams<T>) => {
				if (poFormParams?.hideActionButtons)
					return of(false);
				else
					return combineLatest([this.observableCanEdit.value$, this.observableCanDelete.value$]).pipe(map(ArrayHelper.some));
			}),
		);
	}

	protected getPermission$(psPermission: TCRUDPermissions): Observable<boolean> {
		return combineLatest([this.moObservablePermissionScope.value$, this.moObservableDescriptor.value$]).pipe(
			map(([psScope, poDescriptor]: [string, IEntityDescriptor]) =>
				this.isvcPermissions.evaluatePermission(psScope as EPermissionScopes, psPermission, poDescriptor.entry)
			)
		);
	}

	public onEditAsync(): Promise<boolean> {
		return this.onOptionClicked$(EListItemOption.edit).toPromise();
	}

	public onDeleteAsync(): Promise<boolean> {
		return this.onOptionClicked$(EListItemOption.delete).toPromise();
	}

	@Queue<
		EntityHeaderComponent<T>,
		Parameters<EntityHeaderComponent<T>["onOptionClicked$"]>,
		ReturnType<EntityHeaderComponent<T>["onOptionClicked$"]>
	>({
		excludePendings: true
	})
	protected onOptionClicked$(peOption: EListItemOption): Observable<boolean> {
		switch (peOption) {
			case EListItemOption.delete:
				return this.delete$();
			case EListItemOption.edit:
				return this.edit$();
			default:
				return throwError(() => new Error(`Event type ${peOption} not supported for document ${peOption}.`));
		}
	}

	public edit$(): Observable<boolean> {
		return defer(() => this.ioRouter.navigate([ERouteUrlPart.edit], { relativeTo: this.ioRoute })).pipe(mapTo(true));
	}

	private delete$(): Observable<boolean> {
		return this.moObservableDescriptor.value$.pipe(
			take(1),
			switchMap((poDescriptor?: IEntityDescriptor) => {
				if (poDescriptor?.entry) {
					return this.isvcEntitiesUpdate.deleteEntity(poDescriptor.entry, poDescriptor).pipe(
						mapTo(true),
						tap(
							() => this.isvcPageManager.goBack())
					);
				}

				return of(false);
			})
		);
	}

	//#endregion METHODS

}
