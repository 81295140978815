import { RouteComponent } from '@calaosoft/osapp/model/RouteComponent';
import { ConfigOption } from '@ngx-formly/core';
import { BusinessActivitiesSlideComponent } from '../modules/businesses/components/business-activities-slide/business-activities-slide.component';
import { TradeConversationTabComponent } from '../modules/businesses/components/conversation-tab/conversation-tab.component';
import { TradeConversationsListComponent } from '../modules/businesses/components/conversationsList/tradeConversationsList.component';
import { SignatureWrapperComponent } from '../modules/businesses/components/signature-wrapper/signature-wrapper.component';
import { ConfidentialityPageComponent } from '../modules/mentionsLegales/confidentialityPage.component';
import { TermsOfSalesPageComponent } from '../modules/mentionsLegales/termesOfSalesPage.component';
export abstract class TradeComponentRegister {

	//#region FIELDS

	/** Tableau de tous les composants d'Tradeizy. */
	private static readonly maComponents: RouteComponent[] = [
		{ id: "confidentiality-page", componentType: ConfidentialityPageComponent },
		{ id: "terms-of-sales-page", componentType: TermsOfSalesPageComponent },
		{ id: "trade-conversations-list", componentType: TradeConversationsListComponent },
		{ id: "trade-conversations-tab", componentType: TradeConversationTabComponent },
		{ id: "trade-business-activities-slide", componentType: BusinessActivitiesSlideComponent },
	];

	//#endregion

	//#region METHODS

	/** Retourne tous les composants spécifiques d'Tradeizy, avec leurs id. */
	public static getTradeRouteComponents(): RouteComponent[] {
		return this.maComponents;
	}

	//#endregion

}

//#region FORMLY

//#endregion

/** Objet de configuration spécifiques à TRADE concernant les formulaires (champs, validateurs, ...). */
export const TRADE_FORMLY_CONFIG_OPTION: ConfigOption = {
	types: [
	],
	wrappers: [
		{
			name: "signature",
			component: SignatureWrapperComponent
		},
	],
	validators: [
	],
	validationMessages: [
	]
};