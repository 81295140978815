import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OSActionButton, OSActionType, OSNotificationOpenedResult } from '@awesome-cordova-plugins/onesignal/ngx';
import { ActionPerformed, LocalNotifications } from '@capacitor/local-notifications';
import OneSignal, { LogLevel, NotificationClickEvent, NotificationWillDisplayEvent, OSNotification, PushSubscriptionChangedState } from 'onesignal-cordova-plugin';
import { Observable, Subject, Subscription, Timestamp, fromEventPattern, merge, of, throwError } from 'rxjs';
import { filter, first, map, mapTo, mergeMap, take, tap, timestamp } from 'rxjs/operators';
import { ArrayHelper } from '../helpers/arrayHelper';
import { StoreDocumentHelper } from '../helpers/storeDocumentHelper';
import { StringHelper } from '../helpers/stringHelper';
import { EPrefix } from '../model/EPrefix';
import { EApplicationEventType } from '../model/application/EApplicationEventType';
import { ENetworkFlag } from '../model/application/ENetworkFlag';
import { UserData } from '../model/application/UserData';
import { ETaskPrefix } from '../model/backgroundTask/ETaskPrefix';
import { INotificationTaskParams } from '../model/backgroundTask/taskParams/INotificationTaskParams';
import { ConfigData } from '../model/config/ConfigData';
import { EConfigFlag } from '../model/config/EConfigFlag';
import { IConfigOneSignal } from '../model/config/IConfigOneSignal';
import { EContactFlag } from '../model/contacts/EContactFlag';
import { IConversation } from '../model/conversation/IConversation';
import { ENotificationEventType } from '../model/notification/ENotificationEventType';
import { ENotificationTaskAction } from '../model/notification/ENotificationTaskAction';
import { ENotificationTaskModel } from '../model/notification/ENotificationTaskModel';
import { INotificationEvent } from '../model/notification/INotificationEvent';
import { IOneSignalData } from '../model/notification/IOneSignalData';
import { ILocalNotificationExtra } from '../model/notification/ilocal-notification-extra';
import { ESecurityFlag } from '../model/security/ESecurityFlag';
import { IStoreDocument } from '../model/store/IStoreDocument';
import { AuthenticatedRequestOptionBuilder } from '../modules/api/models/authenticated-request-option-builder';
import { EFlag } from '../modules/flags/models/EFlag';
import { ELogActionId } from '../modules/logger/models/ELogActionId';
import { LoggerService } from '../modules/logger/services/logger.service';
import { TaskDescriptor } from './backgroundTask/TaskDescriptor';
import { ConversationService } from './conversation.service';
import { FlagService } from './flag.service';
import { PlatformService } from './platform.service';
import { Store } from './store.service';

interface IOneSignalMarker extends IStoreDocument { }
interface IOneSignalMarkerAndData {
	marker?: IOneSignalMarker;
	data: IOneSignalData;
}

interface IDeviceState {
	pushToken: string;
	userId: string;
	subscribed: boolean;
}

@Injectable({ providedIn: "root" })
export class NotificationService {

	//#region FIELDS

	private static readonly C_LOG_ID = "NOTIF.S::";
	private static readonly C_ONESIGNAL_USER_DATA_DOCUMENT_ID = "one_signal_user_data";

	/** Sujet pour l'envoi d'événement. */
	private readonly moEventSubject = new Subject<INotificationEvent>();
	/** Utilisation du typage du plugin car la librairie `@awesome-cordova-plugins/onesignal` n'est pas à jour avec la version 3 du plugin `onesignal-cordova-plugin`.
	 * https://github.com/danielsogl/awesome-cordova-plugins/issues/3722
	 */
	private readonly moOneSignal = OneSignal;

	/** Contient les données retournées par OneSignal. `undefined` si OneSignal n'a pas encore été initialisé. */
	private moOneSignalData: IOneSignalData;

	private mbLocalNotificationsInitialized = false;

	private moLocalNotificationActions$ = new Subject<ActionPerformed>();

	//#endregion

	//#region PROPERTIES

	public static readonly C_NB_OF_NOTIF_DATA_KEY = "nbOfNotif";

	/** Retourne `true` si le plugin OneSignal est initialisé. */
	public get isInitialized(): boolean { return !!this.moOneSignalData; }

	/** Permet de récupérer l'identifiant OneSignal de l'appareil. */
	public get deviceId(): string {
		if (!this.moOneSignalData) {
			console.warn(`${NotificationService.C_LOG_ID}Trying to get 'deviceId' before init.`);
			return undefined;
		}

		return this.moOneSignalData.userId;
	}

	/** Permet de récupérer le token OneSignal. */
	public get pushToken(): string {
		if (!this.moOneSignalData) {
			console.warn(`${NotificationService.C_LOG_ID}Trying to get 'pushToken' before init.`);
			return undefined;
		}

		return this.moOneSignalData.pushToken;
	}

	/** Observable des évènements sur les notifications locales (ex : clic sur un bouton d'action) */
	public get localNotificationActions$(): Observable<ActionPerformed> {
		return this.moLocalNotificationActions$.asObservable();
	}

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcConversation: ConversationService,
		/** Service de gestion de la plateforme. */
		private readonly isvcPlatform: PlatformService,
		private readonly isvcFlag: FlagService,
		private readonly ioHttp: HttpClient,
		private readonly ioRouter: Router,
		private readonly isvcStore: Store,
		private readonly isvcLogger: LoggerService
	) { }

	/** Initialise les notifications locales (totalement indépendantes de OneSignal)
	 * En l'état, s'abonne aux évènements de tap sur les notifications présentées à l'utilisateur,
	 * pour pouvoir le rediriger dans le cas où la notification porte une route
	 */
	public async initLocalNotifications() {
		if (!this.mbLocalNotificationsInitialized) {
			this.mbLocalNotificationsInitialized = true;
			console.debug(`${NotificationService.C_LOG_ID}Initializing local notifications`);

			// Il faut attendre d'avoir accès aux infos de config car on ne veut initialiser ces notifs que sur mobile
			await this.isvcFlag.waitForFlagAsync(EConfigFlag.StaticConfigReady, true);
			if (this.isvcPlatform.isMobileApp) {
				LocalNotifications.addListener("localNotificationActionPerformed", (poAction: ActionPerformed) => {
					console.debug(`${NotificationService.C_LOG_ID}Local Notification tap detected`);

					// Pour le moment, le routage simple vers une entité est géré directement par ce service
					const lsRoute = ((poAction.notification.extra) as ILocalNotificationExtra)?.route;
					if (poAction.actionId === "tap" && lsRoute) {
						console.debug(`${NotificationService.C_LOG_ID}Routing to ${lsRoute}`);
						this.routeAfterNotificationTapped(lsRoute);
					}
					else {
						// Si on n'est pas dans le cas d'un simple routage, on propage l'évènement pour qu'il soit (potentiellement) intercepté par un autre service plus apte à le traiter
						this.moLocalNotificationActions$.next(poAction);
					}
				});
			}
		}
	}

	/** Retourne un objet de notification à partir d'un id de conversation.
	 * @param psConversationGuid Guid de la conversation.
	 * @param psContactGuid Guid du contact.
	 */
	private getNotificationFromConversationId(psConversationGuid: string, psContactGuid?: string): OSNotificationOpenedResult {
		return {
			action: {
				type: OSActionType.ActionTake,
				actionID: `${EPrefix.conversation}${psConversationGuid}`
			},
			notification: {
				payload: {
					notificationID: "",
					title: "",
					body: "",
					sound: "",
					actionButtons: [],
					rawPayload: "",
					additionalData: {
						model: "Conversation",
						action: "Route",
						contact: psContactGuid ? `${EPrefix.contact}${psContactGuid}` : ""
					}
				}
			}
		};
	}

	/** Démarre l'initialisation de OneSignal. La récupération des clés et leur affectation au `ConfigData.appInfo` ne se fait qu'après authentification et réseau fiable. */
	public initPushNotifications(poConfig: IConfigOneSignal): Observable<void> {
		console.debug(`${NotificationService.C_LOG_ID}Initializing push notifications`);
		return this.initOneSignal(poConfig);
	}

	private initOneSignal(poConfig: IConfigOneSignal): Observable<void> {
		if (poConfig && this.isvcPlatform.isMobileApp) {
			this.moOneSignal.initialize(poConfig.appId);
			this.initOneSignalNotificationsHandler();
			return this.getAndInitOneSignalIds().pipe(
				tap(() => this.subscribeToOneSignalChanges())
			);
		}
		else {
			console.debug(`${NotificationService.C_LOG_ID}No oneSignal config.`);
			return of(undefined);
		}
	}

	/** Initialisation du comportement lors de la reception et lors de l'ouverture d'une notification oneSignal. */
	private initOneSignalNotificationsHandler(): void {
		this.moOneSignal.Notifications.addEventListener("click", (poPushOpened: NotificationClickEvent) => {
			console.debug(`${NotificationService.C_LOG_ID}Opening push notification`, poPushOpened);
			this.onNotificationClickedAsync(poPushOpened);
		});
		this.moOneSignal.Notifications.addEventListener("foregroundWillDisplay", (poPushWillShow: NotificationWillDisplayEvent) => {
			// Si l'utilisateur n'est pas dans la conversation concernée, on affiche la notification.
			// TODO A SUPPRIMER (après modif workflow conversations)
			if (JSON.parse(JSON.parse(poPushWillShow.getNotification().rawPayload).custom).a.actionButtons?.[0]?.id !== this.ioRouter.url.split("/").join("").slice(13))
				poPushWillShow.getNotification().display();
		});
	}

	/** Récupère les identifiants OneSignal dans PouchDb :
	 * - Si existant, on les met à jour avec la nouvelle donnée récupérée du plugin.
	 * - Sinon il s'agit d'une première initialisation de l'app, donc on attend que le plugin récupère les identifiant, puis on les met à jour dans PouchDb.
	 */
	private getAndInitOneSignalIds(): Observable<void> {
		console.debug(`${NotificationService.C_LOG_ID}Trying to get OneSignal ids.`);

		return this.isvcStore.getLocal(NotificationService.C_ONESIGNAL_USER_DATA_DOCUMENT_ID)
			.pipe(
				mergeMap((poMarker?: IOneSignalMarker) => this.getOneSignalMarkerAndDataAsync(poMarker)),
				mergeMap((poMarkerAndData: IOneSignalMarkerAndData) => this.saveOneSignalMarkerIfNeeded(poMarkerAndData.marker).pipe(mapTo(poMarkerAndData.data))),
				mergeMap((poOneSignalData: IOneSignalData) => this.initOneSignalIds(poOneSignalData)),
			);
	}

	/** Joint les données de OneSignal au marqueur passé en paramètre.
	 * @param poOneSignalData
	 */
	private getOneSignalMarkerAndDataAsync(poOneSignalData?: IOneSignalMarker): Observable<IOneSignalMarkerAndData> {
		// Si l'appareil est déjà abonné on retourne son état
		return merge(
			this.getOneSignalDeviceStateAsync(),
			this.getOneSignalSubscriptionChanges()
				.pipe(
					// Permet d'attendre que les infos OneSignal ('userId' et 'pushToken') soient correctement initialisés par le plugin.
					filter((poChangeEvent: PushSubscriptionChangedState) => !!poChangeEvent.current),
					take(1), // On initialise juste, on ne reste pas abonné pour les changements post-initialisation.
					mergeMap(() => this.getOneSignalDeviceStateAsync())
				)
		).pipe(
			mergeMap(async (poDeviceState: IDeviceState) => {
				if (!poDeviceState.subscribed)
					await this.moOneSignal.Notifications.requestPermission();
				return poDeviceState;
			}),
			filter((poDeviceState: IDeviceState) => poDeviceState.subscribed),
			first(),
			map((poDeviceState: IDeviceState) => this.getOneSignalMarkerAndData(poDeviceState, poOneSignalData)));
	}

	/** Récupère les changements au niveau de l'id OneSignal de l'utilisateur. */
	private getOneSignalSubscriptionChanges(): Observable<PushSubscriptionChangedState> {
		return fromEventPattern((pfCallback: (poEvent: PushSubscriptionChangedState) => void) =>
			this.moOneSignal.User.pushSubscription.addEventListener("change", (pfCallback))
		);
	}

	/** Récupère l'état actuel de la configuation OneSignal de l'utilisateur. */
	private async getOneSignalDeviceStateAsync(): Promise<IDeviceState> {
		return {
			pushToken: await this.moOneSignal.User.pushSubscription.getTokenAsync(),
			userId: await this.moOneSignal.User.pushSubscription.getIdAsync(),
			subscribed: await this.moOneSignal.User.pushSubscription.getOptedInAsync()
		};
	}

	private getOneSignalMarkerAndData(poDeviceState: IDeviceState, poOneSignalMarker?: IOneSignalMarker): IOneSignalMarkerAndData {
		return {
			marker: poOneSignalMarker,
			data: { pushToken: poDeviceState.pushToken, userId: poDeviceState.userId } as IOneSignalData
		};
	}

	/** Enregistre en local les informations (pushToken et userId) de OneSignal.
	 * @param poMarker Donnée à enregistrer.
	 */
	private saveOneSignalMarkerIfNeeded(poMarker?: IOneSignalMarker): Observable<void> {
		// Si le marqueur a une révision alors il existe, sinon il faut l'enregistrer en base de données.
		if (StoreDocumentHelper.hasRevision(poMarker))
			return of(undefined);
		else {
			return this.isvcStore.putLocal({ _id: NotificationService.C_ONESIGNAL_USER_DATA_DOCUMENT_ID } as IOneSignalMarker)
				.pipe(
					tap(_ => this.isvcLogger.action(NotificationService.C_LOG_ID, "oneSignal marker initialized", ELogActionId.notifMarkerInit)),
					mapTo(undefined)
				);
		}
	}

	/** Initialise les ids oneSignal puis met à jour l'identifiant de l'appareil en base.
	 * @param poOneSignalData Identifiants OneSignal.
	 */
	private initOneSignalIds(poOneSignalData: IOneSignalData): Observable<void> {
		return this.onAuthenticatedAndOnline().pipe( // Attendre que l'utilisateur soit connecté et qu'on ait du réseau.
			filter(_ => !this.isInitialized), // Si les clefs ne sont pas déjà chargées.
			tap(
				_ => {
					if (poOneSignalData) {
						console.debug(`${NotificationService.C_LOG_ID}OneSignal ids got.`);

						this.setOneSignalData(poOneSignalData);

						console.info(`${NotificationService.C_LOG_ID}OneSignal initialized :`, poOneSignalData);

						this.raiseInitialisationEvent();
					}
					else {
						console.error(`${NotificationService.C_LOG_ID}OneSignal ids are empty`);
						throw new Error("OneSignal IDs are empty.");
					}
				},
				poError => console.error(`${NotificationService.C_LOG_ID}Unable to get OneSignal ids: `, poError)
			),
			mergeMap(_ => this.updateOneSignal(poOneSignalData)),
			tap(_ => this.isvcLogger.action(NotificationService.C_LOG_ID, "oneSignal initialized", ELogActionId.notifInit)),
			mapTo(undefined)
		);
	}

	private setOneSignalData(poData: IOneSignalData): void {
		if (poData !== this.moOneSignalData || poData.pushToken !== this.moOneSignalData.pushToken || poData.userId !== this.moOneSignalData.userId) {
			this.moOneSignalData = poData; // Ne pas redemander les clés à OneSignal.
			this.updateAppInfo(poData.userId); // Affectation des valeurs à l'objet de config.
		}
	}

	/** Met à jour le `ConfigData.appInfo` avec les informations du device passé en paramètre. */
	private updateAppInfo(psDeviceId: string): void {
		if (!ConfigData.appInfo)
			console.error(`${NotificationService.C_LOG_ID}Trying change ConfigData from NotificationService before its init from ConfigService.`);
		else
			ConfigData.appInfo.oneSignalId = psDeviceId;
	}

	private async routeAfterNotificationTapped(psRoute: string) {
		await this.isvcFlag.waitForFlagAsync(EFlag.appAvailable, true);
		await this.ioRouter.navigateByUrl(psRoute);
	}

	/** Traite les notifications Push quand on click dessus, ou sur un bouton.
	 * @param poNotificationClicked click sur une notification Push.
	 */
	public async onNotificationClickedAsync(poNotificationClicked?: NotificationClickEvent): Promise<void> {
		const lsDefault = "__DEFAULT__";
		const loNotificationOpened: OSNotification = poNotificationClicked?.notification as OSNotification;

		const lsRouteUrl: string = loNotificationOpened?.additionalData["route"];

		if (!StringHelper.isBlank(lsRouteUrl)) {
			this.routeAfterNotificationTapped(lsRouteUrl);
		}
		else if (loNotificationOpened?.actionButtons[0] &&
			(loNotificationOpened.actionButtons[0] as OSActionButton).id !== lsDefault) {
			switch (loNotificationOpened.additionalData["model"] + loNotificationOpened.additionalData["action"]) {
				case ENotificationTaskModel.message + ENotificationTaskAction.route:
				case ENotificationTaskModel.conversation + ENotificationTaskAction.route:
					// Lève un événement dans l'application.
					const loEvent: INotificationEvent = {
						type: EApplicationEventType.notificationEvent,
						createDate: new Date(),
						data: { notificationEventType: ENotificationEventType.OnPushClick, openResult: poNotificationClicked }
					};

					this.moEventSubject.next(loEvent);
					break;
			}
		}
	}

	public routeToConversation(poConversationGuid: string, poUserGuid: string, isGuest: boolean): Subscription {
		return this.routeToConversationPage(
			this.getNotificationFromConversationId(poConversationGuid, poUserGuid),
			isGuest
		).subscribe();
	}

	/** Créer la tâche permettant de router vers une conversation.
	 * @param poNotificationClicked Paramètres de la notification reçue.
	 */
	private routeToConversationPage(poNotificationClicked: OSNotificationOpenedResult, pbIsGuest: boolean = false): Observable<void> {
		const lnStartTime: number = Date.now();
		const lsConvId: string = ArrayHelper.hasElements(poNotificationClicked.notification.payload.actionButtons) ?
			poNotificationClicked.notification.payload.actionButtons[0].id : poNotificationClicked.action.actionID;
		const loQueryParams = {
			contactId: poNotificationClicked.notification.payload.additionalData.contact,
			isGuest: pbIsGuest
		};

		return this.isvcConversation.downloadConversation(lsConvId) // TODO Modif workflow pour passer l'id de la conversation dans les additionnalDatas.
			.pipe(
				mergeMap((poConversation?: IConversation) => poConversation ? of(poConversation) : throwError(() => `Impossible d'accéder à la conversation.`)),
				tap(
					(poConversation: IConversation) => this.isvcConversation.routeToConversation(poConversation, loQueryParams),
					poError => console.error(`${NotificationService.C_LOG_ID}Impossible to go to conversation '${lsConvId}'.`, poError)
				),
				timestamp(),
				map((poResult: Timestamp<IConversation>) => console.debug(`${NotificationService.C_LOG_ID}Redirection in ${poResult.timestamp - lnStartTime}ms.`)),
			);
	}

	/** Créer la tâche permettant de router vers une conversation.
	 * @param poNotificationClicked paramètre de la notification reçue.
	 */
	public buildRouteToConversationPageTask(poNotificationClicked: NotificationClickEvent): TaskDescriptor<INotificationTaskParams> {
		console.debug(`${NotificationService.C_LOG_ID}Add routing's task.`);

		return new TaskDescriptor({
			id: StringHelper.getFormatedRouteTaskDescriptor(
				ETaskPrefix.routePage,
				"conversation_",
				poNotificationClicked
			),
			name: "Task Open Conversation",
			taskType: "NotificationOpenConversationTask",
			params: poNotificationClicked as INotificationTaskParams,
			execAfter: [{ key: EContactFlag.userContactInitialized, value: true }]
		});
	}

	/** Observable retourné dès que l'utilisateur est connecté ET le réseau est disponible. */
	private onAuthenticatedAndOnline(): Observable<boolean> {
		// On récupère chaque émission d'authentification et de l'état du réseau, avec la dernière valeur émise de chacun.
		return this.isvcFlag.waitForFlags([ESecurityFlag.authenticated, ENetworkFlag.isOnlineReliable], true);
	}

	/** Permet de s'abonner aux événements du NotificationService.
	 * @param pfNext Fonction appelée lors du next => function(poResult: INotificationEvent).
	 * @param pfError Fonction appelée lors du error => function(poError: Any).
	 */
	public subscribe(pfNext: Function, pfError?: Function): void {
		this.moEventSubject.asObservable().subscribe(
			(poResult: INotificationEvent) => pfNext(poResult),
			poError => pfError(poError)
		);
	}

	/** Lève un événement indiquant à l'application que le notification service est bien initialisé. */
	private raiseInitialisationEvent(): void {
		this.moEventSubject.next({
			type: EApplicationEventType.notificationEvent,
			createDate: new Date(),
			data: { notificationEventType: ENotificationEventType.Initialised }
		} as INotificationEvent);
	}

	/** Active le debug OneSignal. */
	public enableOneSignalDebug(): void {
		this.moOneSignal.Debug.setLogLevel(LogLevel.Debug);
	}

	/** Désactive le debug OneSignal. */
	public disableOneSignalDebug(): void {
		this.moOneSignal.Debug.setLogLevel(LogLevel.None);
	}

	/** Met à jour l'identifiant oneSignal d'un device.
	 * @param poOneSignalData Donnée oneSignal à mettre à jour.
	 */
	private updateOneSignal<T = any>(poOneSignalData: IOneSignalData): Observable<T> {
		return this.ioHttp.post<T>(
			`${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_suffix}apps/${ConfigData.appInfo.appId}/security/users/${UserData.current._id}/notifications/${poOneSignalData.userId}?type=onesignal&deviceId=${ConfigData.appInfo.deviceId}`,
			{},
			AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()
		)
			.pipe(
				tap(
					_ => { },
					poError => console.error(`${NotificationService.C_LOG_ID}unable to update oneSignal id`, poError)
				)
			);
	}

	/** Abonnement aux changements oneSignal (pour mettre à jour les infos sur l'abonnement oneSignal de l'appareil). */
	private subscribeToOneSignalChanges(): void {
		this.getOneSignalSubscriptionChanges()
			.pipe(
				// Permet d'attendre que les infos OneSignal ('userId' et 'pushToken') soient correctement initialisés par le plugin.
				filter((poChangeEvent: PushSubscriptionChangedState) => !!poChangeEvent.current),
				mergeMap(_ => this.getOneSignalDeviceStateAsync()),
				filter((poDeviceState: IDeviceState) => poDeviceState.pushToken !== this.moOneSignalData.pushToken || poDeviceState.userId !== this.moOneSignalData.userId),
				map((poDeviceState: IDeviceState) => this.getOneSignalMarkerAndData(poDeviceState).data),
				tap((poOneSignalData: IOneSignalData) => this.setOneSignalData(poOneSignalData)),
				mergeMap((poOneSignalData: IOneSignalData) => this.updateOneSignal(poOneSignalData)),
				tap(() => this.isvcLogger.action(NotificationService.C_LOG_ID, "oneSignal updated", ELogActionId.notifUpdated, this.moOneSignalData))
			).subscribe();
	}

	//#endregion

}