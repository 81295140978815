import { CommonModule } from "@angular/common";
import { NgModule, Provider, Type } from "@angular/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatRadioButton } from "@angular/material/radio";
import { SharedComponentsModule } from "@calaosoft/osapp/components/sharedComponents.module";
import { HeaderModule } from "@calaosoft/osapp/modules/header";
import { ListsModule } from "@calaosoft/osapp/modules/lists/lists.module";
import { CalaoMapModule } from "@calaosoft/osapp/modules/maps/map.module";
import { TabsModule } from "@calaosoft/osapp/modules/tabs/tabs.module";
import { SectorBusinessSelectorModalComponent } from "./components/sector-business-selector-modal.component";
import { BusinessDistributionPage } from "./pages/business-distribution/business-distribution.page";
import { SectorizationComparisonPage } from "./pages/comparison/sectorization-comparison.page";
import { SectorizationPage } from "./pages/sectorization/sectorization.page";
import { SectorizationsPageRoutingModule } from "./sectorizations-routing.module";
import { SectorsModule } from "./sectors/sectors.module";
import { SectorOptimizeService } from "./services/sector-optimization.service";
import { SectorizationsService } from "./services/sectorizations.service";

const modules: Type<any>[] = [
	SharedComponentsModule,
	CalaoMapModule,
	SectorsModule,
	ListsModule,
	CommonModule,
	SectorizationsPageRoutingModule,
	TabsModule,
	HeaderModule,
	MatRadioButton,
	MatDividerModule,
	MatListModule,
];
const components: Type<any>[] = [
	SectorizationPage,
	SectorizationComparisonPage,
	BusinessDistributionPage,
	SectorBusinessSelectorModalComponent
];
const providers: Provider[] = [
	SectorizationsService,
	SectorOptimizeService
];

@NgModule({
	exports: components,
	declarations: components,
	providers: providers,
	imports: modules
})
export class SectorizationsModule { }