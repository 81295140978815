import { EMPTY, Observable, combineLatest, defer, fromEvent, of, throwError } from 'rxjs';
import { catchError, finalize, map, mapTo, mergeMap, take, tap } from 'rxjs/operators';
import { IHeartbeatTaskParams } from '../../model/backgroundTask/taskParams/IHeartbeatTaskParams';
import { TokenExpiredError } from '../../modules/security/errors/TokenExpiredError';
import { InjectorService } from '../injector.service';
import { ShowMessageParamsPopup } from '../interfaces/ShowMessageParamsPopup';
import { NetworkService } from '../network.service';
import { PlatformService } from '../platform.service';
import { SecurityService } from '../security.service';
import { UiMessageService } from '../uiMessage.service';
import { TaskBase } from './TaskBase';
import { TaskDescriptor } from './TaskDescriptor';

export class HeartbeatTask<T extends IHeartbeatTaskParams = IHeartbeatTaskParams> extends TaskBase<T> {

	//#region FIELDS

	private static readonly C_LOG_ID = "HRTBT.T::";

	private readonly msvcSecurity: SecurityService;
	private readonly msvcNetwork: NetworkService;
	private readonly msvcUiMessage: UiMessageService;
	private readonly isvcPlatform: PlatformService;

	/** Sémaphore permettant de ne pas attendre plusieurs fois l'activité d'un utilisateur. */
	private static isWaitingForUserActivity = false;

	/** Nom de l'événement pour attendre l'interaction d'un utilisateur. */
	private get touchScreenEventName(): string { return this.isvcPlatform.isMobile ? "touchstart" : "mousemove"; }

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);
		this.msvcSecurity = InjectorService.instance.get(SecurityService);
		this.msvcNetwork = InjectorService.instance.get(NetworkService);
		this.msvcUiMessage = InjectorService.instance.get(UiMessageService);
		this.isvcPlatform = InjectorService.instance.get(PlatformService);
		console.debug(`${HeartbeatTask.C_LOG_ID}HeartbeatTask.name = ${HeartbeatTask.name}`);
	}

	/** Vérifie régulièrement la durée de validité du token.
	 * Si le token est expiré alors on affiche l'authenticator.
	 * Si le token est valide, que l'on a du réseau et pas d'écouteur sur le Dom, on crée un écouteur pour envoyer un heartbeat pendant l'activité de l'application.
	 */
	protected override execTask$(): Observable<void> {
		return combineLatest([this.msvcSecurity.checkTokenExpiration(), this.msvcNetwork.asyncIsNetworkReliable()])
			.pipe(
				mergeMap(([pbIsTokenExpired, pbHasNetwork]: [boolean, boolean]): Observable<void> => {
					console.debug(`${HeartbeatTask.C_LOG_ID}Started task instance with parameter: Token expired: "${pbIsTokenExpired}", Network: "${pbHasNetwork}", is Semaphore active: "${HeartbeatTask.isWaitingForUserActivity}".`);

					if (pbIsTokenExpired)
						return this.handleTokenExpired();

					if (pbHasNetwork && !HeartbeatTask.isWaitingForUserActivity)
						return this.waitForUserActivity().pipe(mergeMap(() => this.renewToken()));

					return of(null);
				}),
			);
	}

	/** Appelé si le token est expiré.\
	 * Affiche un message d'erreur et le redirige vers la page de connexion.
	 */
	private handleTokenExpired(): Observable<void> {
		console.warn(`${HeartbeatTask.C_LOG_ID}Expired token! Redirecting to authentication page.`);

		return defer(() => this.msvcSecurity.clearTokenAsync()).pipe(
			mergeMap(() => this.msvcSecurity.logOutAsync()),
			tap(_ =>
				this.msvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Votre session a expiré. Merci de vous reconnecter.", header: "Authentification" }))
			),
			mapTo(undefined)
		);
	}

	/** Attend que l'utilisateur clique sur l'écran.\
	 * ## Cette méthode ne doit être appelée qu'une seule fois, car elle ne permet qu'une seule écoute active à la fois.
	 */
	private waitForUserActivity(): Observable<void | null> {
		if (!HeartbeatTask.isWaitingForUserActivity) {
			HeartbeatTask.isWaitingForUserActivity = true;

			return fromEvent(document.body, this.touchScreenEventName)
				.pipe(
					take(1),
					tap(() => console.debug(`${HeartbeatTask.C_LOG_ID}User activity detected.`)),
					finalize(() => HeartbeatTask.isWaitingForUserActivity = false),
					map(() => undefined)
				);
		}
		else {
			console.error(`${HeartbeatTask.C_LOG_ID}Tried to listen to an event already in progress. Listen cancelled.`);
			return EMPTY;
		}
	}

	/** Tente de renouveler un token et gère l'échec de renouvellement. */
	private renewToken(): Observable<void> {
		console.debug(`${HeartbeatTask.C_LOG_ID}Trying to renew token.`);
		return this.msvcSecurity.requestToExpandSession$().pipe(
			tap(() => console.debug(`${HeartbeatTask.C_LOG_ID}Successful renewal.`)),
			catchError((poError: any) => {
				console.error(`${HeartbeatTask.C_LOG_ID}failed renewal.`, poError);
				if (poError instanceof TokenExpiredError)
					return this.handleTokenExpired();
				else
					return throwError(() => poError)
			})
		);
	}

	//#endregion

}