import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UserData } from '../../../../../../model/application/UserData';
import { PermissionsService } from '../../../../../../modules/permissions/services/permissions.service';
import { ConversationService } from '../../../../../../services/conversation.service';
import { HasPermissions } from '../../../../../permissions/decorators/has-permissions.decorator';
import { EPermissionScopes } from '../../../../../permissions/models/epermission-scopes';
import { IActionButtonFieldConvParams } from '../../../../models/actionButtonFields/IActionButtonFieldConvParams';
import { FormsService } from '../../../../services/forms.service';
import { ActionButtonFieldBaseComponent } from './actionButtonFieldBase.component';

@Component({
	selector: 'calao-meeting-action-button-field',
	templateUrl: './actionButtonFieldMeeting.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionButtonFieldMeetingComponent extends ActionButtonFieldBaseComponent<IActionButtonFieldConvParams> {

	//#region PROPERTIES

	@HasPermissions({ permission: "call", permissionScopes: EPermissionScopes.conversations })
	public get canCall(): boolean { return true; }

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		public readonly isvcPermissions: PermissionsService,
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef,
		private isvcConversation: ConversationService,
	) {
		super(psvcForms, poChangeDetectorRef);
		/** On stocke cet état dans window pour y avoir accès dans les expressions du formDescriptor. */
		(window as any).userId = UserData.current.name;
	}

	/** Ne fait rien (l'action est gérée par le composant interne). */
	public override action(): void { }

	public onClick(): void {
		this.isvcConversation.createOrJoinLinkedMeetingConversation(this.params).subscribe();
	}

	//#endregion

}