<!-- SERA RE-INTRODUIT LORS DE LA COMPARAISON ENTRE DES SECTEURS -->
<!-- <ion-card *ngIf="(observableOptionsMode.value$| async) === 'many'" class="fixed-ion-card">
	<ion-card-header>

		<ion-card-subtitle>
			<div class="sub-title">
				<ion-note>
					Comparaison
				</ion-note>
			</div>

			<ion-button *ngIf="!(showTourPanelInfo.value$ | async)" (click)="toggleTourPanel()" class="toogle-button">
				<ion-icon name="chevron-up-outline"></ion-icon>
			</ion-button>
			<ion-button *ngIf="(showTourPanelInfo.value$ | async)" (click)="toggleTourPanel()" class="toogle-button">
				<ion-icon name="chevron-down-outline"></ion-icon>
			</ion-button>
		</ion-card-subtitle>
	</ion-card-header>


	<ion-card-content *ngIf="(showTourPanelInfo.value$ | async)">
		<ion-grid>
			<ion-row>
				<ion-col size-md="3"></ion-col>
				<ion-col size-md="3" *ngFor="let item of tourPanelOptions.options" [ngStyle]="{'background-color':item.color}">
					<ion-label>
						{{item.title }}
					</ion-label>
				</ion-col>
			</ion-row>

			<ion-row *ngFor="let value of tourPanelOptions.options[0].data; index as i">
				<ion-col size-md="3">
					<ion-label>{{value.name}}</ion-label>
				</ion-col>

				<ng-container *ngFor="let temp of tourPanelOptions.options">

					<ion-col size-md="3">
						<ion-label>{{temp.data[i].value}}</ion-label>
					</ion-col>

				</ng-container>
			</ion-row>

		</ion-grid>
	</ion-card-content>

</ion-card> -->

<ion-card *ngIf="(currentMode.value$| async) === 'one'" class="fixed-ion-card">
	<ion-card-header>

		<ion-card-subtitle>
			<div class="sub-title">
				<ion-note>
					Informations sur le secteur
				</ion-note>
			</div>

			<ion-button *ngIf="!(isSectorPanelVisible.value$ | async)" (click)="toggleSectorPanelOn()" class="toogle-button">
				<ion-icon name="chevron-up-outline"></ion-icon>
			</ion-button>
			<ion-button *ngIf="(isSectorPanelVisible.value$ | async)" (click)="toggleSectorPanelOff()" class="toogle-button">
				<ion-icon name="chevron-down-outline"></ion-icon>
			</ion-button>
		</ion-card-subtitle>

		<ion-card-title *ngIf="(isSectorPanelVisible.value$ | async)">
			{{observableOptions[0].title }}
		</ion-card-title>
	</ion-card-header>

	<ion-card-content *ngIf="(isSectorPanelVisible.value$ | async)">

		<ion-list>
			<ion-item-group *ngFor="let value of observableOptions[0].data">
				<ion-item>
					<ion-label>{{value.name}}</ion-label>
					<ion-label>{{value.value}}</ion-label>
				</ion-item>
			</ion-item-group>
		</ion-list>
	</ion-card-content>

</ion-card>