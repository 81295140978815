<osapp-header-modal title="Sélectionner un groupe"></osapp-header-modal>

<ion-content>
	<div class="filter-bar">
		<calao-groups-filter-bar [filterValues]="observableFilterValues.value$ | async"
			(onGroupFilterValuesChange)="onGroupFilterValuesChange($event)"></calao-groups-filter-bar>
	</div>

	<calao-virtual-scroll [items]="observableGroups.changes$ | async" [itemSize]="60" autosize margeLastItem transparent>
		<ng-template let-group="$implicit">
			<ion-item class="group-item" (click)="onGroupClicked(group)">
				<ion-label>{{ group.name }}</ion-label>
			</ion-item>
		</ng-template>
	</calao-virtual-scroll>

	<ion-fab vertical="bottom" horizontal="end" slot="fixed">
		<ion-fab-button color="primary" (click)="routeToAddGroupAsync()">
			<ion-icon name="add"></ion-icon>
		</ion-fab-button>
	</ion-fab>
</ion-content>