<ng-container *ngTemplateOutlet="(observableLayout.value$ | async) === 'inline' ? inline : normal"></ng-container>

<ng-template #normal>
	<ion-item lines="none">
		<input matInput [hidden]="to.hidden" [(ngModel)]="fieldValue" [formControl]="$any(formControl)"
			[placeholder]="to.placeholder" [ngStyle]="to.uppercase? {'text-transform': 'uppercase'} : {}" type="email"
			class="input" style="border: none;" />
	</ion-item>
</ng-template>

<ng-template #inline>
	<ng-container *ngIf="observableLayoutParams.value$ | async as inlineParams">
		<calao-inline-field-layout [icon]="inlineParams.icon" [label]="inlineParams.label"
			[separator]="inlineParams.separator" [hideLabel]="observableHideLabel.value$ | async">
			<calao-inline-field-layout-content>
				<input #input matInput [hidden]="to.hidden" [(ngModel)]="fieldValue" [formControl]="$any(formControl)"
					[placeholder]="to.placeholder" [ngStyle]="to.uppercase? {'text-transform': 'uppercase'} : {}" type="email"
					class="inline" style="border: none;" />
			</calao-inline-field-layout-content>
		</calao-inline-field-layout>
	</ng-container>
</ng-template>