import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalOptions } from '@ionic/core';
import { EMPTY, Observable, combineLatest, defer, firstValueFrom, of } from 'rxjs';
import { catchError, defaultIfEmpty, distinctUntilChanged, filter, map, mapTo, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { GalleryAddModalComponent } from '../../../components/gallery/components/gallery-add-modal/gallery-add-modal.component';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { ContactHelper } from '../../../helpers/contactHelper';
import { GuidHelper } from '../../../helpers/guidHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { MapHelper } from '../../../helpers/mapHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { PathHelper } from '../../../helpers/path-helper';
import { StoreDocumentHelper } from '../../../helpers/storeDocumentHelper';
import { StoreHelper } from '../../../helpers/storeHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { UserHelper } from '../../../helpers/user.helper';
import { EPrefix } from '../../../model/EPrefix';
import { ESortOrder } from '../../../model/ESortOrder';
import { ConfigData } from '../../../model/config/ConfigData';
import { IConversation } from '../../../model/conversation/IConversation';
import { IFlag } from '../../../model/flag/IFlag';
import { IGalleryFile } from '../../../model/gallery/IGalleryFile';
import { ActivePageManager } from '../../../model/navigation/ActivePageManager';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { ContactsService } from '../../../services/contacts.service';
import { ConversationService } from '../../../services/conversation.service';
import { FlagService } from '../../../services/flag.service';
import { Store } from '../../../services/store.service';
import { Contact } from '../../contacts/models/contact';
import { IDmsDocument } from '../../dms/model/IDmsDocument';
import { IDmsMeta } from '../../dms/model/IDmsMeta';
import { DmsPermissionError } from '../../dms/services/DmsPermissionError';
import { DmsMetaService } from '../../dms/services/dms-meta.service';
import { DmsService } from '../../dms/services/dms.service';
import { EntityModalComponent } from '../../entities/components/entity-modal/entity-modal.component';
import { Entity } from '../../entities/models/entity';
import { IEntityDescriptor } from '../../entities/models/ientity-descriptor';
import { IEntityModalParams } from '../../entities/models/ientity-modal-params';
import { EntitiesUpdateService } from '../../entities/services/entities-update.service';
import { EntitiesService } from '../../entities/services/entities.service';
import { ILogActionHandler } from '../../logger/models/ILogActionHandler';
import { LogActionHandler } from '../../logger/models/log-action-handler';
import { LoggerService } from '../../logger/services/logger.service';
import { ModalService } from '../../modal/services/modal.service';
import { ObservableProperty } from '../../observable/models/observable-property';
import { EPermissionsFlag } from '../../permissions/models/EPermissionsFlag';
import { EPermissionScopes } from '../../permissions/models/epermission-scopes';
import { IPermissionContext } from '../../permissions/models/ipermission-context';
import { TCRUDPermissions } from '../../permissions/models/tcrud-permissions';
import { PermissionsService } from '../../permissions/services/permissions.service';
import { DestroyableServiceBase } from '../../services/models/destroyable-service-base';
import { IDataSourceRemoteChanges } from '../../store/model/IDataSourceRemoteChanges';
import { ModelResolver } from '../../utils/models/model-resolver';
import { Queue } from '../../utils/queue/decorators/queue.decorator';
import { secure } from '../../utils/rxjs/operators/secure';
import { IGetVersionedDocumentsParams } from '../../versioned-documents/models/iget-versioned-documents-params';
import { VersionedDocumentsService } from '../../versioned-documents/services/versioned-documents.service';
import { IPathPermission } from '../models/IPathPermission';
import { DmsDocument } from '../models/dms-document';
import { DocExplorerConfig } from '../models/doc-explorer-config';
import { Document } from '../models/document';
import { DocumentsCache } from '../models/documents-cache';
import { DocumentsCounters } from '../models/documents-counters';
import { EDocumentsPermissions } from '../models/edocuments-permissions';
import { Folder } from '../models/folder';
import { FolderConfig } from '../models/folder-config';
import { FolderContent } from '../models/folder-content';
import { FormDocument } from '../models/form-document';
import { IDocExplorerConfig } from '../models/idoc-explorer-config';
import { IDocumentsByPathCache } from '../models/idocuments-by-path-cache';
import { IDocumentsViewMeta } from '../models/idocuments-view-meta';
import { IDocumentViewMetaCache } from '../models/idocuments-view-meta-cache';
import { IFormDocument } from '../models/iform-document';
import { IUserStatus } from '../models/iuser-status';
import { DocumentStatusService } from './document-status.service';

interface IPathFolderConfigs {
	pathFolderConfig?: FolderConfig;
	parentFolderConfigs: FolderConfig[];
	path: string;
}

interface IDocExplorerPermissionContext extends IPermissionContext {
	paths: string[];
}

@Injectable({
	providedIn: 'root'
})
export class DocExplorerDocumentsService extends DestroyableServiceBase implements ILogActionHandler {

	//#region FIELDS

	private static readonly C_WS_CONFIG_NAME = "docExplorer";
	private static readonly C_LOCAL_DESCRIPTORS_BASE_URL = "/configs/";
	private static readonly C_DOCUMENTS_VIEW_NAME = "app_docs-by-generic-path/docsByGenericPath";
	private static readonly C_LOG_ID = "DOC.EXPLR.DOC.S::";
	private static readonly C_PATH_PATTERN_REGEX = /\(|_|\.|\*|\)/g;

	private readonly moActivePageManager = new ActivePageManager(this, this.ioRouter, () => true);

	/** Fichier de configuration. */
	private moObservableConfig = new ObservableProperty<DocExplorerConfig>();
	/** Cache des documents. */
	private readonly moDocumentsCache = new DocumentsCache();

	private readonly moEntitiesCache = new Map<string, ObservableProperty<Entity | undefined>>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** @implements */
	public readonly logSourceId: string = DocExplorerDocumentsService.C_LOG_ID;
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	public static readonly C_UNDEFINED_DATE_KEY = "undefined";
	public static readonly C_UNDEFINED_DATE_LABEL = "Date non définie";

	/** Indique si le chargement du doc explorer est fini */
	public readonly observableIsInit = new ObservableProperty<boolean>(false);

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcContacts: ContactsService,
		private readonly isvcPermissions: PermissionsService,
		private readonly isvcMeta: DmsMetaService,
		private readonly ioRouter: Router,
		private readonly isvcDocumentStatus: DocumentStatusService,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		private readonly isvcEntities: EntitiesService,
		private readonly isvcEntitiesUpdate: EntitiesUpdateService,
		private readonly isvcConversations: ConversationService,
		private readonly isvcVersionedDocuments: VersionedDocumentsService,
		private readonly isvcModal: ModalService,
		private readonly isvcDms: DmsService,
		psvcFlag: FlagService,
	) {
		super();

		psvcFlag.observeFlag(EPermissionsFlag.isLoaded)
			.pipe(
				filter((poFlag: IFlag) => !!ConfigData.appInfo.useDocExplorer && poFlag.value !== undefined),
				map((poFlag: IFlag) => poFlag.value),
				distinctUntilChanged(),
				mergeMap((pbValue: boolean) => pbValue ? this.init$() : of(this.resetCache()))
			)
			.subscribe();
	}

	//#region EXPLORER

	private init$() {
		return this.initConfig$().pipe(
			tap(_ => this.observableIsInit.value = true)
		);
	}

	private resetCache(): void {
		this.moDocumentsCache.clear();
		this.moEntitiesCache.clear();
		this.moObservableConfig.value = undefined;
	}

	/** Initialise le fichier de configuration de l'explorateur de documents. */
	private initConfig$(): Observable<void> {
		const lsConfigId = `${EPrefix.wsCfg}${DocExplorerDocumentsService.C_WS_CONFIG_NAME}`;

		const loGetVersionedDocumentsParams: IGetVersionedDocumentsParams = {
			prefix: EPrefix.wsCfg,
			suffix: ".json",
			ids: ConfigData.builtInConfigDescIds,
			baseUrl: DocExplorerDocumentsService.C_LOCAL_DESCRIPTORS_BASE_URL,
			roles: [EDatabaseRole.formsDefinitions],
			activePageManager: this.moActivePageManager
		};

		return this.isvcVersionedDocuments.getVersionedDocumentsByGuid$(loGetVersionedDocumentsParams).pipe(
			map((poConfigs: Map<string, IStoreDocument>) => {
				this.moObservableConfig.value = ModelResolver.toClass(DocExplorerConfig, poConfigs.get(IdHelper.getGuidFromId(lsConfigId, EPrefix.wsCfg)) as IDocExplorerConfig);
			})
		);
	}

	/** Initialise les documents pour un chemin.
	 * @param psPath
	 * @param pbDeep Si `true`, récupère les documents des sous-dossiers.
	 */
	@Queue<
		DocExplorerDocumentsService,
		Parameters<DocExplorerDocumentsService["initDocuments$"]>,
		ReturnType<DocExplorerDocumentsService["initDocuments$"]>
	>({
		excludePendings: true,
		idBuilder: (psPath: string, pbDeep?: boolean) => psPath + pbDeep
	})
	private initDocuments$(psPath: string = "", pbDeep?: boolean): Observable<Document[]> {
		return this.getDocumentsDataSource$(psPath, pbDeep).pipe(
			switchMap((poDataSource: IDataSourceRemoteChanges) => this.isvcStore.get<IDmsDocument | IFormDocument>(poDataSource)),
			map((paAllDocuments: (IDmsDocument | IFormDocument)[]) => this.getInstantiatedDocuments(paAllDocuments)),
			mergeMap((paAllDocuments: Document[]) => defer(() => this.fillDocumentsAsync(this.initReadableDocuments(paAllDocuments))).pipe(
				mergeMap((paDocuments: Document[]) => this.initDocumentsStatuses$(paDocuments)),
				tap((paDocuments: Document[]) => this.moDocumentsCache.fillDocumentsCache(paAllDocuments, paDocuments, psPath))
			))
		);
	}

	private getDocumentsDataSource$(psPath: string, pbDeep?: boolean): Observable<IDataSourceRemoteChanges> {
		return defer(() => {
			if (pbDeep) {
				return this.getAvailablePaths$(psPath).pipe(
					map((paPaths: string[]) => {
						const loDataSource: IDataSourceRemoteChanges = {
							role: EDatabaseRole.workspace,
							viewName: DocExplorerDocumentsService.C_DOCUMENTS_VIEW_NAME,
							viewParams: {
								keys: ArrayHelper.getDifferences(
									paPaths,
									this.moDocumentsCache.observableDocumentsByPath.value?.map((poCache: IDocumentsByPathCache) => poCache.path)
								),
								include_docs: true
							},
							live: true,
							remoteChanges: true,
							activePageManager: this.moActivePageManager
						};

						return loDataSource;
					})
				);
			}

			const loDataSource: IDataSourceRemoteChanges = {
				role: EDatabaseRole.workspace,
				viewName: DocExplorerDocumentsService.C_DOCUMENTS_VIEW_NAME,
				viewParams: {
					key: PathHelper.preparePath(psPath),
					include_docs: true
				},
				live: true,
				remoteChanges: true,
				activePageManager: this.moActivePageManager
			};

			return of(loDataSource);
		});
	}

	private getInstantiatedDocuments(paDocuments: (IDmsDocument | IFormDocument)[]): Document[] {
		const laFilteredDocumentIds: string[] = [];
		const laFilteredDocuments: Document[] = [];
		paDocuments.forEach((poDocument: IDmsDocument | IFormDocument) => {
			if (!laFilteredDocumentIds.includes(poDocument._id)) {
				laFilteredDocumentIds.push(poDocument._id);
				if ((poDocument as IFormDocument).$document)
					laFilteredDocuments.push(ModelResolver.toClass(FormDocument, ModelResolver.toPlain(poDocument)));
				else
					laFilteredDocuments.push(ModelResolver.toClass(DmsDocument, ModelResolver.toPlain(poDocument)));
			}
		});
		return laFilteredDocuments;
	}

	private initReadableDocuments(paDocuments: Document[]): Document[] {
		const laReadableDocuments: Document[] = [];

		paDocuments.forEach((poDocument: Document) => {
			const loCacheDocument: Document | undefined = this.moDocumentsCache.documentsById.get(poDocument._id);
			const laCacheDocumentPaths: string[] | undefined = this.moDocumentsCache.allDocumentsPathsById.get(poDocument._id);
			let pbCanRead: boolean;

			if (ArrayHelper.areArraysEqual(laCacheDocumentPaths, poDocument.paths)) // Si les chemins sont les même alors la permission reste la même.
				pbCanRead = !!loCacheDocument;
			else
				pbCanRead = this.checkDocumentPermissions(poDocument, "read", false);

			if (pbCanRead)
				laReadableDocuments.push(poDocument);
		});

		return laReadableDocuments;
	}

	private initDocumentsStatuses$(paDocuments: Document[]): Observable<Document[]> {
		return this.isvcDocumentStatus.getDocumentsUserStatusesById$(paDocuments).pipe(
			map((poDocumentsStatusesById: Map<string, IUserStatus | undefined>) => {
				paDocuments.forEach((poDocument: Document) => {
					const loStatus: IUserStatus | undefined = poDocumentsStatusesById.get(poDocument._id);
					if (poDocument.isInTrash)
						poDocument.observableIsRead.value = true;
					else
						poDocument.observableIsRead.value = !!loStatus && StoreDocumentHelper.getRevisionNumber(loStatus?.docRev) >= StoreDocumentHelper.getRevisionNumber(poDocument._rev) && loStatus.status === "read";
				});
				return paDocuments;
			})
		);
	}

	private async fillDocumentsAsync(paDocuments: Document[]): Promise<Document[]> {
		const laAuthorIds: string[] = paDocuments.map((poDocument: Document) => poDocument.authorId);
		const loAuthorById: Map<string, Contact> = await firstValueFrom(this.isvcContacts.getContactById(laAuthorIds, undefined, false));

		return paDocuments.filter((poDocument: Document) => {
			const loFolderConfig: FolderConfig | undefined = this.moObservableConfig.value?.getFolderConfig(ArrayHelper.getFirstElement(poDocument.paths));

			if (loFolderConfig) {
				poDocument.type = loFolderConfig.shortName;
				poDocument.icon = loFolderConfig.icon;
				poDocument.authorName = ContactHelper.getCompleteFormattedName(loAuthorById.get(poDocument.authorId));
				return true;
			}
			else
				return false;
		});
	}

	private fillCacheForPath(psPath: string, pbDeep?: boolean): void {
		this.initDocuments$(psPath, pbDeep).pipe(secure(this)).subscribe();
	}

	/** Récupère le fichier de configuration de l'explorateur de documents. */
	public getConfig$(): Observable<DocExplorerConfig | undefined> {
		return this.moObservableConfig.value$;
	}

	private getDocumentsByPathFromCache$(psPath: string, pbDeep?: boolean): Observable<Document[]> {
		return this.moDocumentsCache.observableDocumentsByPath.value$.pipe(
			map((paDocumentsByPath?: IDocumentsByPathCache[]) => {
				if (paDocumentsByPath) {
					const laDocumentsCache: IDocumentsByPathCache[] = ArrayHelper.binarySliceBy(
						paDocumentsByPath,
						{
							from: { path: psPath, documents: [] },
							to: { path: pbDeep ? `${psPath}${Store.C_ANYTHING_CODE_ASCII}` : psPath, documents: [] }
						},
						(poItem: IDocumentsByPathCache) => poItem.path
					);

					if (!ArrayHelper.hasElements(laDocumentsCache) || pbDeep)
						this.fillCacheForPath(psPath, pbDeep);

					return ArrayHelper.unique(
						laDocumentsCache.map((poCache: IDocumentsByPathCache) => poCache.documents).flat(),
						(poItem: Document) => poItem._id
					);
				}

				return undefined;
			}),
			filter((paDocuments?: Document[]) => !!paDocuments)
		);
	}

	/** Récupère un document.
	* @param psId L'id du document à récupérer.
	* @param pbLive
	*/
	public getDocumentById$(psId: string, pbLive?: boolean): Observable<Document | undefined> {
		const loDataSource: IDataSourceRemoteChanges = {
			role: EDatabaseRole.workspace,
			viewParams: {
				key: psId,
				include_docs: true,
			},
			live: pbLive,
			remoteChanges: true,
			activePageManager: this.moActivePageManager
		};

		return this.isvcStore.getOne<Document>(loDataSource).pipe(
			map((poDocument: Document) => {
				if ((poDocument as IFormDocument).$document)
					return ModelResolver.toClass(FormDocument, ModelResolver.toPlain(poDocument));
				else
					return ModelResolver.toClass(DmsDocument, ModelResolver.toPlain(poDocument));
			}));
	}

	/** Récupère le nombre de documents. */
	public getDocumentsCounters$(psPath: string): Observable<DocumentsCounters | undefined> {
		return this.getDocCountPermission$().pipe(
			switchMap((pbHasPermission: boolean) => {
				if (!pbHasPermission)
					return of(undefined);

				return this.getDocumentsViewMetaByPathsFromCache$(psPath).pipe(
					switchMap((paResults: IDocumentViewMetaCache[]) => {
						return this.getViewMetaDocumentsStatuses$(paResults).pipe(
							map((poDocumentsStatusesById: Map<string, IUserStatus | undefined>) => {
								const laUniqueViewMetas: IDocumentViewMetaCache[] = this.getUniqueViewMetas(paResults);
								return new DocumentsCounters(
									this.getRedDocumentsCount(laUniqueViewMetas, poDocumentsStatusesById),
									this.getDocumentsViewMetaCount(laUniqueViewMetas)
								);
							})
						);
					})
				);
			}),
		);
	}

	private getDocCountPermission$(): Observable<boolean> {
		return this.isvcPermissions.evaluatePermission$(EPermissionScopes.documents, EDocumentsPermissions.count);
	}

	/** Récupère des documents.
	* @param paIds Liste des ids de document à récupérer.
	* @param pbLive
	*/
	public getDocumentsByIds$(paIds: string[], pbLive?: boolean): Observable<Document[]> {
		if (!ArrayHelper.hasElements(paIds))
			return of([]);

		const loDataSource: IDataSourceRemoteChanges = {
			role: EDatabaseRole.workspace,
			viewParams: {
				keys: paIds,
				include_docs: true,
			},
			live: pbLive,
			remoteChanges: true,
			activePageManager: this.moActivePageManager
		};

		return this.isvcStore.get<Document>(loDataSource).pipe(
			map((paDocuments: Document[]) => paDocuments.map((poDocument: Document) => {
				if ((poDocument as IFormDocument).$document)
					return ModelResolver.toClass(FormDocument, ModelResolver.toPlain(poDocument));
				else
					return ModelResolver.toClass(DmsDocument, ModelResolver.toPlain(poDocument));
			}))
		);
	}

	public getDocumentsViewMetaCount(paViewMetas: IDocumentViewMetaCache[]): number {
		return paViewMetas.length;
	}

	private getUniqueViewMetas(paViewMetas: IDocumentViewMetaCache[]): IDocumentViewMetaCache[] {
		return ArrayHelper.unique(paViewMetas, (poItem: IDocumentViewMetaCache) => poItem.meta._id);
	}

	private getAvailablePaths$(psPath: string): Observable<string[]> {
		return this.getConfig$().pipe(
			map((poConfig?: DocExplorerConfig) => {
				const laPaths: string[] = [];

				poConfig?.paths.forEach((poPath: FolderConfig) => {
					if (this.checkFolderConfigPermission(poPath, "read", undefined, false) &&
						!ObjectHelper.isNullOrEmpty(poPath.documentTypes) // Si le path peut contenir directement des documents et pas juste des dossiers.
					) {
						const lsPath: string = poPath.path.replace(DocExplorerDocumentsService.C_PATH_PATTERN_REGEX, ""); // On enlève la partie variable du path
						const laPathsWithGenericPath: [string, string] = [psPath, this.removeGuidPart(psPath)];
						if (lsPath.startsWith(laPathsWithGenericPath[1]) || lsPath.startsWith(laPathsWithGenericPath[0])) {
							const laPathParts: string[] = StringHelper.isBlank(psPath) ? [] : psPath.split(PathHelper.C_PATH_SEPARATOR);
							const laConfigPathParts: string[] = lsPath.split(PathHelper.C_PATH_SEPARATOR);
							laConfigPathParts.splice(0, laPathParts.length, ...laPathParts);
							laPaths.push(laConfigPathParts.join(PathHelper.C_DATABASE_PATH_SEPARATOR));
						}
					}
				});
				return laPaths;
			})
		);
	}

	private removeGuidPart(psPath: string): string {
		return psPath.split(PathHelper.C_PATH_SEPARATOR)
			.map((psPathPart: string) => ArrayHelper.getFirstElement(psPathPart.split("_"))).join(PathHelper.C_PATH_SEPARATOR);
	}

	private getDocumentsViewMetaByPathsFromCache$(psPath: string): Observable<IDocumentViewMetaCache[]> {
		this.fillViewMetaCacheForPaths(psPath);

		return this.moDocumentsCache.getDocumentsViewMetaCacheFromCache$(psPath).pipe(
			switchMap((paViewMetas: IDocumentViewMetaCache[]) =>
				this.isLoadedViewMetaPath$(psPath).pipe(
					filter((pbLoaded: boolean) => pbLoaded),
					map(() => paViewMetas)
				)
			)
		);
	}

	private fillViewMetaCacheForPaths(psPath: string): void {
		this.initDocumentsViewMeta$(psPath).pipe(secure(this)).subscribe();
	}

	private isLoadedViewMetaPath$(psPath: string): Observable<boolean> {
		return this.preparePath$(psPath).pipe(
			switchMap((paPaths: string[]) => this.moDocumentsCache.areLoadedViewMetaPaths$(paPaths))
		);
	}

	private preparePath$(psPath: string): Observable<string[]> {
		return this.getAvailablePaths$(psPath);
	}

	private initDocumentsViewMeta$(psPath: string): Observable<IStoreDocument[]> {
		return this.preparePath$(psPath).pipe(
			switchMap((paPaths: string[]) => {
				const laPathsToRequest: string[] = paPaths.filter(
					(psPath: string) => !this.moDocumentsCache.isDocumentsViewMetaPathLoaded(psPath)
				);

				if (ArrayHelper.hasElements(laPathsToRequest))
					return this.requestDocumentsViewMetaForMultiplePaths$(laPathsToRequest);

				return EMPTY;

			})
		);
	}

	@Queue<
		DocExplorerDocumentsService,
		Parameters<DocExplorerDocumentsService["requestDocumentsViewMetaForMultiplePaths$"]>,
		ReturnType<DocExplorerDocumentsService["requestDocumentsViewMetaForMultiplePaths$"]>
	>({
		excludePendings: true,
		idBuilder: (paPathsToRequest: string[]) => paPathsToRequest
	})
	private requestDocumentsViewMetaForMultiplePaths$(paPathsToRequest: string[]): Observable<IStoreDocument[]> {
		return this.isvcStore.get({
			role: EDatabaseRole.workspace,
			viewName: DocExplorerDocumentsService.C_DOCUMENTS_VIEW_NAME,
			viewParams: {
				keys: paPathsToRequest
			},
			live: true
		}).pipe(
			tap({
				next: (paViewMetas: IDocumentsViewMeta[]) => {
					const laAvailableViewMetas: IDocumentsViewMeta[] =
						paViewMetas.filter((poViewMeta: IDocumentsViewMeta) => this.checkDocumentPermissions(
							{
								// Garder les paths dans cet ordre car il y a plus de chance que le document ai été chargé par un path générique.
								paths: [...poViewMeta.otherPaths, StoreHelper.getDocumentCacheData(poViewMeta).key],
								authorId: poViewMeta.authorId, _id: poViewMeta._id
							},
							"read",
							false,
							true
						));
					this.moDocumentsCache.fillDocumentsViewMetaCache(laAvailableViewMetas, paPathsToRequest);
				}
			})
		);
	}

	public getOrganizedDocumentsByDate(paDocuments: Document[]): Map<string, Map<string, Document[]>[]> {
		const loOrganizedMap = new Map<string, Map<string, Document[]>[]>();

		ArrayHelper.dynamicSort(paDocuments, "displayDate", ESortOrder.descending).forEach((poDoc: Document) => {
			const ldDisplayDate: Date | undefined = poDoc.displayDate ? new Date(poDoc.displayDate) : undefined;
			const lsYearMonthKey = ldDisplayDate ? `${ldDisplayDate.getFullYear()}-${(ldDisplayDate.getMonth() + 1).toString().padStart(2, '0')}` : DocExplorerDocumentsService.C_UNDEFINED_DATE_KEY;

			if (!loOrganizedMap.has(lsYearMonthKey))
				loOrganizedMap.set(lsYearMonthKey, []);

			const lsDayKey: string = ldDisplayDate ? ldDisplayDate.getDate().toString().padStart(2, '0') : DocExplorerDocumentsService.C_UNDEFINED_DATE_KEY;

			const laDayMaps: Map<string, Document[]>[] = loOrganizedMap.get(lsYearMonthKey) ?? [];
			let loDayMap: Map<string, Document[]> | undefined = laDayMaps.find((dayDoc) => dayDoc.has(lsDayKey));

			if (!loDayMap) {
				loDayMap = new Map<string, Document[]>();
				laDayMaps.push(loDayMap);
			}

			if (!loDayMap.has(lsDayKey))
				loDayMap.set(lsDayKey, []);

			const laDayDocs: Document[] | undefined = loDayMap.get(lsDayKey);
			if (laDayDocs)
				laDayDocs.push(poDoc);
		});

		return loOrganizedMap;
	}

	public getFolderContent$(psPath: string = "", pbDeep?: boolean): Observable<FolderContent | undefined> {
		return combineLatest([
			this.moObservableConfig.value$,
			this.getDocumentsByPathFromCache$(psPath, pbDeep),
			this.getDocumentsViewMetaByPathsFromCache$(psPath)
		]).pipe(
			switchMap(([poConfig, paDocuments, paViewMetas]: [DocExplorerConfig | undefined, Document[], IDocumentViewMetaCache[]]) => {
				return this.getViewMetaDocumentsStatuses$(paViewMetas).pipe(
					map((poDocumentsStatusesById: Map<string, IUserStatus | undefined>) =>
						this.getFolderContent(psPath, paDocuments, paViewMetas, poDocumentsStatusesById, poConfig)
					)
				);
			}),
			switchMap((poFolderContent?: FolderContent) =>
				this.resolveFolderContentPropertiesPattern$(poFolderContent)
			)
		);
	}

	private getViewMetaDocumentsStatuses$(
		paViewMetas: IDocumentViewMetaCache[]
	): Observable<Map<string, IUserStatus | undefined>> {
		const laIds: string[] = [];
		const loUserAuthoredDocMetasByIds = new Map<string, IStoreDocument>();
		const lsUserContactId: string = UserHelper.getUserContactId();

		paViewMetas.forEach((poMetaCache: IDocumentViewMetaCache) => {
			laIds.push(poMetaCache.meta._id);
			if (poMetaCache.meta.authorId === lsUserContactId)
				loUserAuthoredDocMetasByIds.set(poMetaCache.meta._id, poMetaCache.meta);
		});

		return this.isvcDocumentStatus.getDocumentsUserStatusesById$(
			laIds,
			loUserAuthoredDocMetasByIds
		);
	}

	private getFolderContent(
		psPath: string,
		paDocuments: Document[],
		paViewMetas: IDocumentViewMetaCache[],
		poDocumentsStatusesById?: Map<string, IUserStatus | undefined>,
		poConfig?: DocExplorerConfig
	): FolderContent | undefined {
		const loFolderContent: FolderContent | undefined = this.createFolderContent(
			psPath,
			paDocuments,
			paViewMetas,
			poDocumentsStatusesById,
			poConfig
		);

		if (!loFolderContent)
			return undefined;

		const lnPathPartsLength: number = StringHelper.isBlank(psPath) ? 0 : psPath.split("/").length;
		let laDirectChildFolderPath: string[] = [];
		const loDirectChildRegex = new RegExp(`^${psPath}\/[^\/]+$`);
		const loUniquePaths = new Set<string>();

		paViewMetas.forEach((poViewMeta: IDocumentViewMetaCache) => {
			[
				StoreHelper.getDocumentCacheData(poViewMeta.meta).key,
				...poViewMeta.meta.otherPaths
			].forEach((psPath: string) =>
				loUniquePaths.add(psPath)
			);
		});

		loUniquePaths.forEach((psDocumentPath: string) => {
			if (!StringHelper.isBlank(psDocumentPath)) {
				const lsDocumentPath: string = PathHelper.parsePath(psDocumentPath);
				const laDocumentPathParts: string[] = lsDocumentPath.split(PathHelper.C_PATH_SEPARATOR);
				const lsDirectChildFolderPath: string = laDocumentPathParts.slice(0, lnPathPartsLength + 1).join(PathHelper.C_PATH_SEPARATOR);
				if (poConfig?.getFolderConfig(lsDirectChildFolderPath)) { // Si on ne trouve pas de config pour le path alors on l'ignore (path générique à ne pas afficher)
					if (loDirectChildRegex.test(lsDirectChildFolderPath) && !ArrayHelper.binarySearch(laDirectChildFolderPath, lsDirectChildFolderPath))
						ArrayHelper.binaryInsert(laDirectChildFolderPath, lsDirectChildFolderPath);
				}
			}
		});

		laDirectChildFolderPath = this.fillBuiltInFolders(
			psPath,
			loFolderContent,
			laDirectChildFolderPath,
			paViewMetas,
			poDocumentsStatusesById,
			poConfig
		);

		// On ajoute les autres dossiers.
		this.fillFolders(laDirectChildFolderPath, loFolderContent, paViewMetas, poDocumentsStatusesById, poConfig);

		return loFolderContent;
	}

	private fillBuiltInFolders(
		psPath: string,
		poFolderContent: FolderContent,
		paDirectChildFolderPath: string[],
		paViewMetas: IDocumentViewMetaCache[],
		poDocumentsStatusesById?: Map<string, IUserStatus | undefined>,
		poConfig?: DocExplorerConfig
	): string[] {
		const laDirectChildFolderPath: string[] = [...paDirectChildFolderPath];
		// On récupère les configurations des dossiers built-in.
		const laBuiltInFolders: FolderConfig[] = poConfig?.getChildStaticFoldersConfig(psPath) ?? [];
		// On ajoute les dossiers built-in.
		laBuiltInFolders.forEach((poFolderConfig: FolderConfig) => {
			const lsPath: string = psPath ? `${psPath}/${poFolderConfig.lastPathPart}` : poFolderConfig.path;
			const loFolder: Folder | undefined = this.createFolder(
				poConfig?.getFolderConfig(lsPath),
				lsPath,
				this.moDocumentsCache.requestViewMetaCacheByPath(paViewMetas, PathHelper.preparePath(lsPath)),
				poDocumentsStatusesById
			);

			if (loFolder)
				poFolderContent.folders.push(loFolder);
			ArrayHelper.removeElement(laDirectChildFolderPath, lsPath);
		});

		return laDirectChildFolderPath;
	}

	private fillFolders(
		paDirectChildFolderPath: string[],
		poFolderContent: FolderContent,
		paViewMetas: IDocumentViewMetaCache[],
		poDocumentsStatusesById?: Map<string, IUserStatus | undefined>,
		poConfig?: DocExplorerConfig,
	): void {
		paDirectChildFolderPath.forEach((psChildFolderPath: string) => {
			const loFolder: Folder | undefined = this.createFolder(
				poConfig?.getFolderConfig(psChildFolderPath),
				psChildFolderPath,
				this.moDocumentsCache.requestViewMetaCacheByPath(paViewMetas, PathHelper.preparePath(psChildFolderPath)),
				poDocumentsStatusesById
			);

			if (loFolder)
				poFolderContent.folders.push(loFolder);
		});
	}

	public createFolderContent(
		psPath: string,
		paDocuments: Document[],
		paViewMetas: IDocumentViewMetaCache[],
		poDocumentsStatusesById?: Map<string, IUserStatus | undefined>,
		poConfig?: DocExplorerConfig
	): FolderContent | undefined {
		const loCurrentFolderConfig: FolderConfig | undefined = poConfig?.getFolderConfig(psPath);
		// On créé le dossier courant.
		const loCurrentFolder: Folder | undefined = this.createFolder(loCurrentFolderConfig, psPath, paViewMetas, poDocumentsStatusesById);

		if (!loCurrentFolder)
			return undefined;

		const loFolderContent = new FolderContent();
		loFolderContent.current = loCurrentFolder;
		if (StringHelper.isBlank(psPath))
			loFolderContent.current.icon = "documents";

		loFolderContent.documents = [...paDocuments];
		return loFolderContent;
	}

	public createFolder(
		poCurrentFolderConfig: FolderConfig | undefined,
		psPath: string,
		paViewMetas: IDocumentViewMetaCache[],
		poDocumentsStatusesById?: Map<string, IUserStatus | undefined>
	): Folder | undefined {
		if (!this.checkFolderPermissions(psPath, "read", false))
			return undefined;

		const loFolder = new Folder(
			poCurrentFolderConfig ?
				{ ...poCurrentFolderConfig, path: psPath, configPath: poCurrentFolderConfig.path } :
				{ path: psPath, documentTypes: {} }
		);

		const laUniqueViewMetas: IDocumentViewMetaCache[] = this.getUniqueViewMetas(paViewMetas);

		if (poDocumentsStatusesById) {
			loFolder.documentsCounters = new DocumentsCounters(
				this.getRedDocumentsCount(laUniqueViewMetas, poDocumentsStatusesById),
				this.getDocumentsViewMetaCount(laUniqueViewMetas)
			);
		}
		else
			loFolder.documentsCounters = undefined;

		return loFolder;
	}

	private getRedDocumentsCount(
		paUniqueViewMetas: IDocumentViewMetaCache[],
		poDocumentsStatusesById: Map<string, IUserStatus | undefined>
	): number {
		return paUniqueViewMetas.filter((poViewMeta: IDocumentViewMetaCache) =>
			poDocumentsStatusesById.get(poViewMeta.meta._id)?.status === "read"
		).length;
	}

	public resolveFolderContentPropertiesPattern$(
		poFolderContent?: FolderContent,
	): Observable<FolderContent | undefined> {
		if (!poFolderContent)
			return of(undefined);

		return defer(() => {
			const laEntityIds: string[] = this.extractEntityIdsFromFolderContent(poFolderContent);
			return this.getEntities$(laEntityIds);
		}).pipe(
			map((paEntities: Entity[]) => {
				const loEntitiesById: Map<string, Entity> = ArrayHelper.groupByUnique(paEntities, (poEntity: Entity) => poEntity._id);
				this.resovleFolderContentPattern(poFolderContent, loEntitiesById);
				return poFolderContent;
			})
		);
	}

	private resovleFolderContentPattern(poFolderContent: FolderContent, poEntitiesById: Map<string, Entity>): void {
		this.resolveFolderPropertiesPattern(poFolderContent.current, poEntitiesById);
	}

	private extractEntityIdsFromFolderContent(poFolderContent: FolderContent): string[] {
		return this.extractEntityIdsFromFolders([poFolderContent.current]);
	}

	public extractEntityIdsFromFolders(paFolders: Folder[]): string[] {
		const loEntityIds = new Set<string>();

		for (let lnIndex = 0; lnIndex < paFolders.length; ++lnIndex) {
			const loFolder: Folder = paFolders[lnIndex];

			if (loFolder.hasPattern()) {
				const laFolderEntityIds: string[] = this.extractEntityIdsFromFolder(loFolder);

				for (let lnEntityIndex = 0; lnEntityIndex < laFolderEntityIds.length; ++lnEntityIndex) {
					loEntityIds.add(laFolderEntityIds[lnEntityIndex]);
				}
			}
		}

		return Array.from(loEntityIds.values());
	}

	public extractEntityIdsFromFolder(poFolder: Folder): string[] {
		let laFolderEntityIds: string[] = new RegExp(poFolder.configPath ?? "").exec(poFolder.path) ?? [];
		laFolderEntityIds.shift(); // Supprime le 1er résultat de la RegExp.
		laFolderEntityIds = ArrayHelper.unique(laFolderEntityIds);
		return laFolderEntityIds;
	}

	public resolveFolderPropertiesPattern(poFolder: Folder, poEntitiesById: Map<string, Entity>): Folder {
		if (poFolder.hasPattern()) {
			Object.entries(poFolder).forEach(([psKey, poValue]: [string, any]) => {
				if (typeof poValue === "string" && /\{{.*}}/.test(poValue)) {
					const loExtractedValues: { methodName: string; params: string[]; } | undefined = DocExplorerDocumentsService.extractPatternMethodAndParams(poValue);
					if (loExtractedValues)
						poFolder[psKey] = this[loExtractedValues.methodName](...loExtractedValues.params, poEntitiesById);

					else
						poFolder[psKey] = "Inconnu";
				}
			});
		}

		return poFolder;
	}

	/** Récupère le nom du dossier. (utilisé par les patterns du fichier de configuration du docExplorer). */
	public getFolderName(psEntityId: string, poEntitiesById: Map<string, Entity>): string {
		const loEntity: Entity | undefined = poEntitiesById.get(psEntityId);
		return this.isvcEntities.getEntityName(loEntity);
	}

	/** Récupère le nom court du dossier. (utilisé par les patterns du fichier de configuration du docExplorer). */
	public getFolderShortName(psEntityId: string, poEntitiesById: Map<string, Entity>): string {
		const loEntity: Entity | undefined = poEntitiesById.get(psEntityId);
		return this.isvcEntities.getEntityName(loEntity);
	}

	public getEntity$(psEntityId: string, psDatabaseId?: string): Observable<Entity | undefined> {
		return this.isvcEntities.getModel$(psEntityId, psDatabaseId);
	}

	public getEntityAsync(psEntityId: string, psDatabaseId?: string): Promise<Entity | undefined> {
		return this.isvcEntities.getModelAsync(psEntityId, psDatabaseId);
	}

	public getEntitiesAsync(paEntityIds: string[]): Promise<Entity[]> {
		return firstValueFrom(this.getEntities$(paEntityIds));
	}

	public getEntities$(paEntityIds: string[]): Observable<Entity[]> {
		const loObservablePropertiesByIds: Map<string, ObservableProperty<Entity | undefined>> =
			this.initEntities(paEntityIds);

		return combineLatest(
			MapHelper.valuesToArray(loObservablePropertiesByIds).map(
				(poObservableProperty: ObservableProperty<Entity | undefined>) => poObservableProperty.value$
			)
		).pipe(
			map((paEntities: (Entity | undefined)[]) => ArrayHelper.getValidValues(paEntities)),
			defaultIfEmpty([])
		);
	}

	private initEntities(paEntityIds: string[]): Map<string, ObservableProperty<Entity | undefined>> {
		const laEntityIds: string[] = [];
		const loObservablePropertiesByIds = new Map<string, ObservableProperty<Entity | undefined>>();

		paEntityIds.forEach((psId: string) => {
			let loObservableProperty: ObservableProperty<Entity | undefined> | undefined = this.moEntitiesCache.get(psId);
			if (!loObservableProperty) {
				laEntityIds.push(psId);
				loObservableProperty = new ObservableProperty();
				this.moEntitiesCache.set(psId, loObservableProperty);
			}

			loObservablePropertiesByIds.set(psId, loObservableProperty);
		});

		this.getEntitiesNotInCache$(laEntityIds).pipe(
			tap((paEntities: Entity[]) => {
				const laEntityByIds: Map<string, Entity> = ArrayHelper.groupByUnique(paEntities, (poEntity: Entity) => poEntity._id);

				laEntityIds.forEach((psId: string) => {
					const loEntity: Entity | undefined = laEntityByIds.get(psId);
					const loObservableProperty: ObservableProperty<Entity | undefined> | undefined =
						loObservablePropertiesByIds.get(psId);

					if (loObservableProperty)
						loObservableProperty.value = loEntity;
				});
			}),
			secure(this)
		).subscribe();

		return loObservablePropertiesByIds;
	}

	private getEntitiesNotInCache$(
		paEntityIds: string[],
	): Observable<Entity[]> {
		return this.isvcEntities.getModels$(paEntityIds, true, this.moActivePageManager);
	}

	public static extractPatternMethodAndParams(psValue: string): { methodName: string, params: string[] } | undefined {
		const nameRegex = /\{\{(.+?)\((.+?)\)\}\}/;
		const match = psValue.match(nameRegex);

		if (match)
			return { methodName: match[1], params: match[2].split(',').map(param => param.trim()) };
		else
			return undefined;
	}

	public getMatchingPathFolderConfig(psPath: string, poConfig: DocExplorerConfig | undefined): FolderConfig | undefined {
		return poConfig?.paths.find((poFolderConfig: FolderConfig) => {
			const loRegex = new RegExp(poFolderConfig.path);
			return psPath.split("/").length === poFolderConfig.path.split("/").length && psPath.match(loRegex);
		});
	}

	//#endregion EXPLORER

	//#region DOCUMENTS


	public moveToTrashFormDocument$(psPath: string, poDocument: FormDocument): Observable<Document> {
		return this.getFolderContent$(psPath).pipe(
			take(1),
			switchMap((poFolder?: FolderContent) => {
				return this.isvcEntities.getDescriptor$(
					ArrayHelper.getFirstElement(poFolder?.current.documentTypes.forms)?.descriptor,
					{ guid: GuidHelper.extractGuid(poDocument._id) }
				);
			}),
			take(1),
			mergeMap((poDescriptor: IEntityDescriptor) => this.isvcEntitiesUpdate.saveModel(poDocument, poDescriptor)),
			mapTo(poDocument)
		);
	}

	public moveToTrashDmsDocument$(poDocument: DmsDocument): Observable<Document> {
		return this.isvcMeta.saveSharedDocumentMeta$(poDocument, StoreHelper.getDatabaseIdFromCacheData(poDocument));
	}

	public restoreFormDocument$(psPath: string, poDocument: FormDocument): Observable<FormDocument> {
		return this.getFolderContent$(psPath).pipe(
			take(1),
			switchMap((poFolder?: FolderContent) => {
				return this.isvcEntities.getDescriptor$(
					ArrayHelper.getFirstElement(poFolder?.current.documentTypes.forms)?.descriptor,
					{ guid: GuidHelper.extractGuid(poDocument._id) }
				);
			}),
			take(1),
			mergeMap((poDescriptor: IEntityDescriptor) => this.isvcEntitiesUpdate.saveModel(poDocument, poDescriptor)),
			mapTo(poDocument)
		);
	}

	public restoreDmsDocument$(poDocument: DmsDocument): Observable<DmsDocument> {
		return this.isvcMeta.saveSharedDocumentMeta$(poDocument, StoreHelper.getDatabaseIdFromCacheData(poDocument));
	}

	public deleteFormDocument$(psPath: string, poDocument: FormDocument): Observable<boolean> {
		return this.getFolderContent$(psPath).pipe(
			take(1),
			switchMap((poFolder?: FolderContent) => {
				return this.isvcEntities.getDescriptor$(
					ArrayHelper.getFirstElement(poFolder?.current.documentTypes.forms)?.descriptor,
					{ guid: GuidHelper.extractGuid(poDocument._id) }
				);
			}),
			take(1),
			mergeMap((poDescriptor: IEntityDescriptor) => this.isvcEntitiesUpdate.deleteEntity(poDocument, poDescriptor)),
			mapTo(true)
		);
	}

	public deleteDmsDocument$(poDocument: DmsDocument): Observable<boolean> {
		return this.isvcMeta.deleteSharedDocument$(poDocument._id);
	}

	/**
	 * Déterminer un document dont le chemin de classification est indiqué est concerné par une configuration de dossier.
	 * @param psConfigPath chemin ou expression régulière représentant un ensemble de chemins.
	 * @param psDocumentPath chemin de classification d'un document.
	 * @param pbNotPreparedPaths Indique si le chemin du document est préparé (séparateur /) ou non (séparateur \\).
	 * @returns true si la configuration s'applique au document.
	 */
	public matchPath(
		poConfig: FolderConfig,
		psDocumentPath: string,
		pbNotPreparedPaths?: boolean
	): boolean {
		try {
			return pbNotPreparedPaths ? poConfig.notPreparedPathRegex.test(psDocumentPath) : poConfig.pathRegex.test(psDocumentPath);
		} catch (_) {
			console.warn(`${DocExplorerDocumentsService.C_LOG_ID}::Invalid path regex: ${poConfig.path} matched on ${psDocumentPath}.`);
			return false;
		}
	}

	/** Détermine si l'utilisateur dispose de la permission indiquée sur le dossier
	 * de classification dont la configuration est fournie, en évaluant l'ensemble des permissions requises.
	 * NB : supporte l'agrégation de plusieurs permissions pour constituer la permission du dossier : dans ce
	 * cas, TOUTES les permissions doivent être accordées pour que la permission sur le dossier soit accordée.
	 *
	 * @param poPermissionValue
	 * @param poDocument
	 * @param pbThrowOnPermissionRefused
	 * @returns true si la permission est accordée
	 * @throws Error si une configuration de dossier de classification n'a pas pu être interprêtée.
	 */
	private evaluateDynamicPathPermission(
		poPermissionValue?: boolean | IPathPermission[],
		poDocument?: IPermissionContext,
		pbThrowOnPermissionRefused?: boolean
	): boolean | undefined {
		let lbGlobalPermissionResult: boolean | undefined;

		if (poPermissionValue !== undefined) {
			if (typeof poPermissionValue === "boolean")
				lbGlobalPermissionResult = poPermissionValue;
			else {
				try {// poPermissionValue doit être de type IPathPermission[] sinon => catch
					lbGlobalPermissionResult = poPermissionValue.every((poPermission: IPathPermission) => {
						const lbSinglePermissionResult: boolean = this.isvcPermissions.evaluatePermission(poPermission.scope, poPermission.permission, poDocument);

						if (pbThrowOnPermissionRefused && !lbSinglePermissionResult)
							throw new DmsPermissionError(poPermission.scope, poPermission.permission);
						else
							return lbSinglePermissionResult;
					});
				} catch (poError) {
					if (poError instanceof DmsPermissionError)
						throw poError;
					else
						throw new Error(`${DocExplorerDocumentsService.C_LOG_ID}::Malformed path permissions set: ${JSON.stringify(poPermissionValue)}.\nError:\n${JSON.stringify(poError)}`);
				}
			}
		}

		return lbGlobalPermissionResult;
	}

	/** Détermine si l'utilisateur actif dispose de la permission indiquée sur le document.
	 * @param poDocument Document.
	 * @param pePermission Identifiant d'une permission CRUD.
	 * @param pbThrowOnPermissionRefused Détermine si une exception DmsPermissionError doit être levée si l'autorisation est refusée
	 * @param pbNotPreparedPaths Indique si le chemin du document est préparé (séparateur /) ou non (séparateur \\).
	 * @returns true si l'utilisateur est autorisé, false sinon.
	 */
	public checkDocumentPermissions(
		poDocument: IDocExplorerPermissionContext,
		pePermission: TCRUDPermissions,
		pbThrowOnPermissionRefused: boolean,
		pbNotPreparedPaths?: boolean
	): boolean {
		return this.checkPermissions(
			poDocument.paths,
			pePermission,
			poDocument,
			pbThrowOnPermissionRefused,
			pbNotPreparedPaths
		);
	}

	/** Détermine si l'utilisateur actif dispose de la permission indiquée sur le dossier.
	 * @param psPath Chemin du dossier.
	 * @param pePermission Identifiant d'une permission CRUD.
	 * @param pbThrowOnPermissionRefused Détermine si une exception DmsPermissionError doit être levée si l'autorisation est refusée
	 * @returns true si l'utilisateur est autorisé, false sinon.
	 */
	public checkFolderPermissions(psPath: string, pePermission: TCRUDPermissions, pbThrowOnPermissionRefused: boolean): boolean {
		return psPath === "" || // cas root
			this.checkPermissions([psPath], pePermission, undefined, pbThrowOnPermissionRefused);
	}

	/** Détermine si l'utilisateur actif dispose de la permission indiquée sur l'ensemble des chemins indiqués.
	 * @param paPaths Liste des chemins.
	 * @param pePermission Identifiant d'une permission CRUD.
	 * @param poContext Contexte, utile pour tester la permission `mine` par exemple.
	 * @param pbThrowOnPermissionRefused Détermine si une exception DmsPermissionError doit être levée si l'autorisation est refusée
	 * @param pbNotPreparedPaths Indique si le chemin du document est préparé (séparateur /) ou non (séparateur \\).
	 * @returns true si l'utilisateur est autorisé, false sinon.
	 */
	private checkPermissions(
		paPaths: string[],
		pePermission: TCRUDPermissions,
		poContext?: IDocExplorerPermissionContext,
		pbThrowOnPermissionRefused?: boolean,
		pbNotPreparedPaths?: boolean
	): boolean {
		if (ArrayHelper.hasElements(paPaths) && !!ConfigData.environment.dms.shareDocumentMeta) {
			const loConfig: DocExplorerConfig | undefined = this.moObservableConfig.value;
			if (loConfig) {
				const laPathFolderConfigs: IPathFolderConfigs[] = paPaths.map((psPath: string) => {
					const loPathFolderConfigs: IPathFolderConfigs = {
						path: psPath,
						pathFolderConfig: loConfig.getFolderConfig(psPath),
						parentFolderConfigs: []
					};

					loConfig.paths.forEach((poFolderConfig: FolderConfig) => {
						if (
							poFolderConfig.path !== loPathFolderConfigs.pathFolderConfig?.path &&
							this.matchPath(poFolderConfig, psPath, pbNotPreparedPaths)
						)
							loPathFolderConfigs.parentFolderConfigs.push(poFolderConfig)
					});

					return loPathFolderConfigs;
				});

				const laFoldersPermissions: (boolean | undefined)[] = this.getFolderPermissions(
					laPathFolderConfigs,
					pePermission,
					pbThrowOnPermissionRefused,
					poContext
				);

				return laFoldersPermissions.every((pbPermission: boolean | undefined) =>
					pbPermission !== false
				) && laFoldersPermissions.includes(true);
			}
		}

		// Pas de contrôle de permission si aucun path n'est associé au document ou si le partage des méta est désactivé
		return true;
	}

	private getFolderPermissions(
		paPathFolderConfigs: IPathFolderConfigs[],
		pePermission: string,
		pbThrowOnPermissionRefused?: boolean,
		poContext?: IDocExplorerPermissionContext
	): (boolean | undefined)[] {
		const laFoldersPermissions: (boolean | undefined)[] = [];
		paPathFolderConfigs?.forEach((poPathFolderConfig: IPathFolderConfigs) => {
			poPathFolderConfig.parentFolderConfigs.forEach((poFolderConfig: FolderConfig) => {
				let loContext: IDocExplorerPermissionContext | undefined;
				const laRegexResults: string[] | null = poFolderConfig.notPreparedPathRegex.exec(poPathFolderConfig.path);
				if (laRegexResults && ArrayHelper.hasElements(laRegexResults)) {
					const lsEntityId: string | undefined = ArrayHelper.getFirstElement(
						ArrayHelper.getLastElement(laRegexResults)?.split(PathHelper.C_DATABASE_PATH_SEPARATOR)
					);
					if (lsEntityId && IdHelper.hasPrefixId(lsEntityId))
						loContext = { _id: lsEntityId, paths: poContext?.paths ?? [] };
				}

				laFoldersPermissions.push(this.checkFolderConfigPermission(
					poFolderConfig,
					pePermission,
					loContext,
					pbThrowOnPermissionRefused
				));
			});
			if (poPathFolderConfig.pathFolderConfig) {
				laFoldersPermissions.push(this.checkFolderConfigPermission(
					poPathFolderConfig.pathFolderConfig,
					pePermission,
					poContext,
					pbThrowOnPermissionRefused
				));
			}
		});
		return laFoldersPermissions;
	}

	private checkFolderConfigPermission(
		poFolderConfig: FolderConfig,
		pePermission: string,
		poContext?: IDocExplorerPermissionContext,
		pbThrowOnPermissionRefused?: boolean
	): boolean | undefined {
		return poFolderConfig.permissions ?
			this.evaluateDynamicPathPermission(poFolderConfig.permissions[pePermission], poContext, pbThrowOnPermissionRefused ?? false) :
			true;
	}

	/** Détermine si l'utilisateur actif dispose de la permission indiquée sur l'ensemble des chemins du document dont l'ID est indiqué.
	 * @param psDocGuid Guid du document.
	 * @param pePermission Identifiant d'une permission CRUD.
	 * @param pbThrowOnPermissionRefused Détermine si une exception DmsPermissionError doit être levée si l'autorisation est refusée.
	 * @returns true si l'utilisateur est autorisé, false sinon.
	 */
	public checkDocumentPathPermissionsFromGuid$(psDocGuid: string, pePermission: TCRUDPermissions, pbThrowOnPermissionRefused: boolean): Observable<boolean> {
		if (!!ConfigData.environment.dms.shareDocumentMeta) {
			return this.isvcMeta.getSharedDocument$(psDocGuid)
				.pipe(
					map((poDocumentMeta: IDmsMeta) => this.checkDocumentPermissions(poDocumentMeta, pePermission, pbThrowOnPermissionRefused)),
					catchError(() => of(false))
				);
		}
		else
			return of(true);
	}

	public async shareAsync(poDocument: Document): Promise<boolean> {
		const loConversation: IConversation | undefined = await this.isvcConversations.createOrOpenConversation(UserHelper.getUserContactId(), { sharedDocuments: [poDocument] }).toPromise();
		return !!loConversation;
	}

	public addDocument$(poFolder: Folder): Observable<Document | undefined> {
		return this.isvcModal.open<IGalleryFile>({
			component: GalleryAddModalComponent,
			componentProps: { title: "Nouveau document", multiple: false }
		}).pipe(
			filter((poFile?: IGalleryFile) => !!poFile),
			mergeMap((poFile: IGalleryFile) => {
				if (poFile.file)
					return this.isvcDms.save(poFile.file, poFile.file.createDmsMeta(poFile.guid, undefined, poFolder.lastPathPart, [poFolder.path]));
				return EMPTY;
			}),
			mergeMap((poDmsData?: IDmsMeta) => {
				if (!!poDmsData)
					return this.getDocumentById$(IdHelper.buildId(EPrefix.dmsDoc, GuidHelper.extractGuid(poDmsData._id)), false);
				return of(undefined);
			})
		);
	}

	public addFromForm$(poFolder: Folder, poActivatedRoute: ActivatedRoute): Observable<Document | undefined> {
		if (!ArrayHelper.hasElements(poFolder.documentTypes.forms))
			return EMPTY;

		const loModalParams: IEntityModalParams = {
			entityDescGuid: IdHelper.getGuidFromId(ArrayHelper.getFirstElement(poFolder.documentTypes.forms).descriptor, EPrefix.entityDesc),
			entityGuid: undefined,
			context: { state: { paths: [PathHelper.preparePath(poFolder.path)] }, relativeTo: poActivatedRoute },
			isEdit: true,
			closeAfterSave: true
		};

		const loModalOptions: ModalOptions = {
			component: EntityModalComponent,
			componentProps: loModalParams
		};

		return this.isvcModal.open<Entity>(loModalOptions).pipe(
			filter((poEntity?: Entity) => !!poEntity),
			mergeMap((poEntity: Entity) => this.getDocumentById$(poEntity._id, false))
		);
	}

	//#endregion DOCUMENTS

	//#endregion METHODS

}
