import { CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DateRangePickerModalComponent } from '@calaosoft/osapp/components/date/date-range-picker-modal/date-range-picker-modal.component';
import { IDateRange } from '@calaosoft/osapp/components/date/date-range-picker/model/IDateRange';
import { DateTimePickerComponent } from '@calaosoft/osapp/components/date/dateTimePicker.component';
import { ComponentBase } from '@calaosoft/osapp/helpers/ComponentBase';
import { ArrayHelper } from '@calaosoft/osapp/helpers/arrayHelper';
import { DateHelper } from '@calaosoft/osapp/helpers/dateHelper';
import { GuidHelper } from '@calaosoft/osapp/helpers/guidHelper';
import { MapHelper } from '@calaosoft/osapp/helpers/mapHelper';
import { NumberHelper } from '@calaosoft/osapp/helpers/numberHelper';
import { ObjectHelper } from '@calaosoft/osapp/helpers/objectHelper';
import { StringHelper } from '@calaosoft/osapp/helpers/stringHelper';
import { IIndexedArray } from '@calaosoft/osapp/model/IIndexedArray';
import { UserData } from '@calaosoft/osapp/model/application/UserData';
import { IContact } from '@calaosoft/osapp/model/contacts/IContact';
import { IGroup } from '@calaosoft/osapp/model/contacts/IGroup';
import { Group } from '@calaosoft/osapp/model/contacts/group';
import { IConversation } from '@calaosoft/osapp/model/conversation/IConversation';
import { IGetConversationOptions } from '@calaosoft/osapp/model/conversation/IGetConversationOptions';
import { IOpenConversationOptions } from '@calaosoft/osapp/model/conversation/IOpenConversationOptions';
import { EDateTimePickerMode } from '@calaosoft/osapp/model/date/EDateTimePickerMode';
import { ETimetablePattern } from '@calaosoft/osapp/model/date/ETimetablePattern';
import { EWeekDay } from '@calaosoft/osapp/model/date/EWeekDay';
import { IDateTimePickerParams } from '@calaosoft/osapp/model/date/IDateTimePickerParams';
import { ActivePageManager } from '@calaosoft/osapp/model/navigation/ActivePageManager';
import { IUiResponse } from '@calaosoft/osapp/model/uiMessage/IUiResponse';
import { Contact } from '@calaosoft/osapp/modules/contacts/models/contact';
import { IHydratedGroupMember } from '@calaosoft/osapp/modules/groups/model/IHydratedGroupMember';
import { Loader } from '@calaosoft/osapp/modules/loading/Loader';
import { EModalSize } from '@calaosoft/osapp/modules/modal/model/EModalSize';
import { ModalService } from '@calaosoft/osapp/modules/modal/services/modal.service';
import { C_SECTORS_ROLE_ID } from '@calaosoft/osapp/modules/permissions/services/permissions.service';
import { IFavorites } from '@calaosoft/osapp/modules/preferences/favorites/model/IFavorites';
import { FavoritesService } from '@calaosoft/osapp/modules/preferences/favorites/services/favorites.service';
import { ISector } from '@calaosoft/osapp/modules/sectors/models/isector';
import { ESelectorDisplayMode } from '@calaosoft/osapp/modules/selector/selector/ESelectorDisplayMode';
import { ISelectOption } from '@calaosoft/osapp/modules/selector/selector/ISelectOption';
import { ValidationPopup } from '@calaosoft/osapp/modules/validations/decorators/validation-popup.decorator';
import { ContactsService } from '@calaosoft/osapp/services/contacts.service';
import { ConversationService } from '@calaosoft/osapp/services/conversation.service';
import { EntityLinkService } from '@calaosoft/osapp/services/entityLink.service';
import { GroupsService } from '@calaosoft/osapp/services/groups.service';
import { ShowMessageParamsPopup } from '@calaosoft/osapp/services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '@calaosoft/osapp/services/interfaces/ShowMessageParamsToast';
import { LoadingService } from '@calaosoft/osapp/services/loading.service';
import { UiMessageService } from '@calaosoft/osapp/services/uiMessage.service';
import { AlertButton, AlertOptions } from '@ionic/core';
import { Observable, Subject, from, of, throwError } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, finalize, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { C_INTERVENANTS_ROLE_ID, C_PREFIX_PLANNING_RH } from '../../../app/app.constants';
import { TradeActionButtonService } from '../../businesses/services/trade-action-button.service';
import { ETourneeDisplayMode } from '../../tournees/model/ETourneeDisplayMode';
import { PlanificationModalComponent } from '../components/planification-modal/planification-modal.component';
import { SlotEditModalComponent } from '../components/slot-edit-modal/slot-edit-modal.component';
import { IAffectation } from '../model/IAffectation';
import { IPlanificationData } from '../model/IPlanificationData';
import { IPlanificationModalParams } from '../model/IPlanificationModalParams';
import { IPlanningRH } from '../model/IPlanningRH';
import { IPlanningTitles } from '../model/IPlanningTitles';
import { ISlot } from '../model/ISlot';
import { ISlotEditData } from '../model/ISlotEditData';
import { ISlotEditModalParams } from '../model/ISlotEditModalParams';
import { PlanningRHService } from '../services/planning-rh.service';

@Component({
	selector: 'trade-planning-rh',
	templateUrl: './planning-rh.component.html',
	styleUrls: ['./planning-rh.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningRHComponent extends ComponentBase implements OnInit, OnDestroy {

	//#region FIELDS

	/** Phrase indiquant que des conversations existent pour un customer. */
	private static readonly C_PLANNING_CONVERSATION_SENTENCE = "Il existe des conversations au sujet de ce planning.\nSélectionnez une conversation existante ou créez-en une nouvelle.";

	private moUpdatePlanningSubject = new Subject<IPlanningRH>();
	private mbRunningAction = false;
	private maSectors: Group[] = [];
	private moUpdateFavoritesSubject = new Subject<string[]>();
	private moActivePageManager: ActivePageManager;


	//#endregion

	//#region PROPERTIES

	public readonly C_PLANNING_TITLE_DATE_FORMAT = ETimetablePattern.dd_MMMM_yyyy;
	public readonly C_PLANNING_HEADER_DATE_FORMAT = ETimetablePattern.EEEE_dd_MM_slash;

	public date: Date = new Date();
	public datePickerParams: IDateTimePickerParams = DateHelper.datePickerParamsFactory(this.C_PLANNING_TITLE_DATE_FORMAT, EDateTimePickerMode.date);

	private moPlanningRH?: IPlanningRH;
	public get planningRH(): IPlanningRH | undefined {
		return this.moPlanningRH;
	}
	public set planningRH(poPlanningRH: IPlanningRH | undefined) {
		this.moPlanningRH = poPlanningRH;
	}

	public slotsDataSource = new MatTableDataSource<ISlot>();
	public displayedColumns: string[] = [
		'slotLabel',
		'monday',
		'tuesday',
		'wednesday',
		'thursday',
		'friday',
		'saturday',
		'sunday'
	];
	public sectorsOptions: ISelectOption<string>[] = [];
	public preselectedSectorsIds: string[];
	public filteredSectors: IGroup[] = [];
	public intervenants: IGroup[] = [];
	public intervenantIds: string[];
	public contactsByIds: Map<string, IHydratedGroupMember<IContact>[]>;
	public contactsBySectorIds: Map<string, string[]>;
	public affectationsByGroupIdDateSlotId: Map<string, Map<string, Map<string, IAffectation>>> = new Map();
	/** Mode de sélection. */
	public selectorDisplayMode = ESelectorDisplayMode;

	/** Permet d'avoir les différents titres du header du planning selon la semaine. */
	public C_PLANNING_HEADER_TITLE: IPlanningTitles;

	//#endregion

	//#region METHODS

	constructor(
		private isvcPlanningRH: PlanningRHService,
		private isvcGroups: GroupsService,
		private ioRoute: ActivatedRoute,
		private ioRouter: Router,
		private isvcLoading: LoadingService,
		private isvcUiMessage: UiMessageService,
		private isvcModal: ModalService,
		private isvcConversation: ConversationService,
		private isvcEntityLink: EntityLinkService,
		private isvcActionButton: TradeActionButtonService,
		private isvcFavorites: FavoritesService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poChangeDetector);

		this.moActivePageManager = new ActivePageManager(this, ioRouter, (psNewUrl: string, psPageUrl: string) => psNewUrl.includes("/planning-rh"));
	}

	public ngOnInit(): void {
		// Initialise le filtrage du planning.
		this.isvcFavorites.get(C_PREFIX_PLANNING_RH)
			.pipe(
				filter((poPreferences: IFavorites) => poPreferences?.filters !== this.preselectedSectorsIds),
				tap((poPreferences: IFavorites) => this.preselectedSectorsIds = poPreferences.filters),
				takeUntil(this.destroyed$)
			).subscribe();

		// On initialise la sauvegarde du filtrage.
		this.moUpdateFavoritesSubject.asObservable()
			.pipe(
				debounceTime(1000),
				switchMap((paFilters: any[]) => this.isvcFavorites.setFilters(C_PREFIX_PLANNING_RH, paFilters))
			).subscribe();

		let loLoader: Loader;

		this.preparePlanningAutoSave();
		from(this.isvcLoading.create("Chargement des groupes..."))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(() => this.isvcGroups.getDisplayableGroups()),
				tap((paGroups: Group[]) => {
					this.sectorsOptions = []; // Obligatoire pour l'affichage du selecteur.
					paGroups.forEach((poGroup: Group) => {
						if (this.isvcGroups.hasRole(poGroup, C_SECTORS_ROLE_ID) && (poGroup as ISector).siteId === UserData.currentSite?._id) {
							this.maSectors.push(poGroup);
							this.sectorsOptions.push({ label: poGroup.name, value: poGroup._id });
						}
						else if (this.isvcGroups.hasRole(poGroup, C_INTERVENANTS_ROLE_ID))
							this.intervenants.push(poGroup);
					});

					this.onSectorSelectionChanged(this.preselectedSectorsIds);
				}),
				mergeMap(_ => this.isvcGroups.getGroupContactsIds(this.intervenants)),
				tap((poContactsIdByGroupId: IIndexedArray<string[]>) => this.intervenantIds = ArrayHelper.unique(ArrayHelper.flat(Object.values(poContactsIdByGroupId)))),
				mergeMap(_ => this.isvcGroups.getGroupContacts(this.maSectors)),
				tap((poContactsIdByGroupId: Map<string, Contact[]>) => {
					poContactsIdByGroupId.forEach((paContacts: IContact[], psKey: string, poMap: Map<string, IContact[]>) =>
						poMap.set(psKey, paContacts.filter((poContact: IContact) => this.intervenantIds.includes(poContact._id)))
					);
					this.contactsByIds = ArrayHelper.groupBy(
						ArrayHelper.unique(ArrayHelper.flat(MapHelper.valuesToArray(poContactsIdByGroupId)))
							.map((poContact: IContact) => ContactsService.hydrateContact(poContact)), (poContact: IHydratedGroupMember<IContact>) => poContact.groupMember._id
					);

					this.contactsBySectorIds = MapHelper.map(poContactsIdByGroupId, (paContacts: IContact[]) => paContacts.map((poContact: IContact) => poContact._id));
				}),
				switchMap(_ => this.ioRoute.queryParamMap),
				distinctUntilChanged(ObjectHelper.areEqual),
				mergeMap(async (poParamMap: ParamMap) => {
					const lsLoaderText = "Chargement du planning...";
					if (loLoader.isDismissed)
						loLoader = await (await this.isvcLoading.create(lsLoaderText)).present();
					else
						loLoader.text = lsLoaderText;

					return poParamMap;
				}),
				switchMap((poParamMap: ParamMap) => {
					this.planningRH = undefined;
					const lsDate: string | null = poParamMap.get('date');
					if (lsDate)
						this.date = new Date(lsDate);
					this.C_PLANNING_HEADER_TITLE = this.getPlanningHeaderTitle();
					return this.isvcPlanningRH.getPlanning(this.date.toISOString(), this.moActivePageManager);
				}),
				tap((poPlanningRH: IPlanningRH) => {
					if (this.moActivePageManager.isActive)
						this.initPlanningRH(poPlanningRH);
					loLoader.dismiss();
					this.detectChanges();
				}),
				finalize(() => loLoader?.dismiss()),
				takeUntil(this.destroyed$)
			).subscribe();
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();

		this.moUpdatePlanningSubject.complete();
		this.moUpdateFavoritesSubject.complete();
	}

	private preparePlanningAutoSave(): void {
		this.moUpdatePlanningSubject.asObservable()
			.pipe(
				concatMap((poPlanning: IPlanningRH) => this.isvcPlanningRH.updatePlanning(poPlanning)
					.pipe(
						catchError((poError: any) => {
							this.displayUpdateOrCreateErrorMessage();

							console.error("PLAN-RH.C:: Error while updating planning :", poError);
							return of(null);
						})
					))
			)
			.subscribe();
	}

	private displayUpdateOrCreateErrorMessage(pbCreate?: boolean): void {
		this.isvcUiMessage.showMessage(new ShowMessageParamsToast({
			message: `Une erreur est survenue lors de la ${pbCreate ? "création" : "mise à jour"} du planning. Veuillez vérifier votre connexion internet.`
		}));
	}

	/** Créé un nouveau planning. */
	public createPlanning(): void {
		let loLoader: Loader;
		from(this.isvcLoading.create("Chargement..."))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => this.isvcPlanningRH.createPlanning(this.date.toISOString())),
				tap((poPlanningRH: IPlanningRH) => {
					this.planningRH = poPlanningRH;
					this.updateAndSortPlanningSlots();
					loLoader.dismiss();
					this.detectChanges();
				},
					(poError: any) => {
						this.displayUpdateOrCreateErrorMessage(true);
						console.error("PLAN-RH.C:: Error while creating planning :", poError);
					}),
				takeUntil(this.destroyed$),
				finalize(() => loLoader?.dismiss()),
			).subscribe();
	}

	/** Met à jour le planning. */
	public updatePlanning(): void {
		let loLoader: Loader;
		from(this.isvcLoading.create("Enregistrement du planning..."))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => {
					if (this.planningRH)
						return this.isvcPlanningRH.updatePlanning(this.planningRH);
					return of(undefined);
				}),
				tap((poPlanningRH?: IPlanningRH) => {
					if (poPlanningRH)
						this.initPlanningRH(poPlanningRH);
					this.updateAndSortPlanningSlots();
					loLoader.dismiss();
					this.detectChanges();
				}),
				takeUntil(this.destroyed$),
				finalize(() => loLoader?.dismiss()),
			).subscribe();
	}

	/** Supprime le planning. */
	public deletePlanning(): void {
		if (this.mbRunningAction)
			return;

		let loLoader: Loader;
		this.mbRunningAction = true;
		this.isvcUiMessage.showAsyncMessage(new ShowMessageParamsPopup({
			message: "Vous allez supprimer ce planning. Voulez-vous continuer?",
			header: "Suppression",
			buttons: [
				{ text: "Non", handler: () => UiMessageService.getFalsyResponse() },
				{ text: "Oui", handler: () => UiMessageService.getTruthyResponse() }
			]
		}))
			.pipe(
				filter((poResponse: IUiResponse<boolean>) => !!poResponse.response),
				mergeMap(() => this.isvcLoading.create("Suppression du planning...")),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(() => {
					if (this.planningRH)
						return this.isvcPlanningRH.deletePlanning(this.planningRH);
					return of(undefined);
				}),
				tap(() => {
					this.planningRH = undefined;
					this.detectChanges();
				}),
				takeUntil(this.destroyed$),
				finalize(() => {
					this.mbRunningAction = false;
					loLoader?.dismiss();
				})
			).subscribe();
	}

	/** Vide le planning. */
	public clearPlanning(): void {
		if (this.mbRunningAction)
			return;

		let loLoader: Loader;
		this.mbRunningAction = true;
		this.isvcUiMessage.showAsyncMessage(new ShowMessageParamsPopup({
			message: "Vous allez supprimer toutes les affectations de ce planning. Voulez-vous continuer?",
			header: "Nettoyage",
			buttons: [
				{ text: "Non", handler: () => UiMessageService.getFalsyResponse() },
				{ text: "Oui", handler: () => UiMessageService.getTruthyResponse() }
			]
		}))
			.pipe(
				filter((poResponse: IUiResponse<boolean>) => !!poResponse.response),
				mergeMap(() => this.isvcLoading.create("Nettoyage du planning...")),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				tap(() => {
					if (this.planningRH) {
						this.planningRH.affectations = [];
						this.initPlanningRH(this.planningRH);
					}

					this.moUpdatePlanningSubject.next(this.planningRH);
				}),
				takeUntil(this.destroyed$),
				finalize(() => {
					this.mbRunningAction = false;
					loLoader?.dismiss();
				})
			).subscribe();
	}

	private pickDateRange(poDefaultRange?: IDateRange, psPopupTitle?: string): Observable<IDateRange> {
		return this.isvcModal.open<IDateRange>(
			{
				component: DateRangePickerModalComponent,
				componentProps: {
					defaultRange: poDefaultRange,
					title: psPopupTitle
				}
			},
			EModalSize.rangePicker
		);
	}

	/**
	 * Retourne les données d'une cellule.
	 * @param psSlotId l'id du slot.
	 * @param pnDayIndex le rang du jour voulu.
	 */
	public getCellAffectation(psGroupId: string, psDate: string, psSlotId: string): IAffectation | undefined {
		return this.affectationsByGroupIdDateSlotId.get(psGroupId)?.get(psDate)?.get(psSlotId);
	}

	/**
	 * Remplie la map des affectations par id de groupe, par date et par slot.
	 * @param poPlanning le planning.
	 */
	public fillAffectationsByGroupIdDateSlotId(poPlanning: IPlanningRH): void {
		if (poPlanning) {
			if (this.affectationsByGroupIdDateSlotId.size > 0)
				this.inner_fillAffectationsByGroupIdDateSlotId(poPlanning);

			poPlanning.affectations.forEach((poAffectation: IAffectation) => {
				if (!this.affectationsByGroupIdDateSlotId.has(poAffectation.groupId))
					this.affectationsByGroupIdDateSlotId.set(poAffectation.groupId, new Map());

				if (!this.affectationsByGroupIdDateSlotId.get(poAffectation.groupId)?.get(new Date(poAffectation.date).toISOString()))
					this.affectationsByGroupIdDateSlotId.get(poAffectation.groupId)?.set(new Date(poAffectation.date).toISOString(), new Map());

				this.affectationsByGroupIdDateSlotId.get(poAffectation.groupId)?.get(new Date(poAffectation.date).toISOString())?.set(poAffectation.slotId, poAffectation);
			});
		}
	}

	private inner_fillAffectationsByGroupIdDateSlotId(poPlanning: IPlanningRH): void {
		const loExistingAffectationsGroupIds = new Set<string>(MapHelper.keysToArray(this.affectationsByGroupIdDateSlotId));
		const loPlanningAffectationsGroupIds = new Set<string>();
		const loPlanningAffectationsDates = new Set<string>();
		const loPlanningAffectationsSlotIds = new Set<string>();

		poPlanning.affectations.forEach((poAffectation: IAffectation) => {
			loPlanningAffectationsGroupIds.add(poAffectation.groupId);
			loPlanningAffectationsDates.add(new Date(poAffectation.date).toISOString());
			loPlanningAffectationsSlotIds.add(poAffectation.slotId);
		});

		ArrayHelper.getDifferences(Array.from(loExistingAffectationsGroupIds), Array.from(loPlanningAffectationsGroupIds)).forEach(
			(psGroupId: string) => this.affectationsByGroupIdDateSlotId.delete(psGroupId)
		);

		this.affectationsByGroupIdDateSlotId.forEach((poAffectationsBySlotIdByDate: Map<string, Map<string, IAffectation>>) => {
			const loExistingAffectationsDates = new Set<string>(MapHelper.keysToArray(poAffectationsBySlotIdByDate));

			ArrayHelper.getDifferences(Array.from(loExistingAffectationsDates), Array.from(loPlanningAffectationsDates)).forEach(
				(psDate: string) => poAffectationsBySlotIdByDate.delete(psDate)
			);

			poAffectationsBySlotIdByDate.forEach((poAffectionsBySlotId: Map<string, IAffectation>) => {
				const loExistingAffectationsSlotIds = new Set<string>(MapHelper.keysToArray(poAffectionsBySlotId));

				ArrayHelper.getDifferences(Array.from(loExistingAffectationsSlotIds), Array.from(loPlanningAffectationsSlotIds)).forEach(
					(psSlotIds: string) => poAffectionsBySlotId.delete(psSlotIds)
				);
			});
		});
	}

	/**
	 * Retourne la ou les classes à affecter à la cellule.
	 * @param pnColumnIndex l'index de la colonne.
	 * @param pnRowIndex l'index de la ligne.
	 */
	public getCellCssClass(pnColumnIndex: number, pnRowIndex: number): string {
		const laClass: string[] = [];

		if (pnColumnIndex === 0)
			laClass.push('first-column');
		if (pnRowIndex % 2 === 0)
			laClass.push('row-even');
		else
			laClass.push('row-odd');

		return laClass.join(" ");
	}

	/**
	 * Retourne la ou les classes à affecter à la ligne.
	 * @param pnRowIndex l'index de la ligne.
	 */
	public getRowCssClass(pnRowIndex: number): string {
		const laClass: string[] = [];

		if (pnRowIndex % 2 === 0)
			laClass.push('row-even');
		else
			laClass.push('row-odd');

		return laClass.join(" ");
	}

	/**
	 * Ajoute un nombre de semaine à la date courante.
	 * @param pnValue le nombre de semaine à ajouter.
	 */
	public async addWeeks(pnValue: number): Promise<void> {
		this.onDateChanged(DateHelper.addWeeks(this.date, pnValue));
	}

	/** Ouvre le sélecteur de date pour modifier la date du planning.
	 * @param poDatePicker Composant de changement de date.
	 */
	public onChoosePlanningDate(poDatePicker: DateTimePickerComponent): void {
		poDatePicker.pickDate();
	}

	/**
	 * Event à effectuer lors d'un changement de date.
	 * @param pdNewDate la nouvelle date.
	 */
	public onDateChanged(pdNewDate: Date): void {
		this.ioRouter.navigate(['planning-rh'], { queryParams: { date: DateHelper.transform(pdNewDate, ETimetablePattern.isoFormat_hyphen) } });
	}

	/**
	 * Affiche la modale d'édition d'une tranche horaire.
	 * @param poSlot la tranche horaire.
	 */
	public showSlotEditModal(psSectorId: string, poSlot?: ISlot): void {
		this.isvcModal.open(
			{
				component: SlotEditModalComponent,
				componentProps: {
					slotEditData: {
						label: poSlot?.label,
						startHour: poSlot?.startHour,
						endHour: poSlot?.endHour,
						slotId: poSlot?.slotId
					},
				} as ISlotEditModalParams
			},
			EModalSize.small
		).pipe(
			filter((poSlotEditData: ISlotEditData) => !ObjectHelper.isNullOrEmpty(poSlotEditData)),
			tap((poSlotEditData: ISlotEditData) => this.editSlots(poSlotEditData, psSectorId, poSlot)),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	/**
	 * Affiche la popoup de suppression d'une tranche horaire.
	 * @param poSlot la tranche horaire.
	 * @param psSectorId id du secteur.
	 */
	public presentDeleteSlotPopup(poSlot: ISlot, psSectorId: string): void {
		const lbIsCommonSlot: boolean = StringHelper.isBlank(poSlot.groupId);
		const loCancelBtn: AlertButton = { text: "Annuler", handler: () => UiMessageService.getFalsyResponse() };
		const loValidateBtn: AlertButton = { text: "Valider", handler: () => UiMessageService.getTruthyResponse() };
		const loPopupParams: AlertOptions = {
			header: "Suppression d'une tranche horaire",
			subHeader: "Êtes vous sûr de vouloir supprimer cette tranche horaire?",
			message: "Si vous avez des affectations sur cette période, cette action les supprimera.",
			buttons: [loCancelBtn, loValidateBtn],
			cssClass: "delete-hour-planning"
		};
		this.isvcUiMessage.showAsyncMessage(
			new ShowMessageParamsPopup(loPopupParams)
		).pipe(
			tap((poUiResponse: IUiResponse<boolean>) => {
				if (poUiResponse.response)
					this.deleteSlot(poSlot, lbIsCommonSlot ? psSectorId : undefined);
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	/** Modifie les slots du planning.
	 * @param poSlotEditData Données nécessaires à l'édition.
	 * @param psSectorId Id du secteur.
	 * @param poSlotToEdit La tranche horaire dans le cas d'une modification.
	 */
	private editSlots(poSlotEditData: ISlotEditData, psSectorId: string, poSlotToEdit?: ISlot): void {
		if (poSlotToEdit) {
			if (StringHelper.isBlank(poSlotToEdit.groupId)) {
				const loNewSlot: ISlot = {
					slotId: GuidHelper.newGuid(),
					groupId: psSectorId,
					startHour: +poSlotEditData.startHour,
					endHour: +poSlotEditData.endHour,
					label: poSlotEditData.label
				};
				this.planningRH?.slots.push(loNewSlot);
				poSlotToEdit.disabledSectorIds = ArrayHelper.hasElements(poSlotToEdit.disabledSectorIds) ? [...poSlotToEdit.disabledSectorIds, psSectorId] : [psSectorId];
				const loOldAffectation: IAffectation | undefined = this.planningRH?.affectations.find((poAffectation: IAffectation) => poAffectation.slotId === poSlotToEdit.slotId && poAffectation.groupId === psSectorId);

				if (loOldAffectation) {
					const loNewAffectation: IAffectation = {
						slotId: loNewSlot.slotId,
						groupId: psSectorId,
						date: loOldAffectation.date,
						contactIds: loOldAffectation.contactIds
					};

					if (this.planningRH) {
						ArrayHelper.removeElement(this.planningRH.affectations, loOldAffectation);
						this.planningRH.affectations.push(loNewAffectation);
					}
				}
			}
			else {
				poSlotToEdit.label = poSlotEditData.label;
				poSlotToEdit.startHour = +poSlotEditData.startHour;
				poSlotToEdit.endHour = +poSlotEditData.endHour;
			}
		}
		else {
			const loNewSlot: ISlot = {
				slotId: GuidHelper.newGuid(),
				groupId: psSectorId,
				startHour: +poSlotEditData.startHour,
				endHour: +poSlotEditData.endHour,
				label: poSlotEditData.label
			};
			this.planningRH?.slots.push(loNewSlot);
		};

		this.updateAndSortPlanningSlots();
		this.updatePlanning();
	}

	/**
	 * Supprime la tranche horaire ainsi que les affectations associées.
	 * @param poSlot la tranche horaire à supprimer.
	 * @param psSectorId Id du secteur pour lequel il faut supprimer la tranche horaire.
	 */
	private deleteSlot(poSlot: ISlot, psSectorId?: string): void {
		if (this.planningRH) {
			if (StringHelper.isBlank(psSectorId)) {
				for (let lnIndex = 0; lnIndex < this.planningRH.affectations.length; ++lnIndex) {
					const loAffectation: IAffectation = this.planningRH.affectations[lnIndex];
					if (loAffectation.slotId === poSlot.slotId)
						ArrayHelper.removeElement(this.planningRH.affectations, loAffectation);
				}
				ArrayHelper.removeElement(this.planningRH.slots, poSlot);
				this.planningRH.affectations = this.planningRH.affectations.filter((poAffectation: IAffectation) => poAffectation.slotId !== poSlot.slotId);
			}
			else {
				poSlot.disabledSectorIds = ArrayHelper.hasElements(poSlot.disabledSectorIds) ? [...poSlot.disabledSectorIds, psSectorId] : [psSectorId];
				ArrayHelper.removeElementByFinder(this.planningRH.affectations, (poAffectation: IAffectation) => poAffectation.slotId === poSlot.slotId && poAffectation.groupId === psSectorId);
			}
			this.updateAndSortPlanningSlots();
			this.updatePlanning();
		}
	}

	/** Tri les tranches d'horaires dans l'ordre et met à jour l'affichage. */
	private updateAndSortPlanningSlots(): void {
		this.slotsDataSource.data = this.planningRH?.slots.sort((poSlotA: ISlot, poSlotB: ISlot) => poSlotA.startHour - poSlotB.startHour) ?? [];
	}

	/**
	 * Affiche la modal pour ajouter le contact à des affectations du planning.
	 * @param poContact le contact.
	 * @param psSectorId le groupe du contact.
	 */
	public showPlanificationModal(poContact: IContact, psSectorId: string): void {
		const loPlanningRH: IPlanningRH | undefined = this.planningRH;

		if (loPlanningRH) {
			const loDefaultDateRange: IDateRange = {
				from: new Date(loPlanningRH.startDate),
				to: new Date(loPlanningRH.endDate)
			};
			this.isvcModal.open(
				{
					component: PlanificationModalComponent,
					componentProps: {
						contact: poContact,
						slots: this.getFilteredSlots(psSectorId, loPlanningRH.slots),
						groupId: psSectorId,
						defaultDateRange: loDefaultDateRange
					} as IPlanificationModalParams
				},
				EModalSize.small
			).pipe(
				filter((poPlanificationData: IPlanificationData) => !ObjectHelper.isNullOrEmpty(poPlanificationData)),
				mergeMap(async (poPlanificationData: IPlanificationData) => {
					const loLoader = await this.isvcLoading.create("Chargement...");
					loLoader.present();
					const loPlanning: IPlanningRH | undefined = await this.isvcPlanningRH.planContactAffectations(poPlanificationData, this.planningRH);
					if (loPlanning)
						this.initPlanningRH(loPlanning);

					loLoader.dismiss();
				}),
				catchError(poError => { console.error("PLANRH.C:: Error while applying planification.", poError); return throwError(() => poError); }),
				takeUntil(this.destroyed$)
			).subscribe();
		}
	}

	/** Affiche la modale du duplication du planning. */
	public showDuplicationModal(): void {
		let loDateRange: IDateRange;

		this.pickDateRange(undefined, "Dupliquer le planning")
			.pipe(
				filter((poDateRange: IDateRange) => !ObjectHelper.isNullOrEmpty(poDateRange)),
				tap((poDuplicationData: IDateRange) => loDateRange = poDuplicationData),
				mergeMap(_ => this.presentDuplicationWarningPopup()),
				tap(async (poUiResponse: IUiResponse<boolean>) => {
					if (poUiResponse.response) {
						const loLoader: Loader = await this.isvcLoading.create("Chargement ...");
						loLoader.present();
						if (this.planningRH)
							await this.isvcPlanningRH.duplicatePlanning(this.planningRH, loDateRange.from!, loDateRange.to!);
						loLoader.dismiss();
					};
				}),
				takeUntil(this.destroyed$)
			).subscribe();
	}

	/** Affiche la popup d'avertissement avant de dupliquer un planning. */
	private presentDuplicationWarningPopup(): Observable<IUiResponse<unknown, any>> {
		const loCancelBtn: AlertButton = { text: "Annuler", handler: () => UiMessageService.getFalsyResponse() };
		const loValidateBtn: AlertButton = { text: "Valider", handler: () => UiMessageService.getTruthyResponse() };
		const loPopupParams: AlertOptions = {
			header: "Duplication du planning",
			subHeader: "Attention si des plannings existent sur cette période ils seront écrasés!",
			buttons: [loCancelBtn, loValidateBtn],
		};
		return this.isvcUiMessage.showAsyncMessage(new ShowMessageParamsPopup(loPopupParams));
	}

	/**
	 * Initialise le planning.
	 * @param poPlanningRH le planning.
	 */
	private initPlanningRH(poPlanningRH: IPlanningRH): void {
		if (this.planningRH && poPlanningRH)
			this.applyNewPlanning(this.planningRH, poPlanningRH);
		else
			this.planningRH = poPlanningRH;

		this.fillAffectationsByGroupIdDateSlotId(this.planningRH);

		this.updateAndSortPlanningSlots();
		this.detectChanges();
	}

	private applyNewPlanning(poOldPlanning: IPlanningRH, poNewPlanning: IPlanningRH): void {
		// On affecte les modifications de slots.
		ArrayHelper.removeElementsByFinder(poOldPlanning.slots,
			(poOldSlot: ISlot) => !poNewPlanning.slots.some((poNewSlot: ISlot) => this.isvcPlanningRH.areSlotsEqual(poOldSlot, poNewSlot))
		);

		poNewPlanning.slots.forEach((poNewSlot: ISlot) =>
			ArrayHelper.pushIfNotPresent(poOldPlanning.slots, poNewSlot, (poOldSlot: ISlot) => this.isvcPlanningRH.areSlotsEqual(poOldSlot, poNewSlot))
		);

		// Puis on s'occupe des modifications d'affectations.
		ArrayHelper.removeElementsByFinder(poOldPlanning.affectations,
			(poOldAffectation: IAffectation) =>
				!poNewPlanning.affectations.some((poNewAffectation: IAffectation) => this.isvcPlanningRH.areAffectationsEqual(poOldAffectation, poNewAffectation))
		);

		poNewPlanning.affectations.forEach((poNewAffectation: IAffectation) =>
			ArrayHelper.pushIfNotPresent(poOldPlanning.affectations, poNewAffectation,
				(poOldAffectation: IAffectation) => this.isvcPlanningRH.areAffectationsEqual(poOldAffectation, poNewAffectation))
		);

		poOldPlanning._rev = poNewPlanning._rev;
	}

	public onElementDropped(poEvent: CdkDragDrop<string[]>, paContactIds: string[]): void {
		const lsItem: string = poEvent.previousContainer.data[poEvent.previousIndex];
		const lbItemIncluded: boolean = poEvent.container.data?.includes(lsItem);
		let lbUpdated = false;

		if (poEvent.previousContainer === poEvent.container) {
			moveItemInArray(
				poEvent.container.data as any[],
				this.getContainerIndex(poEvent.previousContainer, poEvent.previousIndex),
				this.getContainerIndex(poEvent.container, poEvent.currentIndex)
			);
			lbUpdated = true;
		}
		else if (poEvent.previousContainer.id === "basket" && NumberHelper.isValid(poEvent.container.id) && !lbItemIncluded) {
			paContactIds.splice(this.getContainerIndex(poEvent.container, poEvent.currentIndex), 0, lsItem);
			lbUpdated = true;
		}
		else if (NumberHelper.isValid(poEvent.previousContainer.id) && NumberHelper.isValid(poEvent.container.id) && !lbItemIncluded) {
			transferArrayItem(
				poEvent.previousContainer.data,
				poEvent.container.data,
				this.getContainerIndex(poEvent.previousContainer, poEvent.previousIndex),
				this.getContainerIndex(poEvent.container, poEvent.currentIndex)
			);
			lbUpdated = true;
		}

		else if (poEvent.container.id === "basket" && NumberHelper.isValid(poEvent.previousContainer.id)) {
			ArrayHelper.removeElementByIndex(poEvent.previousContainer.data, this.getContainerIndex(poEvent.previousContainer, poEvent.previousIndex));
			lbUpdated = true;
		}

		if (lbUpdated)
			this.moUpdatePlanningSubject.next(this.planningRH);
	}

	public createAffectation(poEvent: CdkDragDrop<string[]>, psSectorId: string, psDate: string, psSlotId: string): void {
		const loItem: string = poEvent.previousContainer.data[this.getContainerIndex(poEvent.previousContainer, poEvent.previousIndex)];

		this.planningRH?.affectations.push({ contactIds: [loItem], date: psDate, groupId: psSectorId, slotId: psSlotId });

		if (poEvent.previousContainer.id !== "basket")
			ArrayHelper.removeElementByIndex(poEvent.previousContainer.data as any[], +poEvent.previousContainer.id);

		if (this.planningRH)
			this.fillAffectationsByGroupIdDateSlotId(this.planningRH);
		this.moUpdatePlanningSubject.next(this.planningRH);

		this.detectChanges();
	}

	private getContainerIndex(poContainer: CdkDropList<string[]>, pnEventIndex: number): number {
		return NumberHelper.isValid(+poContainer.id) ? +poContainer.id : pnEventIndex;
	}

	private getPlanningHeaderTitle(): IPlanningTitles {
		return {
			slotLabel: "",
			monday: DateHelper.getDateOfWeekDay(this.date, EWeekDay.monday).toISOString(),
			tuesday: DateHelper.getDateOfWeekDay(this.date, EWeekDay.tuesday).toISOString(),
			wednesday: DateHelper.getDateOfWeekDay(this.date, EWeekDay.wednesday).toISOString(),
			thursday: DateHelper.getDateOfWeekDay(this.date, EWeekDay.thursday).toISOString(),
			friday: DateHelper.getDateOfWeekDay(this.date, EWeekDay.friday).toISOString(),
			saturday: DateHelper.getDateOfWeekDay(this.date, EWeekDay.saturday).toISOString(),
			sunday: DateHelper.getDateOfWeekDay(this.date, EWeekDay.sunday).toISOString()
		};
	}

	/** Créé une conversation à propos du planning courant. */
	public createOrOpenConversation(): void {
		const loPlanningRH: IPlanningRH | undefined = this.planningRH;
		if (UserData.current && loPlanningRH) {
			const lsUserContactId: string = ContactsService.getContactIdFromUserId(UserData.current.name);

			this.isvcConversation.getConversations(lsUserContactId, { links: [loPlanningRH._id] } as IGetConversationOptions)
				.pipe(
					mergeMap((paLinkedConversations: IConversation[]) => {
						const loOptions: IOpenConversationOptions = {
							routeToConversationAfterCreation: true,
							searchForMatchingConversation: false,
							linkedEntities: [loPlanningRH]
						};

						if (ArrayHelper.hasElements(paLinkedConversations)) {
							const laButtons: AlertButton[] = [
								{
									text: "Annuler",
									role: UiMessageService.C_CANCEL_ROLE
								},
								{
									text: "Créer une nouvelle conversation",
									handler: () => this.isvcConversation.createOrOpenConversation(lsUserContactId, loOptions).subscribe()
								}
							];

							return this.isvcActionButton.warningCreationFromActionButton(PlanningRHComponent.C_PLANNING_CONVERSATION_SENTENCE, laButtons);
						}
						else {
							return this.isvcConversation.createOrOpenConversation(lsUserContactId, loOptions)
								.pipe(map((poConversation?: IConversation) => !!poConversation));
						}
					}),
					take(1),
					takeUntil(this.destroyed$)
				).subscribe();
		}
		else
			console.error("PLANRH.C:: Error creating a conversation: no current user.");
	}

	public routeToTournee(): void {
		this.ioRouter.navigateByUrl(`tournees/${DateHelper.transform(this.date, ETimetablePattern.isoFormat_hyphen)}?mode=${ETourneeDisplayMode.week}`);
	}

	public onSectorSelectionChanged(paSelectedSectorsIds: string[]): void {
		this.filteredSectors = !ArrayHelper.hasElements(paSelectedSectorsIds) ? this.maSectors : this.maSectors.filter((poGroup: IGroup) => paSelectedSectorsIds.includes(poGroup._id));
		ArrayHelper.dynamicSort(this.filteredSectors, "name");
		this.moUpdateFavoritesSubject.next(paSelectedSectorsIds);
		this.detectChanges();
	}

	@ValidationPopup({ message: "Voulez-vous vider toutes les affectations pour ce groupe ?" })
	public clearSector(psSectorId: string): void {
		if (this.planningRH)
			this.planningRH.affectations = this.planningRH.affectations.filter((poAffectation: IAffectation) => poAffectation.groupId !== psSectorId);
		this.moUpdatePlanningSubject.next(this.planningRH);
	}

	public getFilteredDataSource(poDataSource: MatTableDataSource<ISlot>, psSectorId: string): MatTableDataSource<ISlot> {
		return new MatTableDataSource<ISlot>(this.getFilteredSlots(psSectorId, poDataSource.data));
	}

	private getFilteredSlots(psSectorId: string, paSlots: ISlot[]): ISlot[] {
		return paSlots.filter((poSlot: ISlot) => (!poSlot.groupId || poSlot.groupId === psSectorId) && !poSlot.disabledSectorIds?.includes(psSectorId));
	}

	//#endregion

}
