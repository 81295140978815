<ng-container *ngTemplateOutlet="!params.readOnly && layout === 'inline' ? inline : normal"></ng-container>

<ng-template #normal>
	<ion-item *ngIf="to.label" lines="none">
		<ion-label>{{to.label}}</ion-label>
	</ion-item>
</ng-template>

<ng-template #inline>
	<calao-inline-list-field-layout [icon]="layoutParams.icon" [addButtonLabel]="layoutParams.addButtonLabel"
		[separator]="layoutParams.separator" [required]="required"
		(onAddClicked)="gallery.reachedLimit ? {} : gallery.filePicker.pickFiles()">
		<ng-container *ngIf="(observableTags.changes$ | async) as tags">
			<calao-deletable-tags-list *ngIf="tags.length" [tags]="tags"
				(onRemoveTagClicked)="params.removeFromModel ? removeFileFromModel($event) : gallery.deleteItem(getFileIndex($event.id, tags))">
			</calao-deletable-tags-list>
		</ng-container>
	</calao-inline-list-field-layout>
</ng-template>

<gallery #gallery [displayMode]="params.displayMode" [cameraButtonVisible]="params.cameraButtonVisible"
	[cameraOptions]="params.cameraOptions" [command$]="galleryCommand$" [filePickerVisible]="params.filePickerVisible"
	[filePickerIcon]="params.filePickerIcon" [imagePickerIcon]="params.imagePickerIcon"
	[imagePickerVisible]="params.imagePickerVisible" [files]="observableFieldValue.value$ | async"
	[maxSizeKb]="params.maxSizeKb" [readOnly]="params.readOnly"
	[filePickerFilesButtonText]="params.filePickerFilesButtonText"
	[filePickerImagesButtonText]="params.filePickerImagesButtonText" [cameraButtonText]="params.cameraButtonText"
	[guidWithHyphens]="params.guidWithHyphens" [guidUpperCase]="params.guidUpperCase"
	(onFilesChanged)="onFilesChanged($event)" [acceptedFiles]="params.acceptedFiles"
	[acceptedImages]="params.acceptedImages" [limit]="params.limit" [hideNoFileText]="params.hideNoFileText"
	[selectDocumentType]="params.selectDocumentType">
</gallery>