<ng-container *ngIf="(observableParams.value$ | async) as params">
	<ng-container *ngTemplateOutlet="params.layout === 'inline' ? inline : normal"></ng-container>

	<ng-template #normal>
		<ng-container *ngIf="params.readOnly">
			<span>{{ getEntityName(model) || (params.placeholder || 'Aucune entité sélectionnée') }}</span>
		</ng-container>

		<ng-container *ngIf="!params.readOnly">
			<div class="container">
				<div class="select-ctn" [ngClass]="model ? 'with-close-btn' : ''" (click)="onSelectEntityClickedAsync()">
					<ion-item lines="none" class="select-item">
						<ion-select [ngModel]="model" [multiple]="false"
							[placeholder]="params.placeholder || 'Aucune entité sélectionnée'">
							<ion-select-option *ngFor="let option of [model]" [value]="option">
								{{ getEntityName(option) }}
							</ion-select-option>
						</ion-select>
					</ion-item>
				</div>

				<ion-button *ngIf="model !== undefined" color="danger" size="small" (click)="onResetEntityClicked()">
					<ion-icon name="close" shape="round"></ion-icon>
				</ion-button>
			</div>
		</ng-container>
	</ng-template>

	<ng-template #inline>
		<ng-container *ngIf="params.readOnly">
			<span>{{ getEntityName(model) || (params.placeholder || 'Aucune entité sélectionnée') }}</span>
		</ng-container>

		<ng-container *ngIf="!params.readOnly">
			<calao-inline-field-layout [icon]="params.layoutParams.icon" [label]="params.layoutParams.label"
				[separator]="params.layoutParams.separator" (onFieldClicked)="onSelectEntityClickedAsync()">
				<calao-inline-field-layout-content>
					{{ getEntityName(model) }}
				</calao-inline-field-layout-content>
			</calao-inline-field-layout>
		</ng-container>
	</ng-template>
</ng-container>