export abstract class ColorHelper {

	private constructor() { }

	private static hslToHex(pnHue: number, pnSaturation: number, pnLightness: number): string {
		pnLightness /= 100;
		const pnBuffer: number = pnSaturation * Math.min(pnLightness, 1 - pnLightness) / 100;
		const toHexa = hsl => {
			const pnBuffer2: number = (hsl + (pnHue / 30)) % 12;
			const lnColor: number = pnLightness - (pnBuffer * Math.max(Math.min(pnBuffer2 - 3, 9 - pnBuffer2, 1), -1));
			return Math.round(255 * lnColor).toString(16).padStart(2, '0');
		};
		return `#${toHexa(0)}${toHexa(8)}${toHexa(4)}`;
	}

	/** Génère un tableau de couleurs HEX distinctes pour l'œil humain.
 *
 * @param {number} pnNumColors - Le nombre de couleurs distinctes à générer.
 * @returns {string[]} Un tableau contenant les couleurs en format HEX.
 */
	public static generateDistinctHexColors(pnNumColors: number): string[] {
		const laColors: string[] = [];
		const lnStep: number = 360 / pnNumColors;
		for (let lnIndex = 0; lnIndex < pnNumColors; lnIndex++) {
			const lnHue: number = Math.floor(lnStep * lnIndex);
			const lnSaturation = 70; // fixed saturation value
			const lnLightness = 50;  // fixed lightness value
			laColors.push(this.hslToHex(lnHue, lnSaturation, lnLightness));
		}
		return laColors;
	}
}