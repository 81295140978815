import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, mergeMap, mergeMapTo, take } from 'rxjs/operators';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { EApplicationEventType } from '../../model/application/EApplicationEventType';
import { IApplicationEvent } from '../../model/application/IApplicationEvent';
import { ConfigData } from '../../model/config/ConfigData';
import { EPrefix } from '../../model/EPrefix';
import { IMenuComponentDescriptor } from '../../model/IMenuComponentDescriptor';
import { IPopoverItem } from '../../model/IPopoverItem';
import { Database } from '../../model/store/Database';
import { EDatabaseRole } from '../../model/store/EDatabaseRole';
import { EStoreEventStatus } from '../../model/store/EStoreEventStatus';
import { EStoreEventType } from '../../model/store/EStoreEventType';
import { IStoreEvent } from '../../model/store/IStoreEvent';
import { IGetVersionedDocumentsParams } from '../../modules/versioned-documents/models/iget-versioned-documents-params';
import { VersionedDocumentsService } from '../../modules/versioned-documents/services/versioned-documents.service';
import { ApplicationService } from '../../services/application.service';
import { Store } from '../../services/store.service';

@Injectable({ providedIn: "root" })
export class MenuService {

	//#region FIELDS

	/** Tableau des objets personnalisés du popover. */
	private moPopoverCustomItems: Array<IPopoverItem>;

	/** Chemin des fichiers de menu locaux présent dans les "resources" */
	private readonly C_LOCAL_MENU_DESCRIPTORS_BASE_URL = "/components/";

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcApplication: ApplicationService,
		private readonly isvcVersionedDocuments: VersionedDocumentsService
	) { }

	/** Permet de vider le popover sans supprimer les champs par défaut. */
	public clearPopover(): void {
		if (ArrayHelper.hasElements(this.moPopoverCustomItems))
			this.moPopoverCustomItems = this.moPopoverCustomItems.filter((poItem: IPopoverItem) => poItem.isPinned);
	}

	/** Récupère un menu en gardant celui ayant la version la plus récente, renvoie une erreur si aucun menu n'est trouvé.
	 * @param psMenuKey Identifiant du menu à trouver.
	 */
	public getMenu(psMenuKey: string): Observable<IMenuComponentDescriptor> {
		const loDatabaseComponents: Database = this.isvcStore.getDatabaseById(ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.components)) ?? "");
		const loDatabaseWs: Database = this.isvcStore.getDatabaseById(ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)) ?? "");
		const laDatabases: Database[] = [loDatabaseComponents, loDatabaseWs];

		if (ArrayHelper.hasElements(laDatabases) && laDatabases.every((poDatabase: Database) => poDatabase.id && poDatabase.isReady))
			return this.getComponentDescriptor(psMenuKey);
		else {
			return this.isvcApplication.appEvent$
				.pipe(
					filter((poEvent: IApplicationEvent) => this.filterComponentsDatabaseInitApplicationEvent(poEvent, laDatabases)),
					take(1),
					mergeMapTo(this.getComponentDescriptor(psMenuKey))
				);
		}
	}

	/** Filtre les événements d'application pour ne garder que celui qui vérifie que la base de données des composants a été initialisée.
	 * @param poEvent Événement d'application reçu.
	 * @param paWantedDatabases Bases de données permettant de vérifier si l'identifiant de la base de données traitées est celui souhaité.
	 */
	private filterComponentsDatabaseInitApplicationEvent(poEvent: IApplicationEvent, paWantedDatabases: Database[]): boolean {
		let lbIsFilterOkay = false;

		if (poEvent.type === EApplicationEventType.StoreEvent && (poEvent as IStoreEvent).data.status === EStoreEventStatus.successed &&
			(poEvent as IStoreEvent).data.storeEventType === EStoreEventType.Init) {

			lbIsFilterOkay = paWantedDatabases.some((poWantedDatabase: Database) => (poEvent as IStoreEvent).data.databaseId === poWantedDatabase.id);
		}

		return lbIsFilterOkay;
	}

	/** Renvoi le tableau des objets personnalisés du popover. */
	public getPopoverCustomItems(): Array<IPopoverItem> {
		return this.moPopoverCustomItems;
	}

	/** Récupère le descripteur de menu dans la base de données ayant la version la plus récente, renvoie une erreur si aucun menu n'est trouvé.
	 * @param psMenuKey Clé du menu que l'on veut initialiser.
	 */
	private getComponentDescriptor(psMenuKey: string): Observable<IMenuComponentDescriptor> {
		const loGetVersionedDocumentsParams: IGetVersionedDocumentsParams = {
			prefix: EPrefix.component,
			suffix: ".json",
			ids: [psMenuKey.toLowerCase()],
			baseUrl: this.C_LOCAL_MENU_DESCRIPTORS_BASE_URL,
			roles: [EDatabaseRole.components],
		};

		return this.isvcVersionedDocuments.getVersionedDocumentsByGuid$(loGetVersionedDocumentsParams).pipe(
			mergeMap((poConfigs: Map<string, IMenuComponentDescriptor>) => {
				const loMenu = poConfigs.get(psMenuKey.toLowerCase());
				if (loMenu) {
					console.debug(`MNU.S::Menu component ${loMenu._id} is selected for application version ${ConfigData.appInfo.appVersion}.`);
					return of(loMenu);
				}
				else {
					const lsMessage = `No menu component descriptor matching ID ${psMenuKey}.`;
					console.error(`MNU.S:: ${lsMessage}`);
					return throwError(() => lsMessage);
				}
			}),
			catchError(poError => {
				console.error(`MNU.S:: Erreur récupération dans les bases de données : `, poError);
				return throwError(() => poError);
			})
		)
	}

	/** Modifie le tableau des objets personnalisés du popover.
	 * @param paLinkItems Tableau d'objets personnalisés pour le popover.
	 */
	public setCustomPopoverItem(paLinkItems: Array<IPopoverItem>): void {
		if (!this.moPopoverCustomItems)
			this.moPopoverCustomItems = paLinkItems;

		else {
			paLinkItems.forEach((poLinkItem: IPopoverItem) => {
				if (!this.moPopoverCustomItems.some((poPopoverItem: IPopoverItem) => poPopoverItem.id === poLinkItem.id))
					this.moPopoverCustomItems.unshift(poLinkItem);
			});
		}
	}

	//#endregion
}