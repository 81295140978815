import { StoreBenchmarkParams } from './StoreBenchmarkParams';

export class StoreBenchmarkResult {
	public totalDurationMs = ""; // String pour faciliter le formatage des décimales
	public totalDocs = 0;
	public averagePerGetDurationMs = ""; // String pour faciliter le formatage des décimales
	public averagePerDocDurationMs = ""; // String pour faciliter le formatage des décimales

	public constructor(
		public readonly benchmarkParams: StoreBenchmarkParams,
		public readonly databaseInfo: PouchDB.Core.DatabaseInfo
	) { }
}
