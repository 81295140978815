<ng-container *ngTemplateOutlet="layout === 'inline' ? inline : normal"></ng-container>

<ng-template #normal>
	<span *ngIf="params.readOnly; else notReadonly;">{{observableDurationReadonlyLabel.value$ |
		async}}</span>

	<ng-template #notReadonly>
		<div class="title-section">
			<ion-label class="label-group">Date et heure</ion-label>
			<div *ngIf="!required" class="activation-wrapper">
				<ion-note>Programmée</ion-note>
				<ion-toggle [checked]="observableActivation.value$ | async"
					(ionChange)="onActivationChanged($event.detail.checked)" mode="ios">
				</ion-toggle>
			</div>
		</div>

		<ng-container *ngIf="observableActivation.value$ | async">
			<ng-container *ngTemplateOutlet="body"></ng-container>
		</ng-container>
	</ng-template>
</ng-template>

<ng-template #inline>
	<ng-container *ngIf="params.readOnly">
		<span>{{observableDurationReadonlyLabel.value$ | async}}</span>
	</ng-container>

	<ng-container *ngIf="!params.readOnly">
		<calao-inline-field-layout [icon]="layoutParams.icon" [label]="layoutParams.label"
			[separator]="layoutParams.separator" (onFieldClicked)="!required ? onFieldClicked($event) : {}">
			<calao-inline-field-layout-tags-content *ngIf="this.params.hasOptimizationButton">
				<ion-button color="ai" class="ai-button" size="small" (click)="optimizeAsync()">
					<ion-icon color="ai-button" class="optimize-icon" name="bulb"></ion-icon>
					<ion-label color="ai-button" class="optimize-label">SUGGÉRER</ion-label>
				</ion-button>
			</calao-inline-field-layout-tags-content>
			<calao-inline-field-layout-content>
				<ion-toggle *ngIf="!required" [checked]="observableActivation.value$ | async"
					(ionChange)="onActivationChanged($event.detail.checked)" mode="ios">
				</ion-toggle>
			</calao-inline-field-layout-content>

			<calao-inline-field-layout-bottom-content>
				<ng-container *ngIf="observableActivation.value$ | async">
					<ng-container *ngTemplateOutlet="body"></ng-container>
				</ng-container>
			</calao-inline-field-layout-bottom-content>
		</calao-inline-field-layout>
	</ng-container>
</ng-template>

<ng-template #body>
	<div class="date-wrapper">
		<ng-container *ngIf="observableStartDate.value$ | async as startDate">
			<osapp-date-time [params]="observableStartDateTimePickerParams.value$ | async" [model]="startDate"
				(modelChange)="onStartDateChanged($event)" class="date"></osapp-date-time>

			<ng-container *ngIf="!(observableFullDay.value$ | async)">
				<osapp-date-time [params]="observableStartTimePickerParams.value$ | async" [model]="startDate"
					(modelChange)="onStartDateChanged($event)" class="time"></osapp-date-time>
			</ng-container>
		</ng-container>
	</div>
	<div class="date-wrapper">
		<ng-container *ngIf="observableEndDate.value$ | async as endDate">
			<osapp-date-time [params]="observableEndDateTimePickerParams.value$ | async" [model]="endDate"
				(modelChange)="onEndDateChanged($event)" class="date"></osapp-date-time>

			<ng-container *ngIf="!(observableFullDay.value$ | async)">
				<osapp-date-time [params]="observableEndTimePickerParams.value$ | async" [model]="endDate"
					(modelChange)="onEndDateChanged($event)" class="time"></osapp-date-time>
			</ng-container>
		</ng-container>
	</div>

	<div lines="none" class="full-day-wrapper">
		<ion-checkbox labelPlacement="start" [checked]="observableFullDay.value$ | async"
			(ionChange)="onFullDayChanged($event)">
			<ion-note class="full-day-label">Journée entière</ion-note>
		</ion-checkbox>
	</div>
</ng-template>