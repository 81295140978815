<div class="modal-content">
	<ion-label class="title">Suggestion d'horaire</ion-label>
	<ion-note>
		<strong>Renseignez la durée et la période de planification souhaitées,</strong> nous rechercherons dans votre agenda
		un créneau
		disponible en tenant compte de vos autres activités et des temps de trajets associés.
	</ion-note>

	<div class="section-content">
		<ion-label class="subtitle">Durée</ion-label>
		<div class="date-wrapper">
			<osapp-date-time [params]="observableStartTimePickerParams.value$ | async" [model]="frontDuration"
				(modelChange)="ondDateChanged($event)" class="time"></osapp-date-time>
		</div>
	</div>

	<div class="section-content">
		<ion-label class="subtitle">Choisir une période</ion-label>
		<ion-select [(ngModel)]="periode" class="select" interface="alert" value="3days">
			<ion-select-option value="today">Aujourd'hui</ion-select-option>
			<ion-select-option value="3days">D'ici 3 jours</ion-select-option>
			<ion-select-option value="1week">D'ici 1 semaine</ion-select-option>
		</ion-select>
	</div>

	<div class="buttons">
		<ion-button (click)="close()" class="close-button" fill="clear" shape="round">Annuler</ion-button>
		<ion-button (click)="submitFormAsync()" strong="true" shape="round">Suggérer</ion-button>
	</div>
</div>