<div *ngFor="let comp of (observableParams.value$ | async)?.headers" [ngClass]="comp.cssClass || ''">
	<ng-container #header></ng-container>
</div>

<calao-tabs #tabs [activeTabIndex]="observableDefaultSegmentIndex.value$| async">
	<!-- Obligé de passer les tabs en paramètre pour appliquer la directive "drag-scroll-item", ne fonctionne pas si on l'applique à ce niveau. -->
	<calao-scroll-tabs-segments [tabs]="(observableParams.value$ | async)?.tabs" [navigateOnClick]="true">
	</calao-scroll-tabs-segments>
	<calao-tabs-tab-container [navigateOnSwipe]="true">
		<ng-template #tabTemplate *ngFor="let tab of (observableParams.value$ | async)?.tabs">
			<calao-tabs-tab>
				<ng-container #tabContainer></ng-container>
			</calao-tabs-tab>
		</ng-template>
	</calao-tabs-tab-container>
</calao-tabs>