import { Component, Input, OnInit } from '@angular/core';
import { GuidHelper } from 'libs/osapp/src/helpers/guidHelper';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { IDynHostComponent } from '../../../dynHost/IDynHost.component';
import { IFormDynHostOptions } from '../../../dynHost/IFormDynHostOptions';

@Component({
	selector: 'calao-dyn-host-wrapper',
	templateUrl: './dynHostWrapper.component.html'
})
export class DynHostWrapperComponent extends ComponentBase implements IDynHostComponent, OnInit {

	//#region PROPERTIES

	/** @implements */
	public instanceId: string;
	/** @implements */
	@Input() public params: IFormDynHostOptions;

	//#endregion

	//#region METHODS

	constructor() {
		super();
		this.instanceId = GuidHelper.newGuid();
	}

	public ngOnInit(): void {
		if (!this.params)
			this.params = {};
	}

	//#endregion

}