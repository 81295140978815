import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ObservableProperty } from '@calaosoft/osapp/modules/observable/models/observable-property';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StringHelper } from '../../../../../helpers/stringHelper';
import { AiGenerationService } from '../../../../../services/ai-generation.service';
import { ConsentService } from '../../../../../services/consent.service';
import { EConsent } from '../../../../../services/interfaces/econsent';
import { ShowMessageParamsPopup } from '../../../../../services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '../../../../../services/interfaces/ShowMessageParamsToast';
import { NetworkService } from '../../../../../services/network.service';
import { UiMessageService } from '../../../../../services/uiMessage.service';
import { ModelResolver } from '../../../../utils/models/model-resolver';
import { FieldBase } from '../../../models/FieldBase';
import { IAiGeneratedTextFieldParams } from '../../../models/IAiGeneratedTextFieldParams';
import { TextGenerationResponse } from '../../../models/text-generation-response';
import { UserUsageQuota } from '../../../models/user-usage-quota';
import { FormsService } from '../../../services/forms.service';
import { IInlineFieldLayoutParams } from './inline-field-layout/models/iinline-field-layout-params';
import { IInlineField } from './inline-field-layout/models/iinlineField';

/** Permet d'ajouter la fonctionnalité de création de rapport à un champ dans un formulaire */
@Component({
	selector: 'calao-ai-generate-text-field',
	templateUrl: './ai-generate-text-field.component.html',
	styleUrls: ['./ai-generate-text-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AiGenerateTextFieldComponent extends FieldBase implements OnInit, IInlineField {

	//#region FIELDS

	private readonly C_LOG_ID = "FIELD.IA.C::";

	/** Représente le champ de formulaire où l'IA sera utilisée */
	private moTargetField: AbstractControl<any, any>;

	//#endregion

	//#region PROPERTIES

	/** Paramètres de configuration passés via le formulaire  */
	public params: IAiGeneratedTextFieldParams & IInlineField;
	public layout: 'inline';
	public layoutParams: IInlineFieldLayoutParams;
	public hideLabelWhenFilled: boolean;

	/** Indique si le champ IA est ouvert */
	public readonly observableIsOpen = new ObservableProperty<boolean>(false);

	/** Indique si c'est la première fois que l'IA est activée */
	public isFirstOpen = true;

	/** Contient la proposition de l'IA à afficher */
	public generatedTextPosition = -1;

	/** Contient les différentes générations de l'IA */
	public generatedTexts: string[] = [];

	/** Indique si un appel à l'api est en cours */
	public readonly observableIsLoading = new ObservableProperty<boolean>(false);

	/** Représente le texte à afficher dans la proposition */
	public readonly observableProposalText = new ObservableProperty<string>("");

	/** Représente l'usage de l'utilisateur */
	public readonly observableUserUsage = new ObservableProperty<UserUsageQuota>()
		.bind(
			this.isvcAiGeneration.getUserUsage()
				.pipe(
					map((paResult: UserUsageQuota) => ModelResolver.toClass(UserUsageQuota, paResult)),
					catchError((loError: Error) => {
						console.error(this.C_LOG_ID, "Erreur lors de la récupération de l'usage de l'utilisateur", loError);
						return of(null);
					})
				),
			this
		);

	/** Indique si on peut monter/descendre dans les propositions */
	public readonly observableProposalCanGoUp = new ObservableProperty<boolean>(false);
	public readonly observableProposalCanGoDown = new ObservableProperty<boolean>(false);

	/** Reprèsente le texte initial de l'utilisateur */
	public userText: string;

	public readonly observableConsent = new ObservableProperty<boolean>(true)
		.bind(
			this.isvcConsents.get$(EConsent.aiReport),
			this
		);

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcAiGeneration: AiGenerationService,
		private readonly isvcConsents: ConsentService,
		private readonly isvcNetwork: NetworkService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly poChangeDetectorRef: ChangeDetectorRef,
		psvcForms: FormsService,
	) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.params = this.to.data;
		this.layout = this.params.layout;
		this.layoutParams = this.params.layoutParams;
		this.hideLabelWhenFilled = this.params.hideLabelWhenFilled;

		this.moTargetField = this.form.get(this.params.targetKey);
		if (!StringHelper.isBlank(this.fieldValue)) {
			this.userText = this.fieldValue;
			this.isFirstOpen = false;
		}
	}

	/** Permet de générer un rapport */
	private async generateReport(): Promise<boolean> {
		let loResponse: TextGenerationResponse;

		if (StringHelper.isBlank(this.moTargetField.value)) {
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Il est impossible de générer un rapport sans texte.", header: "Erreur" }));
			return false;
		}

		// La détection de l'absence d'internet ne fonctionne pas sur navigateur
		if (!await this.isvcNetwork.hasNetworkAsync()) {
			this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Le service IA a besoin d'une connexion internet.", header: "Erreur" }));
			return false;
		}

		this.observableIsLoading.value = true;
		try {
			loResponse = ModelResolver.toClass(TextGenerationResponse, await this.isvcAiGeneration.generateReport(this.moTargetField.value));
		} catch (loError) {
			console.error(this.C_LOG_ID, "Erreur lors de l'appel à l'API", loError);
			const loErrorMessage = loError.error;
			if (loErrorMessage?.type === "USER_QUOTA_EXCEEDED") {
				this.toastQuotaExcedeed();
			} else {
				this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Erreur lors de communication avec l'IA. Vous devez être connecté à internet pour utiliser ce service. Si vous êtes connecté à internet, contactez le support technique.", header: "Erreur" }));
			}

			this.observableIsLoading.value = false;
			this.poChangeDetectorRef.detectChanges();
			return false;
		}

		this.generatedTexts.push(loResponse.text);
		this.generatedTextPosition = this.generatedTexts.length - 1;
		this.observableUserUsage.value.usage += loResponse.usage?.total;

		this.observableProposalText.value = this.getTextProposition();
		this.observableIsLoading.value = false;
		this.setProposalCanGoUpDown();
		this.poChangeDetectorRef.detectChanges();
		return true;
	}

	/** Permet d'ouvrir le champs de proposition IA et de faire disparaître le bouton */
	public openProposalField(): void {
		if (this.isFirstOpen) {
			this.generateReport().then((lbIsGenerate) => {
				if (lbIsGenerate) {
					this.isFirstOpen = false;
					this.observableIsOpen.value = true;
					this.poChangeDetectorRef.detectChanges();
				}
			});
		} else {
			this.observableProposalText.value = this.getTextProposition();
			this.observableIsOpen.value = true;
			this.setProposalCanGoUpDown();
		}
	}

	/** Permet de fermer le champs de proposition IA et de faire réapparaître le bouton */
	private closeProposalField(): void {
		this.observableIsOpen.value = false;
	}

	/** Action lors de l'acceptation de la proposition IA */
	public acceptProposal(): void {
		if (!this.userText) {
			this.userText = StringHelper.removeHtmlTags(this.moTargetField.value.replace(/<div><br>|<div>|<br>/g, "\n").replace(/&nbsp;/g, ' '));
		}
		this.moTargetField.setValue(this.getTextProposition().replace(/\n/g, "<br>"));
		this.generatedTextPosition = -1;
		this.closeProposalField();
	}

	/** Action lors du refus de la proposition IA */
	public refuseProposal(): void {
		this.closeProposalField();
	}

	/** Génére un nouveau rapport */
	public newProposal(): void {
		this.generateReport();
	}

	/** Récupére le texte à afficher selon le generatedTextPosition */
	private getTextProposition(): string {
		if (this.generatedTextPosition === -1) {
			return this.userText;
		}
		return this.generatedTexts[this.generatedTextPosition];
	}

	/** Passe à la génération de texte précédente si possible */
	public downTextPosition(): void {
		if (this.generatedTextPosition > 0 || (this.generatedTextPosition === 0 && StringHelper.isValid(this.userText))) {
			this.generatedTextPosition -= 1;
			this.observableProposalText.value = this.getTextProposition();
		}
		this.setProposalCanGoUpDown();
	}

	/** Passe à la génération de texte suivante si possible */
	public upTextPosition(): void {
		if (this.generatedTextPosition < this.generatedTexts.length - 1) {
			this.generatedTextPosition += 1;
			this.observableProposalText.value = this.getTextProposition();
		}
		this.setProposalCanGoUpDown();
	}

	/** Met à jour les observables affichant ou non les flèches permettant de changer de proposition */
	private setProposalCanGoUpDown() {
		this.observableProposalCanGoDown.value = !(this.generatedTextPosition > 0 || (this.generatedTextPosition === 0 && StringHelper.isValid(this.userText)));
		this.observableProposalCanGoUp.value = !(this.generatedTextPosition < this.generatedTexts.length - 1);
	}

	/** Consent à l'envoi de donnée à des services tiers */
	public consent(): void {
		this.isvcConsents.consentAsync(EConsent.aiReport);
	}

	/** Message de'avertissement au dépassement du quota utilisateur */
	private toastQuotaExcedeed() {
		this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: `Votre quota d’utilisation IA est atteint. Il se réinitialisera le ${this.observableUserUsage.value.formattedDate}.​` }));

	}

	/** Affiche un toast si besoin pour avertir du dépassement du quota */
	public toastIfQuotaExcedeed(): void {
		if (this.observableUserUsage.value.isQuotaExcedeed) {
			this.toastQuotaExcedeed();
		}
	}


	//#endregion
}
