import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ArrayHelper } from "@calaosoft/osapp/helpers/arrayHelper";
import { IdHelper } from "@calaosoft/osapp/helpers/idHelper";
import { ObservableArray } from "@calaosoft/osapp/modules/observable/models/observable-array";
import { ObservableProperty } from "@calaosoft/osapp/modules/observable/models/observable-property";
import { DestroyableComponentBase } from "@calaosoft/osapp/modules/utils/components/destroyable-component-base";
import { secure } from "@calaosoft/osapp/modules/utils/rxjs/operators/secure";
import { NetworkService } from "@calaosoft/osapp/services/network.service";
import { plainToClass } from "class-transformer";
import { Observable } from "rxjs";
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { C_PREFIX_SECTORIZATION } from "../../../../../app/app.constants";
import { Sectorization } from "../../../models/sectorization";
import { SectorizationsService } from "../../../services/sectorizations.service";
import { ISector } from "../../models/isector";
import { Sector } from "../../models/sector";
import { SectorsService } from "../../services/sectors.service";

interface IFrontSector extends ISector {
	readonly namePointOfContact: string | undefined;
	readonly mapButtonEnabled: boolean | undefined;
}
class FrontSector extends Sector implements IFrontSector {
	/** @implements */
	public readonly namePointOfContact: string | undefined;
	/** @implements */
	public readonly mapButtonEnabled: boolean | undefined;
}

@Component({
	selector: 'trade-sectors-list',
	templateUrl: './sectors-list.component.html',
	styleUrls: ['./sectors-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectorsListComponent extends DestroyableComponentBase {

	//#region PROPERTIES

	public readonly sectors: ObservableArray<FrontSector>;
	public readonly title = new ObservableProperty<string>("");
	public readonly isOnline$: Observable<boolean>;

	private mbSectorsMapButtonEnabled = false;
	public get sectorsMapButtonEnabled(): boolean { return this.mbSectorsMapButtonEnabled; }

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcSectorization: SectorizationsService,
		private readonly isvcSectors: SectorsService,
		private readonly ioRoute: ActivatedRoute,
		psvcNetwork: NetworkService
	) {
		super();

		this.sectors = new ObservableArray(this.getSectors$().pipe(secure(this)));
		this.isOnline$ = psvcNetwork.hasNetwork$.pipe(secure(this));
	}

	private getSectors$(): Observable<FrontSector[]> {
		return this.isvcSectorization.getActiveSectorization$()
			.pipe(
				filter((poSctrz: Sectorization | undefined) => !!poSctrz),
				tap((poSctrz: Sectorization) => this.title.value = poSctrz.title),
				map((poSctrz: Sectorization) => poSctrz.linkedSectors),
				map((paSectors: Sector[]) => {
					return paSectors.map((poSector: Sector) => {
						const lbCanDisplayMap: boolean = poSector.canDisplayMap();

						const loFrontSector: IFrontSector = {
							...poSector,
							namePointOfContact: poSector.getNamePointOfContact(),
							mapButtonEnabled: lbCanDisplayMap
						};

						if (lbCanDisplayMap)
							this.mbSectorsMapButtonEnabled = true;

						return plainToClass(FrontSector, loFrontSector);
					});
				})
			);
	}

	public navigateToSectorVisuModeAsync(poSector: FrontSector): Promise<boolean> {
		return this.isvcSectors.navigateToSectorAsync(poSector._id, this.ioRoute);
	}

	public navigateToSectorMapModeAsync(poSector: FrontSector): Promise<boolean> {
		return this.isvcSectors.navigateToSectorMapAsync(poSector._id, this.ioRoute);
	}


	public navigateToSectorsMapAsync(): Promise<boolean> {
		return this.isvcSectors.navigateToSectorsMapAsync(this.ioRoute);
	}

	public navigateToSectorizationAsync(): Promise<boolean> {
		return this.isvcSectorization.getInactivesSectorizations$()
			.pipe(
				take(1),
				filter((paSctrz: Sectorization[]) => ArrayHelper.hasElements(paSctrz)),
				map((paSctrz: Sectorization[]) => ArrayHelper.getFirstElement(paSctrz)),
				mergeMap((poSctrz: Sectorization) =>
					this.isvcSectors.navigateToSectorizationAsync(IdHelper.getGuidFromId(poSctrz._id, C_PREFIX_SECTORIZATION))
				),
				secure(this)
			).toPromise();
	}

	//#endregion METHODS

}