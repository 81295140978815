<calao-sub-header>
	<calao-sub-header-title>
		{{(observabletitle.value$ | async)}}
	</calao-sub-header-title>
</calao-sub-header>

<mat-list>
	<calao-virtual-scroll autosize [templateCacheSize]="0" [itemSize]="50" [items]="frontRow.value$ | async">
		<ng-template let-item="$implicit">
			<mat-list-item>
				<mat-radio-button class="mat-radio-button" [value]="item._id" (change)="onSelectionChanged(item._id)">
					<div>
						{{ item.lastName ?? item.title ?? "Titre non trouvé." }}
					</div>
				</mat-radio-button>
			</mat-list-item>
		</ng-template>
	</calao-virtual-scroll>
</mat-list>

<ion-toolbar>
	<ion-buttons slot="end">
		<ion-button (click)="onCancelClicked()">Annuler</ion-button>
		<ion-button (click)="onValidateClicked()" color="primary">Ok</ion-button>
	</ion-buttons>
</ion-toolbar>