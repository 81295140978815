<calao-tabs-segments [activeTabIndex]="observableActiveTabIndex.value$ | async"
	[navigateOnClick]="observableNavigateOnClick.value$ | async">
	<calao-tabs-segment *ngFor="let segment of observableSegments.changes$ | async; index as index"
		(onSegmentClicked)="onSegmentClicked(segment, index)">
		<div class="segment-content">
			<ion-icon [name]="segment.icon"
				[ngClass]="(observableActiveTabIndex.value$ | async) === index ? 'checked' : ''"></ion-icon>
			<ion-label *ngIf="(observableActiveTabIndex.value$ | async) === index">{{ segment.name }}</ion-label>
		</div>
	</calao-tabs-segment>
</calao-tabs-segments>