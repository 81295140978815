
export class StoreBenchmarkParams {
	loops = 100;
	log = true;
	nbDocsPerGet = 100;
	includeDocs = false;
	viewName = "links/by-id-and-linktype";
	randomOffset = false;
	randomStartKey = true;
}
