import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import { defer, firstValueFrom, skip, switchMap, tap } from 'rxjs';
import { ArrayHelper } from '../../../../../../helpers/arrayHelper';
import { StringHelper } from '../../../../../../helpers/stringHelper';
import { IGeolocData } from '../../../../../../model/navigation/IGeolocData';
import { NavigationService } from '../../../../../../services/navigation.service';
import { GeolocationHelper } from '../../../../../geolocation/helpers/geolocation.helper';
import { ObservableArray } from '../../../../../observable/models/observable-array';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { secure } from '../../../../../utils/rxjs/operators/secure';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IInlineFieldLayoutParams } from '../inline-field-layout/models/iinline-field-layout-params';
import { IInlineField } from '../inline-field-layout/models/iinlineField';
import { ILocation } from './models/ilocation';

interface ILocationFieldParams extends IInlineField {
	geocodingSuccessLabel: string;
	forceDisplaying: boolean;
	longitudeKey: string;
	latitudeKey: string;
	readOnly?: boolean;
	streetKey?: string;
	zipCodeKey?: string;
	cityKey?: string;
}

interface IPrintGeoloc extends IGeolocData {
	distance?: number
}

@Component({
	selector: 'calao-location-field',
	templateUrl: './location-field.component.html',
	styleUrls: ['../inputs/inputs.component.scss', 'location-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationFieldComponent extends FieldBase<string> implements OnInit, AfterViewInit, IInlineField {

	//#region FIELDS

	private static readonly C_MAX_AUTOCOMPLETE_PROPOSAL = 5;
	private static readonly C_MIN_FIELD_LENGTH_TO_AUTOCOMPLETE = 5;

	private isClickChange = false;

	/** Cache de l'objet location à enregistrer dans le modèle. */
	public readonly moObservableLocationCache = new ObservableProperty<ILocation>();

	//#endregion

	//#region PROPERTIES

	public params: ILocationFieldParams;
	public layout: 'inline';
	public layoutParams: IInlineFieldLayoutParams;
	public hideLabelWhenFilled: boolean;

	public readonly observableCurrentPosition = new ObservableProperty<Position>();
	public readonly observableHasPlaceCoords = new ObservableProperty<boolean>(false);
	public readonly observableItems = new ObservableArray<IPrintGeoloc>([]);
	public readonly observableHideLabel = new ObservableProperty<boolean>();

	public readonly observableIsModalOpen = new ObservableProperty<boolean>(false);
	public readonly observableModalInputValue = new ObservableProperty<string>();

	/** Adresse sélectionnée depuis les propositions. */
	public readonly observableSelectedLocation = new ObservableProperty<IPrintGeoloc>();

	/** Doc propriété. */
	public readonly observableDisplayValue = new ObservableProperty<string>();

	//#endregion

	//#region METHODS
	constructor(
		private readonly isvcNavigation: NavigationService,
		psvcForms: FormsService,
	) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.params = this.to.data;
		this.layout = this.params.layout;
		this.layoutParams = this.params.layoutParams;
		this.hideLabelWhenFilled = this.params.hideLabelWhenFilled;
		this.observableHasPlaceCoords.value = !!this.model[this.params.latitudeKey];

		this.fieldValue = this.model[this.fieldKey] ?? this.getAddressFromModel();

		this.moObservableLocationCache.value = this.getLocationCache();

		defer(() => Geolocation.getCurrentPosition()).pipe(
			tap((poCurrentPosition: Position) => this.observableCurrentPosition.value = poCurrentPosition),
			secure(this)
		).subscribe();

		this.observableFieldValue.value$.pipe(
			tap((psValue?: string) => {
				this.observableHideLabel.value = this.hideLabelWhenFilled && (!StringHelper.isBlank(psValue) || this.observableHasPlaceCoords.value);
			}),
			skip(1),
			switchMap((psValue?: string) => this.refreshSuggestions(psValue)),
			secure(this)
		).subscribe();

		this.observableModalInputValue.value$.pipe(
			skip(1),
			switchMap((psValue: string) => this.refreshSuggestions(psValue)),
			secure(this)
		).subscribe();
	}

	public async refreshSuggestions(psValue?: string): Promise<void> {
		if (this.isClickChange) {
			this.isClickChange = false;
			return;
		}

		if (StringHelper.isValid(psValue) && psValue.length >= LocationFieldComponent.C_MIN_FIELD_LENGTH_TO_AUTOCOMPLETE) {
			const laSearchPos: IGeolocData[] = await firstValueFrom(this.isvcNavigation.getGeolocDataByAddress(psValue));

			if (ArrayHelper.hasElements(laSearchPos))
				this.resetSuggestions();

			for (let lnIndex = 0; lnIndex < laSearchPos.length; ++lnIndex) {
				const loSearchItem: IGeolocData = laSearchPos[lnIndex];
				const lnDistanceToThisLoc: number = GeolocationHelper.calculateDistanceUsingCoordinatesKm(
					{ latitude: this.observableCurrentPosition.value.coords.latitude, longitude: this.observableCurrentPosition.value.coords.longitude },
					{ latitude: loSearchItem.latitude as number, longitude: loSearchItem.longitude as number }
				);
				const loItem: IPrintGeoloc = loSearchItem;
				loItem.distance = Math.trunc(lnDistanceToThisLoc);

				if (this.observableItems.length <= LocationFieldComponent.C_MAX_AUTOCOMPLETE_PROPOSAL - 1) // 5 choix maximun dans l'autocomplete
					ArrayHelper.pushIfNotPresent(this.observableItems, loItem, (poItem: IPrintGeoloc) => poItem.zipCode === loItem.zipCode)
			}

			this.observableItems.sort((poItem1: IPrintGeoloc, poItem2: IPrintGeoloc) => {
				return poItem1.distance - poItem2.distance;
			});
		}
		else
			this.resetSuggestions();
	}

	public onBlurEvent(): void {
		setTimeout(_ => this.resetSuggestions(), 200); // Utile pour des raisons d'ordre d'exécution.
	}

	private resetSuggestions(): void {
		this.observableItems.clear();
	}

	public onSelecteItem(poItem: IPrintGeoloc, psOldValue: string): void {
		this.resetSuggestions();

		this.observableSelectedLocation.value = poItem;

		this.isClickChange = true;
		psOldValue = this.observableModalInputValue.value = `${poItem.street} ${poItem.zipCode} ${poItem.city}`;
		if (this.model[this.params.latitudeKey]) { // Parfois l'api du gouv ne donne pas les coordonnées pour les coins perdus
			this.observableHasPlaceCoords.value = true;
		}
	}

	public locate(): Promise<IGeolocData[]> {
		return firstValueFrom(this.isvcNavigation.getCurrentGeolocData(true).pipe(
			tap((paCurrentGeolocData: IGeolocData[]) => {
				if (ArrayHelper.hasElements(paCurrentGeolocData))
					this.observableItems.resetArray([ArrayHelper.getFirstElement(paCurrentGeolocData)])
			})
		));
	}

	public onValidate(): void {

		const lsModalInputValue: string = this.observableModalInputValue.value;
		const loSelectedLocation: IPrintGeoloc = this.observableSelectedLocation.value;

		if (StringHelper.isBlank(lsModalInputValue)) {
			this.clearAddress();
			this.observableHasPlaceCoords.value = false;
			this.model[this.fieldKey] = undefined;
			this.fieldValue = undefined;
			this.observableSelectedLocation.value = undefined;
		}
		else {
			if (loSelectedLocation) {
				if (this.params.latitudeKey)
					this.model[this.params.latitudeKey] = loSelectedLocation.latitude;
				if (this.params.longitudeKey)
					this.model[this.params.longitudeKey] = loSelectedLocation.longitude;
				if (this.params.streetKey)
					this.model[this.params.streetKey] = loSelectedLocation.street;
				if (this.params.zipCodeKey)
					this.model[this.params.zipCodeKey] = loSelectedLocation.zipCode;
				if (this.params.cityKey)
					this.model[this.params.cityKey] = loSelectedLocation.city;

				const lsAddress: string = this.getAddressFromModel();

				this.fieldValue = !StringHelper.isBlank(lsAddress) ? lsAddress : this.observableModalInputValue.value;
			}
			else {
				if (this.moObservableLocationCache.value.fieldValue !== lsModalInputValue) {
					this.clearAddress();
					this.fieldValue = lsModalInputValue;
				}
			}

		}

		this.observableIsModalOpen.value = false;
	}

	private clearAddress(): void {
		if (this.params.latitudeKey)
			this.model[this.params.latitudeKey] = undefined;
		if (this.params.longitudeKey)
			this.model[this.params.longitudeKey] = undefined;
		if (this.params.streetKey)
			this.model[this.params.streetKey] = undefined;
		if (this.params.zipCodeKey)
			this.model[this.params.zipCodeKey] = undefined;
		if (this.params.cityKey)
			this.model[this.params.cityKey] = undefined;
	}

	private getAddressFromModel(): string | undefined {
		let lsStreet: string = "";
		let lsZipCode: string = "";
		let lsCity: string = "";
		let lsFullAddress: string = "";

		if (this.params.streetKey)
			lsStreet = this.model[this.params.streetKey] ?? "";

		if (this.params.zipCodeKey)
			lsZipCode = this.model[this.params.zipCodeKey] ?? "";

		if (this.params.cityKey)
			lsCity = this.model[this.params.cityKey] ?? "";

		lsFullAddress = `${lsStreet} ${lsZipCode} ${lsCity}`;

		return !StringHelper.isBlank(lsFullAddress) ? lsFullAddress : undefined;
	}

	public onFieldClicked() {
		this.observableModalInputValue.value = this.fieldValue;
		this.observableIsModalOpen.value = true;
	}

	public clear(): void {
		this.observableModalInputValue.value = undefined;
	}

	private getLocationCache(): ILocation {
		return {
			fieldValue: this.model[this.fieldKey] ?? undefined,
			latitude: this.model[this.params.latitudeKey] ?? undefined,
			longitude: this.model[this.params.longitudeKey] ?? undefined,
			city: this.model[this.params.cityKey] ?? undefined,
			street: this.model[this.params.streetKey] ?? undefined,
			zipCode: this.model[this.params.zipCodeKey] ?? undefined
		}
	}

	//#endregion
}