<div class="field-wrapper" [ngStyle]="{'padding': (observablePadding.value$ | async)}">
	<ng-container [ngTemplateOutlet]="(observableReadonly.value$ | async) ? visuTemplate: editTemplate"></ng-container>
</div>

<!-- Template en mode edit -->
<ng-template #editTemplate>
	<ng-container *ngTemplateOutlet="(observableLayout.value$ | async) === 'inline' ? inline : normal"></ng-container>
</ng-template>

<!-- Template pour le layout normal -->
<ng-template #normal>
	<div class="title-section">
		<ion-label class="label-group">{{ observableFieldLabel.value$ | async }}</ion-label>
		<div class="activation-wrapper">
			<ion-note>{{ observableToggleLabel.value$ | async }}</ion-note>
			<ng-container *ngTemplateOutlet="toggle"></ng-container>
		</div>
	</div>

	<ng-container *ngIf="observableActivation.value$ | async">
		<div class="date-wrapper">
			<ng-container *ngIf="observableDate.value$ | async as date">
				<osapp-date-time [params]="observableDatePickerParams.value$ | async" [model]="date"
					(modelChange)="onDateChanged($event)"></osapp-date-time>
				<osapp-date-time [params]="observableTimePickerParams.value$ | async" [model]="date"
					(modelChange)="onDateChanged($event)"></osapp-date-time>
			</ng-container>
		</div>
	</ng-container>
</ng-template>

<!-- Template pour la layout inline -->
<ng-template #inline>
	<ng-container *ngIf="observableLayoutParams.value$ | async as inlineParams">
		<calao-inline-field-layout [icon]="inlineParams.icon" [label]="inlineParams.label"
			[separator]="inlineParams.separator" (onFieldClicked)="onFieldClicked($event)">
			<calao-inline-field-layout-content>
				<ng-container *ngTemplateOutlet="toggle"></ng-container>
			</calao-inline-field-layout-content>
			<calao-inline-field-layout-bottom-content>
				<ng-container *ngIf="observableActivation.value$ | async">
					<div class="date-wrapper">
						<ng-container *ngIf="observableDate.value$ | async as date">
							<osapp-date-time [params]="observableDatePickerParams.value$ | async" [model]="date"
								(modelChange)="onDateChanged($event)"></osapp-date-time>
							<osapp-date-time [params]="observableTimePickerParams.value$ | async" [model]="date"
								(modelChange)="onDateChanged($event)" class="flex-end"></osapp-date-time>
						</ng-container>
					</div>
				</ng-container>
			</calao-inline-field-layout-bottom-content>
		</calao-inline-field-layout>
	</ng-container>
</ng-template>

<!-- Template en mode visu -->
<ng-template #visuTemplate>
	<ion-label class="visu-label">{{ observableFieldLabel.value$ | async }} le {{ observableReadonlyDateLabel.value$ |
		async }}</ion-label>
</ng-template>

<ng-template #toggle>
	<ion-toggle [checked]="observableActivation.value$ | async" (ionChange)="onActivationChanged($event)" mode="ios">
	</ion-toggle>
</ng-template>