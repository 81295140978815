import { ErrorHandler, ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Contacts } from '@awesome-cordova-plugins/contacts/ngx';
import { SharedComponentsModule } from '@calaosoft/osapp/components/sharedComponents.module';
import { ConflictsModule } from '@calaosoft/osapp/modules/conflicts/conflicts.module';
import { FilterModule } from '@calaosoft/osapp/modules/filter/filter.module';
import { FormModule } from '@calaosoft/osapp/modules/forms/form/form.module';
import { IntegrityModule } from '@calaosoft/osapp/modules/integrity/integrity.module';
import { SelectorModule } from '@calaosoft/osapp/modules/selector/selector.module';
import { TabsModule } from '@calaosoft/osapp/modules/tabs/tabs.module';
import { OsappModule } from '@calaosoft/osapp/osapp.module';
import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { BusinessesModule } from './businesses/businesses.module';
import { SignatureWrapperComponent } from './businesses/components/signature-wrapper/signature-wrapper.component';
import { ConfidentialityPageComponent } from './mentionsLegales/confidentialityPage.component';
import { TermsOfSalesPageComponent } from './mentionsLegales/termesOfSalesPage.component';
import { SectorizationsModule } from './sectorizations/sectorizations.module';
import { SectorsModule } from './sectorizations/sectors/sectors.module';

const importModules: Array<Type<any> | ModuleWithProviders<any>> = [
	IonicModule,
	OsappModule,
	SharedComponentsModule,
	FormModule,
	FormlyModule,
	TabsModule,
	FilterModule,
	SelectorModule,
	MatSlideToggleModule,
	ConflictsModule,
	IntegrityModule,
	BusinessesModule,
	SectorizationsModule
];
const exportModules: Type<any>[] = [
	BusinessesModule,
	SectorsModule,
	SectorizationsModule
];
const components: Type<any>[] = [
	ConfidentialityPageComponent,
	TermsOfSalesPageComponent,
	SignatureWrapperComponent
];
const providers: Provider[] = [
	{ provide: ErrorHandler, useClass: ErrorHandler },
	Contacts
];

@NgModule({
	imports: importModules,
	exports: exportModules,
	declarations: components,
	providers: providers
})
export class CustomModule { }