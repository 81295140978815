import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Type } from '@angular/core';
import { DeletableTagsListComponent } from './components/deletable-tags-list/deletable-tags-list.component';

const components: Array<Type<any>> = [
	DeletableTagsListComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TagsModule { }
