<div *ngIf="(sctrzTitle.value$ | async)">
	<ion-title> Organisation de secteurs brouillons : {{ (sctrzTitle.value$ | async) }} </ion-title>
</div>
<ng-container *ngIf="(sectors.changes$ | async); else loadingSpinner;">

	<div class="buttons">
		<ion-button size="small" [disabled]="!(isOnline$ | async) || !sectorsMapButtonEnabled"
			(click)="navigateToSectorsMapAsync()">
			<ion-icon slot="start" name="map"></ion-icon> Carte
		</ion-button>

		<ion-button size="small" (click)="switchSectorizationAsync()" [disabled]="!(sectors.changes$ | async)?.length">
			<ion-icon slot="start" name="repeat"></ion-icon> Rendre active
		</ion-button>

		<ion-button size="small" (click)="compareSectorizationAsync()" [disabled]="!(sectors.changes$ | async)?.length">
			<ion-icon slot="start" name="bar-chart"></ion-icon>Comparer l'organisation active
		</ion-button>

		<ion-button size="small" (click)="navigateToBusinessDistributionAsync()"
			[disabled]="!(sectors.changes$ | async)?.length">
			<ion-icon slot="start" name="construct"></ion-icon> Répartir les businesses
		</ion-button>


		<ion-button size="small" color="warning" (click)="navigateToBusinessDistributionWithOptimizationAsync()">
			<ion-icon slot="start" name="flash"></ion-icon> Optimiser
		</ion-button>

	</div>

	<ng-container *ngIf="(sectors.changes$ | async)?.length; else emptyScreen;">

		<calao-virtual-scroll [items]="(sectors.changes$ | async)" [itemSize]="88" margeLastItem transparent autosize
			class="virtual-scroll">
			<ng-template let-item="$implicit">

				<ion-item-sliding osappAutoCloseSlideItem class="ion-no-padding">
					<ion-item (click)="navigateToSectorVisuModeAsync(item)" class="ion-no-padding" lines="none">
						<calao-list-item>
							<calao-list-item-start>
								<ion-icon name="extension-puzzle" class="start-item"></ion-icon>
							</calao-list-item-start>
							<calao-list-item-content detail detailIcon="ellipsis-vertical" class="end-item">
								<calao-list-item-content-title>
									<ion-label> {{ item.title}} </ion-label>
								</calao-list-item-content-title>
								<span> {{ item.getNamePointOfContact() }} </span>
							</calao-list-item-content>
						</calao-list-item>
					</ion-item>
					<ion-item-options>
						<ion-item-option (click)="navigateToSectorEditModeAsync(item)" calaoSlidingItemOptionsToggle
							color="primary">
							<ion-icon name="create"></ion-icon>
							<ion-label>Éditer</ion-label>
						</ion-item-option>

						<ion-item-option [disabled]="!(isOnline$ | async) || !item.mapButtonEnabled"
							(click)="navigateToSectorMapModeAsync(item)" calaoSlidingItemOptionsToggle color="primary">
							<ion-icon name="map"></ion-icon>
							<ion-label>Carte</ion-label>
						</ion-item-option>

						<ion-item-option (click)="onDeleteItemClickedAsync(item)" calaoSlidingItemOptionsToggle color="danger">
							<ion-icon name="trash"></ion-icon>
							Supprimer
						</ion-item-option>
					</ion-item-options>
				</ion-item-sliding>
			</ng-template>
		</calao-virtual-scroll>
	</ng-container>

</ng-container>
<!-- Afficher s'il y a un chargement -->
<ng-template #loadingSpinner>
	<div class="full-width">
		<osapp-spinner></osapp-spinner>
	</div>
</ng-template>


<!-- Si le composant a terminé de s'initialiser et qu'il y a une barre de recherche et qu'elle n'a retourné aucun résultat. -->
<ng-template #emptyScreen>
	<div class="empty-screen flex-col">
		<span class="txt-message">
			<ion-note>Aucun résultat.</ion-note>
		</span>
	</div>
</ng-template>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin fab-list-text">
	<ion-fab-button (click)="navigateToSectorCreateModeAsync()" class="fab-shadow">
		<ion-icon name="add"></ion-icon>
	</ion-fab-button>
</ion-fab>