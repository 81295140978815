import { Pipe, PipeTransform } from '@angular/core';
import { NumberHelper } from '../helpers/numberHelper';

@Pipe({
	name: 'percentage'
})
export class PercentagePipe implements PipeTransform {
	public transform(pnValue: number): string {
		return `${NumberHelper.isValid(pnValue) ? (pnValue * 100).toFixed(2) : '--'}%`;
	}
}