import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ImageOptions } from '@capacitor/camera';
import { Observable, Subscription, combineLatest, defer, from, of, throwError, timer } from 'rxjs';
import { catchError, delay, filter, finalize, map, mapTo, mergeMap, retryWhen, take, takeUntil, tap, toArray } from 'rxjs/operators';
import { ComponentBase } from '../../helpers/ComponentBase';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { EXTENSIONS_AND_MIME_TYPES, FileHelper } from '../../helpers/fileHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { EPrefix } from '../../model/EPrefix';
import { ENetworkFlag } from '../../model/application/ENetworkFlag';
import { ConfigData } from '../../model/config/ConfigData';
import { IFlag } from '../../model/flag/IFlag';
import { EGalleryCommand } from '../../model/gallery/EGalleryCommand';
import { EGalleryFilesChanged } from '../../model/gallery/EGalleryFilesChanged';
import { IGalleryCommand } from '../../model/gallery/IGalleryCommand';
import { IGalleryFile } from '../../model/gallery/IGalleryFile';
import { IGalleryParams } from '../../model/gallery/IGalleryParams';
import { GalleryFile } from '../../model/gallery/gallery-file';
import { IStoreDocument } from '../../model/store/IStoreDocument';
import { IUiResponse } from '../../model/uiMessage/IUiResponse';
import { CameraService } from '../../modules/camera/services/camera.service';
import { DmsFile } from '../../modules/dms/model/DmsFile';
import { IDmsData } from '../../modules/dms/model/IDmsData';
import { IDmsMeta } from '../../modules/dms/model/IDmsMeta';
import { IDmsProgressEvent } from '../../modules/dms/model/idms-progress-event';
import { DmsMetaService } from '../../modules/dms/services/dms-meta.service';
import { DmsService } from '../../modules/dms/services/dms.service';
import { DocPickerComponent } from '../../modules/doc-explorer/components/doc-picker/doc-picker.component';
import { SelectSubFolderModalComponent } from '../../modules/doc-explorer/components/select-sub-folder-modal/select-sub-folder-modal.component';
import { DmsDocument } from '../../modules/doc-explorer/models/dms-document';
import { Document } from '../../modules/doc-explorer/models/document';
import { Folder } from '../../modules/doc-explorer/models/folder';
import { DocExplorerDocumentsService } from '../../modules/doc-explorer/services/doc-explorer-documents.service';
import { DocExplorerService } from '../../modules/doc-explorer/services/doc-explorer.service';
import { IEntity } from '../../modules/entities/models/ientity';
import { EntitiesService } from '../../modules/entities/services/entities.service';
import { LongGuidBuilder } from '../../modules/guid/models/long-guid-builder';
import { ModalService } from '../../modules/modal/services/modal.service';
import { ObservableArray } from '../../modules/observable/models/observable-array';
import { ObservableProperty } from '../../modules/observable/models/observable-property';
import { TCRUDPermissions } from '../../modules/permissions/models/tcrud-permissions';
import { ESelectorDisplayMode } from '../../modules/selector/selector/ESelectorDisplayMode';
import { ISelectOption } from '../../modules/selector/selector/ISelectOption';
import { secure } from '../../modules/utils/rxjs/operators/secure';
import { tapError } from '../../modules/utils/rxjs/operators/tap-error';
import { ApplicationService } from '../../services/application.service';
import { EntityLinkService } from '../../services/entityLink.service';
import { FileService } from '../../services/file.service';
import { GalleryService } from '../../services/gallery.service';
import { ShowMessageParamsPopup } from '../../services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '../../services/interfaces/ShowMessageParamsToast';
import { UiMessageService } from '../../services/uiMessage.service';
import { FilePickerComponent } from '../filePicker/filePicker.component';
import { EImageClass } from '../image/model/EImageClass';
import { EGalleryDisplayMode } from './models/EGalleryDisplayMode';

/** Ce composant permet de gérer différents type de documents et leur synchronisation avec le DMS. */
@Component({
	selector: "gallery",
	templateUrl: './gallery.component.html',
	styleUrls: ['./gallery.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryComponent extends ComponentBase implements OnInit, AfterViewInit, OnDestroy, IGalleryParams {

	//#region FIELDS

	/** Identifiant du composant pour les logs. */
	private static readonly C_LOG_ID = "GLR.C::";
	private static readonly C_DEFAULT_IMAGE_TYPE = "image/*";
	private static readonly C_DEFAULT_IMAGE_PICKER_ICON = "images";
	private static readonly C_DEFAULT_FILE_PICKER_ICON = "document";

	/** Indique si un fichier est en cours d'ouverture ou non dans toutes les galeries créées. */
	private static isFileOpening = false;

	/** Notifie que le composant a ajouté un ou plusieurs fichiers. */
	@Output("filesChanged") private readonly moFilesChangedEvent = new EventEmitter<EGalleryFilesChanged>();
	@Output("onFilesChanged") private readonly moOnFilesChangedEvent = new EventEmitter<GalleryFile[]>();
	@Output("onFileDeleted") private readonly moOnFileDeletedEvent = new EventEmitter<GalleryFile>();
	@Output("onDocumentPicked") private readonly moOnDocumentPicked = new EventEmitter<Document>();

	/** On doit garder un longGuidBuilder pour être compatible avec la GED. */
	private readonly moGuidBuilder = new LongGuidBuilder();
	private readonly moPendingUploads$: Observable<IStoreDocument[]> = this.getPendingUploads$().pipe(secure(this));

	private maFileIdsToDelete: string[] = [];
	private maFileIdsToMoveToTrash: string[] = [];
	/** Abonnement de l'initialisation des fichiers. */
	private moInitFilesSubscription: Subscription;

	//#endregion

	//#region PROPRTIES

	/** @implements */
	@Input() public displayMode: EGalleryDisplayMode = EGalleryDisplayMode.thumbnails;
	/** @implements */
	@Input() public fileFilterPattern?: string; // TODO : utiliser ce filtre dans l'input
	/** @implements */
	@Input() public maxSizeKb?: number;
	/** @implements */
	@Input() public readOnly?: boolean;
	/** @implements */
	@Input() public filePickerVisible?: boolean;
	/** @implements */
	@Input() public imagePickerVisible?: boolean;
	/** @implements */
	@Input() public filePickerIcon?: string;
	/** @implements */
	@Input() public imagePickerIcon?: string;
	/** @implements */
	@Input() public filePickerFilesButtonText?: string;
	/** @implements */
	@Input() public filePickerImagesButtonText?: string;
	/** @implements */
	@Input() public hideFileDetails?: boolean;
	/** @implements */
	@Input() public command$?: Observable<IGalleryCommand>;
	/** @implements */
	@Input() public guidWithHyphens?: boolean;
	/** @implements */
	@Input() public guidUpperCase = false;
	/** @implements */
	@Input() public cameraButtonVisible?: boolean;
	/** @implements */
	@Input() public cameraButtonText?: string;
	/** @implements */
	@Input() public cameraOptions?: ImageOptions;
	/** @implements */
	@Input() public acceptedFiles?: string;
	/** @implements */
	@Input() public acceptedImages?: string;
	/** @implements */
	@Input() public limit?: number;
	/** @implements */
	@Input() public hideNoFileText?: boolean;
	/** @implements */
	@Input() public allowCustomDescription?: boolean;
	/** @implements */
	@Input() public defaultCustomDescription?: string;
	/** @implements */
	@Input() public showDisplayModeSelector = false;
	/** @implements */
	@Input() public excludeFileGuids: string[] = [];
	/** @implements */
	@Input() public selectDocumentType: boolean;
	@Input() public customButtonsTemplate: TemplateRef<any>;
	private mbMultiple = true;
	/** @implements */
	public get multiple(): boolean { return this.mbMultiple; }
	@Input() public set multiple(pbMultiple: boolean) {
		if (pbMultiple === undefined)
			this.mbMultiple = true;
		else
			this.mbMultiple = pbMultiple;
	}

	/** Liste des fichiers qu'il faut charger. */
	private readonly moFiles = new ObservableArray<GalleryFile>;
	public get files(): ObservableArray<GalleryFile> { return this.moFiles; }
	/** @implements */
	@Input() public set files(paNewFiles: IGalleryFile[] | undefined) {
		if (paNewFiles && !ArrayHelper.areArraysEqual(this.moFiles, paNewFiles, (poFileA: GalleryFile, poFileB: GalleryFile) => poFileA.guid === poFileB.guid)) {
			this.moFiles.resetArray(
				this.initFiles(paNewFiles),
				(poItemA?: GalleryFile, poItemB?: GalleryFile) => poItemA?.equals(poItemB)
			);
		}
	}

	/** `true` si la limite de fichiers est atteinte, `false` sinon. */
	public get reachedLimit(): boolean { return this.files?.length === this.limit; }

	@ViewChild("filePicker") public filePicker: FilePickerComponent;
	@ViewChild("imagePicker") public imagePicker: FilePickerComponent;

	/** Indique si le filepicker charge une image ou non. */
	public isLoading = false;
	/** Indique si internet est disponible pour le téléchargement d'image. */
	public networkAvailable = false;
	/** Paramètres du composant osapp-selector pour la selection du mode d'affichage. */
	public readonly displayModeOptions: ISelectOption[] = [
		{ label: "miniatures", value: "thumbnails" },
		{ label: "liste", value: "list" }
	];
	/** Mode de sélection. */
	public selectorDisplayMode = ESelectorDisplayMode;

	/** Liste des permissions par guid de document. */
	public readonly observablePermissionsByGuid = new ObservableProperty<Map<string, Map<string, boolean>>>(new Map());

	public readonly imgClass = EImageClass;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcDms: DmsService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcApplication: ApplicationService,
		private readonly isvcGallery: GalleryService,
		private readonly isvcCamera: CameraService,
		private readonly isvcModal: ModalService,
		private readonly isvcFile: FileService,
		private readonly isvcDocExplorer: DocExplorerService,
		private readonly isvcDocExplorerDocuments: DocExplorerDocumentsService,
		private readonly isvcEntityLinks: EntityLinkService,
		private readonly isvcEntities: EntitiesService,
		private readonly isvcMeta: DmsMetaService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		this.initSubscriptions();
		this.initInputs();
	}

	public override ngAfterViewInit(): void {
		this.detectChanges();
		super.ngAfterViewInit();
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moInitFilesSubscription?.unsubscribe();
	}

	/** Prend une photo à l'aide du plugin camera et l'ajoute au modèle. */
	public takePicture(): void {
		const loTimerSubscription: Subscription = timer(500).pipe(tap((pnValue: number) => this.onLoadingChanged(true)))
			.subscribe();

		this.isvcCamera.takePicture(this.cameraOptions, this.maxSizeKb)
			.pipe(
				tapError((poError: Error) => {
					console.error(GalleryComponent.C_LOG_ID, poError);
					this.onLoadingChanged(false); // Dans le cas d'une erreur, on enlève le loader.
					this.isvcUiMessage.showMessage(
						new ShowMessageParamsPopup({ message: "Une erreur est survenue lors du traitement de l'image.", header: "Erreur" })
					);
				}),
				mergeMap((poFile: File) => this.isvcFile.processFile$(poFile, this.maxSizeKb)),
				mergeMap((poFile?: File) => {
					if (poFile)
						return this.addToModelAsync(poFile);
					return of(undefined);
				}),
				takeUntil(this.destroyed$),
				finalize(() => loTimerSubscription.unsubscribe()) // On se désabonne du timer à la fin (prise de photo ou erreur).
			)
			.subscribe();
	}

	private async pickDocumentAsync(): Promise<void> {
		const loDocument: Document | undefined = await this.isvcModal.open<Document | undefined>({
			component: DocPickerComponent
		}).toPromise();

		if (loDocument)
			this.moOnDocumentPicked.emit(loDocument);
	}

	/** Initialise les fichiers de la galerie en les récupérant/téléchargeant s'il y en a. */
	private initFiles(paFiles: IGalleryFile[]): GalleryFile[] {
		const laGalleryFileInstances: GalleryFile[] = this.getGalleryFileInstances(paFiles ?? []);

		this.moInitFilesSubscription?.unsubscribe();

		// On itère sur chaque instances pour télécharger le fichier associé et mettre à jour les diférents champs nécessaires.
		this.moInitFilesSubscription = defer(() => laGalleryFileInstances)
			.pipe(
				filter((poGalleryFile: GalleryFile) => !StringHelper.isBlank(poGalleryFile.guid)),
				mergeMap((poFile: GalleryFile) => this.getFilePermissions$(poFile).pipe(
					tap((poFilePermissions: Map<TCRUDPermissions, boolean>) => this.observablePermissionsByGuid.value.set(poFile.guid, poFilePermissions)),
					mapTo(poFile)
				)),
				mergeMap((poFile: GalleryFile) => this.getFile$(poFile).pipe(mergeMap(_ => this.setIsUploadedAsync(poFile)))),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		return laGalleryFileInstances;
	}

	private getFilePermissions$(poFile: GalleryFile): Observable<Map<TCRUDPermissions, boolean>> {
		const lbEvaluatePermission = !!ConfigData.environment.dms.shareDocumentMeta;
		const loFilePermissions = new Map<TCRUDPermissions, boolean>();

		return combineLatest([
			lbEvaluatePermission ? this.isvcDocExplorerDocuments.checkDocumentPathPermissionsFromGuid$(poFile.guid, "delete", false) : of(true),
			lbEvaluatePermission ? this.isvcDocExplorerDocuments.checkDocumentPathPermissionsFromGuid$(poFile.guid, "trash", false) : of(false)
		]).pipe(
			map(([pbCanDelete, pbCanTrash]: [boolean, boolean]) => {
				loFilePermissions.set("delete", poFile.isNew ? true : pbCanDelete);
				loFilePermissions.set("trash", poFile.isNew ? false : pbCanTrash);
				return loFilePermissions;
			})
		);
	}

	private getFile$(poFile: GalleryFile): Observable<IDmsData> {
		return this.isvcDms.get(poFile.guid, undefined, (poEvent: IDmsProgressEvent) => { poFile.progress = this.percentToDecimal(poEvent.progress); this.detectChanges(); })
			.pipe(
				tap(
					(poGetResult: IDmsData) => {
						poFile.file = poGetResult.file;
						poFile.isAvailable = true;
					},
					poError => {
						poFile.isAvailable = false;
						poFile.isLoading = false;
						console.error(GalleryComponent.C_LOG_ID, poError);
						this.detectChanges();
					}
				),
				retryWhen((poErrors$: Observable<any>) => poErrors$.pipe(take(2), delay(2000))),
				finalize(() => {
					poFile.isLoading = false;
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			);
	}

	/** Tranforme le format des fichiers de manière à ce qu'ils soient traités uniformément pour chaque version du logiciel.
	 * @param paFiles Liste des fichiers.
	 */
	private getGalleryFileInstances(paFiles: IGalleryFile[]): GalleryFile[] {
		//! Pour la rétrocompatibilité avec Texcom v1.
		for (let index = 0; index < paFiles.length; index++) {
			// Affecte le champs obsolète "label" au champs "name" si nécessaire.
			if (StringHelper.isBlank(paFiles[index].name) && !StringHelper.isBlank(paFiles[index].label))
				paFiles[index].name = paFiles[index].label;

			// Affecte le champs obsolète "dmsId" au champs "guid" si nécessaire.
			if (StringHelper.isBlank(paFiles[index].guid) && !StringHelper.isBlank(paFiles[index].dmsId))
				paFiles[index].guid = paFiles[index].dmsId;

			paFiles[index].isLoading = true;
		}

		return paFiles.map((poFile: IGalleryFile) => new GalleryFile(poFile));
	}

	/** Initialise les différents abonnements nécessaires au bon fonctionnement du composant. */
	private initSubscriptions(): void {
		// Abonnement pour le réseau.
		this.isvcApplication.observeFlag(ENetworkFlag.isOnlineReliable)
			.pipe(
				map((poFlag: IFlag) => poFlag.value),
				tap((pbValue: boolean) => {
					this.networkAvailable = pbValue;
					this.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();

		// Abonnement sur une gestion externe du composant via des commandes.
		if (this.command$) {
			this.command$
				.pipe(
					tap((poValue: IGalleryCommand) => this.manageReceivedCommand(poValue)),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	/** Initialise les différents inputs nécessaires au bon fonctionnement du composant. */
	private initInputs(): void {
		if (!this.maxSizeKb)
			this.maxSizeKb = CameraService.C_MAX_PICTURE_SIZE_KB;

		if (this.filePickerVisible === undefined)
			this.filePickerVisible = true;

		if (this.cameraButtonVisible === undefined)
			this.cameraButtonVisible = true;

		if (this.imagePickerVisible === undefined)
			this.imagePickerVisible = true;

		if (StringHelper.isBlank(this.acceptedImages))
			this.acceptedImages = GalleryComponent.C_DEFAULT_IMAGE_TYPE;

		if (StringHelper.isBlank(this.filePickerIcon))
			this.filePickerIcon = GalleryComponent.C_DEFAULT_FILE_PICKER_ICON;

		if (StringHelper.isBlank(this.imagePickerIcon))
			this.imagePickerIcon = GalleryComponent.C_DEFAULT_IMAGE_PICKER_ICON;

		this.cameraOptions = this.isvcCamera.getAndSetCameraOptions(this.cameraOptions);

		// Façon intéressante pour initialiser les inputs booléen, permet de les utiliser sans obligatoirement passer de valeur dans le template appelant.
		this.hideFileDetails = typeof this.hideFileDetails === "boolean" ? this.hideFileDetails : this.hideFileDetails !== undefined;
	}

	/** Gère les différentes actions possible en fonction de la commande reçue.
	 * @param poCommand Commande de la galerie à appliquer.
	 */
	private manageReceivedCommand(poCommand: IGalleryCommand): void {

		switch (poCommand.type) {

			case EGalleryCommand.saveFiles:
				this.onValidate(poCommand);
				break;

			case EGalleryCommand.pickFile:
				if (!!ConfigData.environment.dms.shareDocumentMeta)
					this.pickDocumentAsync();
				else if (this.filePicker)
					this.filePicker.pickFiles();
				break;

			case EGalleryCommand.takePicture:
				this.takePicture();
				break;

			case EGalleryCommand.pickImage:
				if (this.imagePicker)
					this.imagePicker.pickFiles();
				break;
		}
	}

	/** Reçoit un événement qui indique que le chargement d'un fichier est en cours (`true`) ou non (`false`).
	 * @param pbIsLoading Indique si le composant est en train de charger une image ou non.
	 */
	public onLoadingChanged(pbIsLoading: boolean): void {
		if (this.isLoading !== pbIsLoading) {
			this.isLoading = pbIsLoading;
			this.detectChanges();
		}
	}

	/** Traite les fichiers lorsque l'enregistrement est demandé.
	 * @param poCommand Objet permettant d'exécuter une méthode après la fin des enregistrements si renseignée.
	 */
	public onValidate(poCommand: IGalleryCommand): void {
		combineLatest([this.saveFiles$(poCommand), this.removeFiles$()]).subscribe();
	}

	/** Traite le changement de mode d'affichage.
	 * @param paDisplayModes
	 */
	public onDisplayModeChange(paDisplayModes: string[]): void {
		this.displayMode = ArrayHelper.getFirstElement(paDisplayModes as EGalleryDisplayMode[]);
	}

	/** Enregistre les fichiers dans le dms.
	 * @param poCommand Objet permettant de lancer une méthode à la fin des enregistrement si renseignée.
	 */
	private saveFiles$(poCommand: IGalleryCommand): Observable<void> {
		return from(this.files)
			.pipe(
				filter((poFile: GalleryFile) => (poFile.isNew || !poFile.isAvailable) && !!poFile.file),
				mergeMap((poFile: GalleryFile) => {
					return this.isvcDms.save(poFile.file, poFile.file.createDmsMeta(poFile.guid, undefined, poFile.subType), (poEvent: IDmsProgressEvent) => {
						if (poEvent.progress === 100)
							poFile.isLoading = false;
						else if (poFile.isLoading === false)
							poFile.isLoading = true;

						poFile.progress = this.percentToDecimal(poEvent.progress);
						this.detectChanges();
					})
						.pipe(
							mergeMap(() => this.setIsUploadedAsync(poFile)),
							catchError(poError => {
								console.error(`${GalleryComponent.C_LOG_ID}Error when submitting file '${poFile.name}'.`, poError);
								this.isvcUiMessage.showMessage(
									new ShowMessageParamsPopup({ header: "Erreur de téléversement", message: `Erreur lors de la soumission du fichier ${poFile.name}.` })
								);
								return throwError(() => poError);
							})
						);
				}),
				toArray(),
				mapTo(undefined),
				finalize(() => {
					if (poCommand.callback)
						poCommand.callback();

					ArrayHelper.clear(this.files);
				})
			);
	}

	private removeFiles$(): Observable<boolean[]> {
		return combineLatest([this.deleteFiles$(), this.moveToTrashFiles$()]).pipe(
			map(([paDeletedRes, paMovedToTrashRes]: [boolean[], boolean[]]) => paDeletedRes.concat(paMovedToTrashRes))
		);
	}

	/** Supprime les fichiers et les méta associés. */
	private deleteFiles$(): Observable<boolean[]> {
		if (ArrayHelper.hasElements(this.maFileIdsToDelete)) {
			const laFailedDeleteFiles: string[] = []; // Tableau des fichiers qui n'ont pas pu être supprimés.

			return defer(() => from(this.maFileIdsToDelete))
				.pipe(
					mergeMap((psIdToDelete: string) => this.isvcDms.delete(psIdToDelete)
						.pipe(
							catchError((poError: any) => {
								console.error(`${GalleryComponent.C_LOG_ID}Error when deleting file '${psIdToDelete}'.`, poError);

								this.isvcUiMessage.showMessage(
									new ShowMessageParamsPopup({
										header: "Erreur de suppression",
										message: "Erreur lors de la suppression du fichier.",
										buttons: [{ text: "Ok" }]
									})
								);

								laFailedDeleteFiles.push(psIdToDelete); // On ajoute les identifiants de fichiers qui n'ont pas pu être supprimés.
								return of(undefined);
							})
						)),
					toArray(),
					// Le tableau de fichiers à supprimer est vide si les suppressions se sont bien passées, ou contient les fichiers qui n'ont pu être supprimés.
					tap(_ => this.maFileIdsToDelete = laFailedDeleteFiles) // NB pas de takeUntil ! sinon le flux peut être clos à la fermeture de la page avant la fin.
				);
		}
		else
			return of([]);
	}

	/** Déplace les fichiers dans la corbeille. */
	private moveToTrashFiles$(): Observable<boolean[]> {
		if (ArrayHelper.hasElements(this.maFileIdsToMoveToTrash)) {
			const laFailedTrashFiles: string[] = [];

			return defer(() => from(this.maFileIdsToMoveToTrash))
				.pipe(
					mergeMap((psId: string) =>
						this.isvcMeta.getSharedDocument$(psId).pipe(
							mergeMap((poFile: IDmsMeta) =>
								this.isvcDocExplorer.moveToTrash$(
									new DmsDocument({ ...poFile, createDate: new Date(poFile.createDate) }),
									ArrayHelper.getFirstElement(poFile.paths)
								).pipe(
									catchError((poError: any) => {
										console.error(`${GalleryComponent.C_LOG_ID}Error when move file '${psId}' to trash.`, poError);

										this.isvcUiMessage.showMessage(
											new ShowMessageParamsPopup({
												header: "Erreur de suppression",
												message: "Erreur lors du déplacement dans la corbeille.",
												buttons: [{ text: "Ok" }]
											})
										);

										laFailedTrashFiles.push(psId);
										return of(undefined);
									})
								))
						)
					),
					toArray(),
					tap(_ => this.maFileIdsToMoveToTrash = laFailedTrashFiles)
				);
		}
		else
			return of([]);
	}

	/** Fonction d'ajout au modèle du fichier séléctionné.
	 * @param poFile Objet correspondant au fichier sélectionné.
	 */
	public async addToModelAsync(poFile: File): Promise<void> {
		if (poFile) {
			const loDmsFile: DmsFile = new DmsFile(poFile, poFile.name);
			const loGalleryFile: GalleryFile = new GalleryFile(
				{
					file: loDmsFile,
					isNew: true,
					name: loDmsFile.Name,
					description: "",
					guid: this.moGuidBuilder.build({ withHyphens: this.guidWithHyphens, upperCase: this.guidUpperCase })
				}
			);

			if (this.allowCustomDescription)
				await this.editFileDescription$(loGalleryFile).toPromise();

			let lbCanAddFile = true;
			if (this.selectDocumentType) {
				const loEntity: IEntity | undefined = this.isvcEntityLinks.currentEntity;
				if (loEntity) {
					const loFolder: Folder | undefined = await this.isvcModal.open<Folder>({
						component: SelectSubFolderModalComponent,
						componentProps: { path: ArrayHelper.getFirstElement(this.isvcEntities.getEntityDocumentPaths(loEntity)) }
					}).toPromise();
					loGalleryFile.subType = loFolder?.lastPathPart;
				}
				lbCanAddFile = !StringHelper.isBlank(loGalleryFile.subType);
			}

			if (lbCanAddFile) {
				this.files.push(loGalleryFile);
				this.emitFilesChangedEvent(); // Évite de lever un événement si on n'a pas ajouté de fichier finalement.
			}
		}

		this.onLoadingChanged(false);
		this.detectChanges();
	}

	private editFileDescription$(poGalleryFile: GalleryFile): Observable<IUiResponse<any, { description: string; }>> {
		const loPopupParams = new ShowMessageParamsPopup({
			header: "Nom du document",
			inputs: [{ type: "text", name: "description", value: StringHelper.isBlank(poGalleryFile.description) ? this.defaultCustomDescription : poGalleryFile.description }],
			buttons: [{ text: "Annuler", role: "cancel" }, { text: "Valider", role: "submit" }]
		});

		return this.isvcUiMessage.showAsyncMessage<any, { description: string }>(loPopupParams)
			.pipe(
				tap((poResponse: IUiResponse<any, { description: string }>) => {
					if (!StringHelper.isBlank(poResponse.values?.description)) {
						poGalleryFile.description = poResponse.values?.description;
					};
				}),
				takeUntil(this.destroyed$)
			);
	}

	public editDescription(poFile: GalleryFile): void {
		if (this.allowCustomDescription) {
			const lsTempDescription: string | undefined = poFile.description;

			this.editFileDescription$(poFile)
				.pipe(
					tap(() => {
						if (lsTempDescription !== poFile.description)
							this.emitFilesChangedEvent();
					}),
					takeUntil(this.destroyed$)
				)
				.subscribe();
		};
	}

	/** Supprime le fichier uploadé du modèle en fonction de l'index de celui-ci.
	 * @param pnIndex Index correspondant au fichier qu'il faut supprimer.
	 * @param pbSoft Indique si on doit placer dans la corbeille.
	 */
	public deleteItem(pnIndex: number, pbSoft?: boolean): void {
		const loItem: GalleryFile = this.files[pnIndex];
		if (pbSoft)
			pbSoft = !loItem.isNew;

		if (this.maFileIdsToDelete.concat(this.maFileIdsToMoveToTrash).find((psFileGuid: string) => loItem.guid === psFileGuid)) {
			return this.isvcUiMessage.showMessage(
				new ShowMessageParamsToast({ message: `Ce document est déjà en attente de suppression.` })
			);
		}

		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				header: "Suppression",
				message: `Êtes-vous sûr de vouloir ${pbSoft ? `placer ${loItem.name} dans la corbeille` : `supprimer ${loItem.name}`} ?`,
				buttons: [
					{ text: "Annuler" },
					{ text: "Supprimer", handler: () => this.deleteFileAt(pnIndex, pbSoft) }
				]
			})
		);
	}

	/** Supprime du model l'item à l'index donné tout en mettant de côté cet item s'il a été sauvegardé dans le DMS pour le supprimer
	 * de la bdd locale et sur le serveur lors de l'enregistrement.
	 * @param pnIndex Index correspondant au fichier qu'il faut supprimer.
	 * @param pbSoft Indique si on doit placer dans la corbeille.
	 */
	private deleteFileAt(pnIndex: number, pbSoft?: boolean): void {
		const loItem: GalleryFile = this.files[pnIndex];

		if (!loItem.isNew) {
			if (pbSoft)
				this.maFileIdsToMoveToTrash.push(loItem.guid);
			else
				this.maFileIdsToDelete.push(loItem.guid);
		}

		this.files.splice(pnIndex, 1); // Retire 1 élément à l'index pnIndex du tableau.

		this.moOnFileDeletedEvent.next(loItem);
		this.emitFilesChangedEvent();
	}

	/** Ouvre le fichier selectioné avec l'application par défaut du smartphone.
	 * @param pnItemIndex Index de l'item.
	 */
	public openFileAt(pnItemIndex: number): void {
		if (!GalleryComponent.isFileOpening) { // Si on n'a pas d'ouverture de fichier en cours alors on peut ouvrir celui-ci, sinon, il faut attendre.
			const loCurrentFile: GalleryFile = this.files[pnItemIndex];

			this.isvcGallery.openFile(loCurrentFile, GalleryComponent.isFileOpening)
				.pipe(
					takeUntil(this.destroyed$)
				)
				.subscribe();
		}
	}

	/** Récupère le nom du fichier sans son extension.
	 * @param psItemName Nom du fichier.
	 */
	public getItemNameWithoutExtension(psItemName: string): string {
		return FileHelper.getFileNameWithoutExtension(psItemName);
	}

	/** Récupère l'extension du fichier à partir de son nom, `undefined` si non trouvé.
	 * @param psItemName Nom du fichier.
	 */
	public getItemExtensionFromName(psItemName: string): string | undefined {
		const lsFileExtension: string | undefined = FileHelper.getFileExtensionFromFileName(psItemName);
		return lsFileExtension ? `.${lsFileExtension}` : undefined;
	}

	/** Émet un événement indiquant que les fichiers de la galerie ont changé. */
	private emitFilesChangedEvent(): void {
		let leEmitValue: EGalleryFilesChanged;

		if (this.files.length === 0)
			leEmitValue = EGalleryFilesChanged.NoFile;
		else if (this.files.length === 1)
			leEmitValue = EGalleryFilesChanged.OneFile;
		else
			leEmitValue = EGalleryFilesChanged.SomeFiles;

		this.moOnFilesChangedEvent.emit(Array.from(this.files));
		this.moFilesChangedEvent.emit(leEmitValue);
	}

	/** Permet de set isUpload à un fichier en paramètre.
	 * @param poFile Le fichier à set isUpload.
	 * @returns `true` si le fichier est upload, `false` sinon.
	 */
	private async setIsUploadedAsync(poFile: GalleryFile): Promise<void> {
		const pbIsUpload = await this.isUploadedAsync(poFile);
		poFile.isUploaded = pbIsUpload;
	}

	/** Retourne `true` si le fichier est téléversé, `false` sinon.
	 * @param poFile Fichier à vérifier.
	 */
	private isUploadedAsync(poFile: GalleryFile): Promise<boolean> {
		return this.moPendingUploads$.pipe(
			take(1),
			map((paPendingDocs: IStoreDocument[]) => !paPendingDocs.some((poPendingDoc: IStoreDocument) => poPendingDoc._id.includes(poFile.guid)))
		).toPromise();
	}

	/** Récupère la liste des documents en attente de téléversement. */
	private getPendingUploads$(): Observable<IStoreDocument[]> {
		return this.isvcDms.getPendingDocs(EPrefix.pendingUpload, true);
	}

	/** Télécharge ou téléverse un fichier en fonction de son statut.
	 * @param poFile Le fichier à télécharger ou téléverser.
	 */
	public downloadOrUpload(poFile: GalleryFile): void {
		if (!poFile.isUploaded) {
			poFile.isLoading = true;
			this.uploadFile$(poFile).subscribe();
		}
		else if (!poFile.isAvailable) {
			poFile.isLoading = true;
			this.getFile$(poFile).subscribe();
		}
	}

	/** Téléverse un fichier.
	 * @param poFile Le fichier à téléverser.
	 * @returns L'identifiant du fichier téléversé.
	 */
	private uploadFile$(poFile: GalleryFile): Observable<string[]> {
		return this.moPendingUploads$.pipe(
			map((paPendingDocs: IStoreDocument[]) => paPendingDocs.filter((poPendingDoc: IStoreDocument) => poPendingDoc._id.includes(poFile.guid))),
			mergeMap((paPendingDocs: IStoreDocument[]) => this.isvcDms.execUploads(paPendingDocs)),
			tap(
				() => {
					poFile.isAvailable = true;
				},
				poError => {
					poFile.isAvailable = false;
					poFile.isLoading = false;
					console.error(GalleryComponent.C_LOG_ID, poError);
					this.detectChanges();
				}
			),
			finalize(() => {
				poFile.isLoading = false;
				this.detectChanges();
			}),
			takeUntil(this.destroyed$)
		);
	}

	/** Permet de savoir si un fichier est une image ou non.
	 * @param psItemName Le fichier à vérifier.
	 * @returns `true` si le fichier est une image, `false` sinon.
	 */
	public isImage(poItem: GalleryFile): boolean {
		let lsItemExtension: string | undefined = this.getItemExtensionFromName(poItem.name)?.toLowerCase();

		if (!lsItemExtension)
			return false;
		else {
			if (lsItemExtension.startsWith("."))
				lsItemExtension = lsItemExtension.slice(1);

			return EXTENSIONS_AND_MIME_TYPES[lsItemExtension]?.mimeType.includes("image");
		}
	}

	/** Transforme un nombre compris entre 0 et 100 en un nombre compris entre 0 et 1 et le retourne.
	 * @param poNumber Le nombre à transformer.
	 */
	private percentToDecimal(poNumber: number): number {
		return poNumber * 0.01;
	}

	//#endregion

}