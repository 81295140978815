import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalOptions } from '@ionic/core';
import { ActionButtonFieldBaseComponent } from '../../../../../../components/forms/form/customFields/actionButtons/actionButtonFieldBase.component';
import { FormsService } from '../../../../../../services/forms.service';
import { EntityModalComponent } from '../../../../../entities/components/entity-modal/entity-modal.component';
import { IEntityModalParams } from '../../../../../entities/models/ientity-modal-params';
import { ModalService } from '../../../../../modal/services/modal.service';
import { IActionButtonFieldNewEntityParams } from '../../../../models/actionButtonFields/iaction-button-field-new-entity-params';

@Component({
	selector: 'calao-new-entity-action-button-field',
	templateUrl: './actionButtonFieldBase.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionButtonFieldNewEntityComponent extends ActionButtonFieldBaseComponent<IActionButtonFieldNewEntityParams> {

	//#region METHODS

	constructor(
		private readonly isvcModal: ModalService,
		psvcForms: FormsService
	) {
		super(psvcForms);
	}

	public override action(): void {
		const loModalParams: IEntityModalParams = {
			entityDescGuid: this.params.specParams?.entityDescId,
			entityGuid: undefined,
			context: this.params.specParams?.createParams,
			isEdit: true,
			closeAfterSave: true
		};

		const loModalOptions: ModalOptions = {
			component: EntityModalComponent,
			componentProps: loModalParams
		};

		this.isvcModal.open(loModalOptions).subscribe();
	}

	//#endregion
}