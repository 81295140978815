import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, defaultIfEmpty, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { UserHelper } from '../../../helpers/user.helper';
import { EPrefix } from '../../../model/EPrefix';
import { IFlag } from '../../../model/flag/IFlag';
import { ActivePageManager } from '../../../model/navigation/ActivePageManager';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../model/store/IDataSource';
import { IStoreDataResponse } from '../../../model/store/IStoreDataResponse';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { FlagService } from '../../../services/flag.service';
import { Store } from '../../../services/store.service';
import { EPermissionsFlag } from '../../permissions/models/EPermissionsFlag';
import { DestroyableServiceBase } from '../../services/models/destroyable-service-base';
import { IDataSourceRemoteChanges } from '../../store/model/IDataSourceRemoteChanges';
import { secure } from '../../utils/rxjs/operators/secure';
import { Document } from '../models/document';
import { IDocumentStatus } from '../models/idocument-status';
import { IUserStatus } from '../models/iuser-status';

@Injectable({ providedIn: "root" })
export class DocumentStatusService extends DestroyableServiceBase {

	//#region FIELDS

	private readonly moUserStatusesById$: Observable<Map<string, IDocumentStatus>>;
	private readonly moActivePageManager = new ActivePageManager(this, this.ioRouter, () => true);

	//#endregion FIELDS

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcFlag: FlagService,
		private readonly ioRouter: Router
	) {
		super();
		this.moUserStatusesById$ = this.getUserStatusesById$();
	}

	private getUserStatusesById$(): Observable<Map<string, IDocumentStatus>> {
		return this.isvcFlag.observeFlag(EPermissionsFlag.isLoaded).pipe(
			switchMap((poFlag: IFlag) => {
				if (poFlag.value) {
					const loDataSource: IDataSourceRemoteChanges = {
						databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
						live: true,
						viewName: "app_doc-statuses-by-user-id/docStatusesByUserId",
						viewParams: {
							include_docs: true,
							key: UserHelper.getUserId()
						},
						remoteChanges: true,
						activePageManager: this.moActivePageManager
					};

					return this.isvcStore.get<IDocumentStatus>(loDataSource);
				}
				else
					return of([]);
			}),
			secure(this),
			map((paDocStatuses: IDocumentStatus[]) =>
				ArrayHelper.groupByUnique(paDocStatuses, (poDoc: IDocumentStatus) => poDoc._id)
			)
		);
	}

	/** Crée un objet de type `IDocumentStatus` à partir d'une donnée issue de la base de données.
	 * @param poDocument Donnée issue de la base de données avec laquelle créer un document de statut de lecture.
	 */
	private createDocumentStatus(poDocument: Document): IDocumentStatus {
		const loDocumentStatus: IDocumentStatus = {
			_id: this.createDocumentStatusId(poDocument),
			docId: poDocument._id,
			userStatus: {}
		};

		return loDocumentStatus;
	}

	/** Crée un identifiant pour un document de type `IDocumentStatus`.
	 * @param poDocument Donnée issue de la base de données ou identifiant de la donnée.
	 */
	private createDocumentStatusId(poDocument: Document | string): string {
		return `${EPrefix.documentStatus}${typeof poDocument === "string" ? poDocument : poDocument._id}`;
	}

	/** Récupère un document de staut de lecture associé à une donnée.
	 * @param poDocument Donnée dont on veut récupérer le statut de lecture.
	 */
	private getDocumentStatus$(poDocument: Document): Observable<IDocumentStatus> {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				key: this.createDocumentStatusId(poDocument),
				include_docs: true
			}
		};

		return this.isvcStore.getOne<IDocumentStatus>(loDataSource, false);
	}

	/** Récupère les statuts des documents.
	 * @param paDocuments Liste documents dont il faut récupérer les statuts.
	 * @param pbLive Indique si on doit faire une récupération continue des statuts.
	 */
	private getDocumentStatuses$(
		paDocuments?: (Document | string)[],
	): Observable<IDocumentStatus[]> {
		return this.moUserStatusesById$.pipe(
			map((poUserStatusesById: Map<string, IDocumentStatus>) => {
				const laResults: IDocumentStatus[] = [];

				paDocuments?.forEach((poDoc: string | Document) => {
					const loStatus: IDocumentStatus | undefined = poUserStatusesById.get(this.createDocumentStatusId(poDoc));

					if (loStatus)
						laResults.push(loStatus);
				});

				return laResults;
			})
		);
	}

	/** Récupère les statuts de lecture par tableau de documents (ou tous si tableau non renseigné/vide).
	 * @param paDocuments Tableau des documents dont il faut récupérer les statuts de lecture, récupération générique par défaut.
	 * @param pbLive Indique si la récupération est continue ou non, `false` par défaut.
	 */
	public getDocumentsUserStatusesById$(
		paDocuments: (Document | string)[] = [],
		paUserAuthoredDocViewMetasByIds?: Map<string, IStoreDocument>
	): Observable<Map<string, IUserStatus | undefined>> {
		return this.getDocumentStatuses$(paDocuments)
			.pipe(
				map((paDocumentStatuses: IDocumentStatus[]) => {
					const loDocumentsUserStatusesById = new Map<string, IUserStatus | undefined>(
						paDocumentStatuses.map((poDocumentStatus: IDocumentStatus) => [poDocumentStatus.docId, poDocumentStatus.userStatus[UserHelper.getUserId()]])
					);

					paDocuments.forEach((poDocument: Document | string) => {
						const lsDocId: string = typeof poDocument === "string" ? poDocument : poDocument._id;
						if (!loDocumentsUserStatusesById.has(lsDocId)) {
							loDocumentsUserStatusesById.set(lsDocId, this.getDocumentUserStatus(poDocument, paUserAuthoredDocViewMetasByIds));
						}
					});

					return loDocumentsUserStatusesById;
				})
			);
	}

	private getDocumentUserStatus(
		poDocument: Document | string,
		paUserAuthoredDocViewMetasByIds?: Map<string, IStoreDocument>
	): IUserStatus | undefined {
		let loDoc: IStoreDocument | undefined;
		if (typeof poDocument === "string")
			loDoc = paUserAuthoredDocViewMetasByIds?.get(poDocument);
		else if (poDocument.authorId === UserHelper.getUserContactId())
			loDoc = poDocument;

		return loDoc ?
			{ docRev: loDoc._rev, status: "read", statusUpdate: new Date().toISOString() } :
			undefined;
	}

	/** Change le statut de lecture du document par l'utilisateur courant.
	 * @param poDocument Donnée qu'il faut marquer comme non lue.
	 */
	public changeReadSatus$(poDocument: Document, psStatus: "read" | "notRead"): Observable<boolean> {
		return this.getDocumentStatus$(poDocument)
			.pipe(
				take(1),
				mergeMap((poDocumentStatus?: IDocumentStatus) => {
					if (!poDocumentStatus)
						poDocumentStatus = this.createDocumentStatus(poDocument);

					poDocumentStatus.userStatus[UserHelper.getUserId()] = {
						docRev: poDocument._rev,
						status: psStatus,
						statusUpdate: new Date().toISOString()
					};

					return this.isvcStore.put(poDocumentStatus, ArrayHelper.getLastElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)));
				}),
				map((poResponse: IStoreDataResponse) => poResponse.ok),
				defaultIfEmpty(false),
				catchError((poError: any) => { console.warn(`RS.S:: Échec du marquage '${psStatus}' : `, poError); return of(false); })
			);
	}

	//#endregion

}