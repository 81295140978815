import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, last, map, mapTo, mergeMap, take } from 'rxjs/operators';
import { IFlag } from '../model/flag/IFlag';

@Injectable({ providedIn: "root" })
export class FlagService {

	//#region FIELDS

	private readonly moFlagMap = new Map<string, boolean>();
	private readonly moFlagSubjectMap = new Map<string, BehaviorSubject<boolean>>();

	//#endregion

	//#region METHODS

	/** Récupère la valeur d'un flag, `undefined` si jamais définie.
	 * @param psKey
	 */
	public getFlagValue(psKey: string): boolean {
		return this.moFlagMap.get(psKey);
	}

	/** Affecte la valeur d'un flag.
	 * @param psKey
	 * @param pbValue
	 */
	public setFlagValue(psKey: string, pbValue: boolean): void {
		let loFlag$: BehaviorSubject<boolean> = this.moFlagSubjectMap.get(psKey);
		this.moFlagMap.set(psKey, pbValue);
		console.debug(`FLAG.S:: ${psKey} flag value set to ${pbValue}.`);

		if (loFlag$)
			loFlag$.next(this.getFlagValue(psKey));
		else {
			loFlag$ = new BehaviorSubject(this.getFlagValue(psKey));
			this.moFlagSubjectMap.set(psKey, loFlag$);
		}
	}

	/** Récupère un flag. La propriété `value` du flag retourné peut être `undefined` si jamais définie.
	 * @param psFlagName
	 */
	public getFlag(psFlagName: string): IFlag {
		return this.createFlag(psFlagName);
	}

	private createFlag(psFlagName: string, pbValue?: boolean): IFlag {
		return { key: psFlagName, value: pbValue ?? this.getFlagValue(psFlagName) };
	}

	/** Permet d'attendre qu'un flag passe à l'état voulu.
	 * @param psFlagName Nom du flag.
	 * @param pbFlagValue Valeur du flag souhaité.
	 */
	public waitForFlag<T extends boolean>(psFlagName: string, pbFlagValue: T): Observable<T> {
		return this.observeFlagValue(psFlagName)
			.pipe(
				filter((pbObservedFlagValue: boolean) => pbObservedFlagValue === pbFlagValue),
				take(1)
			) as Observable<T>;
	}

	/** Permet d'attendre qu'un flag passe à l'état voulu.
	 * @param psFlagName Nom du flag.
	 * @param pbFlagValue Valeur du flag souhaité.
	 */
	public waitForFlagAsync(psFlagName: string, pbFlagValue: boolean): Promise<boolean> {
		return this.waitForFlag(psFlagName, pbFlagValue).toPromise();
	}

	/** Permet d'attendre que plusieurs flags atteignent un état voulu.
	 * @param paFlagNames Nom des flags.
	 * @param pbFlagValue Valeur du flag souhaité.
	 */
	public waitForFlags(paFlagNames: string[], pbFlagValue: boolean): Observable<boolean> {
		return merge(...paFlagNames.map((psFlagName: string) => this.waitForFlag(psFlagName, pbFlagValue))).pipe(last());
	}

	/** Permet d'observer un flag en continu. La propriété `value` du flag retourné peut être `undefined` si jamais définie.
	 * @param psFlagName Nom du flag.
	 */
	public observeFlag(psFlagName: string): Observable<IFlag> {
		if (!this.moFlagSubjectMap.has(psFlagName))
			this.moFlagSubjectMap.set(psFlagName, new BehaviorSubject(this.getFlagValue(psFlagName)));

		return this.moFlagSubjectMap.get(psFlagName).asObservable().pipe(distinctUntilChanged(), map((pbValue: boolean) => this.createFlag(psFlagName, pbValue)));
	}

	/** Permet d'observer la valeur d'un flag en continu. Le booleen retourné peut être `undefined` si jamais défini.
	 * @param psFlagName Nom du flag.
	 */
	public observeFlagValue(psFlagName: string): Observable<boolean> {
		return this.observeFlag(psFlagName).pipe(map((poFlag: IFlag) => poFlag.value));
	}

	/** Permet de récupérer la stratégie de gestion d'erreur pour retenter lorsque tous les flags passés en paramètres sont ok.
	 * @param poErrors$ Observable donnant les erreurs, donné par l'opérateur retryWhen.
	 * @param paFlagNames Noms des flags.
	 * @param pbFlagValue
	 * @returns
	 */
	public getRetryWhenFlagsReadyStrategy(poErrors$: Observable<any>, paFlagNames: string[], pbFlagValue: boolean): Observable<any> {
		return poErrors$.pipe(mergeMap((poError: any) => this.waitForFlags(paFlagNames, pbFlagValue).pipe(mapTo(poError))));
	}

	//#endregion

}