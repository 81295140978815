<calao-sub-header>
	<calao-sub-header-title>Comparaison entre: {{ observableTitleFirstGroup.value$ | async}} et {{
		observableTitleSecondGroup.value$ |
		async}} </calao-sub-header-title>
</calao-sub-header>

<br>

<calao-tabs #tabs class="tabs">
	<calao-tabs-segments>
		<calao-tabs-segment>
			<ion-label>Informations</ion-label>
		</calao-tabs-segment>
		<calao-tabs-segment>
			<ion-label>Cartes</ion-label>
		</calao-tabs-segment>
	</calao-tabs-segments>
</calao-tabs>

<div *ngIf="(tabs.observableActiveTabIndex.value$ | async) === 0" vertical="bottom" horizontal="end" slot="fixed">
	<br>
	<div class="container">
		<table>
			<thead>
				<tr>
					<th class="bandeau" [attr.colspan]="columnNames.length * 2">Groupes de secteurs</th>
				</tr>

				<tr>
					<th *ngFor="let cell of columnNames">
						{{ cell}}
					</th>
					<th *ngFor="let cell of columnNames">
						{{ cell}}
					</th>
				</tr>

			</thead>
			<tbody>
				<tr>
					<td *ngFor="let cell of sctrzFrontRow">
						{{ cell }}
					</td>
				</tr>

			</tbody>

			<thead>
				<tr>
					<th class="bandeau" [attr.colspan]="columnNames.length * 2">Secteurs</th>
				</tr>
				<tr>
					<th *ngFor="let item of columnNames">
						{{ item }}
					</th>
					<th *ngFor="let item of columnNames">
						{{ item }}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let column of relatedSectors">
					<td *ngFor=" let cell of column">
						{{cell}}
					</td>
				</tr>
			</tbody>

			<tbody>
				<tr *ngFor="let column of unrelatedSectors">
					<td *ngFor=" let cell of column">
						{{cell}}
					</td>
				</tr>

			</tbody>
		</table>
	</div>
</div>

<div *ngIf="(tabs.observableActiveTabIndex.value$ | async) === 1" vertical="bottom" horizontal="end" slot="fixed"
	class="map-container-container">

	<br>

	<div class="map-container">
		<calao-map *ngIf="mapOptionsFirst" [options]="(mapOptionsFirst)" class="map"> </calao-map>
		<calao-map *ngIf="mapOptionsSecond" [options]="(mapOptionsSecond)" class="map"> </calao-map>
	</div>

</div>