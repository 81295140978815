import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalOptions } from '@ionic/core';
import { filter, Observable, tap } from 'rxjs';
import { ArrayHelper } from '../../../../../../helpers/arrayHelper';
import { StringHelper } from '../../../../../../helpers/stringHelper';
import { EModalSize } from '../../../../../modal';
import { ModalService } from '../../../../../modal/services/modal.service';
import { ObserveArray } from '../../../../../observable/decorators/observe-array.decorator';
import { ObserveProperty } from '../../../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../../../observable/models/observable-array';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { Queue } from '../../../../../utils/queue/decorators/queue.decorator';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IInlineFieldLayoutParams } from '../inline-field-layout/models/iinline-field-layout-params';
import { IInlineField } from '../inline-field-layout/models/iinlineField';
import { IOption } from './models/ioption';
import { IRadioAndCheckboxFieldModal } from './models/iradio-and-checkbox-field-modal';
import { IRadioAndCheckboxFieldModalResult } from './models/iradio-and-checkbox-field-modal-result';
import { IRadioAndCheckboxFieldParams } from './models/iradio-and-checkbox-field-params';
import { RadioAndCheckboxFieldModalComponent } from './radio-and-checkbox-field-modal/radio-and-checkbox-field-modal.component';

@Component({
	selector: 'calao-radio-and-checkbox-field',
	templateUrl: './radio-and-checkbox-field.component.html',
	styleUrls: ['./radio-and-checkbox-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioAndCheckboxFieldComponent extends FieldBase<string> implements OnInit, IInlineField {

	//#region FIELDS

	private readonly moObservableCheckboxKey = new ObservableProperty<string>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Style de layout à afficher (undefined par défaut). */
	public layout: "inline";
	@ObserveProperty<RadioAndCheckboxFieldComponent>({ sourcePropertyKey: "layout" })
	public readonly observableLayout = new ObservableProperty<"inline">();

	/** Paramètres d'affichage à passer au layout. */
	public layoutParams: IInlineFieldLayoutParams;
	@ObserveProperty<RadioAndCheckboxFieldComponent>({ sourcePropertyKey: "layoutParams" })
	public readonly observableLayoutParams = new ObservableProperty<IInlineFieldLayoutParams>();

	/** Masque le libellé du champs dans le layout si `true` et que le champs est renseigné. */
	public hideLabelWhenFilled: boolean;
	@ObserveProperty<RadioAndCheckboxFieldComponent>({ sourcePropertyKey: "hideLabelWhenFilled" })
	public readonly observableHideWhenFilled = new ObservableProperty<boolean>();

	/** Tableau des options du sélecteur radio. */
	public radioOptions: IOption[];
	@ObserveArray<RadioAndCheckboxFieldComponent>("radioOptions")
	public readonly observableRadioOptions = new ObservableArray<IOption>();

	/** Tableau des options du sélecteur checkbox. */
	public checkboxOptions: IOption[];
	@ObserveArray<RadioAndCheckboxFieldComponent>("checkboxOptions")
	public readonly observableCheckboxOptions = new ObservableArray<IOption>();

	/** Chaîne concaténée de la valeur du radio et du checkbox. */
	public readonly observableRadioAndCheckboxValueLabel = new ObservableProperty<string>();

	/** `true` si le champs est requis pour la validation du formulaire. */
	public readonly observableRequired = new ObservableProperty<boolean>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcModal: ModalService,
		protected override readonly isvcForms: FormsService
	) {
		super(isvcForms);
	}

	public override ngOnInit(): void {
		this.observableFieldValue.value = this.model[this.fieldKey];

		const loProps: IRadioAndCheckboxFieldParams = this.props as IRadioAndCheckboxFieldParams;
		this.observableLayout.value = loProps.layout;
		this.observableLayoutParams.value = loProps.layoutParams;
		this.observableHideWhenFilled.value = loProps.hideLabelWhenFilled;

		const laRadioOptions: IOption[] = loProps.radioOptions;
		if (ArrayHelper.hasElements(laRadioOptions))
			this.observableRadioOptions.resetArray(loProps.radioOptions);

		this.moObservableCheckboxKey.value = loProps.checkboxKey;

		const laCheckboxOptions: IOption[] = loProps.checkboxOptions;
		if (ArrayHelper.hasElements(laCheckboxOptions))
			this.observableCheckboxOptions.resetArray(laCheckboxOptions);

		this.observableRadioAndCheckboxValueLabel.value = this.getRadioAndCheckboxValueLabel();

		this.observableRequired.value = !!this.props.required;
	}

	private getRadioAndCheckboxValueLabel(): string {
		let lsRadioValueLabel: string;
		let lsCheckboxValueLabel: string;

		if (this.model[this.fieldKey] && ArrayHelper.hasElements(this.observableRadioOptions))
			lsRadioValueLabel = this.observableRadioOptions
				.find((poOption: IOption) => poOption.value === this.model[this.fieldKey]).label;

		if (ArrayHelper.hasElements(this.model[this.moObservableCheckboxKey.value]) && ArrayHelper.hasElements(this.observableCheckboxOptions)) {
			lsCheckboxValueLabel = this.model[this.moObservableCheckboxKey.value]
				.map((psValue: string) => {
					return this.observableCheckboxOptions.find((poOption: IOption) => poOption.value === psValue).label;
				}).join(", ");
		}

		return `${lsRadioValueLabel}${!StringHelper.isBlank(lsCheckboxValueLabel) ? "," : ""} ${lsCheckboxValueLabel ?? ""}`;
	}

	public onFieldClicked(): void {
		this.showModal$().subscribe();
	}

	@Queue<RadioAndCheckboxFieldComponent, Parameters<RadioAndCheckboxFieldComponent["showModal$"]>, ReturnType<RadioAndCheckboxFieldComponent["showModal$"]>>({
		excludePendings: true
	})
	private showModal$(): Observable<IRadioAndCheckboxFieldModal> {
		const loModalOptions: ModalOptions = {
			component: RadioAndCheckboxFieldModalComponent,
			componentProps: {
				label: this.observableLayoutParams.value?.label,
				radioOptions: this.observableRadioOptions,
				checkboxOptions: this.observableCheckboxOptions,
				radioValue: this.observableFieldValue.value,
				checkboxValues: this.model[this.moObservableCheckboxKey.value]
			} as IRadioAndCheckboxFieldModal,
			canDismiss: true,
			cssClass: "transparent"
		}

		return this.isvcModal.open(loModalOptions, EModalSize.medium)
			.pipe(
				filter((poResult: IRadioAndCheckboxFieldModalResult) => !!poResult),
				tap((poResult: IRadioAndCheckboxFieldModalResult) => {
					this.observableFieldValue.value = poResult.radioValue;
					this.model[this.moObservableCheckboxKey.value] = poResult.checkboxValues;
					this.observableRadioAndCheckboxValueLabel.value = this.getRadioAndCheckboxValueLabel();
				}),
			);
	}

	//#endregion METHODS

}
