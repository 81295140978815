<div *ngIf="observableDocument.value$ | async as folder" class="folder"
	[ngClass]="folder.documentsCounters?.notRead ? 'not-read' : 'read'" (click)="openFolder(folder)">
	<div class="header">
		<ng-container *ngIf="folder.documentsCounters?.notRead as notRead; else read">
			<div class="notif-badge" calaoBadgeIcon=" " calaoBadgeIconPosition="after" calaoBadgeSize="5px">
				<ion-icon class="folder-icon" [name]="folder.icon || 'document'"></ion-icon>
			</div>

			<div class="read-satus">
				<ion-label class="counter">{{ notRead }}</ion-label>
				<ion-note>{{ folder.documentsCounters?.notReadLabel }}</ion-note>
			</div>
		</ng-container>

		<ng-template #read>
			<ion-icon class="folder-icon" [name]="folder.icon || 'document'"></ion-icon>
		</ng-template>
	</div>

	<div class="content">
		<ion-label class="folder-name">{{ folder.name }}</ion-label>

		<ion-label *ngIf="folder.documentsCounters?.total">
			{{ folder.documentsCounters?.total }} {{ folder.documentsCounters?.total ?? 0 > 1 ? "documents" : "document" }}
		</ion-label>
		<ion-label *ngIf="folder.documentsCounters?.total === 0">
			Aucun document
		</ion-label>
	</div>
</div>