<ng-container *ngIf="!params.readOnly || isDefined">
	<ng-container *ngIf="layout === 'inline'">
		<ng-container *ngTemplateOutlet="params.displayType === 'single' ? single : multiple"></ng-container>

		<ng-template #single>
			<calao-inline-field-layout [icon]="layoutParams.icon || 'person'" [label]="$any(layoutParams).label"
				[separator]="layoutParams.separator" [hideLabel]="hideLabelWhenFilled && !!contactsList.singleModel"
				[required]="required" (onFieldClicked)="contactsList.selectContacts()">
				<calao-inline-field-layout-content>
					<span class="contact-name">{{ contactsList.getContactName(contactsList.singleModel) }}</span>
				</calao-inline-field-layout-content>
			</calao-inline-field-layout>
		</ng-template>

		<ng-template #multiple>
			<calao-inline-list-field-layout [icon]="layoutParams.icon || 'person'" [required]="required"
				[addButtonLabel]="$any(layoutParams).addButtonLabel || 'Ajouter ...'" [separator]="layoutParams.separator"
				(onAddClicked)="contactsList.selectContacts()">
				<ng-container *ngIf="getTagsFromContacts(contactsList.selectedContacts, contactsList.getContactName) as tags">
					<calao-deletable-tags-list *ngIf="tags.length > 0" [tags]="tags"
						(onRemoveTagClicked)="contactsList.remove($event.id)">
					</calao-deletable-tags-list>
				</ng-container>
			</calao-inline-list-field-layout>
		</ng-template>
	</ng-container>

	<contacts-list #contactsList [params]="params" [model]="observableFieldValue.value$ | async"
		[class]="layout === 'inline' ? 'hidden' : ''" (modelChange)="onModelChanged($event)">
	</contacts-list>
</ng-container>