/** Requête pour obtenir des clés des résultats depuis une table. */
export const SELECT_REQUEST = "SELECT";
/** Requête pour identifier dans quelle table requêter. */
export const FROM_REQUEST = "FROM";
/** Requête pour obtenir tous les éléments depuis une table. */
export const SELECT_ALL_REQUEST = "SELECT * FROM";
/** Requête pour filtrer de façon générique. */
export const WHERE_REQUEST = "WHERE";
/** Permet de donner un ensemble de valeurs à satisfaire pour une requête `WHERE` notamment. */
export const IN_REQUEST = "IN";
/** Limite le nombre de résultats lors d'une requête. */
export const LIMIT_REQUEST = "LIMIT";
/** Requête de regroupement de résultats par une clé. */
export const GROUP_BY_REQUEST = "GROUP BY";
/** Requête pour ajouter une condition. */
export const AND_REQUEST = "AND";
export const INSERT_REQUEST = "INSERT INTO";
export const DELETE_REQUEST = "DELETE FROM";
export const VALUES_REQUEST = "VALUES";
export const CREATE_TABLE_REQUEST = "CREATE TABLE IF NOT EXISTS";
export const CREATE_UNIQUE_INDEX_REQUEST = "CREATE UNIQUE INDEX IF NOT EXISTS";
export const ON_REQUEST = "ON";
export const DESC_REQUEST = "DESC";
export const ORDER_BY_REQUEST = "ORDER BY";
export const ON_CONFLICT_REQUEST = "ON CONFLICT";
export const DO_REQUEST = "DO";
export const UPDATE_REQUEST = "UPDATE";
export const SET_REQUEST = "SET";
export const EXCLUDED_REQUEST = "excluded";
export const COUNT_ALL_REQUEST = "COUNT(*) as count";
export const NOT_REQUEST = "NOT";
export const EXISTS_REQUEST = "EXISTS";