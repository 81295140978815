import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DateHelper } from "@calaosoft/osapp/helpers/dateHelper";
import { EDatabaseRole } from "@calaosoft/osapp/model/store/EDatabaseRole";
import { IDataSource } from "@calaosoft/osapp/model/store/IDataSource";
import { IStoreDataResponse } from "@calaosoft/osapp/model/store/IStoreDataResponse";
import { IStoreDocument } from "@calaosoft/osapp/model/store/IStoreDocument";
import { Contact } from "@calaosoft/osapp/modules/contacts/models/contact";
import { ILogSource } from "@calaosoft/osapp/modules/logger/models/ILogSource";
import { LoggerService } from "@calaosoft/osapp/modules/logger/services/logger.service";
import { ContactsService } from "@calaosoft/osapp/services/contacts.service";
import { EntityLinkService } from "@calaosoft/osapp/services/entityLink.service";
import { Store } from "@calaosoft/osapp/services/store.service";
import { plainToClass } from "class-transformer";
import { Observable, of, switchMap } from 'rxjs';
import { map, mergeMap, tap } from "rxjs/operators";
import { C_PREFIX_SECTOR, C_PREFIX_SECTORIZATION } from "../../../app/app.constants";
import { Business } from "../../businesses/model/business";
import { ISectorOptimizationInputDTO } from "../models/isector-optimization-input-dto";
import { Sectorization } from '../models/sectorization';
import { Sector } from "../sectors/models/sector";
import { SectorsService } from "../sectors/services/sectors.service";
import { SectorOptimizeService } from "./sector-optimization.service";

@Injectable()
export class SectorizationsService implements ILogSource {

	//#region PROPERTIES

	public readonly logSourceId = "TRD.SCTRZ.S::";

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcSectors: SectorsService,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		private readonly isvcStore: Store,
		private readonly isvcEntityLink: EntityLinkService,
		private readonly ioRouter: Router,
		private readonly isvcSectorOptimize: SectorOptimizeService,
		private readonly isvcContacts: ContactsService,
	) { }

	/** Réccupère les sectorisations inactives.	*/
	public getInactivesSectorizations$(): Observable<Sectorization[]> {
		return this.getSectorizations$().pipe(
			map((paSctrz: Sectorization[]) =>
				paSctrz.filter((poSctrz: Sectorization) => !this.isInRange(poSctrz))
			)
		);
	}

	private isInRange(poSctrz: Sectorization): boolean {
		return !!poSctrz.startDate &&
			!!poSctrz.endDate &&
			DateHelper.isDateInRange(new Date(), { from: poSctrz.startDate, to: poSctrz.endDate });
	}

	public async switchSectorizationAsync(poSctrz1: Sectorization, poSctrz2: Sectorization)
		: Promise<boolean> {
		poSctrz1.startDate = undefined;
		poSctrz1.endDate = undefined;

		poSctrz2.startDate = new Date();
		poSctrz2.endDate = DateHelper.addYears(new Date(), 10);

		const loResult2 = await this.saveSectorizationAsync(poSctrz2);
		const loResult1 = await this.saveSectorizationAsync(poSctrz1);

		return loResult1.ok && loResult2.ok;
	}


	/** Permet de sauvegarder une sectorisation en base de données (mais pas ses secteurs).
	* @param poSctrz Sectorisation à enregistrer.
	*/
	public saveSectorizationAsync(poSctrz: Sectorization): Promise<IStoreDataResponse> {
		return this.isvcEntityLink.getLinkedEntities([poSctrz], C_PREFIX_SECTOR)
			.pipe(
				tap((poLinksById: Map<string, IStoreDocument[]>) => {
					this.removeCacheLinks(poSctrz, poLinksById);
					this.addCacheLinks(poSctrz, poLinksById);
				}),
				mergeMap(_ => this.isvcEntityLink.saveEntityLinks(poSctrz)),
				tap(_ => poSctrz.linkedSectors = undefined),
				mergeMap(_ => this.isvcStore.put(poSctrz))
			)
			.toPromise();
	}

	private removeCacheLinks(poSctrz: Sectorization, poLinksById: Map<string, IStoreDocument[]>): void {
		poLinksById.get(poSctrz._id)
			?.forEach((poSectFromBd: IStoreDocument) => {
				if (!poSctrz.linkedSectors?.map((poSect: Sector) => poSect._id).includes(poSectFromBd._id))
					this.isvcEntityLink.cacheLinkToRemove(poSctrz, poSectFromBd);
			});
	}

	private addCacheLinks(poSctrz: Sectorization, poLinksById: Map<string, IStoreDocument[]>): void {
		poSctrz.linkedSectors?.forEach((poSect: Sector) => {
			if (!poLinksById.get(poSctrz._id)
				?.map((poSectFromBd: IStoreDocument) => poSectFromBd._id)
				.includes(poSect._id)
			)
				this.isvcEntityLink.cacheLinkToAdd(poSctrz, poSect);
		});
	}

	/** Réccupère toutes les sectorisations. */
	public getSectorizations$(): Observable<Sectorization[]> {
		const loDataSource: IDataSource<IStoreDocument> =
		{
			role: EDatabaseRole.workspace,
			viewParams: {
				startkey: C_PREFIX_SECTORIZATION,
				endkey: C_PREFIX_SECTORIZATION + Store.C_ANYTHING_CODE_ASCII,
				include_docs: true
			},
			baseClass: Sectorization,
			live: true
		};
		return this.isvcStore.get<Sectorization>(loDataSource).pipe(
			switchMap((paSctrz: Sectorization[]) => {
				return this.isvcEntityLink.getLinkedEntities(paSctrz, C_PREFIX_SECTOR, undefined, true)
					.pipe(
						mergeMap(async (poLinksById: Map<string, IStoreDocument[]>) => {
							for (let lnIndex = 0; lnIndex < paSctrz.length; ++lnIndex) {
								await this.fillSectorsAsync(paSctrz[lnIndex], poLinksById);
							}

							return paSctrz;
						})
					);
			})
		);
	}

	/** Remplit les secteurs d'une sectorisation. */
	private async fillSectorsAsync(poSctrz: Sectorization, poLinksById: Map<string, IStoreDocument[]>): Promise<Sectorization> {
		poSctrz.linkedSectors = [];
		poLinksById.get(poSctrz._id)?.forEach((poStoreDocument: IStoreDocument) =>
			poSctrz.linkedSectors?.push(plainToClass(Sector, poStoreDocument))
		);

		poSctrz.linkedSectors = await this.isvcSectors.addLinksAsync(poSctrz.linkedSectors);

		return poSctrz;
	}

	/** Réccupère une sectorisation en fonction de son id.
	* @param psSctrzId id de la sectorisation à reccupérer.
	*/
	public getSectorization$(psSctrzId: string, pbLive?: boolean): Observable<Sectorization | undefined> {
		const loDataSource: IDataSource<IStoreDocument> = {
			role: EDatabaseRole.workspace,
			viewParams: {
				key: psSctrzId,
				include_docs: true
			},
			baseClass: Sectorization,
			live: pbLive
		};
		return this.isvcStore.getOne<Sectorization>(loDataSource, false).pipe(
			switchMap((poSctrz: Sectorization | undefined) => {
				if (poSctrz) {
					return this.isvcEntityLink.getLinkedEntities([poSctrz], C_PREFIX_SECTOR, undefined, true)
						.pipe(mergeMap((poLinksById: Map<string, IStoreDocument[]>) => this.fillSectorsAsync(poSctrz, poLinksById)));
				}
				else
					return of(undefined);
			})
		);
	}

	/** Récupère la sectorisation active.	*/
	public getActiveSectorization$(): Observable<Sectorization | undefined> {
		return this.getSectorizations$().pipe(
			map((paSctrz: Sectorization[]) => {
				for (let lnIndex = 0; lnIndex < paSctrz.length; ++lnIndex) {
					if (this.isInRange(paSctrz[lnIndex]))
						return paSctrz[lnIndex];
				}
				return undefined;
			})
		);
	}

	public optimizeSectors(poOptimizationInput: Observable<ISectorOptimizationInputDTO>): Observable<any> {
		return this.isvcSectorOptimize.optmizeSectors(poOptimizationInput, "trade");
	}

	public navigateToActiveSectorsComparisonAsync(psId: string, poRoute: ActivatedRoute): Promise<boolean> {
		return this.ioRouter.navigate(["compare", psId], { relativeTo: poRoute });
	}

	public navigateToBusinessDistributionWithOptimizationAsync(poRoute: ActivatedRoute) {
		return this.ioRouter.navigate(["changes"], { relativeTo: poRoute, queryParams: ["optimize"] });
	}

	public navigateToBusinessDistributionAsync(poRoute: ActivatedRoute) {
		return this.ioRouter.navigate(["changes"], { relativeTo: poRoute, queryParams: [] });
	}

	public createSectorWithBusinesses(poSctrz: Sectorization): Sector[] {
		return plainToClass(Sector, poSctrz.linkedSectors?.filter((poSect: Sector) => !!poSect.linkedBusinesses?.length)!);
	}


	public makeSectorOptimizationInput(poSctrz: Sectorization): Observable<ISectorOptimizationInputDTO> | undefined {
		if (!poSctrz.linkedSectors)
			return undefined
		let loSectorOptimizationInput: ISectorOptimizationInputDTO = { poiSources: [], poiTargets: [] }

		let sectorsIds: string[] = poSctrz.linkedSectors.filter((poSect: Sector) => !!poSect.pointOfContactIds && !!poSect.pointOfContactIds.length)
			.map((poSect: Sector) => poSect.pointOfContactIds[0])

		poSctrz.linkedSectors.forEach(poSect => poSect.linkedBusinesses?.filter((poBiz: Business) => poBiz.longitude && poBiz.latitude).forEach(
			poBiz => loSectorOptimizationInput.poiTargets.push({
				id: poBiz._id, coordinates:
					{ longitude: poBiz.longitude!, latitude: poBiz.latitude! }
			})
		))

		if (sectorsIds?.length)
			return this.isvcContacts.getContactById(sectorsIds).pipe(
				map((poContactMap: Map<string, Contact>) => Array.from(poContactMap.values()).filter((poContact: Contact) => poContact.longitude && poContact.latitude)),
				map((paContact: Contact[]) => {
					paContact.forEach((poContact: Contact) =>
						loSectorOptimizationInput.poiSources.push({
							id: poContact._id,
							coordinates: { longitude: poContact.longitude!, latitude: poContact.latitude! }
						}))
					return loSectorOptimizationInput
				})
			)
		else
			return undefined;
	}

	//#endregion METHODS

}