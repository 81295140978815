import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ISearchOptions } from '../../../model/search/ISearchOptions';
import { PlatformService } from '../../../services/platform.service';
import { ModalComponentBase } from '../../modal/model/ModalComponentBase';
import { ISelectorModalButton } from '../models/iselector-modal-button';
import { ESelectorDisplayMode } from '../selector/ESelectorDisplayMode';
import { ISelectOption } from '../selector/ISelectOption';
import { SelectorComponent } from '../selector/selector.component';
import { ISelectorModalParams } from './ISelectorModalParams';

@Component({
	selector: 'calao-selector-modal',
	templateUrl: './selector-modal.component.html',
	styleUrls: ['./selector-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectorModalComponent<T = any> extends ModalComponentBase<T[]> implements ISelectorModalParams {

	//#region FIELDS

	@ViewChild(SelectorComponent)
	private readonly moSelectorComponent: SelectorComponent;

	//#endregion

	//#region PROPERTIES

	/** @implements */
	public preselectedValues?: T;
	/** @implements */
	public options: ISelectOption<T>[];
	/** @implements */
	public displayMode?: ESelectorDisplayMode;
	/** @implements */
	public multiple?: boolean;
	/** @implements */
	public selectCss?: string;
	/** @implements */
	public searchOptions?: ISearchOptions<T>;
	/** @implements */
	public limit?: number;
	/** @implements */
	public label?: string;
	/** @implements */
	public color?: string;
	/** @implements */
	public title?: string;
	/** Valeurs sélectionnées. */
	public selectedValues?: T[];
	/** @implements */
	public customButtons?: ISelectorModalButton[];

	//#endregion

	//#region METHODS

	constructor(poModalCtrl: ModalController, psvcPlatform: PlatformService, poChangeDetector: ChangeDetectorRef) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public onSelectionChanged(paSelectedValues: T[]): void {
		this.selectedValues = paSelectedValues;
	}

	public onCustomButtonClicked(poButton: ISelectorModalButton): void {
		poButton.handler(this.moSelectorComponent);
	}

	//#endregion

}