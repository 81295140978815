import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IInlineFieldLayoutParams } from '../inline-field-layout/models/iinline-field-layout-params';
import { IInlineField } from '../inline-field-layout/models/iinlineField';
import { IStarRatingFieldParams } from './models/istar-rating-field-params';

// https://github.com/BioPhoton/angular-star-rating/wiki/Installing
@Component({
	selector: 'calao-star-rating-field',
	templateUrl: './star-rating-field.component.html',
	styleUrls: ['./star-rating-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StarRatingFieldComponent extends FieldBase<number> implements OnInit, IInlineField {

	//#region PROPERTIES

	public readonly observableParams = new ObservableProperty<IStarRatingFieldParams>();

	public layout: "inline";
	public layoutParams: IInlineFieldLayoutParams;
	public hideLabelWhenFilled: boolean;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.observableParams.value = this.props.data;
		this.layout = this.props.data.layout;
		this.layoutParams = this.props.data.layoutParams;
		this.hideLabelWhenFilled = this.props.data.hideLabelWhenFilled;

		if (!this.fieldValue)
			this.fieldValue = this.observableParams.value.defaultValue;
	}

	public onRatingChange(poEvent: { rating: number }): void {
		if (poEvent.rating !== this.fieldValue) {
			this.fieldValue = poEvent.rating;
			this.markAsDirty();
		}
	}

	public onClearClicked(): void {
		if (this.fieldValue !== undefined) {
			this.fieldValue = undefined;
			this.markAsDirty();
		}
	}

	//#endregion METHODS

}
