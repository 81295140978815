<ion-button class="left-button" fill="clear" size="big" (click)="moveLeft()"
	[hidden]="observableHidLeftBtn.value$ | async">
	<ion-icon name="chevron-back-outline"></ion-icon>
</ion-button>

<drag-scroll #dragScroll [ngClass]="isMobile ? '' : 'web-drag-scroll'" (reachesLeftBound)="leftBoundStat($any($event))"
	(reachesRightBound)="rightBoundStat($any($event))">
	<ion-segment [value]="activeTabValue$ | async" mode="md">
		<calao-tabs-segment *ngFor="let tab of (observableTabs.changes$ | async)" drag-scroll-item>
			<ion-icon *ngIf="tab.icon" [name]="tab.icon" size="large"></ion-icon>
			<ion-label class="tab-label">{{tab.title}}</ion-label>
		</calao-tabs-segment>
	</ion-segment>
</drag-scroll>

<ion-button class="right-button" fill="clear" size="big" (click)="moveRight()"
	[hidden]="observableHidRightBtn.value$ | async">
	<ion-icon name="chevron-forward-outline"></ion-icon>
</ion-button>