import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ModalModule } from "../../modules/modal/modal.module";
import { PipeModule } from '../../pipes/pipes.module';
import { DynamicPageModule } from "../dynamicPage/dynamicPage.module";
import { WebViewModalComponent } from './webview-modal/webview-modal.component';
import { WebViewComponent } from './webview.component';

@NgModule({
	imports: [CommonModule, IonicModule, PipeModule, ModalModule, DynamicPageModule],
	exports: [WebViewComponent, WebViewModalComponent],
	declarations: [WebViewComponent, WebViewModalComponent]
})
export class WebViewModule { }