<ng-container *ngIf="(observableLayout.value$ | async) === 'inline'">
	<ng-container
		*ngTemplateOutlet="(observableIsReadOnly.value$ | async) ? readOnlyInlineTemplate : editInlineTemplate"></ng-container>
</ng-container>

<!-- Template pour la lecture seule en mode inline -->
<ng-template #readOnlyInlineTemplate>
	<ng-container *ngIf="observableLayoutParams.value$ | async as inlineParams">
		<calao-inline-readonly-field-layout *ngIf="fieldValue" [separator]="inlineParams.separator"
			[label]="observableLabel.value$ | async">
			<calao-inline-readonly-field-layout-content>
				<ng-container *ngTemplateOutlet="dateTemplate"></ng-container>
			</calao-inline-readonly-field-layout-content>
		</calao-inline-readonly-field-layout>
	</ng-container>
</ng-template>

<!-- Template mode edit en mode inline -->
<ng-template #editInlineTemplate>
	<ng-container *ngIf="observableLayoutParams.value$ | async as inlineParams">
		<calao-inline-field-layout [icon]="inlineParams.icon" [label]="observableLabel.value$ | async"
			[separator]="inlineParams.separator">
			<calao-inline-field-layout-content>
				<ng-container *ngTemplateOutlet="dateTemplate"></ng-container>
			</calao-inline-field-layout-content>
		</calao-inline-field-layout>
	</ng-container>
</ng-template>

<!-- Template commun d'affichage de la date -->
<ng-template #dateTemplate>
	<ion-label class="event-date">
		{{ fieldValue | dateWithLocale: 'fr-FR' : (observableDateLabelFormat.value$ | async)}}
	</ion-label>
</ng-template>