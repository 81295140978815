import { Component } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { GuidHelper } from "../../../helpers/guidHelper";
import { IDestroyable } from "../lifecycle/models/IDestroyable";

@Component({ template: "" })
export abstract class DestroyableComponentBase implements IDestroyable {

	//#region FIELDS

	private readonly moDestroySubject: ReplaySubject<void>;

	protected msInstanceId: string = GuidHelper.newGuid();

	//#endregion

	//#region PROPERTIES

	/** @implements */
	public get destroyed$(): Observable<void> { return this.moDestroySubject.asObservable(); }

	private mbDestroyed = false;
	/** @implements */
	public get destroyed(): boolean { return this.mbDestroyed; }

	//#endregion PROPERTIES

	//#region METHODS

	constructor() {
		this.moDestroySubject = new ReplaySubject(1);
	}

	/** Récupère l'identifiant de l'instance. */
	public getInstanceId(): string {
		return this.msInstanceId;
	}

	public ngOnDestroy(): void {
		this.mbDestroyed = true;
		this.moDestroySubject.next(undefined);
		this.moDestroySubject.complete();
	}

	//#endregion METHODS

}