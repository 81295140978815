import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldBase } from '../../../../../model/forms/FieldBase';

@Component({
	selector: 'calao-email-field',
	templateUrl: './email-field.component.html',
	styleUrls: ['./inputs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailFieldComponent extends FieldBase<string> {

	public override get fieldValue(): string {
		return super.fieldValue;
	}
	public override set fieldValue(psValue: string) {
		if (this.fieldValue !== psValue) {
			super.fieldValue = psValue;
			this.emailChanged();
		}
	}

	//#region METHODS

	public emailChanged(): void {
		if (!this.formControl.touched)
			this.markAsDirty();
	}

	//#endregion
}