import { Transform } from "class-transformer";
import { ArrayHelper } from "../../../helpers/arrayHelper";
import { PathHelper } from "../../../helpers/path-helper";
import { DocExplorerDocumentsService } from "../services/doc-explorer-documents.service";
import { DocumentsCounters } from "./documents-counters";
import { IDocumentType } from "./idocument-type";
import { IFolder } from "./ifolder";

export class Folder implements IFolder {

	//#region PROPERTIES

	/** @implements */
	@Transform(({ value }: { value: string; }): string => PathHelper.parsePath(value), { toClassOnly: true })
	@Transform(({ value }: { value: string; }): string => PathHelper.preparePath(value), { toPlainOnly: true })
	public path: string;

	/** @implements */
	public icon: string;

	private msName: string;
	/** @implements */
	public get name(): string {
		if (this.configPath && /\{{.*}}/.test(this.msName) && /\$/.test(this.msName)) { // S'il y a un pattern et si les paramètres n'ont pas été résolus.
			const loExtractedValue: { methodName: string; params: string[]; } | undefined = DocExplorerDocumentsService.extractPatternMethodAndParams(this.msName);
			if (loExtractedValue) {
				this.msName = new RegExp(this.configPath).test(this.path) ? `{{${loExtractedValue.methodName}(${loExtractedValue.params.map((psParam: string) => RegExp[psParam]).join(",")})}}` : this.msName;
			}
		}
		return this.msName ?? "";
	}
	public set name(psName: string) {
		this.msName = psName;
	}

	private msShortName: string;
	/** @implements */
	public get shortName(): string {
		if (this.configPath && /\{{.*}}/.test(this.msShortName) && /\$/.test(this.msShortName)) { // S'il y a un pattern et si les paramètres n'ont pas été résolus.
			const loExtractedValue: { methodName: string; params: string[]; } | undefined = DocExplorerDocumentsService.extractPatternMethodAndParams(this.msShortName);
			if (loExtractedValue) {
				this.msShortName = new RegExp(this.configPath).test(this.path) ? `{{${loExtractedValue.methodName}(${loExtractedValue.params.map((psParam: string) => RegExp[psParam]).join(",")})}}` : this.msShortName;
			}
		}
		return this.msShortName ?? "";
	}
	public set shortName(psShortName: string) {
		this.msShortName = psShortName;
	}

	/** @implements */
	public color?: string;

	/** @implements */
	public style?: string;

	public documentsCounters: DocumentsCounters | undefined;

	/** Chemin de la configuration correspondant au chemin réel du dossier. */
	public configPath?: string;

	private msLastPathPart: string;
	/** Dernière partie du chemin. */
	public get lastPathPart(): string {
		if (this.msLastPathPart)
			return this.msLastPathPart;
		return this.msLastPathPart = ArrayHelper.getLastElement(this.path.split("/"));
	}

	public get isInTrash(): boolean {
		return this.path.startsWith("trash");
	}

	/** @implements */
	public documentTypes: IDocumentType;

	//#endregion

	//#region METHODS

	constructor(poData?: IFolder & { configPath?: string }) {
		if (poData) {
			this.path = poData.path;
			this.icon = poData.icon ?? "folder";
			this.name = poData.name ?? ArrayHelper.getLastElement(this.path?.split("/"));
			this.shortName = poData.shortName ?? this.name.substring(0, 3);
			this.color = poData.color;
			this.style = poData.style;
			this.configPath = poData.configPath;
			this.documentTypes = poData.documentTypes;
		}
	}

	public hasPattern(): boolean {
		return [this.name, this.shortName].some((psProp: string) => /\{{.*}}/.test(psProp));
	}

	//#endregion

}
