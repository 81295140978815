<ng-container *ngTemplateOutlet="layout === 'inline' ? inline : normal"></ng-container>

<ng-template #normal>
	<ion-textarea rows="2" [formControl]="$any(formControl)" [placeholder]="params.placeholder"
		class="input text-area-style" [autoGrow]="params.autoGrow" [autocapitalize]="params.autocapitalize" [debounce]="250"
		(ionInput)="onModelChanged()">
	</ion-textarea>
</ng-template>

<ng-template #inline>
	<calao-inline-field-layout class="inline-textarea" [icon]="layoutParams.icon" [separator]="layoutParams.separator"
		[hideLabel]="true">
		<calao-inline-field-layout-content>
			<ion-textarea [formControl]="$any(formControl)" [placeholder]="layoutParams.label" [autoGrow]="params.autoGrow"
				[autocapitalize]="params.autocapitalize" [debounce]="250" (ionInput)="onModelChanged()">
			</ion-textarea>
		</calao-inline-field-layout-content>
	</calao-inline-field-layout>
</ng-template>