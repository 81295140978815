import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ArrayHelper } from "@calaosoft/osapp/helpers/arrayHelper";
import { GuidHelper } from "@calaosoft/osapp/helpers/guidHelper";
import { IdHelper } from "@calaosoft/osapp/helpers/idHelper";
import { ICoordinates } from "@calaosoft/osapp/model/navigation/ICoordinates";
import { ColorHelper } from "@calaosoft/osapp/modules/colors/helper/color.helper";
import { GeolocationHelper } from "@calaosoft/osapp/modules/geolocation/helpers/geolocation.helper";
import { IMapOptions } from "@calaosoft/osapp/modules/maps/models/imap-options";
import { IPolygonOptions } from "@calaosoft/osapp/modules/maps/models/ipolygon-options";
import { IStartMap } from "@calaosoft/osapp/modules/maps/models/istart-map";
import { DestroyableComponentBase } from "@calaosoft/osapp/modules/utils/components/destroyable-component-base";
import { secure } from "@calaosoft/osapp/modules/utils/rxjs/operators/secure";
import { mergeMap, Observable, tap } from "rxjs";
import { map } from "rxjs/operators";
import { C_PREFIX_SECTORIZATION } from "../../../../../app/app.constants";
import { Sectorization } from "../../../models/sectorization";
import { SectorizationsService } from "../../../services/sectorizations.service";
import { EMapUrlPart } from "../../models/emap-url-part";
import { ESectors } from "../../models/esectors";
import { Sector } from "../../models/sector";

@Component({
	selector: 'sectors-map',
	templateUrl: './sectors-map.page.html',
	styleUrls: ['./sectors-map.page.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectorsMapPage extends DestroyableComponentBase {

	//#region FIELDS

	private isInSectorization: boolean | undefined;

	//#endregion FIELDS

	//#region PROPERTIES

	public readonly mapOptions$: Observable<IMapOptions>;
	private maColors: string[] = [];
	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly ioRouter: Router,
		psvcSctrz: SectorizationsService,
		private readonly ioActivatedRoute: ActivatedRoute,
	) {
		super();

		// Utilisé dans le HTML.
		this.mapOptions$ = this.ioActivatedRoute.params.pipe(
			mergeMap((poParams: Params) => {
				if (poParams.entityGuid) {
					this.isInSectorization = true;
					return psvcSctrz.getSectorization$(IdHelper.buildId(C_PREFIX_SECTORIZATION, poParams.entityGuid));
				}
				else {
					this.isInSectorization = false;
					return psvcSctrz.getActiveSectorization$();
				}
			}),
			tap((paSectors: Sectorization) => this.maColors = ColorHelper.generateDistinctHexColors(paSectors.linkedSectors!.length)),
			map((paSectors: Sectorization): IMapOptions => this.loadMapOptions(paSectors?.linkedSectors!, this.ioActivatedRoute.snapshot.params.entityGuid)),
			secure(this)
		);
	}

	/** Charge les options de la carte en utilisant les données des secteurs fournies.
	 * @param paSectors Tableau des secteurs.
	 * @returns Les options de la carte pour le rendu.
	 */
	private loadMapOptions(paSectors: Sector[], sctrzId?: string): IMapOptions {
		const loStartMap: IStartMap | undefined = GeolocationHelper.getStartMapOptions(paSectors
			.map((poSector: Sector) => poSector.getSectorZoneCoordinates())
			.flat());
		return {
			start: loStartMap ?? { zoom: 4, center: { latitude: 0, longitude: 0 } },
			polygons: this.createPolygon(paSectors, sctrzId),
			buttons: [],
			pois: []
		};
	}

	/** Crée les options de polygone pour les secteurs.
	 * @param paSectors Tableau des secteurs.
	 * @returns Tableau des options de polygones.
	 */
	private createPolygon(paSectors: Sector[], sctrzId?: string): IPolygonOptions[] {
		const laPolyOptions: IPolygonOptions[] = [];

		paSectors.forEach((poSector: Sector, lnIndex: number) => {
			const laSectorZone: ICoordinates[] = poSector.getSectorZoneCoordinates();
			const lsSectorId: string | undefined = poSector._id.split("_")[1];
			if (ArrayHelper.hasElements(laSectorZone) && lsSectorId) {
				const lsClassCss = `btn-${GuidHelper.newGuid()}`;
				const lsHtmlPopup = `<h1>${poSector.title}</h1><br><ion-button class='${lsClassCss}'>voir</ion-button>`;
				laPolyOptions.push(
					{
						coordinates: laSectorZone,
						options: { color: '#FFFFFF', fillColor: this.maColors[lnIndex], fillOpacity: 0.5, weight: 0.2 },
						popup: {
							htmlText: lsHtmlPopup,
							buttons: {
								[`.${lsClassCss}`]: !this.isInSectorization && !sctrzId ?
									{ onClick: () => this.ioRouter.navigate([`/businesses/${ESectors.sectors}/${lsSectorId}/${EMapUrlPart.map}`]) } :
									{ onClick: () => this.ioRouter.navigate([`/sectorizations/${sctrzId}/${ESectors.sectors}/${lsSectorId}/${EMapUrlPart.map}`]) }
							}
						}
					}
				);
			}
		});
		return laPolyOptions;
	}

	//#endregion METHODS
}