import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom, ReplaySubject } from 'rxjs';
import initSqlJs, { Database, QueryExecResult, SqlJsStatic, SqlValue } from 'sql.js';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { ConfigData } from '../../../model/config/ConfigData';
import { OsappApiHelper } from '../../osapp-api/helpers/osapp-api.helper';
import { PerformanceManager } from '../../performance/PerformanceManager';
import { TransfertService } from '../../transfert/services/transfert.service';
import { SqlAdapter } from '../models/SqlAdapter';
import { SqlDataSource } from '../models/sql-data-source';
import { SqlRequestResult } from '../models/sql-request-result';
import { TRequestParam } from '../models/trequest-param';
import { TTransactionParams } from '../models/ttransaction-params';
import { TTransactionRequest } from '../models/ttransaction-request';

@Injectable()
export class SqljsAdapterService extends SqlAdapter<Database> {

	//#region FIELDS

	private static readonly C_SQLJS_LOG_ID = "SQLJS.ADPTR.S::";
	private static readonly moInitializedSubject = new ReplaySubject(1);
	/** Le flux passe à `true` lorsque le service est initialisé. */
	private static readonly moWaitInitialisation$ = SqljsAdapterService.moInitializedSubject.asObservable();

	private static moSqlJs: SqlJsStatic;

	//#endregion

	//#region METHODS

	constructor(private readonly isvcTransfer: TransfertService) {
		super();
		this.initAsync();
	}

	/** Initialise le service. */
	private async initAsync(): Promise<void> {
		if (!SqljsAdapterService.moSqlJs) {
			// Si la lib SqlJs n'est pas téléchargée et chargée en mémoire, on le fait.
			try {
				const loSqlJs: initSqlJs.SqlJsStatic = await initSqlJs({
					locateFile: (psFileName: string) => this.getFilePath(psFileName)
				});
				SqljsAdapterService.moSqlJs = loSqlJs;
				SqljsAdapterService.moInitializedSubject.next(undefined);
			} catch (poError) {
				console.error(`${SqljsAdapterService.C_SQLJS_LOG_ID}Error to init sqlJs, don't forget to declare assets in angular.json :`, poError);
				throw poError;
			}
		}
	}

	protected getFilePath(psFileName: string): string {
		return `/assets/${psFileName}`;
	}

	/** @implements */
	protected override async openDatabaseAsync(poDataSource: SqlDataSource): Promise<Database> {
		await firstValueFrom(SqljsAdapterService.moWaitInitialisation$);

		if (poDataSource.ignoreExistenceCheck)
			return new SqljsAdapterService.moSqlJs.Database();

		if (poDataSource.isRemote === false) return Promise.reject();


		const loDatabaseBlob: Blob = await lastValueFrom(
			this.isvcTransfer.downloadFile$({
				fileUrl: poDataSource.path,
				headers: new HttpHeaders({
					appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
					token: ConfigData.authentication.token ?? "",
					"api-key": ConfigData.environment.API_KEY ?? "",
					accept: "application/vnd.sqlite3"
				}),
			})
		);

		return new SqljsAdapterService.moSqlJs.Database(new Uint8Array(await (loDatabaseBlob.arrayBuffer())));
	}

	protected execRequestAsync<T>(
		poDatabase: Database,
		psRequest?: string,
		paParams?: TRequestParam[]
	): Promise<SqlRequestResult<T>> {
		const loSqlRequestResult = new SqlRequestResult<T>();

		if (!StringHelper.isBlank(psRequest)) {
			const loPerfManager = new PerformanceManager().markStart();
			const laResults: QueryExecResult | undefined = ArrayHelper.getFirstElement(
				poDatabase.exec(
					psRequest,
					paParams?.map((poValue: TRequestParam) => poValue === undefined ? null : poValue) // On remplace undefined par null pour sqljs
				)
			);
			const laTransformedResults: T[] = laResults ? this.resultTransform<T>(laResults) : [];

			loSqlRequestResult.time = loPerfManager.markEnd().measure();
			loSqlRequestResult.results.push(...laTransformedResults);
		}

		return Promise.resolve(loSqlRequestResult);
	}

	protected override async execTransactionAsync(
		poDatabase: Database,
		paRequests: TTransactionRequest[],
		paParams: TTransactionParams[]
	): Promise<SqlRequestResult<any>[]> {
		const laResults: SqlRequestResult<any>[] = [];

		for (let lnIndex = 0; lnIndex < paRequests.length; lnIndex++) {
			const loRequest: TTransactionRequest = paRequests[lnIndex];
			const loParams: TTransactionParams = paParams[lnIndex];

			laResults.push(await this.execRequestAsync(
				poDatabase,
				typeof loRequest === "function" ? loRequest(laResults) : loRequest,
				typeof loParams === "function" ? loParams(laResults) : loParams
			));
		}

		return laResults;
	}

	/**  Transforme un résultat de requête QueryExecResult en `T[]`.
	 * @param poRequestResult Résultat de la requête.
	 * @returns Le résultat de la requête sous forme `T[]`.
	 */
	private resultTransform<T>(poRequestResult: QueryExecResult): T[] {
		const laResults: T[] = [];

		poRequestResult?.values.forEach((paValues: SqlValue[]) => {
			const loResult: T = {} as any;
			poRequestResult.columns.forEach((psColumn: string, pnColumnIndex: number) => loResult[psColumn] = paValues[pnColumnIndex]);
			laResults.push(loResult);
		});

		return laResults;
	}

	protected override closeDatabaseAsync(poDatabase: Database): Promise<void> {
		poDatabase.close();
		return Promise.resolve();
	}

	//#endregion

}