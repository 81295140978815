import { IContact } from '../../../model/contacts/IContact';
import { ISiteDetailsModel } from './isite-details-model';
import { Site } from './site';

export class SiteDetailsModel extends Site implements ISiteDetailsModel {

	//#region PROPERTIES

	/** Tableau des contacts appartenant au site. */
	public contacts?: IContact[];

	public sectors?: string[];

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poSite?: ISiteDetailsModel) {
		super(poSite);
	}

	//#endregion METHODS
}
