import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, merge } from 'rxjs';
import { map, mapTo, startWith, switchMap, tap } from 'rxjs/operators';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { secure } from '../../../utils/rxjs/operators/secure';
import { TabsSegmentComponent } from '../tabs-segment/tabs-segment.component';

@Component({
	selector: 'calao-tabs-segments',
	templateUrl: './tabs-segments.component.html',
	styleUrls: ['./tabs-segments.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsSegmentsComponent extends DestroyableComponentBase implements AfterContentInit {

	//#region FIELDS

	@ContentChildren(TabsSegmentComponent) private moSegments: QueryList<TabsSegmentComponent>;

	//#endregion

	//#region PROPERTIES

	/** Index de l'onglet actif. */
	@Input() public activeTabIndex?: number;
	@ObserveProperty<TabsSegmentsComponent>({ sourcePropertyKey: "activeTabIndex" })
	public readonly observableActiveTabIndex = new ObservableProperty<number>(0);

	/** Indique si on veut naviguer lors du clic sur un segment. */
	@Input() public navigateOnClick?: boolean;
	@ObserveProperty<TabsSegmentsComponent>({ sourcePropertyKey: "navigateOnClick" })
	public readonly observableNavigateOnClick = new ObservableProperty<boolean>();

	public readonly activeTabValue$: Observable<string> = this.observableActiveTabIndex.value$.pipe(
		map((pnIndex: number) => `${pnIndex}`),
		secure(this)
	);

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioRouter: Router,
		private readonly ioRoute: ActivatedRoute
	) {
		super()
	}

	public ngAfterContentInit(): void {
		this.moSegments.changes.pipe(
			startWith({}),
			switchMap(() => merge(...this.moSegments.map(
				(poItem: TabsSegmentComponent, pnIndex: number) => {
					poItem.index = pnIndex;
					return poItem.segmentClickedEvent.pipe(mapTo(pnIndex));
				}
			))),
			tap((pnIndex: number) => {
				if (this.observableNavigateOnClick.value) {
					this.ioRouter.navigate([], {
						relativeTo: this.ioRoute,
						queryParams: { tab: pnIndex }
					});
				}
				else
					this.observableActiveTabIndex.value = pnIndex;
			}),
			secure(this)
		).subscribe();

		this.observableActiveTabIndex.value$.pipe(
			tap(() => {
				this.moSegments.forEach((poItem: TabsSegmentComponent) => {
					poItem.detectChanges();
				})
			}),
			secure(this)
		).subscribe();
	}

	//#endregion

}
