import { coerceArray } from '@angular/cdk/coercion';
import { ObjectHelper } from '../../../../helpers/objectHelper';
import { IStoreDocument } from '../../../../model/store/IStoreDocument';
import { OsappError } from '../../../errors/model/OsappError';

export class ConflictError extends OsappError {

	constructor(poData: IStoreDocument | IStoreDocument[]) {
		const laDocuments: IStoreDocument[] = coerceArray(poData);

		super(`Un conflit a eu lieu lors de la sauvegarde ${laDocuments.length > 1 ? "des documents suivants" : "du document suivant"} : ${laDocuments.map((poDoc: IStoreDocument) => `${poDoc._id} (rev: ${poDoc._rev})`).join(", ")}.`);

		// Nécessaire pour que le 'instanceof ConflictError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, ConflictError);
	}

}