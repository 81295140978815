import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrayHelper } from '@calaosoft/osapp/helpers/arrayHelper';
import { ContactHelper } from '@calaosoft/osapp/helpers/contactHelper';
import { DateHelper } from '@calaosoft/osapp/helpers/dateHelper';
import { IdHelper } from '@calaosoft/osapp/helpers/idHelper';
import { ObjectHelper } from '@calaosoft/osapp/helpers/objectHelper';
import { StoreDocumentHelper } from '@calaosoft/osapp/helpers/storeDocumentHelper';
import { StringHelper } from '@calaosoft/osapp/helpers/stringHelper';
import { UserHelper } from '@calaosoft/osapp/helpers/user.helper';
import { EPrefix } from '@calaosoft/osapp/model/EPrefix';
import { ESortOrder } from '@calaosoft/osapp/model/ESortOrder';
import { UserData } from '@calaosoft/osapp/model/application/UserData';
import { IContact } from '@calaosoft/osapp/model/contacts/IContact';
import { ETimetablePattern } from '@calaosoft/osapp/model/date/ETimetablePattern';
import { ActivePageManager } from '@calaosoft/osapp/model/navigation/ActivePageManager';
import { ERouteUrlPart } from '@calaosoft/osapp/model/route/ERouteUrlPart';
import { EDatabaseRole } from '@calaosoft/osapp/model/store/EDatabaseRole';
import { IUiResponse } from '@calaosoft/osapp/model/uiMessage/IUiResponse';
import { BaseEvent } from '@calaosoft/osapp/modules/calendar-events/models/base-event';
import { BaseEventOccurrence } from '@calaosoft/osapp/modules/calendar-events/models/base-event-occurrence';
import { ENotificationChannel } from '@calaosoft/osapp/modules/calendar-events/models/enotification-channel';
import { ENotificationType } from '@calaosoft/osapp/modules/calendar-events/models/enotification-type';
import { EventState } from '@calaosoft/osapp/modules/calendar-events/models/event-state';
import { IEventFilterParams } from '@calaosoft/osapp/modules/calendar-events/models/ievent-filter-params';
import { IEventNotification } from '@calaosoft/osapp/modules/calendar-events/models/ievent-notification';
import { IEventOccurrenceDateRange } from '@calaosoft/osapp/modules/calendar-events/models/ievent-occurrence-date-range';
import { IEventOccurrencesFilterParams } from '@calaosoft/osapp/modules/calendar-events/models/ievent-occurrences-filter-params';
import { IUndatedEventOccurrencesFilterParams } from '@calaosoft/osapp/modules/calendar-events/models/iundated-event-occurrences-filter-params';
import { CalendarEventsInvitationService } from '@calaosoft/osapp/modules/calendar-events/services/calendar-events-invitation.service';
import { CalendarEventsParticipationService } from '@calaosoft/osapp/modules/calendar-events/services/calendar-events-participation.service';
import { CalendarEventsService } from '@calaosoft/osapp/modules/calendar-events/services/calendar-events.service';
import { Contact } from '@calaosoft/osapp/modules/contacts/models/contact';
import { EntitiesService } from '@calaosoft/osapp/modules/entities/services/entities.service';
import { Loader } from '@calaosoft/osapp/modules/loading/Loader';
import { LoggerService } from '@calaosoft/osapp/modules/logger/services/logger.service';
import { EModalSize } from '@calaosoft/osapp/modules/modal/model/EModalSize';
import { ModalService } from '@calaosoft/osapp/modules/modal/services/modal.service';
import { EReminderAlarmAction } from '@calaosoft/osapp/modules/reminder-alarms/models/ereminder-alarm-action';
import { IReminderAlarmActionPerformed } from '@calaosoft/osapp/modules/reminder-alarms/models/ireminder-alarm-action-performed';
import { ReminderAlarmsService } from '@calaosoft/osapp/modules/reminder-alarms/services/reminder-alarms.service';
import { ModelResolver } from '@calaosoft/osapp/modules/utils/models/model-resolver';
import { ContactsService } from '@calaosoft/osapp/services/contacts.service';
import { EntityLinkService } from '@calaosoft/osapp/services/entityLink.service';
import { GalleryService } from '@calaosoft/osapp/services/gallery.service';
import { ShowMessageParamsPopup } from '@calaosoft/osapp/services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '@calaosoft/osapp/services/interfaces/ShowMessageParamsToast';
import { LoadingService } from '@calaosoft/osapp/services/loading.service';
import { Store } from '@calaosoft/osapp/services/store.service';
import { UiMessageService } from '@calaosoft/osapp/services/uiMessage.service';
import { Observable, combineLatest, defer, of } from 'rxjs';
import { defaultIfEmpty, filter, map, mapTo, mergeMap, switchMap } from 'rxjs/operators';
import { RescheduleModalComponent } from '../components/reschedule-modal/reschedule-modal.component';
import { EReminderStatus } from '../models/ereminder-status';
import { ETaskStatus } from '../models/etask-status';
import { ETradeEventType } from '../models/etrade-event-type';
import { IDelegatedEventsViewNameResponse } from '../models/idelegated-events-view-name-response';
import { IDoneEventsViewNameResponse } from '../models/idone-events-view-name-response';
import { IOccurrenceListItem } from '../models/ioccurrence-list-item';
import { IPopupResponse } from '../models/ipopup-response';
import { Reminder } from '../models/reminder';
import { ReminderOccurrence } from '../models/reminder-occurrence';
import { Task } from '../models/task';
import { TaskOccurrence } from '../models/task-occurrence';
@Injectable({
	// On ne veut qu'une seule instance de ce service pour toute l'app
	providedIn: 'root'
})
export class TradeEventsService extends CalendarEventsService {

	//#region FIELDS

	private static readonly C_DONE_TASKS_VIEW_NAME = "events/done_tasks_ids_by_date";
	private static readonly C_DELEGATED_EVENTS_VIEW_NAME = "events/delegated_events_ids_by_date";

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioRouter: Router,
		psvcGallery: GalleryService,
		protected override readonly isvcContacts: ContactsService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcModal: ModalService,
		private readonly isvcReminderAlarms: ReminderAlarmsService,
		psvcUiMessage: UiMessageService,
		psvcStore: Store,
		psvcLogger: LoggerService,
		psvcInvitation: CalendarEventsInvitationService,
		psvcEntityLinks: EntityLinkService,
		psvcParticipation: CalendarEventsParticipationService,
		psvcEntities: EntitiesService
	) {
		super(psvcStore, psvcEntities, isvcContacts, psvcUiMessage, psvcLogger, psvcGallery, psvcInvitation, psvcEntityLinks, psvcParticipation);

		this.isvcReminderAlarms.actionsPerformed$?.pipe(
			mergeMap((poActionPerformed: IReminderAlarmActionPerformed) => this.performNotificationAction$(poActionPerformed)),
		).subscribe();
	}

	/**
	 * Récupère depuis la base les tâches respectant les conditions fixées par l'objet de paramétrage
	 * @param poParams Optionnel. Paramètres de filtrage : identifiants de tâches spécifiques, plage de dates
	 * @returns Un observable des tâches
	 */
	protected getTasks$(poParams?: IEventFilterParams, poActivePageManager?: ActivePageManager): Observable<Task[]> {
		return (this.getEvents$(
			{
				ids: poParams?.ids,
				dateRange: poParams?.dateRange,
				types: [ETradeEventType.task],
				participantIds: poParams?.participantIds,
				linkedEntities: poParams?.linkedEntities
			},
			poActivePageManager
		) as Observable<Task[]>);
	}

	/**
	 * Permet de récupérer les prochaine occurrences des tâches "en attente" (pending) depuis la base.
	 * Une tâche est considérée Pending si, parmi les docs d'état lui étant associés, le plus récent porte une date de nextOccurrence valide.
	 * @param poParams Optionnel. Paramètres de filtrage : identifiants de tâches spécifiques, plage de dates
	 * @returns Un observable des prochaines occurrences correspondantes
	 */
	public getPendingTasksOccurrences$(
		poActivePageManager: ActivePageManager,
		poParams: IEventOccurrencesFilterParams
	): Observable<TaskOccurrence[]> {
		return this.getTasksOccurrences$(poActivePageManager, poParams).pipe(
			map((paTaskOccurrences: TaskOccurrence[]) =>
				paTaskOccurrences.filter((poOccurrence: TaskOccurrence) => !poOccurrence.done)
			),
			switchMap((paOccurrences: TaskOccurrence[]) => {
				return combineLatest(paOccurrences.map((poOccurrence: TaskOccurrence) =>
					poOccurrence.isLate$.pipe(
						map((pbIsLate: boolean) => pbIsLate ? undefined : poOccurrence)
					)
				)).pipe(
					map((paFilteredOccurrences: (TaskOccurrence | undefined)[]) => ArrayHelper.getValidValues(paFilteredOccurrences)),
					defaultIfEmpty([])
				);
			})
		);
	}

	public getLateTasksOccurrences$(
		poActivePageManager: ActivePageManager,
		poParams: IEventOccurrencesFilterParams
	): Observable<TaskOccurrence[]> {
		return this.getTasksOccurrences$(poActivePageManager, poParams).pipe(
			map((paTaskOccurrences: TaskOccurrence[]) =>
				paTaskOccurrences.filter((poOccurrence: TaskOccurrence) => !poOccurrence.done)
			),
			switchMap((paOccurrences: TaskOccurrence[]) => {
				return combineLatest(paOccurrences.map((poOccurrence: TaskOccurrence) =>
					poOccurrence.isLate$.pipe(
						map((pbIsLate: boolean) => pbIsLate ? poOccurrence : undefined)
					)
				)).pipe(
					map((paFilteredOccurrences: (TaskOccurrence | undefined)[]) => ArrayHelper.getValidValues(paFilteredOccurrences)),
					defaultIfEmpty([])
				);
			})
		);
	}

	public getLateRemindersOccurrences$(
		poActivePageManager: ActivePageManager,
		poParams: IEventOccurrencesFilterParams
	): Observable<ReminderOccurrence[]> {
		return this.getAllRemindersOccurrences$(poActivePageManager, poParams).pipe(
			map((paTaskOccurrences: ReminderOccurrence[]) =>
				paTaskOccurrences.filter((poOccurrence: ReminderOccurrence) => !poOccurrence.done)
			),
			switchMap((paOccurrences: ReminderOccurrence[]) => {
				return combineLatest(paOccurrences.map((poOccurrence: ReminderOccurrence) =>
					poOccurrence.isLate$.pipe(
						map((pbIsLate: boolean) => pbIsLate ? poOccurrence : undefined)
					)
				)).pipe(
					map((paFilteredOccurrences: (ReminderOccurrence | undefined)[]) => ArrayHelper.getValidValues(paFilteredOccurrences)),
					defaultIfEmpty([])
				);
			})
		);
	}

	/**
	 * Permet de générer des occurrences pour des tâches faites, filtrées selon l'objet de paramètres.
	 * Pour déterminer les occurrences à générer, on se base sur les relevés d'intervention produits lorsqu'un utilisateur indique qu'il a réalisé une tâche.
	 * @param poParams Optionnel. Paramètres de filtrage : identifiants de tâches spécifiques, plage de dates
	 * @returns Un observable des occurrences marquées comme faites
	 */
	public getDoneTasksOccurrences$(poActivePageManager: ActivePageManager, poParams?: IEventFilterParams): Observable<TaskOccurrence[]> {
		return this.isvcStore.get({
			role: EDatabaseRole.workspace,
			viewName: TradeEventsService.C_DONE_TASKS_VIEW_NAME,
			viewParams: {
				startkey: poParams?.dateRange?.from?.getTime(),
				endkey: poParams?.dateRange?.to?.getTime()
			},
			live: true,
			remoteChanges: !!poActivePageManager,
			activePageManager: poActivePageManager,
		}).pipe(
			switchMap((paDocs: IDoneEventsViewNameResponse[]) => {
				const loResponsesByEventId: Map<string, IDoneEventsViewNameResponse[]> = ArrayHelper.groupBy(paDocs, (poDoc: IDoneEventsViewNameResponse) => poDoc.eventId);

				return this.getEvents$({ ids: paDocs.map((poDoc: IDoneEventsViewNameResponse) => poDoc.eventId) }, poActivePageManager).pipe(
					switchMap((paEvents: BaseEvent[]) =>
						this.prepareTasksOccurrences$(loResponsesByEventId, paEvents, poParams, poActivePageManager)
					)
				);
			}),
			map((paTaskOccurrences: TaskOccurrence[]) => paTaskOccurrences.filter((poOccurrence: TaskOccurrence) => poOccurrence.done))
		);
	}

	public getDelegatedTasksOccurrences$(
		poActivePageManager: ActivePageManager,
		poParams?: IEventFilterParams
	): Observable<TaskOccurrence[]> {
		return this.isvcStore.get({
			role: EDatabaseRole.workspace,
			viewName: TradeEventsService.C_DELEGATED_EVENTS_VIEW_NAME,
			viewParams: {
				startkey: poParams?.dateRange?.from ? DateHelper.toUTCString(poParams?.dateRange?.from) : 0,
				endkey: poParams?.dateRange?.to ? DateHelper.toUTCString(poParams?.dateRange?.to) : Store.C_ANYTHING_CODE_ASCII
			},
			live: true
		}).pipe(
			switchMap((paDocs: IDelegatedEventsViewNameResponse[]) => {
				const loResponsesByEventId: Map<string, IDelegatedEventsViewNameResponse[]> = ArrayHelper.groupBy(paDocs, (poDoc: IDelegatedEventsViewNameResponse) => poDoc.eventId);

				return this.getEvents$({ ids: paDocs.map((poDoc: IDelegatedEventsViewNameResponse) => poDoc.eventId) }, poActivePageManager).pipe(
					switchMap((paEvents: BaseEvent[]) => this.prepareTasksOccurrences$(loResponsesByEventId, paEvents, poParams, poActivePageManager)),
					map((paTaskOccurrences: TaskOccurrence[]) =>
						paTaskOccurrences.filter((poOccurrence: TaskOccurrence) => poOccurrence.delegatedByMe)
					)
				);
			}),
		);
	}

	public getPendingEventOccurrences$(
		poActivePageManager: ActivePageManager,
		poRange: IEventOccurrenceDateRange
	): Observable<BaseEventOccurrence[]> {
		return this.innerGetEventOccurrencesAccordingToLateStatus$(poActivePageManager, (pbLate: boolean) => !pbLate, poRange);
	}

	private innerGetEventOccurrencesAccordingToLateStatus$(
		poActivePageManager: ActivePageManager,
		pfIncludeEventOccurrence: (pbLate: boolean) => boolean,
		poRange: IEventOccurrenceDateRange
	): Observable<BaseEventOccurrence[]> {
		return this.getEventsOccurrences$(
			poActivePageManager,
			{
				dateRange: poRange,
				types: [ETradeEventType.standard]
			}
		).pipe(
			switchMap((paEventOccurrences: BaseEventOccurrence[]) => {
				if (ArrayHelper.hasElements(paEventOccurrences)) {
					return combineLatest(paEventOccurrences.map((poEventOccurrence: BaseEventOccurrence) =>
						poEventOccurrence.isLate$.pipe(
							map((pbLate: boolean) => pfIncludeEventOccurrence(pbLate) ? poEventOccurrence : undefined)
						)
					));
				}
				return of([]);
			}),
			map((paEventOccurrences: (BaseEventOccurrence | undefined)[]) => ArrayHelper.getValidValues(paEventOccurrences)),
		);
	}

	public getLateEventOccurrences$(
		poActivePageManager: ActivePageManager,
		poRange: IEventOccurrenceDateRange
	): Observable<BaseEventOccurrence[]> {
		return this.innerGetEventOccurrencesAccordingToLateStatus$(poActivePageManager, (pbLate: boolean) => pbLate, poRange);
	}

	private prepareTasksOccurrences$(
		poResponsesByEventId: Map<string, IDoneEventsViewNameResponse[] | IDelegatedEventsViewNameResponse[]>,
		paEvents: BaseEvent[],
		poParams: IEventFilterParams | undefined,
		poActivePageManager: ActivePageManager
	): Observable<BaseEventOccurrence[]> {
		const laParticipantIds: string[] = [];
		let laOccurrences: BaseEventOccurrence[] = this.generateTaskOccurrences(paEvents, poResponsesByEventId);

		if (poParams?.participantIds)
			laOccurrences = this.filterEventOccurrencesByParticipantsIds(laOccurrences, poParams.participantIds);

		laOccurrences.forEach((poTaskOccurrence: TaskOccurrence) => {
			laParticipantIds.push(...poTaskOccurrence.participantIds);
			const lsContactId: string | undefined = poTaskOccurrence.lastChangeStatusContactId;
			if (!StringHelper.isBlank(lsContactId))
				laParticipantIds.push(lsContactId);
		});

		return this.isvcContacts.getContactById(laParticipantIds, undefined, true, poActivePageManager).pipe(
			map((poContactsById: Map<string, Contact>) => {
				laOccurrences.forEach((poTaskOccurrence: TaskOccurrence) => {
					poTaskOccurrence.participants = ArrayHelper.getValidValues(poTaskOccurrence.participantIds.map((psId: string) => poContactsById.get(psId)));
					const lsContactId: string | undefined = poTaskOccurrence.lastChangeStatusContactId;
					if (!StringHelper.isBlank(lsContactId))
						poTaskOccurrence.lastChangeStatusContact = poContactsById.get(lsContactId);
				}
				);
				return laOccurrences;
			})
		);
	}

	private generateTaskOccurrences(
		paEvents: BaseEvent<BaseEventOccurrence>[],
		poResponsesByEventId: Map<string, IDoneEventsViewNameResponse[] | IDelegatedEventsViewNameResponse[]>
	): BaseEventOccurrence[] {
		const laOccurrences: BaseEventOccurrence[] = [];

		paEvents.forEach((poEvent: BaseEvent) => {
			const laEventOccurrences: BaseEventOccurrence[] = [];
			poResponsesByEventId.get(poEvent._id)?.forEach((poResponse: IDoneEventsViewNameResponse | IDelegatedEventsViewNameResponse) => {
				let loNewOccurrence: BaseEventOccurrence | undefined;

				if (poResponse.occurrenceStartDate) {
					loNewOccurrence = poEvent.generateOccurrence(
						new Date(poResponse.occurrenceStartDate),
						poResponse.eventRev
					);
				}
				else {
					let loOccurrence: BaseEventOccurrence | undefined = poEvent.generateNextOccurrence(undefined);

					if (poEvent.eventType === ETradeEventType.task && !poEvent.startDate) {
						const loUndatedOccurrence: BaseEventOccurrence = poEvent.createOccurrence(poEvent);
						if ((loUndatedOccurrence as TaskOccurrence).done)
							loOccurrence = loUndatedOccurrence;
					}

					loNewOccurrence = loOccurrence;
				}

				if (loNewOccurrence) {
					ArrayHelper.pushIfNotPresent(
						laEventOccurrences,
						loNewOccurrence,
						(poOccurrence: BaseEventOccurrence) => DateHelper.areEqual(poOccurrence.startDate, loNewOccurrence?.startDate)
					);
				}
			});
			laOccurrences.push(...laEventOccurrences);
		});

		return laOccurrences;
	}

	/** Récupère toutes les occurrences de tâches respectant les critères de filtrage donnés, indépendamment de leur état.
	 * @param poParams Optionnel. Paramètres de filtrage : identifiants de tâches spécifiques, plage de dates
	 * @returns Un observable des occurrences
	 */
	public getTasksOccurrences$(
		poActivePageManager: ActivePageManager,
		poParams: IEventOccurrencesFilterParams
	): Observable<TaskOccurrence[]> {
		return (this.getEventsOccurrences$(poActivePageManager, { ...poParams, types: [ETradeEventType.task] }) as Observable<TaskOccurrence[]>);
	}

	/** Récupère toutes les occurrences de rappels respectant les critères de filtrage donnés.
	 * @param poParams Optionnel. Paramètres de filtrage : identifiants des rappels, plage de dates
	 * @returns Un observable des occurrences
	 */
	public getAllRemindersOccurrences$(
		poActivePageManager: ActivePageManager,
		poParams: IEventOccurrencesFilterParams
	): Observable<ReminderOccurrence[]> {
		return (this.getEventsOccurrences$(poActivePageManager, { ...poParams, types: [ETradeEventType.reminder] }) as Observable<ReminderOccurrence[]>);
	}

	/** Récupère toutes les occurrences de rappels actives.
	 * @param poParams Optionnel. Paramètres de filtrage : identifiants des rappels, plage de dates
	 * @returns Un observable des occurrences
	 */
	public getPendingRemindersOccurrences$(
		poActivePageManager: ActivePageManager,
		poParams: IEventOccurrencesFilterParams
	): Observable<ReminderOccurrence[]> {
		return this.getAllRemindersOccurrences$(poActivePageManager, poParams).pipe(
			map((paOccurrences: ReminderOccurrence[]) => paOccurrences.filter((poOccurrence: ReminderOccurrence) => !poOccurrence.done))
		);
	}

	/** Récupère toutes les occurrences de rappels terminés.
	 * @param poParams Optionnel. Paramètres de filtrage : identifiants des rappels, plage de dates
	 * @returns Un observable des occurrences
	 */
	public getDoneRemindersOccurrences$(
		poActivePageManager: ActivePageManager,
		poParams: IEventOccurrencesFilterParams
	): Observable<ReminderOccurrence[]> {
		return this.getAllRemindersOccurrences$(poActivePageManager, poParams).pipe(
			map((paOccurrences: ReminderOccurrence[]) => paOccurrences.filter((poOccurrence: ReminderOccurrence) => poOccurrence.done))
		);
	}

	/** Récupère les prochaines occurences de tâches à faire (tâches à l'état Pending) en les triant par date de début croissante, et en filtrant selon une limite si spécifiée
	 * TODO : Voir à tirer les occurrences de tâches par priorité plutôt que par date
	 * @param poParams Filtres pour la récupération des tâches
	 * @returns Un Observable des occurrences triées et limitées au nombre demandé
	 */
	public getNextTasksOccurrences$(
		poActivePageManager: ActivePageManager,
		poParams: IEventOccurrencesFilterParams
	): Observable<TaskOccurrence[]> {
		return this.getPendingTasksOccurrences$(poActivePageManager, poParams).pipe(
			map(paOccurrences => {
				paOccurrences = this.sortOccurencesByStartDate(paOccurrences) as TaskOccurrence[];
				return poParams?.limit ? paOccurrences.slice(0, poParams.limit) : paOccurrences;
			})
		);
	}

	public getUndatedOccurrences$(
		poActivePageManager: ActivePageManager,
		poParams: IUndatedEventOccurrencesFilterParams
	): Observable<TaskOccurrence[]> {
		return this.getTasks$({ ...poParams, dateRange: undefined }, poActivePageManager).pipe(
			map((paTasks: Task[]) => paTasks.filter((poTask: Task) => !poTask.startDate)),
			switchMap((paTasks: Task[]) =>
				this.generateEventsOccurrences$(
					paTasks,
					poActivePageManager,
					{
						...poParams, dateRange: { to: new Date } // Le to est inutile (car occurrences non datées) mais on doit le spécifier
					}
				)
			),
			map((paTaskOccurrences: TaskOccurrence[]) => paTaskOccurrences.filter((poOcc: TaskOccurrence) => !poOcc.done)),
		);
	}

	/** Permet de connaître le nom de l'icône associée à un évènement. La déduction se fair d'après le sous-type de l'évènement.
	 * @param poEvent Evènement dont on cherche l'icône
	 * @returns Le nom de l'icône associée, ou l'icône par défaut si le type n'est pas connu
	 */
	public getIconNameFromSubtype$(poEvent: BaseEventOccurrence): Observable<string> {
		return poEvent.observableSubtype.value$.pipe(
			map(psSubtype => this.getIconNameFromSubType(psSubtype))
		);
	}

	/** Sauvegarde en base les données saisies dans un formulaire d'évènement
	 * @param poModel
	 * @returns Un observable de la réponse du store
	 */
	public async saveEventFromFormModelAsync(poModel: BaseEvent, poSourceModel?: BaseEvent): Promise<void> {
		poModel.eventType = ETradeEventType.standard;

		ArrayHelper.pushIfNotPresent(poModel.participantIds, UserHelper.getUserContactId(poModel.authorId)); // L'organisateur d'un évènement ne doit pas pouvoir s'en retirer.

		await this.saveModel(poModel, poSourceModel);
	}

	/** Sauvegarde en base les données saisies dans un formulaire d'évènement
	 * @param poModel
	 * @returns Un observable de la réponse du store
	 */
	public async saveTaskFromFormModelAsync(poModel: Task, poSourceModel?: Task): Promise<void> {
		await this.saveModel(poModel, poSourceModel);
	}

	/** Sauvegarde en base les données saisies dans un formulaire d'évènement
	 * @param poModel
	 * @returns Un observable de la réponse du store
	 */
	public async saveReminderFromFormModelAsync(poModel: Reminder, poSourceModel?: Reminder): Promise<void> {
		await this.saveModel(poModel, poSourceModel);
	}

	private async saveModel(poModel: BaseEvent, poSourceModel: BaseEvent | undefined) {
		const loLoader: Loader = await this.isvcLoading.create("Sauvegarde en cours", false);
		await loLoader.present();
		await this.saveAsync(poModel, poSourceModel);
		await loLoader?.dismiss();
	}

	public routeToViewEventAsync(poEventOccurrence: BaseEventOccurrence, poActivatedRoute: ActivatedRoute): Promise<boolean> {
		if (poEventOccurrence.startDate) {
			return this.ioRouter.navigate(
				[
					"events",
					IdHelper.getGuidFromId(poEventOccurrence.eventId, EPrefix.event),
					"occurrences",
					DateHelper.toUTCString(poEventOccurrence.startDate)
				],
				{
					relativeTo: poActivatedRoute,
					queryParams: {
						rev: poEventOccurrence.event._rev
					}
				}
			);
		}
		return this.ioRouter.navigate(
			[
				"events",
				IdHelper.getGuidFromId(poEventOccurrence.eventId, EPrefix.event)
			],
			{
				relativeTo: poActivatedRoute,
				queryParams: {
					rev: poEventOccurrence.event._rev
				}
			}
		);
	}

	public async routeToEditEventAsync(poEventOccurrence: BaseEventOccurrence): Promise<boolean> {
		let lbEditOccurrence = false;
		if (ArrayHelper.hasElements(poEventOccurrence.event.recurrences)) {
			lbEditOccurrence = await this.isvcUiMessage.showAsyncMessage<boolean>(new ShowMessageParamsPopup({
				header: "Que souhaitez-vous modifier?",
				message: "Vous pouvez choisir de modifier tous les évènements de la série ou cet évènement uniquement.",
				buttons: [
					{ text: "Toute la série", handler: () => UiMessageService.getFalsyResponse() },
					{ text: "Cet évènement uniquement", cssClass: "validate-btn", handler: () => UiMessageService.getTruthyResponse() }]
			}))
				.pipe(
					map((poResponse: IUiResponse<boolean>) => !!poResponse.response)
				).toPromise();
		}

		if (!ObjectHelper.isDefined(lbEditOccurrence))
			return false;

		if (lbEditOccurrence) {
			return this.ioRouter.navigateByUrl(`${this.addToEditEventUrl(
				"events",
				IdHelper.getGuidFromId(poEventOccurrence.eventId, EPrefix.event),
				"occurrences",
				poEventOccurrence.startDate ? DateHelper.toUTCString(poEventOccurrence.startDate) : null,
				ERouteUrlPart.edit
			)}?rev=${poEventOccurrence.event._rev}`);
		}

		return this.ioRouter.navigateByUrl(`${this.addToEditEventUrl(
			"events",
			IdHelper.getGuidFromId(poEventOccurrence.eventId, EPrefix.event),
			ERouteUrlPart.edit
		)}?rev=${poEventOccurrence.event._rev}`);

	}

	private addToEditEventUrl(...paUrlParts: (string | null)[]): string {
		let laUrlParts: (string | null)[] = ArrayHelper.getFirstElement(this.ioRouter.url.split("?"))?.split("/") ?? [];
		const lnOccurrenceIndex: number = laUrlParts.indexOf("occurrences");
		if (lnOccurrenceIndex > 0)
			laUrlParts = laUrlParts.slice(0, lnOccurrenceIndex);

		laUrlParts.push(...paUrlParts);

		return ArrayHelper.unique(laUrlParts).join("/");
	}

	public routeToNewEventAsync(poActivatedRoute: ActivatedRoute, poState?: any): Promise<boolean> {
		return this.ioRouter.navigate(["events", ERouteUrlPart.new], { relativeTo: poActivatedRoute, state: poState });
	}

	public routeToViewTaskAsync(poTaskOccurrence: TaskOccurrence, poActivatedRoute: ActivatedRoute): Promise<boolean> {
		if (poTaskOccurrence.startDate) {
			return this.ioRouter.navigate(
				[
					"tasks",
					IdHelper.getGuidFromId(poTaskOccurrence.eventId, EPrefix.event),
					"occurrences",
					DateHelper.toUTCString(poTaskOccurrence.startDate)
				],
				{
					relativeTo: poActivatedRoute,
					queryParams: {
						rev: poTaskOccurrence.event._rev
					}
				}
			);
		}
		return this.ioRouter.navigate(
			[
				"tasks",
				IdHelper.getGuidFromId(poTaskOccurrence.eventId, EPrefix.event)
			],
			{
				relativeTo: poActivatedRoute,
				queryParams: {
					rev: poTaskOccurrence.event._rev
				}
			}
		);
	}

	public routeToViewReminderAsync(poReminderOccurrence: ReminderOccurrence, poActivatedRoute: ActivatedRoute): Promise<boolean> {
		if (poReminderOccurrence.startDate) {
			return this.ioRouter.navigate(
				[
					"reminders",
					IdHelper.getGuidFromId(poReminderOccurrence.eventId, EPrefix.event),
					"occurrences",
					DateHelper.toUTCString(poReminderOccurrence.startDate)
				],
				{
					relativeTo: poActivatedRoute,
					queryParams: {
						rev: poReminderOccurrence.event._rev
					}
				}
			);
		}
		return this.ioRouter.navigate(
			[
				"reminders",
				IdHelper.getGuidFromId(poReminderOccurrence.eventId, EPrefix.event)
			],
			{
				relativeTo: poActivatedRoute,
				queryParams: {
					rev: poReminderOccurrence.event._rev
				}
			}
		);
	}

	public async routeToEditTaskAsync(poTaskOccurrence: TaskOccurrence): Promise<boolean> {
		let lbEditOccurrence = false;
		if (ArrayHelper.hasElements(poTaskOccurrence.event.recurrences)) {
			lbEditOccurrence = await this.isvcUiMessage.showAsyncMessage<boolean>(new ShowMessageParamsPopup({
				header: "Que souhaitez-vous modifier?",
				message: "Vous pouvez choisir de modifier toutes les tâches de la série ou cette tâche uniquement.",
				buttons: [
					{ text: "Toute la série", handler: () => UiMessageService.getFalsyResponse() },
					{ text: "Cette tâche uniquement", cssClass: "validate-btn", handler: () => UiMessageService.getTruthyResponse() }]
			}))
				.pipe(
					map((poResponse: IUiResponse<boolean>) => !!poResponse.response)
				).toPromise();
		}

		if (!ObjectHelper.isDefined(lbEditOccurrence))
			return false;

		if (lbEditOccurrence) {
			return this.ioRouter.navigateByUrl(`${this.addToEditEventUrl(
				"tasks",
				IdHelper.getGuidFromId(poTaskOccurrence.eventId, EPrefix.event),
				"occurrences",
				poTaskOccurrence.startDate ? DateHelper.toUTCString(poTaskOccurrence.startDate) : null,
				ERouteUrlPart.edit
			)}?rev=${poTaskOccurrence.event._rev}`);
		}

		return this.ioRouter.navigateByUrl(`${this.addToEditEventUrl(
			"tasks",
			IdHelper.getGuidFromId(poTaskOccurrence.eventId, EPrefix.event),
			ERouteUrlPart.edit
		)}?rev=${poTaskOccurrence.event._rev}`);
	}

	public async routeToEditReminderAsync(poReminderOccurrence: ReminderOccurrence): Promise<boolean> {
		let lbEditOccurrence = false;
		if (ArrayHelper.hasElements(poReminderOccurrence.event.recurrences)) {
			lbEditOccurrence = await this.isvcUiMessage.showAsyncMessage<boolean>(new ShowMessageParamsPopup({
				header: "Que souhaitez-vous modifier?",
				message: "Vous pouvez choisir de modifier tous les rappels de la série ou ce rappel uniquement.",
				buttons: [
					{ text: "Toute la série", handler: () => UiMessageService.getFalsyResponse() },
					{ text: "Ce rappel uniquement", cssClass: "validate-btn", handler: () => UiMessageService.getTruthyResponse() }]
			}))
				.pipe(
					map((poResponse: IUiResponse<boolean>) => !!poResponse.response)
				).toPromise();
		}

		if (!ObjectHelper.isDefined(lbEditOccurrence))
			return false;

		if (lbEditOccurrence) {
			return this.ioRouter.navigateByUrl(`${this.addToEditEventUrl(
				"reminders",
				IdHelper.getGuidFromId(poReminderOccurrence.eventId, EPrefix.event),
				"occurrences",
				poReminderOccurrence.startDate ? DateHelper.toUTCString(poReminderOccurrence.startDate) : null,
				ERouteUrlPart.edit
			)}?rev=${poReminderOccurrence.event._rev}`);
		}

		return this.ioRouter.navigateByUrl(`${this.addToEditEventUrl(
			"reminders",
			IdHelper.getGuidFromId(poReminderOccurrence.eventId, EPrefix.event),
			ERouteUrlPart.edit
		)}?rev=${poReminderOccurrence.event._rev}`);
	}

	public routeToNewTaskAsync(poActivatedRoute: ActivatedRoute, poState?: any): Promise<boolean> {
		return this.ioRouter.navigate(["tasks", ERouteUrlPart.new], { relativeTo: poActivatedRoute, state: poState });
	}

	public routeToNewReminderAsync(poActivatedRoute: ActivatedRoute, poState?: any): Promise<boolean> {
		return this.ioRouter.navigate(["reminders", ERouteUrlPart.new], { relativeTo: poActivatedRoute, state: poState });
	}

	/** Affiche la popup de confirmation pour valider une tâche. */
	public async showEndtaskPopupAsync(): Promise<boolean> {
		return this.isvcUiMessage.showAsyncMessage<boolean, any>(new ShowMessageParamsPopup({
			header: "Voulez-vous vraiment terminer cette tâche?",
			buttons: [
				{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
				{ text: "Oui, terminer", cssClass: "validate-btn", handler: () => UiMessageService.getTruthyResponse() }]
		}))
			.pipe(
				map((poResponse: IUiResponse<boolean>) => !!poResponse.response)
			)
			.toPromise();
	}

	/** Affiche la popup de confirmation pour terminer un rappel. */
	public async showEndReminderPopupAsync(): Promise<boolean> {
		return this.isvcUiMessage.showAsyncMessage<boolean, any>(new ShowMessageParamsPopup({
			header: "Voulez-vous vraiment terminer ce rappel ?",
			buttons: [
				{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
				{ text: "Oui, terminer", cssClass: "validate-btn", handler: () => UiMessageService.getTruthyResponse() }]
		}))
			.pipe(
				map((poResponse: IUiResponse<boolean>) => !!poResponse.response)
			)
			.toPromise();
	}

	public async showSaveEventPopupAsync(poEvent?: BaseEvent, poSourceEvent?: BaseEvent): Promise<IPopupResponse> {
		let lbResult: IPopupResponse = { response: true, isDestructive: false };

		if (ArrayHelper.hasElements(poSourceEvent?.recurrences) && StoreDocumentHelper.hasRevision(poSourceEvent)) {
			if (
				!ObjectHelper.areEqual(ModelResolver.toPlain(poEvent?.recurrences), ModelResolver.toPlain(poSourceEvent?.recurrences)) ||
				!DateHelper.areEqual(poEvent?.startDate, poSourceEvent?.startDate) ||
				!DateHelper.areEqual(poEvent?.endDate, poSourceEvent?.endDate) ||
				!ArrayHelper.areArraysEqual(poEvent?.participantIds, poSourceEvent?.participantIds)
			)
				lbResult = await this.showDestructiveEditionPopupAsync();
			else
				lbResult = await this.showMultipleRecurrenceEditionPopupAsync();
		}
		else if (
			poSourceEvent &&
			!StringHelper.isBlank(poSourceEvent?._rev) &&
			!(poSourceEvent instanceof Task) &&
			DateHelper.compareTwoDates(poSourceEvent.startDate, new Date) < 0
		)
			lbResult = await this.showPastEditionPopupAsync();

		if (lbResult?.response && poEvent?.participantIds) {
			const laContacts: IContact[] = await this.isvcContacts.getContactsByIds(poEvent?.participantIds).toPromise();
			if (laContacts) {
				const laContactsWithoutMail: IContact[] = laContacts.filter((poContact: IContact) => !poContact.email);
				if (ArrayHelper.hasElements(laContactsWithoutMail))
					lbResult = await this.showContactWithoutMailPopupAsync(laContactsWithoutMail, lbResult.isDestructive);
			}
		}

		return lbResult;
	}

	private showMultipleRecurrenceEditionPopupAsync(): Promise<IPopupResponse> {
		return this.showAskingForSavePopupAsync(
			"Les modifications s'appliqueront à toutes les occurrences, passées et à venir, sauf si celles-ci ont déjà été modifiées individuellement.",
			"Êtes-vous sûr(e) de vouloir modifier toute la série ?"
		);
	}

	private showDestructiveEditionPopupAsync(): Promise<IPopupResponse> {
		return this.showAskingForSavePopupAsync(
			"Vous venez de modifier les informations de planification de la série, les informations contenues dans les occurrences de cette tâche/évènement risquent d'être perdues, pour les occurrences passées comme pour celles à venir. Voulez-vous vraiment continuer ?",
			"Modification destructive",
			true
		);
	}

	private showContactWithoutMailPopupAsync(paContactsWithoutMail: IContact[], pbIsDestructive?: boolean): Promise<IPopupResponse> {
		let loResponse: IPopupResponse;
		const lsStartMessage = "Les participants suivants n'ont aucun mail renseigné dans leur fiche contact, et ne recevront donc pas d'invitation pour cet évènement.<br/>";
		const lsContactList: string = this.generateContactsWithoutMailHtmlList(paContactsWithoutMail);
		const lsEndMessage = "Enregistrer tout de même cet évènement ?";
		return this.isvcUiMessage.showAsyncMessage<boolean, any>(new ShowMessageParamsPopup({
			header: "Certains participants n'ont pas de mail",
			message: `${lsStartMessage}${lsContactList}${lsEndMessage}`,
			buttons: [
				{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
				{ text: "Continuer", cssClass: "validate-btn", handler: () => UiMessageService.getTruthyResponse() }],
		}))
			.pipe(
				map((poResponse: IUiResponse<boolean>) => {
					loResponse = { response: !!poResponse.response, isDestructive: !!pbIsDestructive };
					return loResponse;
				})
			)
			.toPromise();
	}

	private generateContactsWithoutMailHtmlList(paContactsWithoutMail: IContact[]): string {
		let lsItems = "";
		paContactsWithoutMail.forEach((poContact: IContact) =>
			lsItems += `<li>${ContactHelper.getCompleteFormattedName(poContact)}</li>`
		);
		return `<ul>${lsItems}</ul>`;
	}

	private showAskingForSavePopupAsync(psMessage?: string, psTitle?: string, pbIsDestructive?: boolean): Promise<IPopupResponse> {
		let loResponse: IPopupResponse;
		return this.isvcUiMessage.showAsyncMessage<boolean, any>(new ShowMessageParamsPopup({
			header: psTitle,
			message: psMessage,
			buttons: [
				{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
				{ text: "Oui, enregistrer", cssClass: "validate-btn", handler: () => UiMessageService.getTruthyResponse() }],
		}))
			.pipe(
				map((poResponse: IUiResponse<boolean>) => {
					loResponse = { response: !!poResponse.response, isDestructive: !!pbIsDestructive };
					return loResponse;
				})
			)
			.toPromise();
	}

	public override askForDeletetionAsync(poEvent: BaseEvent): Promise<boolean> {
		if (poEvent instanceof Task) {
			if (poEvent.getLastState()?.lastEndedDate) {
				return this.showAskingForDeletePopupAsync(
					undefined,
					"Vous allez supprimer des informations sur une ou plusieurs tâches terminée(s), êtes-vous sûr ?"
				);
			}

			return this.showAskingForDeletePopupAsync(
				undefined,
				`Voulez-vous vraiment supprimer cette tâche ${ArrayHelper.hasElements(poEvent.recurrences) ? "et l'ensemble des occurrences de la série à laquelle elle appartient " : ""}?`
			);
		}

		return super.askForDeletetionAsync(poEvent);
	}

	private askForPostponitionAsync(poReminderOccurrence: ReminderOccurrence): Promise<Date> {
		return this.isvcModal.open<Date>({
			component: RescheduleModalComponent,
			componentProps: {
				item: poReminderOccurrence
			},
			cssClass: "transparent"
		}, EModalSize.medium).toPromise();
	}

	public rescheduleReminder$(poReminderOccurrence: ReminderOccurrence): Observable<void> {
		if (poReminderOccurrence.status === EReminderStatus.done)
			return defer(() => this.isvcUiMessage.showToastMessageAsync(new ShowMessageParamsToast({ message: "Ce rappel a déjà été terminé.", color: "dark" }))).pipe(mapTo(undefined));
		return defer(() => this.askForPostponitionAsync(poReminderOccurrence))
			.pipe(
				filter((poResponse: Date) => !!poResponse),
				mergeMap((pdDate: Date) => {
					const lbHasOccurrence: boolean = ArrayHelper.hasElements(poReminderOccurrence.event.recurrences);
					const loNewReminder: Reminder | ReminderOccurrence = lbHasOccurrence ? ModelResolver.toClass(ReminderOccurrence, ModelResolver.toPlain(poReminderOccurrence)) : ModelResolver.toClass(Reminder, ModelResolver.toPlain(poReminderOccurrence.event));
					loNewReminder.startDate = pdDate;

					return defer(() => {
						if (loNewReminder instanceof Reminder)
							return this.saveAsync(loNewReminder);
						else
							return this.saveOccurrenceFromFormModelAsync(poReminderOccurrence, loNewReminder);
					});
				}),
				mergeMap(() => this.isvcUiMessage.showToastMessageAsync(new ShowMessageParamsToast({ message: "Le rappel a bien été reporté.", color: "dark" }))),
				mapTo(undefined)
			);
	}

	public repeatReminder$(poReminderOccurrence: ReminderOccurrence, pnMinutes: number): Observable<void> {
		if (poReminderOccurrence.status === EReminderStatus.done)
			return defer(() => this.isvcUiMessage.showToastMessageAsync(new ShowMessageParamsToast({ message: "Ce rappel a déjà été terminé.", color: "dark" }))).pipe(mapTo(undefined));
		const laNotifications: IEventNotification[] = [
			{
				channel: ENotificationChannel.localNotification,
				type: ENotificationType.relative,
				before: {
					minutes: 0
				}
			},
			{
				channel: ENotificationChannel.localNotification,
				type: ENotificationType.absolute,
				date: DateHelper.addMinutes(new Date, pnMinutes)
			}
		];

		const lbHasOccurrence: boolean = ArrayHelper.hasElements(poReminderOccurrence.event.recurrences);
		const loNewReminder: Reminder | ReminderOccurrence = lbHasOccurrence ? ModelResolver.toClass(ReminderOccurrence, ModelResolver.toPlain(poReminderOccurrence)) : ModelResolver.toClass(Reminder, ModelResolver.toPlain(poReminderOccurrence.event));
		loNewReminder.notifications = laNotifications;

		return defer(() => {
			if (loNewReminder instanceof Reminder)
				return this.saveAsync(loNewReminder);
			else
				return this.saveOccurrenceFromFormModelAsync(poReminderOccurrence, loNewReminder);
		}).pipe(
			mergeMap(() => this.isvcUiMessage.showToastMessageAsync(new ShowMessageParamsToast({ message: `${poReminderOccurrence.title} sera à nouveau notifié dans ${pnMinutes} minutes.`, color: "dark" }))),
			mapTo(undefined)
		);
	}

	public async showSaveEventOccurrencePopupAsync(poSourceEventOccurrence: BaseEventOccurrence, poModel?: BaseEventOccurrence): Promise<IPopupResponse> {
		let lbResult: IPopupResponse = { response: true, isDestructive: false };

		if (!(poSourceEventOccurrence instanceof TaskOccurrence) && DateHelper.compareTwoDates(poSourceEventOccurrence.startDate, new Date) < 0)
			lbResult = await this.showPastEditionPopupAsync();

		if (lbResult?.response && poModel?.participantIds) {
			const laContacts: IContact[] = await this.isvcContacts.getContactsByIds(poModel.participantIds).toPromise();
			if (laContacts) {
				const laContactsWithoutMail: IContact[] = laContacts.filter((poContact: IContact) => !poContact.email);
				if (ArrayHelper.hasElements(laContactsWithoutMail))
					lbResult = await this.showContactWithoutMailPopupAsync(laContactsWithoutMail, lbResult.isDestructive);
			}
		}

		return lbResult;
	}

	private async showPastEditionPopupAsync(): Promise<IPopupResponse> {
		return this.showAskingForSavePopupAsync(
			"Cet évènement a déjà eu lieu, êtes vous sûr de vouloir le modifier ?",
			"Modification dans le passé"
		);
	}

	/** Met à jour et enregistre le document eventState de l'utilisateur courant et enregistre un document d'intervention.
	 * @param poEventOccurrence Occurrence à passer au statut 'terminé'.
	 */
	public async endOccurrenceAsync(poEventOccurrence: BaseEventOccurrence): Promise<void> {
		const lsUserId: string = UserData.current?._id ?? "";
		const ldCurrentDate = new Date;
		let loUserEventState: EventState | undefined;

		if ((poEventOccurrence.stateByUserId?.size ?? 0) > 0)
			loUserEventState = poEventOccurrence.stateByUserId?.get(lsUserId);
		else {
			poEventOccurrence.stateByUserId = poEventOccurrence.event.stateByUserId = new Map;
			loUserEventState = await this.getParticipantEventStateAsync(poEventOccurrence.eventId, lsUserId);
		}

		if (!loUserEventState)
			loUserEventState = this.getEventStateInstance(lsUserId, poEventOccurrence.event);

		loUserEventState.lastUpdate = ldCurrentDate;
		loUserEventState.lastEndedDate = poEventOccurrence.startDate;

		if (!loUserEventState.lastEndedDate || !poEventOccurrence.event.generateNextOccurrence(DateHelper.addMilliseconds(loUserEventState.lastEndedDate, 1)))
			loUserEventState.status = ETaskStatus.done;

		poEventOccurrence.stateByUserId?.set(lsUserId, loUserEventState);

		if (!ArrayHelper.hasElements(poEventOccurrence.recurrences)) {
			poEventOccurrence.event.status = ETaskStatus.done;

			await this.saveAsync(poEventOccurrence.event);
		}
		else {
			const loTaskOccurrenceClone: TaskOccurrence = ModelResolver.toClass(TaskOccurrence, ModelResolver.toPlain(poEventOccurrence));

			poEventOccurrence.status = ETaskStatus.done;

			await this.saveOccurrenceFromFormModelAsync(loTaskOccurrenceClone, poEventOccurrence);
		}

		await this.updateEventStateAsync(loUserEventState);
	}

	public async deleteTaskAsync(poEvent: BaseEvent): Promise<void> {
		await this.deleteEventStatesAsync([poEvent._id]);
		await this.deleteEventAsync(poEvent);
		this.isvcUiMessage.showToastMessage(new ShowMessageParamsToast({ message: `"${poEvent.title}" supprimé.`, color: "dark" }));
	}

	public getTaskAsync(psTaskId: string): Promise<Task> {
		return this.isvcStore.getOne({
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				key: psTaskId,
				include_docs: true
			},
			baseClass: Task
		}, false).toPromise();
	}

	public getReminderAsync(psReminderId: string): Promise<Reminder> {
		return this.isvcStore.getOne({
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				key: psReminderId,
				include_docs: true
			},
			baseClass: Reminder
		}, false).toPromise();
	}

	public getTask$(psTaskId: string): Observable<Task> {
		return this.isvcStore.getOne({
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				key: psTaskId,
				include_docs: true
			},
			live: true,
			baseClass: Task
		}, false);
	}

	/** Calcule la différence entre le total des tâches toDo et le nombre de tâches affichées.
	 * @param paTodoTasksCount Nombre total des taches toDo
	 * @param paDisplayedTasks Nombre de tâches affichées dans l'item toDo
	 */
	public getTodoItemDiff(paTodoTasksCount: number, paDisplayedTasks: number): number {
		return paTodoTasksCount - paDisplayedTasks;
	}

	public getAllEventsOccurrences$(
		poActivePageManager: ActivePageManager,
		poParams: IEventOccurrencesFilterParams
	): Observable<BaseEventOccurrence[]> {
		return combineLatest([
			this.getEventsOccurrences$(poActivePageManager, { ...poParams, types: [ETradeEventType.standard] }),
			this.getAllTasksOccurrences$(poActivePageManager, poParams),
			this.getAllRemindersOccurrences$(poActivePageManager, poParams),
		]).pipe(
			map(([paEventOccurrences, paTaskOccurrences, paReminders]: [BaseEventOccurrence[], TaskOccurrence[], ReminderOccurrence[]]) => {
				const laOccurrences: BaseEventOccurrence[] = [...paEventOccurrences, ...paReminders];

				paTaskOccurrences.forEach((poDone: TaskOccurrence) => ArrayHelper.binaryInsert(laOccurrences, poDone, "sortDate"));

				return laOccurrences;
			})
		);
	}

	public getAllTasksOccurrences$(
		poActivePageManager: ActivePageManager,
		poParams: IEventOccurrencesFilterParams
	): Observable<BaseEventOccurrence[]> {
		return combineLatest([
			this.getDoneTasksOccurrences$(poActivePageManager, poParams),
			this.getPendingTasksOccurrences$(poActivePageManager, poParams),
			this.getLateTasksOccurrences$(poActivePageManager, poParams)
		]).pipe(
			map(([paDone, paPending, paLate]: [TaskOccurrence[], TaskOccurrence[], TaskOccurrence[]]) => {
				const laOccurrences: BaseEventOccurrence[] = [];

				paDone.forEach((poDone: TaskOccurrence) => ArrayHelper.binaryInsert(laOccurrences, poDone, "sortDate"));
				paPending.forEach((poPending: TaskOccurrence) => ArrayHelper.binaryInsert(laOccurrences, poPending, "sortDate"));
				paLate.forEach((poLate: TaskOccurrence) => ArrayHelper.binaryInsert(laOccurrences, poLate, "sortDate"));

				return laOccurrences;
			})
		);
	}

	public prepareTodoItems(paOccurrences: TaskOccurrence[]): TaskOccurrence[] {
		const laOccurrences: TaskOccurrence[] = paOccurrences
			.sort((poTaskA: TaskOccurrence, poTaskB: TaskOccurrence) => DateHelper.compareTwoDates(poTaskB.event.creationDate, poTaskA.event.creationDate));
		return ArrayHelper.getSection(ArrayHelper.dynamicSort(laOccurrences, "priority", ESortOrder.ascending), 0, 2);
	}

	public prepareLateItems(paOccurrences: BaseEventOccurrence[]): BaseEventOccurrence[] {
		const laOccurrences: BaseEventOccurrence[] = paOccurrences
			.sort((poTaskA: BaseEventOccurrence, poTaskB: BaseEventOccurrence) => DateHelper.compareTwoDates(poTaskB.event.endDate, poTaskA.event.endDate));
		return ArrayHelper.getSection(laOccurrences, 0, 2);
	}

	public prepareOccurrenceListItems(paOccurrences: BaseEventOccurrence[], pdNow?: Date): IOccurrenceListItem[] {
		const laItems: IOccurrenceListItem[] = [];
		let ldPreviousDate: Date;

		paOccurrences.forEach((poOccurrence: BaseEventOccurrence) => {
			const ldOccurenceDate: Date | undefined = poOccurrence.startDate ?? poOccurrence.statusChangeDate;
			if (ldOccurenceDate) {
				if (!DateHelper.areDayEqual(ldPreviousDate, ldOccurenceDate)) {
					ldPreviousDate = ldOccurenceDate;
					laItems.push({
						date: poOccurrence.startDate,
						dateLabel: DateHelper.areDayEqual(pdNow, ldOccurenceDate) ?
							"Aujourd'hui" :
							DateHelper.transform(ldOccurenceDate, ETimetablePattern.dd_MMMM_yyyy)
					});
				}

				laItems.push({ occurrence: poOccurrence });
			}
		});

		return laItems;
	}

	public prepareListItems(paOccurrences: BaseEventOccurrence[]): IOccurrenceListItem[] {
		const laItems: IOccurrenceListItem[] = [];
		paOccurrences.forEach((poOccurrence: BaseEventOccurrence) => laItems.push({ occurrence: poOccurrence }));
		return laItems;
	}

	private performNotificationAction$(poActionPerformed: IReminderAlarmActionPerformed): Observable<void> {
		const lsReminderId: string | undefined = poActionPerformed.notificationExtras?.entityId;
		const ldOccurenceRev: string | undefined = poActionPerformed.notificationExtras?.context.eventRev;
		const ldOccurenceDate: Date | undefined = poActionPerformed.notificationExtras?.context.occurrenceStartDate;

		if (!StringHelper.isBlank(lsReminderId) && !StringHelper.isBlank(ldOccurenceRev) && ldOccurenceDate) {
			return this.getEventOccurrence$<ReminderOccurrence>(lsReminderId, undefined, ldOccurenceDate, ldOccurenceRev).pipe(
				filter((poReminderOccurrence: ReminderOccurrence | undefined) => !!poReminderOccurrence),
				mergeMap((poReminderOccurrence: ReminderOccurrence) => {
					switch (poActionPerformed.action) {
						case EReminderAlarmAction.reschedule:
							return this.rescheduleReminder$(poReminderOccurrence);

						case EReminderAlarmAction.repeatAfter5mn:
							return this.repeatReminder$(poReminderOccurrence, 5);

						case EReminderAlarmAction.repeatAfter15mn:
							return this.repeatReminder$(poReminderOccurrence, 15);

						default:
							return of();
					}
				}),
				mapTo(undefined)
			);
		}
		return of();
	}

	//#endregion
}
