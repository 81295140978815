import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController, ModalOptions } from '@ionic/angular';
import { combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { IDataSource } from '../../../../model/store/IDataSource';
import { PlatformService } from '../../../../services/platform.service';
import { IDocExlorerFilterValues } from '../../../doc-explorer/models/idoc-explorer-filter-values';
import { ModalComponentBase } from '../../../modal';
import { ModalService } from '../../../modal/services/modal.service';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { Queue } from '../../../utils/queue/decorators/queue.decorator';
import { secure } from '../../../utils/rxjs/operators/secure';
import { Entity } from '../../models/entity';
import { IEntityDescriptor } from '../../models/ientity-descriptor';
import { IEntityModalParams } from '../../models/ientity-modal-params';
import { EntitiesService } from '../../services/entities.service';
import { EntityModalComponent } from '../entity-modal/entity-modal.component';
import { IEntityPickerFilterValues } from './components/entity-picker-filter-bar-component/entity-picker-filter-bar-component.component';

@Component({
	selector: 'calao-entity-picker-modal',
	templateUrl: './entity-picker-modal.component.html',
	styleUrls: ['./entity-picker-modal.component.scss'],
})
export class EntityPickerModalComponent<T extends Entity> extends ModalComponentBase<Entity> implements OnInit {

	//#region FIELDS

	/** Filtres. */
	private readonly moObservableFilterValues = new ObservableProperty<IEntityPickerFilterValues>({});

	//#endregion FIELDS

	//#region PROPERTIES

	@Input() public entityDescId?: string;
	@ObserveProperty<EntityPickerModalComponent<T>>({ sourcePropertyKey: "entityDescId" })
	public readonly observableEntityDescId = new ObservableProperty<string>();

	@Input() public dataSource?: IDataSource<T>;
	@ObserveProperty<EntityPickerModalComponent<T>>({ sourcePropertyKey: "dataSource" })
	public readonly observableDataSource = new ObservableProperty<IDataSource<T>>();

	@Input() public hasCreateButton?: boolean;
	@ObserveProperty<EntityPickerModalComponent<T>>({ sourcePropertyKey: "hasCreateButton" })
	public readonly observableHasCreateButton = new ObservableProperty<false>();

	public readonly observableEntries = new ObservableArray<T>();
	public readonly observableFilteredEntries = new ObservableArray<T>();

	public readonly observablePickerDescription = new ObservableProperty<string>("Sélection");
	public readonly observableSearchboxPlaceholder = new ObservableProperty<string>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcEntities: EntitiesService,
		private readonly isvcModal: ModalService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.observableEntityDescId.value$.pipe(
			filter((psEntityDescId?: string) => !StringHelper.isBlank(psEntityDescId)),
			switchMap((psEntityDescId: string) => this.isvcEntities.getDescriptor$(psEntityDescId)),
			switchMap((poDesc: IEntityDescriptor) => {
				if (poDesc.defaultsLabels?.pickerDescription)
					this.observablePickerDescription.value = poDesc.defaultsLabels.pickerDescription;
				if (poDesc.defaultsLabels?.searchboxPlaceholder)
					this.observableSearchboxPlaceholder.value = poDesc.defaultsLabels.searchboxPlaceholder;
				if (ArrayHelper.hasElements(this.observableEntries))
					return of(this.observableEntries);
				else {
					return this.isvcEntities.getEntries$(this.observableDataSource.value ?? this.isvcEntities.getDataSource(poDesc)).pipe(
						tap((paEntities: T[]) => this.observableEntries.resetArray(paEntities))
					);
				}
			}),
			secure(this)
		).subscribe();

		combineLatest([this.observableEntries.changes$, this.moObservableFilterValues.value$]).pipe(
			tap(([paEntities, poFilters]: [T[], IDocExlorerFilterValues]) => {
				if (!StringHelper.isBlank(poFilters.name)) {
					this.observableFilteredEntries.resetArray(this.sortEntities(paEntities.filter((poEntity: T) => {
						return this.getEntityName(poEntity).toLowerCase().includes(poFilters.name!.toLowerCase());
					})));
				}
				else
					this.observableFilteredEntries.resetArray(this.sortEntities(paEntities));
			}),
			secure(this)
		).subscribe();
	}

	private sortEntities(paEntities: T[]): T[] {
		return paEntities.sort((poEntityA: T, poEntityB: T) => this.getEntityName(poEntityA).toLowerCase().localeCompare(this.getEntityName(poEntityB).toLowerCase()));
	}

	public getEntityName(poEntity: T): string {
		return this.isvcEntities.getEntityName(poEntity);
	}

	public onFilterValuesChange(poFilterValues: IDocExlorerFilterValues): void {
		this.moObservableFilterValues.value = poFilterValues;
	}

	public onCreateEntityAsync(): Promise<T | undefined> {
		return firstValueFrom(this.createEntity$());
	}

	@Queue<EntityPickerModalComponent<T>, Parameters<EntityPickerModalComponent<T>["createEntity$"]>, ReturnType<EntityPickerModalComponent<T>["createEntity$"]>>({
		excludePendings: true
	})
	private createEntity$(): Observable<T | undefined> {
		const loModalParams: IEntityModalParams = {
			entityDescGuid: this.observableEntityDescId.value,
			entityGuid: undefined,
			context: {},
			isEdit: true,
			closeAfterSave: true
		};

		const loModalOptions: ModalOptions = {
			component: EntityModalComponent,
			componentProps: loModalParams
		};

		return this.isvcModal.open<T>(loModalOptions);
	}

	//#endregion METHODS

}
