<ng-container *ngIf="observableParams.value$ | async as params">
	<ng-container *ngTemplateOutlet="layout === 'inline' ? inline : normal"></ng-container>

	<ng-template #normal>
		<div class="container">
			<star-rating staticColor="ok" [rating]="fieldValue" [readOnly]="params.readOnly"
				[size]="params.readOnly ? 'medium' : 'large'" (ratingChange)="onRatingChange($event)">
			</star-rating>

			<ion-button *ngIf="!params.readOnly && fieldValue !== undefined" class="close-button" color="danger" size="small"
				(click)="onClearClicked()">
				<ion-icon name="close" shape="round"></ion-icon>
			</ion-button>
		</div>
	</ng-template>

	<ng-template #inline>
		<calao-inline-field-layout [icon]="layoutParams.icon" [label]="layoutParams.label"
			[separator]="layoutParams.separator" [hideLabel]="hideLabelWhenFilled && !!fieldValue" [verticalCenter]="true">
			<calao-inline-field-layout-content [class]="fieldValue ? '' : 'empty'">
				<star-rating class="inline-star-rating" [staticColor]="fieldValue ? 'ok' : ''" [rating]="fieldValue"
					[readOnly]="params.readOnly" size="medium" (ratingChange)="onRatingChange($event)">
				</star-rating>
				<ion-icon class="inline-close-button" name="close" color="medium" (click)="onClearClicked()"></ion-icon>
			</calao-inline-field-layout-content>
		</calao-inline-field-layout>
	</ng-template>
</ng-container>