<ng-container *ngIf="(sectors.changes$ | async); else loadingSpinner;">

	<div *ngIf="(title.value$ | async)">
		<ion-title> Organisation de secteurs actifs : {{ (title.value$ | async) }} </ion-title>
	</div>

	<div class="buttons">
		<ion-button size="small" [disabled]="!(isOnline$ | async)  || !sectorsMapButtonEnabled"
			(click)=" navigateToSectorsMapAsync()">
			<ion-icon slot="start" name="map"></ion-icon>
			Carte
		</ion-button>

		<ion-button size="small" (click)=" navigateToSectorizationAsync()" expand="block">
			<ion-icon slot="start" name="pencil"></ion-icon>
			Éditer l'organisation brouillon
		</ion-button>
	</div>

	<ng-container *ngIf="(sectors.changes$ | async)?.length !== 0; else emptyScreen;">

		<calao-virtual-scroll [items]="(sectors.changes$ | async)" [itemSize]="88" transparent autosize
			class="virtual-scroll">
			<ng-template let-item="$implicit">

				<ion-item-sliding osappAutoCloseSlideItem class="ion-no-padding">
					<ion-item class="ion-no-padding" lines="none" (click)="navigateToSectorVisuModeAsync(item)">
						<calao-list-item>
							<calao-list-item-start>
								<ion-icon name="extension-puzzle" class="start-item"></ion-icon>
							</calao-list-item-start>
							<calao-list-item-content detail detailIcon="ellipsis-vertical" class="end-item">
								<calao-list-item-content-title>
									<ion-label> {{ item.title}} </ion-label>
								</calao-list-item-content-title>
								<span> {{ item.getNamePointOfContact() }} </span>
							</calao-list-item-content>
						</calao-list-item>
					</ion-item>
					<ion-item-options>

						<ion-item-option [disabled]="!(isOnline$ | async) || !item.mapButtonEnabled" calaoSlidingItemOptionsToggle
							color="primary" (click)="navigateToSectorMapModeAsync(item)">
							<ion-icon name="map"></ion-icon>
							<ion-label>Carte</ion-label>
						</ion-item-option>

					</ion-item-options>
				</ion-item-sliding>
			</ng-template>
		</calao-virtual-scroll>
	</ng-container>

</ng-container>
<!-- Afficher s'il y a un chargement -->
<ng-template #loadingSpinner>
	<div class="full-width">
		<osapp-spinner>
		</osapp-spinner>
	</div>
</ng-template>


<!-- Si le composant a terminé de s'initialiser et qu'il y a une barre de recherche et qu'elle n'a retourné aucun résultat. -->
<ng-template #emptyScreen>
	<div class="empty-screen flex-col">
		<span class="txt-message">
			<ion-note>Aucun résultat.</ion-note>
		</span>
	</div>
</ng-template>