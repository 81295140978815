<div *ngIf="observableEvent.value$ | async as item" class="item">
	<ion-item class="ion-no-padding" lines="none">
		<calao-list-item>
			<calao-list-item-start [backgroundColor]="observableStartColor.value$ | async">
				<ion-icon *ngIf="icon$| async as name" [name]="name" slot="start"></ion-icon>
				<span class="type" *ngIf="typeLabel$ | async as value">{{value}}</span>
			</calao-list-item-start>
			<calao-list-item-content [backgroundColor]="observableContentColor.value$ | async" [detail]="hasOptions$ | async"
				detailIcon="ellipsis-vertical" (onDetailClicked)="presentPopOverAsync($event)">
				<calao-list-item-content-prefix *ngIf="item.dateLabel$ | async as value">
					<ion-note class="prefix-note ion-text-wrap">
						{{value}}
					</ion-note>
				</calao-list-item-content-prefix>
				<calao-list-item-content-title *ngIf="item.observableTitle.value$ | async as value">
					<ion-icon *ngIf="observableTitleIcon.value$ | async as icon" [name]="icon"></ion-icon>
					<ion-label class="title">{{ value }}</ion-label>
				</calao-list-item-content-title>
				<ng-container
					*ngTemplateOutlet="!!(observableHasToRequestParticipation.value$ | async) ? buttons : placeAndParticipants; context : { item : item }">
				</ng-container>
				<calao-list-item-content-suffix *ngIf="item.observableComment.value$ | async as value">{{value}}
				</calao-list-item-content-suffix>
			</calao-list-item-content>
		</calao-list-item>
	</ion-item>
</div>

<ng-template #buttons>
	<div class="buttons">
		<ion-button color="success" strong="true" (click)="confirmParticipationAsync($event)">
			<ion-icon slot="start" name="checkmark"></ion-icon>
			Accepter
		</ion-button>
		<ion-button color="danger" strong="true" (click)="rejectParticipationAsync($event)">
			<ion-icon slot="start" name="close"></ion-icon>
			Refuser
		</ion-button>
	</div>
</ng-template>

<ng-template #placeAndParticipants let-item="item">
	<ng-container *ngIf="item.observablePlace.value$ | async as place">
		<div *ngIf="observableDisplayPlace.value$ | async" class="place-label">
			<ion-icon name="location" color="warning"></ion-icon> <span>{{ place }}</span>
		</div>
	</ng-container>
	<div class="participants">
		<ng-container *ngIf="item.authorLabel$ | async as author">
			<span class="author">{{ author }}</span><span *ngIf="item.participantsLabel$ | async"
				class="author-comma">,</span>
		</ng-container>
		<span>{{ item.participantsLabel$ | async }}</span>
	</div>
</ng-template>