import { ArrayHelper } from "../../../helpers/arrayHelper";
import { IIndexedObject } from "../../../model/IIndexedObject";
import { IState } from "./istate";
import { IStateMachine } from "./istate-machine";

export class StateMachine implements IStateMachine {

	//#region PROPERTIES

	/** @implements */
	public firstState: string = "";
	/** @implements */
	public suggestNextStates?: boolean;
	/** @implements */
	public states: IIndexedObject<IState> = {};

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poData?: IStateMachine) {
		if (poData) {
			this.firstState = poData.firstState;
			this.suggestNextStates = poData.suggestNextStates;
			this.states = poData.states;
		}
	}

	public getTransitions(psCurrentStateKey: string): IIndexedObject<IState> {
		const loCurrentState: IState | undefined = this.states[psCurrentStateKey];

		if (loCurrentState && ArrayHelper.hasElements(loCurrentState.transitions)) {
			const loTransitions: IIndexedObject<IState> = {};
			loCurrentState.transitions.forEach((psStateKey: string) => {
				if (this.states[psStateKey])
					loTransitions[psStateKey] = this.states[psStateKey];
			});
			return loTransitions;
		}

		return {};
	}

	public canDoAction(psCurrentStateKey: string, psAction: string): boolean {
		const loCurrentState: IState | undefined = this.states[psCurrentStateKey];
		return !loCurrentState?.disallowedActions?.includes(psAction);
	}

	//#endregion METHODS

}