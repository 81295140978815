import { Injectable, Type } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopoverOptions, PositionSide } from '@ionic/core';
import { PopoverComponent } from '../components/popover/popover.component';
import { IIndexedArray } from '../model/IIndexedArray';
import { IPopoverItemParams } from '../model/popover/IPopoverItemParams';

interface OsappPopoverOptions<T> {
	component: Type<T>;
	event: MouseEvent;
	componentProps: IIndexedArray<any>;
	side?: PositionSide;
	cssClass?: string | string[];
}

@Injectable()
export class PopoverService {

	//#region METHODS

	constructor(private ioPopoverCtrl: PopoverController) { }

	/** Affiche un menu contextuel et retourne l'objet `HTMLIonPopoverElement` associé.
	 * @param paItems Tableau des paramètres des items de menu contextuel à afficher.
	 * @param poEvent Événement de la souris (permet d'afficher correctement le menu contextuel).
	 */
	public showPopoverAsync(paItems: IPopoverItemParams[], poEvent: MouseEvent): Promise<HTMLIonPopoverElement> {
		const loOptions: OsappPopoverOptions<PopoverComponent> = {
			component: PopoverComponent,
			event: poEvent,
			componentProps: { items: paItems.filter((poItem: IPopoverItemParams) => !!poItem) }
		};

		return this.showAsync(loOptions);
	}

	/** Affiche un menu contextuel et retourne l'objet `HTMLIonPopoverElement` associé.
	 * @param poComponent Composant popover à instancier.
	 * @param poComponentProps Paramètres du composant menu contextuel à afficher.
	 * @param poEvent Événement de la souris (permet d'afficher correctement le menu contextuel).
	 */
	public showCustomPopoverAsync<T>(poComponent: Type<T>, poComponentProps: IIndexedArray<any>, poEvent: MouseEvent, poCssClass?: string | string[], peSide: PositionSide = "bottom"): Promise<HTMLIonPopoverElement> {
		const loOptions: OsappPopoverOptions<T> = {
			component: poComponent,
			event: poEvent,
			componentProps: poComponentProps,
			cssClass: poCssClass,
			side: peSide
		};

		return this.showAsync(loOptions);
	}

	private async showAsync<T>(poOptions: OsappPopoverOptions<T>): Promise<HTMLIonPopoverElement> {
		const lsPopoverId: string = (poOptions.component as unknown as PopoverComponent).componentId;
		const loOptions: PopoverOptions = {
			component: poOptions.component,
			event: poOptions.event,
			componentProps: { ...poOptions.componentProps, componentId: lsPopoverId },
			keyboardClose: true,
			mode: "ios",
			id: lsPopoverId,
			side: poOptions.side ?? 'bottom',
			cssClass: poOptions.cssClass
		};

		const loPopover: HTMLIonPopoverElement = await this.ioPopoverCtrl.create(loOptions);
		await loPopover.present(loPopover.event);
		return loPopover;
	}

	//#endregion
}