<ng-container *ngIf="observableParams.value$ | async as params">
	<!-- Mode Edit (!RO) -->
	<div *ngIf="params && !params.readOnly">
		<ng-container
			*ngTemplateOutlet="(observableLayout.value$ | async) === 'inline' ? inline : normal; context : { params : params }"></ng-container>
	</div>

	<!-- Mode Visu (RO) -->
	<ng-container *ngIf="params && params.readOnly && fieldValue">
		<ng-container *ngTemplateOutlet="(!params.readonlyDisplayMode || params.readonlyDisplayMode === 'bar') ? bar : bubble;
			context : { fieldValue : fieldValue, params : params }"></ng-container>
	</ng-container>
</ng-container>

<ng-template #bar let-fieldValue="fieldValue" let-params="params">
	<div class="progress-bar-area">
		<ion-label [class]="'label-group txt14px margin-b'">
			{{ params.label }}
		</ion-label>
		<ion-progress-bar [value]="fieldValue/(params.maxValue-params.minValue)">
		</ion-progress-bar>
	</div>
</ng-template>

<ng-template #bubble let-fieldValue="fieldValue">
	<div [style]="observableBubbleColor.value$ | async" class="bubble">
		{{ fieldValue }}
	</div>
</ng-template>

<ng-template #normal let-params="params">
	<calao-slider [minValue]="params.minValue" [maxValue]="params.maxValue" [step]="params.step" [pin]="params.pin"
		[ticks]="params.ticks" [snaps]="params.snaps" [value]="(observableFieldValue.value$ | async)"
		[showMinValue]="params.showMinValue" [showMaxValue]="params.showMaxValue" [colors]="params.colors"
		[defaultValue]="params.defaultValue" [minLabel]="params.minLabel" [maxLabel]="params.maxLabel"
		[boundsPosition]="params.boundsPosition" (onValueChange)="fieldValue = $event"></calao-slider>
</ng-template>

<ng-template #inline let-params="params">
	<ng-container *ngIf="observableLayoutParams.value$ | async as inlineParams">
		<calao-inline-field-layout [icon]="inlineParams.icon" [label]="inlineParams.label"
			[separator]="inlineParams.separator">
			<calao-inline-field-layout-content>
				<div>
					{{ getFieldLabel(observableFieldValue.value$ | async, params) }} {{ params.unit ?? "" }}
				</div>
			</calao-inline-field-layout-content>
			<calao-inline-field-layout-bottom-content>
				<calao-slider [minValue]="params.minValue" [maxValue]="params.maxValue" [step]="params.step" [pin]="params.pin"
					[ticks]="params.ticks" [snaps]="params.snaps" [value]="(observableFieldValue.value$ | async)"
					[showMinValue]="params.showMinValue" [showMaxValue]="params.showMaxValue" [colors]="params.colors"
					[defaultValue]="params.defaultValue" [minLabel]="params.minLabel" [maxLabel]="params.maxLabel"
					[boundsPosition]="ESliderBoundsPosition.side" (onValueChange)="fieldValue = $event">
				</calao-slider>
			</calao-inline-field-layout-bottom-content>
		</calao-inline-field-layout>
	</ng-container>
</ng-template>