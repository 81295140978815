import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreBenchmarkService } from './services/store-benchmark.service';

@NgModule({
	declarations: [],
	exports: [],
	imports: [CommonModule],
	providers: [StoreBenchmarkService]
})
export class StoreBenchmarkModule { }
