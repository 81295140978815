<div class="header">
	<div>
		<ng-container *ngIf="observableNavigationTree.changes$ | async as tree">
			<div *ngIf="tree.length > 1" class="breadcrumbs">
				<ng-container *ngFor="let folder of tree; let i = index">
					<div class="folder-name" (click)="goToFolder(folder)">
						<ion-icon *ngIf="folder.icon" [name]="folder.icon"></ion-icon>
						<ion-label *ngIf="folder.name">{{ folder.name }}</ion-label>
					</div>
					<ion-icon *ngIf="tree.length !== i + 1" name="chevron-forward"></ion-icon>
				</ng-container>
			</div>
		</ng-container>
	</div>

	<div class="selector">
		<ng-container *ngFor="let option of observableDisplayModeOptions.changes$ | async">
			<ion-button *ngIf="!option.hidden" class="display-mode-btn" (click)="onDisplayModeChanged(option.value)"
				[ngClass]="((displayMode$ | async) === option.value) ? '' : 'not-selected'">
				<ion-icon slot="icon-only" [name]="option.icon"></ion-icon>
			</ion-button>
		</ng-container>
	</div>
</div>

<calao-doc-explorer-filter-bar [filterValues]="observableFilterValues.value$ | async"
	[notReadSelectorParams]="observableNotReadSelectorParams.value$ | async"
	(onFilterValuesChange)="onFilterValuesChange($event)">
</calao-doc-explorer-filter-bar>

<ng-container *ngIf="observableIsLoading.value$ | async; else content">
	<osapp-spinner></osapp-spinner>
</ng-container>

<ng-template #content>
	<div class="lists">
		<ng-container *ngIf="displayFolders$ | async">
			<div class="folder-list">
				<ng-container *ngFor="let folder of observablePaginationFolders.changes$ | async">
					<calao-folder-item [folder]="folder" (onOpenFolderClicked)="goToFolder($event)"></calao-folder-item>
				</ng-container>
			</div>
			<ion-button *ngIf="(observableFolders.length$ | async) > (observableFoldersLimit.value$ | async)" class="more-btn"
				(click)="moreFolders()" shape="round">
				Afficher plus de dossiers
			</ion-button>
		</ng-container>

		<ng-container [ngSwitch]="displayMode$ | async">
			<ng-container *ngSwitchCase="explorerDisplayMode.tab">
				<calao-entity-entries-list-base *ngIf="formListParams$ | async as params" [params]="params"
					(onItemClicked)="onOpenClicked($event)"
					(onDetailClicked)="onDetailClickedAsync($event)"></calao-entity-entries-list-base>
			</ng-container>
			<ng-container *ngSwitchDefault>
				<calao-document-list [documents]="observableDocuments.changes$ | async"
					[displayEmptyText]="(observableFolders.length$ | async) === 0 && (observableDocuments.length$ | async) === 0"
					[itemOptionsById]="observableListItemOptionsById.value$ | async"
					[displayNavigationTree]="observableDisplayNavigationTree.value$ | async"
					(onOpenClicked)="onOpenClicked($event)" (onDetailClicked)="onDetailClickedAsync($event)">
				</calao-document-list>
			</ng-container>
		</ng-container>
	</div>
</ng-template>