import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ContactHelper } from '../../../../helpers/contactHelper';
import { EPrefix } from '../../../../model/EPrefix';
import { IContact } from '../../../../model/contacts/IContact';
import { IGroup } from '../../../../model/contacts/IGroup';
import { Group } from '../../../../model/contacts/group';
import { ActivePageManager } from '../../../../model/navigation/ActivePageManager';
import { GroupsService } from '../../../../services/groups.service';
import { ObserveArray } from '../../../observable/decorators/observe-array.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { secure } from '../../../utils/rxjs/operators/secure';

@Component({
	selector: 'calao-groups-list',
	templateUrl: './groups-list.component.html',
	styleUrls: ['./groups-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupsListComponent extends DestroyableComponentBase implements OnInit {

	//#region FIELDS

	/** Événement lors d'un clic sur un item d'un groupe. */
	@Output("onGroupClicked") private readonly moOnGroupClicked = new EventEmitter<IGroup>();
	/** Événement lors d'un clic sur le bouton supprimer d'un item d'un groupe. */
	@Output("onDeleteGroupClicked") private readonly moOnDeleteGroupClickedEmitter = new EventEmitter<IGroup>();
	/** Événement lors d'un clic sur le bouton editer d'un item d'un groupe. */
	@Output("onEditGroupClicked") private readonly moOnEditGroupClickedEmitter = new EventEmitter<IGroup>();

	private readonly moActivePageManager = new ActivePageManager(this, this.ioRouter);
	private readonly moContactsByGroup = new ObservableProperty<Map<string, IContact[]>>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Tableau des groupes à afficher. */
	@Input() public groups?: Group[];
	@ObserveArray<GroupsListComponent>("groups")
	public readonly observableGroups = new ObservableArray<Group>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcGroups: GroupsService,
		private readonly ioRouter: Router
	) {
		super();
	}

	public ngOnInit(): void {
		this.observableGroups.changes$.pipe(
			switchMap((paGroups: Group[]) => this.isvcGroups.getGroupContacts(paGroups, [EPrefix.contact], true, this.moActivePageManager)),
			map((poContactsByGroup: Map<string, IContact[]>) => {
				this.moContactsByGroup.value = poContactsByGroup;
			}),
			secure(this)
		).subscribe();
	}

	public getMemberslabel$(poGroup: IGroup): Observable<string> {
		const lsGroupId: string = poGroup._id;
		return this.moContactsByGroup.value$.pipe(
			map((poContactsByGroup: Map<string, IContact[]>) => {
				if (poContactsByGroup.has(lsGroupId)) {
					const laContacts: IContact[] = poContactsByGroup.get(lsGroupId) ?? [];
					return laContacts.map((poContact: IContact) => ContactHelper.getCompleteFormattedName(poContact)).join(", ");
				}
				else
					return "Aucun membre";
			})
		);
	}

	public onGroupClicked(poGroup: IGroup): void {
		this.moOnGroupClicked.emit(poGroup);
	}

	public onEditGroupClicked(poGroup: IGroup): void {
		this.moOnEditGroupClickedEmitter.emit(poGroup);
	}

	public onDeleteGroupClicked(poGroup: IGroup): void {
		this.moOnDeleteGroupClickedEmitter.emit(poGroup);
	}

	//#endregion METHODS

}
