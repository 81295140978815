import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable, Subscription, of, timer } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { FileHelper } from '../../../../../helpers/fileHelper';
import { StringHelper } from '../../../../../helpers/stringHelper';
import { IBase64Data } from '../../../../../model/file/IBase64Data';
import { IPictureData } from '../../../../../model/picture/IPictureData';
import { Picture } from '../../../../../model/picture/picture';
import { ShowMessageParamsPopup } from '../../../../../services/interfaces/ShowMessageParamsPopup';
import { PatternResolverService } from '../../../../../services/pattern-resolver.service';
import { UiMessageService } from '../../../../../services/uiMessage.service';
import { CameraService } from '../../../../camera/services/camera.service';
import { IDmsData } from '../../../../dms/model/IDmsData';
import { DmsService } from '../../../../dms/services/dms.service';
import { LongGuidBuilder } from '../../../../guid/models/long-guid-builder';
import { PatternsHelper } from '../../../../utils/helpers/patterns.helper';
import { secure } from '../../../../utils/rxjs/operators/secure';
import { FieldBase } from '../../../models/FieldBase';
import { IPictureFieldParams } from '../../../models/IPictureFieldParams';
import { FormsService } from '../../../services/forms.service';

type PictureUrlAndAlt = { url: string, alt: string };

enum EPictureSourceType {
	none,
	base64,
	url,
	guid,
	defaultImageUrl
}

/** Champs de formulaire contenant une image. */
@Component({
	selector: 'calao-picture-field',
	templateUrl: './pictureField.component.html',
	styleUrls: ['./pictureField.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PictureFieldComponent extends FieldBase<Picture> implements OnInit {

	//#region FIELDS

	/** Alt et icône par défaut de l'image : "image". */
	private static readonly C_DEFAULT_ALT_OR_ICON = "image";

	/** On doit garder un longGuidBuilder pour être compatible avec la GED. */
	private readonly moGuidBuilder = new LongGuidBuilder();

	//#endregion

	//#region PROPERTIES

	/** Paramètres possibles pour ce composant. */
	public params: IPictureFieldParams;
	/** Image manipulée à afficher. */
	public imageSource: string | Blob;
	/** Indique si le composant est en train de charger une image ou non. */
	public isLoading = false;

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des popups et toasts. */
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcDms: DmsService,
		private readonly isvcPatternResolver: PatternResolverService,
		private readonly isvcCamera: CameraService,
		psvcForms: FormsService,
		/** Service de gestion du rafraîchissement de la vue. */
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	/** Endroit où initialiser le composant après sa création. */
	public override ngOnInit(): void {
		super.ngOnInit();
		this.initParams();
		this.setImageSource();

		if (this.params.readOnly) {
			this.observableFieldValue.value$.pipe(
				tap((poValue: Picture) => {
					this.initModel(poValue);
					this.detectChanges();
				}),
				secure(this)
			).subscribe();
		}

	}

	/** Initialise les données du modèle. */
	private initModel(poValue: Picture): void {
		if (!poValue && this.params.value)
			this.fillPictureFromValue();
		else if (!poValue)
			this.setEmptyPicture();

		this.setImageSource();

		if (this.params.forceSaving)
			this.model[this.fieldKey] = poValue;
	}

	private setImageSource() {
		const loValue: Picture = this.observableFieldValue.value;
		if (!StringHelper.isBlank(loValue?.base64))
			this.imageSource = loValue.base64;
		else if (!StringHelper.isBlank(loValue?.guid))
			this.innerInitModel_withGuid();
		else if (!StringHelper.isBlank(loValue?.url))
			this.imageSource = loValue.url;
		else if (!StringHelper.isBlank(this.params.defaultImageUrl))
			this.imageSource = this.params.defaultImageUrl;
		else
			this.imageSource = undefined;
	}

	private innerInitModel_withGuid(): void {
		this.isvcDms.get(this.observableFieldValue.value.guid)
			.pipe(
				tap(
					(poDmsData: IDmsData) => {
						this.imageSource = poDmsData.file.File;
						this.detectChanges();
					},
					poError => console.error("PICF.C::", poError)
				),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	/** Résoud le pattern d'une valeur et la retourne.
	 * @param psValue Valeur sous forme de patter qu'on veut résoudre.
	 */
	private getDynValue(psValue: string): PictureUrlAndAlt {
		return this.isvcPatternResolver.resolveFormsPattern(
			psValue.split(PatternsHelper.C_START_PATTERN)[1].split(PatternsHelper.C_END_PATTERN)[0],
			psValue
		);
	}

	/** Initialise les paramètres d'entrées du composant. */
	private initParams(): void {
		this.params = this.to.data;

		if (this.params.editable === false)
			this.params.readOnly = true;

		this.params.cameraOptions = this.isvcCamera.getAndSetCameraOptions(this.params.cameraOptions);

		if (!this.params.maxSizeKb)
			this.params.maxSizeKb = CameraService.C_MAX_PICTURE_SIZE_KB;

		if (StringHelper.isBlank(this.params.icon))
			this.params.icon = PictureFieldComponent.C_DEFAULT_ALT_OR_ICON;
	}

	/** Remplit l'image si elle a une valeur dans les options de paramètres. */
	private fillPictureFromValue(): void {
		const loPicture: IPictureData = this.getDynValue(this.params.value);

		if (loPicture) {
			this.setEmptyPicture();

			if (StringHelper.isBlank(loPicture.url))
				this.fieldValue.base64 = loPicture.base64;

			else {
				this.fieldValue.url = loPicture.url;
				this.fieldValue.alt = loPicture.alt;
				this.fieldValue.mimeType = FileHelper.extractMimeTypeFromFileNameWithExtension(loPicture.url);

				if (!this.fieldValue.mimeType)
					console.error(`PICF.C::No mimeType for picture from ${this.fieldValue.url ? "url" : "base64"}.`);
			}
		}
	}

	/** Définit l'objet image avec des paramètres vides. */
	private setEmptyPicture(): void {
		this.fieldValue = new Picture({
			mimeType: "",
			size: 0,
			base64: "",
			url: "",
			alt: PictureFieldComponent.C_DEFAULT_ALT_OR_ICON
		});
	}

	/** Reçoit un événement qui indique que le chargement d'une image est en cours (true) ou s'il n'y en a pas (false).
	 * @param pbIsLoading Indique si le composant set en train de charger une image ou non.
	 */
	public onLoadingChanged(pbIsLoading: boolean): void {
		if (this.isLoading !== pbIsLoading) {
			this.isLoading = pbIsLoading;
			this.detectChanges();
		}
	}

	/** L'image choisie par le filepicker a changé, on récupère sa base64 pour mettre à jour le modèle.
	 * @param poFile Fichier image récupéré lors d'un changement.
	 */
	public onSelectedPictureChanged(poFile?: File): void {
		if (poFile) {
			this.imageSource = poFile;

			const loTransform$: Observable<IBase64Data | undefined> = this.params.saveAsBase64 ?
				FileHelper.blobToBase64Data(poFile) : of(undefined);

			loTransform$
				.pipe(
					tap((poResult?: IBase64Data) => {
						this.setPicture(poFile, poResult);
						this.onLoadingChanged(false);
					}),
					takeUntil(this.fieldDestroyed$)
				)
				.subscribe();
		}
		else
			this.onLoadingChanged(false);
	}

	/** Modifie l'image courante par la nouvelle image sélectionnée/prise.
	 * @param poFile Fichier de la nouvelle image.
	 * @param poBase64Object Objet contenant la base64 de l'image, optionnel.
	 */
	private setPicture(poFile: File, poBase64Object?: IBase64Data): void {
		// Si la nouvelle valeur est différente de l'ancienne, il faut marquer le champ comme 'dirty'.
		if (this.fieldValue?.alt !== poFile.name || this.fieldValue?.size !== poFile.size || this.fieldValue?.mimeType !== poFile.type)
			this.markAsDirty();

		this.fieldValue = new Picture({
			base64: this.params.saveAsBase64 ? poBase64Object.base64 : undefined,
			mimeType: poFile.type,
			size: poFile.size,
			alt: poFile.name,
			guid: this.params.saveAsBase64 ? undefined : this.moGuidBuilder.build(),
			file: poFile
		});
	}

	/** Prend une photo et met à jour le modèle. */
	public takePhoto(): void {
		const loTimerSubscription: Subscription = timer(500).pipe(tap(_ => this.onLoadingChanged(true))).subscribe();

		this.isvcCamera.takePicture(this.params.cameraOptions, this.params.maxSizeKb)
			.pipe(
				tap(
					(poFile?: File) => this.onSelectedPictureChanged(poFile),
					poError => {
						console.error("PICF.C::", poError);
						this.onLoadingChanged(false); // Dans le cas d'une erreur, on enlève le loader.
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Une erreur est survenue lors du traitement de l'image.", header: "Erreur" }));
					}
				),
				finalize(() => loTimerSubscription.unsubscribe()), // On se désabonne du timer à la fin (prise de photo ou erreur).
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	/** Gère l'erreur d'une balise `ion-img` en remplaçant sa source si possible ou en ne mettant aucune source. */
	public onError(): void {
		this.setImageSource();
	}

	//#endregion
}