import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { combineLatest, map } from 'rxjs';
import { DateTimePickerComponent } from '../../../../../../components/date/dateTimePicker.component';
import { DateHelper } from '../../../../../../helpers/dateHelper';
import { EDateTimePickerMode } from '../../../../../../model/date/EDateTimePickerMode';
import { ETimetablePattern } from '../../../../../../model/date/ETimetablePattern';
import { IDateTimePickerParams } from '../../../../../../model/date/IDateTimePickerParams';
import { ObserveProperty } from '../../../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IInlineFieldLayoutParams } from '../inline-field-layout/models/iinline-field-layout-params';
import { IInlineField } from '../inline-field-layout/models/iinlineField';
import { IDateFieldParams } from './models/idate-field-params';

@Component({
	selector: 'calao-date-time-field',
	templateUrl: './date-time-field.component.html',
	styleUrls: ['./date-time-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTimeFieldComponent extends FieldBase<Date> implements OnInit, IInlineField {

	//#region PROPERTIES

	/** `true` si readOnly, sinon `false`. Est `false` par défaut. */
	public readonly observableIsReadOnly = new ObservableProperty<boolean>(false);

	/** Masque le sélecteur pour l'heure. Est `false` par défaut. */
	public readonly observableHideTimePicker = new ObservableProperty<boolean>(false);

	/** Préfixe à afficher devant la date. */
	public readonly observableDatePrefix = new ObservableProperty<string>();

	public readonly observableDatePickerParams = new ObservableProperty<IDateTimePickerParams>({
		pickerMode: EDateTimePickerMode.date,
		displayFormat: ETimetablePattern.dd_MMM_yyyy,
		hideIcon: true
	});

	public readonly observableTimePickerParams = new ObservableProperty<IDateTimePickerParams>({
		pickerMode: EDateTimePickerMode.time,
		displayFormat: ETimetablePattern.HH_mm,
		hideIcon: true
	});

	/** Style de layout à afficher (undefined par défaut). */
	public layout: "inline";
	@ObserveProperty<DateTimeFieldComponent>({ sourcePropertyKey: "layout" })
	public readonly observableLayout = new ObservableProperty<"inline">();

	/** Paramètres d'affichage à passer au layout. */
	public layoutParams: IInlineFieldLayoutParams;
	@ObserveProperty<DateTimeFieldComponent>({ sourcePropertyKey: "layoutParams" })
	public readonly observableLayoutParams = new ObservableProperty<IInlineFieldLayoutParams>();

	/** Masque le libellé du champs dans le layout si `true` et que le champs est renseigné. */
	public hideLabelWhenFilled: boolean;
	@ObserveProperty<DateTimeFieldComponent>({ sourcePropertyKey: "hideLabelWhenFilled" })
	public readonly observableHideWhenFilled = new ObservableProperty<boolean>();

	/** Masque l'heure. */
	public hideTime: boolean;
	@ObserveProperty<DateTimeFieldComponent>({ sourcePropertyKey: "hideTime" })
	public readonly observableHideTime = new ObservableProperty<boolean>();

	/** Masque le libellé du champs. */
	public readonly observableHideLabel = new ObservableProperty<boolean>().bind(
		combineLatest([this.observableHideWhenFilled.value$, this.observableFieldValue.value$]).pipe(
			map(([pbHideLabelWhenFilled, poDate]: [boolean, Date]) => pbHideLabelWhenFilled && !!poDate)
		),
		this
	);

	@ViewChild('datePicker', { static: false }) datePickerRef: DateTimePickerComponent;
	@ViewChild('timePicker', { static: false }) timePickerRef: DateTimePickerComponent;

	/** Format d'affichage de la date. */
	public readonly observableDateLabelFormat = new ObservableProperty<string>("EEE dd MMMM yyyy");

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		const loParams = this.props as any as IDateFieldParams;

		this.observableIsReadOnly.value = !!loParams.readOnly;
		this.observableHideTimePicker.value = !!loParams.hideTimePicker;
		this.observableDatePrefix.value = loParams.datePrefix;
		this.observableLayout.value = loParams.layout;
		this.observableLayoutParams.value = loParams.layoutParams;
		this.observableHideWhenFilled.value = loParams.hideLabelWhenFilled;
		this.observableDatePickerParams.value = { ...this.observableDatePickerParams.value, ...(loParams.dateTimePickerParams ?? {}) };
		this.observableDateLabelFormat.value = loParams.dateFormat;
		this.observableHideTime.value = loParams.hideTime;

		if (!this.fieldValue && !loParams.emptyDefaultValue)
			this.fieldValue = DateHelper.roundMinutes(new Date(), loParams.roundDefaultDateToNextMinutes);
	}

	public onDateChanged(pdStartDate: Date): void {
		this.fieldValue = pdStartDate;
	}

	public onDateClicked(): void {
		this.datePickerRef.pickDate();
	}

	public onTimeClicked(): void {
		this.timePickerRef.pickDate();
	}

	//#endregion METHODS

}
