<ng-container *ngTemplateOutlet="(observableLayout.value$ | async) === 'inline' ? inline : normal"></ng-container>

<ng-template #normal>
	<div class="container">
		<ion-checkbox labelPlacement="end" [checked]="observableChecked.value$ | async" (ionChange)="onChanged($event)"
			[ngClass]="observableLabelClass.value$ | async">
			<ng-container *ngIf="(observableIconPosition.value$ | async) === EIconPosition.start">
				<ion-icon *ngIf="observableIcon.value$ | async as icon" [name]="icon"
					[ngClass]="observableIconClass.value$ | async"></ion-icon>
			</ng-container>
			{{ observableLabel.value$ | async }}
		</ion-checkbox>
		<ng-container *ngIf="(observableIconPosition.value$ | async) === EIconPosition.end">
			<ion-icon *ngIf="observableIcon.value$ | async as icon" [name]="icon"
				[ngClass]="observableIconClass.value$ | async"></ion-icon>
		</ng-container>
	</div>
</ng-template>

<ng-template #inline>
	<ng-container *ngIf="observableLayoutParams.value$ | async as inlineParams">
		<calao-inline-field-layout [icon]="inlineParams.icon" [label]="inlineParams.label"
			[separator]="inlineParams.separator" [verticalCenter]="true" (onFieldClicked)="onFieldClicked()">
			<calao-inline-field-layout-content>
				<ion-checkbox labelPlacement="end" [checked]="observableChecked.value$ | async"
					(ionChange)="onChanged($event)"></ion-checkbox>
			</calao-inline-field-layout-content>
		</calao-inline-field-layout>
	</ng-container>
</ng-template>