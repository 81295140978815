<ng-container *ngIf="observableParams.value$ | async as params">
	<ng-container *ngIf="!params.readOnly">
		<calao-inline-list-field-layout [icon]="layoutParams.icon || 'person'" [required]="required"
			[addButtonLabel]="$any(layoutParams).addButtonLabel || 'Ajouter ...'" [separator]="layoutParams.separator"
			(onAddClicked)="onAddDocumentClickedAsync()">
			<ng-container *ngIf="observableTags.changes$ | async as tags">
				<calao-deletable-tags-list *ngIf="tags.length > 0" [tags]="tags"
					(onRemoveTagClicked)="removeDocument($event.id)">
				</calao-deletable-tags-list>
			</ng-container>
		</calao-inline-list-field-layout>
	</ng-container>

	<ng-container *ngIf="params.readOnly">
		<div *ngIf="observableModels.changes$ | async as documents" class="documents-visu">
			<div *ngFor="let document of documents" class="document" (click)="onOpenDocumentAsync(document)">
				<ion-icon name="document" [style.color]="params.color"></ion-icon>
				<span [style.color]="params.color">{{ document.name }}</span>
			</div>
		</div>
	</ng-container>
</ng-container>