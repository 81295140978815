import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EDatabaseRole } from '@calaosoft/osapp/model/store/EDatabaseRole';
import { BatteryModule } from '@calaosoft/osapp/modules/battery/battery.module';
import { CalendarEventsInvitationService } from '@calaosoft/osapp/modules/calendar-events/services/calendar-events-invitation.service';
import { ConflictsModule } from '@calaosoft/osapp/modules/conflicts/conflicts.module';
import { IDatabaseGroupingConfiguration } from '@calaosoft/osapp/modules/database-synchro/model/IDatabaseGroupingConfiguration';
import { DATABASES_GROUPING_CONFIG, DMS_SYNC_CONFIG } from '@calaosoft/osapp/modules/database-synchro/services/database-synchro.service';
import { DeeplinkModule } from '@calaosoft/osapp/modules/deeplink/deeplink.module';
import { FormModule } from '@calaosoft/osapp/modules/forms/form/form.module';
import { GdprModule } from '@calaosoft/osapp/modules/gdpr/gdpr.module';
import { GuidModule } from '@calaosoft/osapp/modules/guid/guid.module';
import { ShortGuidBuilder } from '@calaosoft/osapp/modules/guid/models/short-guid-builder';
import { PatchModule } from '@calaosoft/osapp/modules/patch/patch.module';
import { ChangeTrackingFromIdbToSqlitePatch } from '@calaosoft/osapp/modules/patch/patches/change-tracking-from-idb-to-sqlite-patch';
import { Sqlite2ToSqliteEvcoreExtbuildFreePatch } from '@calaosoft/osapp/modules/patch/patches/sqlite2-to-sqlite-evcore-extbuild-free-patch';
import { PermissionsModule } from '@calaosoft/osapp/modules/permissions/permissions.module';
import { RoutingModule } from '@calaosoft/osapp/modules/routing/routing.module';
import { CanGoBackService as OsappCanGoBackService } from '@calaosoft/osapp/modules/routing/services/can-go-back.service';
import { OsappModule } from '@calaosoft/osapp/osapp.module';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import * as constants from '../config';
import { TRADE_FORMLY_CONFIG_OPTION } from '../model/TradeComponentRegister';
import { CustomModule } from '../modules/customElements.module';
import { InterventionStatementModule } from '../modules/intervention-statement/intervention-statement.module';
import { PlanningRHModule } from '../modules/planning-rh/planning-rh.module';
import { CanGoBackService } from '../modules/routing/services/can-go-back.service';
import { TradeDmsModule } from '../modules/trade-dms/trade-dms.module';
import { TradeEventsModule } from '../modules/trade-events/trade-events.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const databasesGroupingConfigs: IDatabaseGroupingConfiguration[] = [
	{
		roles: [EDatabaseRole.workspace, EDatabaseRole.userContext],
		title: "Espace de travail",
		description: "tâches, évènements, contacts..."
	},
	{
		roles: [EDatabaseRole.conversations],
		title: "Conversations"
	}
];

function initializeAppFactory(psvcInvitation: CalendarEventsInvitationService): () => void {
	return () => psvcInvitation.init();
}

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserAnimationsModule,
		IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
		OsappModule,
		GuidModule.forRoot({ builderType: ShortGuidBuilder }),
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		DeeplinkModule,
		ServiceWorkerModule.register("ngsw-worker.js", { enabled: true, registrationStrategy: "registerImmediately" }),
		TradeDmsModule,
		PatchModule.forRoot([Sqlite2ToSqliteEvcoreExtbuildFreePatch, ChangeTrackingFromIdbToSqlitePatch]),
		GdprModule,
		ConflictsModule,
		PermissionsModule,
		BatteryModule,
		PlanningRHModule,
		InterventionStatementModule,
		TradeEventsModule,
		BrowserAnimationsModule,
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
		RoutingModule.forRoot({ defaultRoute: "" }),
		FormModule,
		FormlyModule.forChild(TRADE_FORMLY_CONFIG_OPTION),
		CustomModule // Le CustomModule de Trade doit être placé après le FormlyModule afin que les composants de formulaires déclarés par le CustomModule soient prioritaires et surchargent ceux d'osapp
	],
	bootstrap: [AppComponent],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: OsappCanGoBackService, useClass: CanGoBackService },
		{ provide: DATABASES_GROUPING_CONFIG, useValue: databasesGroupingConfigs },
		{ provide: DMS_SYNC_CONFIG, useValue: { title: "Documents", description: "photos..." } },
		constants.appInfo.applicationServiceProvider,
		constants.appInfo.domainServiceProvider,
		{ provide: APP_INITIALIZER, deps: [CalendarEventsInvitationService], useFactory: initializeAppFactory, multi: true }
	]
})
export class AppModule { }