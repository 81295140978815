import { NgModule, Provider, Type } from "@angular/core";
import { SharedComponentsModule } from "@calaosoft/osapp/components/sharedComponents.module";
import { HeaderModule } from "@calaosoft/osapp/modules/header";
import { ListsModule } from "@calaosoft/osapp/modules/lists/lists.module";
import { CalaoMapModule } from "@calaosoft/osapp/modules/maps/map.module";
import { TabsModule } from "@calaosoft/osapp/modules/tabs/tabs.module";
import { SectorPanelComponent } from "./components/sector-panel/sector-panel.component";
import { SectorComparisonModelComponent } from "./components/sectors-comparison/sector-comparison-model.component";
import { SectorMapPage } from "./pages/sector-map/sector-map.page";
import { SectorsListComponent } from "./pages/sectors-list/sectors-list.component";
import { SectorsMapPage } from "./pages/sectors-map/sectors-map.page";
import { SectorsService } from "./services/sectors.service";

const modules: Type<any>[] = [
	SharedComponentsModule,
	ListsModule,
	CalaoMapModule,
	TabsModule,
	HeaderModule
];
const components: Type<any>[] = [
	SectorsListComponent,
	SectorMapPage,
	SectorsMapPage,
	SectorPanelComponent,
	SectorComparisonModelComponent,
];
const providers: Provider[] = [
	SectorsService
];

@NgModule({
	exports: components,
	declarations: components,
	providers: providers,
	imports: modules
})
export class SectorsModule { }