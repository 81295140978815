import { registerLocaleData } from '@angular/common';
import LocaleFr from '@angular/common/locales/fr';
import { NgModule, Type } from '@angular/core';
import { AdaptiveDatePipe } from './adaptiveDate.pipe';
import { BooleanPipe } from './boolean.pipe';
import { BytePipe } from './byte.pipe';
import { ContactAddressPipe } from './contactAddress.pipe';
import { ContactNamePipe } from './contactName.pipe';
import { DateWithLocalePipe } from './dateWithLocale.pipe';
import { DelayPipe } from './delay.pipe';
import { FieldsBindingPipe } from './fieldsBinding.pipe';
import { GroupMemberNamesEllipsisPipe } from './groupMemberNamesEllipsis.pipe';
import { ContactPipe, IContactPipe } from './icontact.pipe';
import { ImageSanitizerPipe } from './imageSanitizer.pipe';
import { IsDatePipe } from './isDate.pipe';
import { PatternPipe } from './pattern.pipe';
import { PercentagePipe } from './percentage.pipe';
import { PriceFormatPipe } from './priceFormat.pipe';
import { ReversePipe } from './reverse.pipe';
import { TimetablePipe } from './timetable.pipe';
import { UrlSanitizerpipe } from './urlSanitizer.pipe';

const pipes: Type<any>[] = [
	FieldsBindingPipe,
	DateWithLocalePipe,
	PriceFormatPipe,
	IsDatePipe,
	AdaptiveDatePipe,
	IContactPipe,
	ContactPipe,
	ImageSanitizerPipe,
	UrlSanitizerpipe,
	PatternPipe,
	GroupMemberNamesEllipsisPipe,
	ContactAddressPipe,
	ContactNamePipe,
	TimetablePipe,
	BytePipe,
	BooleanPipe,
	DelayPipe,
	ReversePipe,
	PercentagePipe
];

@NgModule({
	imports: [],
	exports: pipes,
	declarations: pipes,
	providers: pipes
})
export class PipeModule {
	constructor() {
		registerLocaleData(LocaleFr);
	}
}
