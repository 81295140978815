<ng-container *ngTemplateOutlet="(observableLayout.value$ | async) === 'inline' ? inline : normal"></ng-container>

<!-- Template pour un affichage classique -->
<ng-template #normal>
	<ion-item lines="none" (click)="onInputClicked()">
		<input matInput class="input" style="border: none;" [hidden]="to.hidden" [(ngModel)]="displayValue"
			[formControl]="$any(formControl)" [ngStyle]="to.uppercase? {'text-transform': 'uppercase'} : {}"
			[type]="inputType" [pattern]="to.pattern" [maxlength]="maxLength" [placeholder]="to.placeholder" /> </ion-item>
</ng-template>

<!-- Template pour un affichage inline -->
<ng-template #inline>
	<ng-container *ngIf="observableLayoutParams.value$ | async as inlineParams">
		<calao-inline-field-layout [icon]="inlineParams.icon" [label]="inlineParams.label"
			[separator]="inlineParams.separator" [hideLabel]="observableHideLabel.value$ | async"
			(onFieldClicked)="onInputClicked()">
			<calao-inline-field-layout-content>
				<ng-container *ngTemplateOutlet="input"></ng-container>
			</calao-inline-field-layout-content>
		</calao-inline-field-layout>
	</ng-container>
</ng-template>

<ng-template #input>
	<input #inputInline matInput class="inline" style="border: none;" [hidden]="to.hidden" [(ngModel)]="displayValue"
		[value]="displayValue" [formControl]="$any(formControl)"
		[ngStyle]="to.uppercase? {'text-transform': 'uppercase'} : {}" [type]="inputType" [pattern]="to.pattern"
		[maxlength]="maxLength" [placeholder]="to.placeholder"
		[class.align-end]="!(observableHideWhenFilled.value$ | async)" />
</ng-template>