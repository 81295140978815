import { plainToClass } from "class-transformer";
import { PathHelper } from "../../../helpers/path-helper";
import { StringHelper } from "../../../helpers/stringHelper";
import { ResolveModel } from "../../utils/models/decorators/resolve-model.decorator";
import { FolderConfig } from "./folder-config";
import { IDocExplorerConfig } from "./idoc-explorer-config";

export class DocExplorerConfig implements IDocExplorerConfig {

	//#region PROPERTIES

	/** @implements */
	public _id: string;
	/** @implements */
	public _rev?: string;
	/** @implements */
	public _deleted?: boolean;
	/** @implements */
	public deleted?: boolean;
	/** @implements */
	public _conflicts?: string[];
	/** @implements */
	@ResolveModel(FolderConfig)
	public paths: FolderConfig[];

	//#endregion

	//#region METHODS

	public getFolderConfig(psPath?: string): FolderConfig | undefined {
		const laPathParts: string[] = psPath?.split(
			psPath.includes(PathHelper.C_PATH_SEPARATOR) ?
				PathHelper.C_PATH_SEPARATOR :
				PathHelper.C_DATABASE_PATH_SEPARATOR
		) ?? [];

		const laPaths: FolderConfig[] = laPathParts[0] === "trash" ? this.paths.map((poFolderConfig: FolderConfig) => {
			return plainToClass(FolderConfig, { ...poFolderConfig, path: poFolderConfig.path === "trash" ? poFolderConfig.path : `trash/${poFolderConfig.path}` });
		}) : this.paths;

		return laPaths.find((poFolderConfig: FolderConfig) => {
			const laFolderConfigPathParts: string[] = poFolderConfig.path.split(PathHelper.C_PATH_SEPARATOR);

			return laFolderConfigPathParts.length === laPathParts.length &&
				laFolderConfigPathParts.every((psPart: string, pnIndex: number) => new RegExp(psPart).test(laPathParts[pnIndex]));
		});
	}

	public getChildStaticFoldersConfig(psPath: string): FolderConfig[] {
		const laPathParts: string[] = StringHelper.isBlank(psPath) ? [] : psPath.split(PathHelper.C_PATH_SEPARATOR);
		const laPaths: FolderConfig[] = laPathParts[0] === "trash" ? this.paths.map((poFolderConfig: FolderConfig) => {
			return plainToClass(FolderConfig, { ...poFolderConfig, path: poFolderConfig.path === "trash" ? poFolderConfig.path : `trash/${poFolderConfig.path}` });
		}) : this.paths;

		return laPaths.filter((poFolderConfig: FolderConfig) => {
			if (/\([^)]*\)/.test(poFolderConfig.path))
				return false;

			const laFolderConfigPathParts: string[] = poFolderConfig.path.split(PathHelper.C_PATH_SEPARATOR);

			return laFolderConfigPathParts.length === laPathParts.length + 1 &&
				laFolderConfigPathParts.every((psPart: string, pnIndex: number) => laPathParts[pnIndex] ? new RegExp(psPart).test(laPathParts[pnIndex]) : true);
		});
	}

	//#endregion

}
