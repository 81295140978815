import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { IdHelper } from "@calaosoft/osapp/helpers/idHelper";
import { ObservableProperty } from "@calaosoft/osapp/modules/observable/models/observable-property";
import { DestroyableComponentBase } from "@calaosoft/osapp/modules/utils/components/destroyable-component-base";
import { secure } from "@calaosoft/osapp/modules/utils/rxjs/operators/secure";
import { ShowMessageParamsPopup } from "@calaosoft/osapp/services/interfaces/ShowMessageParamsPopup";
import { UiMessageService } from "@calaosoft/osapp/services/uiMessage.service";
import { defer, of } from "rxjs";
import { filter, mergeMap, tap } from "rxjs/operators";
import { C_PREFIX_SECTORIZATION } from "../../../../app/app.constants";
import { Sectorization } from "../../models/sectorization";
import { Sector } from '../../sectors/models/sector';
import { SectorsService } from "../../sectors/services/sectors.service";
import { SectorizationsService } from "../../services/sectorizations.service";

@Component({
	selector: "sectorization-comparison",
	templateUrl: "./sectorization-comparison.page.html",
	styleUrls: ["./sectorization-comparison.page.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectorizationComparisonPage extends DestroyableComponentBase {

	public titleFirstGroup: ObservableProperty = new ObservableProperty<string>("");
	public sectorsFirstGroup: ObservableProperty = new ObservableProperty<Sector[]>([]);

	public titleSecondGroup: ObservableProperty = new ObservableProperty<string>("");
	public sectorsSecondGroup: ObservableProperty = new ObservableProperty<Sector[]>([]);

	constructor(
		private readonly ioActivatedRoute: ActivatedRoute,
		private readonly isvcSctrz: SectorizationsService,
		private readonly isvcSector: SectorsService,
		private readonly isvcUiMessage: UiMessageService,

	) {
		super();

		this.set();
	}

	public set() {
		this.ioActivatedRoute.params.pipe(
			mergeMap((loParams: Params) => this.isvcSctrz.getSectorization$(IdHelper.buildId(C_PREFIX_SECTORIZATION, loParams.entityGuid))
				.pipe(
					filter((poSctrz: Sectorization | undefined) => !!poSctrz),
					tap((poSctrz: Sectorization) => this.titleFirstGroup.value = poSctrz.title),
					mergeMap((poSctrz: Sectorization) => !poSctrz.linkedSectors?.length ?
						defer(() => this.isvcSector.navigateToSectorizationAsync(loParams.entityGuid))
							.pipe(tap(_ => this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
								header: "Comparaison Impossible",
								message: "Une des sectorisations est vide : la comparaison est impossible.",
							})))) :
						of(poSctrz)
					),
					filter((e: boolean | Sectorization) => typeof e !== "boolean"),
					tap((poSctrz: Sectorization) => this.sectorsFirstGroup.value = poSctrz.linkedSectors?.length ? poSctrz.linkedSectors : []),
					mergeMap(_ => this.isvcSctrz.getSectorization$(IdHelper.buildId(C_PREFIX_SECTORIZATION, loParams.entityGuid2))),
					tap((poSctrz: Sectorization) => this.titleSecondGroup.value = poSctrz.title),
					mergeMap((poSctrz: Sectorization) => !poSctrz.linkedSectors?.length ?
						defer(() => this.isvcSector.navigateToSectorizationAsync(loParams.entityGuid))
							.pipe(tap(_ => this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
								header: "Comparaison Impossible",
								message: "Une des sectorisations est vide : la comparaison est impossible.",
							})))) :
						of(poSctrz)
					),
					filter((e: boolean | Sectorization) => typeof e !== "boolean"),
					tap((poSctrz: Sectorization) => this.sectorsSecondGroup.value = poSctrz.linkedSectors?.length ? poSctrz.linkedSectors : []),
				)),
			secure(this)
		).subscribe();
	}

}
