import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ObserveArray } from '@calaosoft/osapp/modules/observable/decorators/observe-array.decorator';
import { ObserveProperty } from '@calaosoft/osapp/modules/observable/decorators/observe-property.decorator';
import { ObservableArray } from '@calaosoft/osapp/modules/observable/models/observable-array';
import { ObservableProperty } from '@calaosoft/osapp/modules/observable/models/observable-property';
import { DestroyableComponentBase } from '@calaosoft/osapp/modules/utils/components/destroyable-component-base';
import { takeUntil, tap } from 'rxjs/operators';
import { TaskOccurrence } from '../../models/task-occurrence';

@Component({
	selector: 'trade-todo-item',
	templateUrl: './todo-item.component.html',
	styleUrls: ['./todo-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TodoItemComponent extends DestroyableComponentBase implements OnInit {

	//#region PROPERTIES

	/** Couleur de fond de la partie gauche de l'item. */
	@Input() public itemStartBackgroundColor?: string;
	@ObserveProperty<TodoItemComponent>({ sourcePropertyKey: "itemStartBackgroundColor" })
	public readonly observableItemStartBackgroundColor = new ObservableProperty<string>("#F1F4FF");

	/** Couleur de l'icône dans la partie gauche de l'item. */
	@Input() public itemStartIconColor?: string;
	@ObserveProperty<TodoItemComponent>({ sourcePropertyKey: "itemStartIconColor" })
	public readonly observableItemStartIconColor = new ObservableProperty<string>("#000000");

	/** Couleur de fond de l'item. */
	@Input() public itemBackgroundColor?: string;
	@ObserveProperty<TodoItemComponent>({ sourcePropertyKey: "itemBackgroundColor" })
	public readonly observableItemBackgroundColor = new ObservableProperty<string>("var(--ion-color-primary-contrast)");

	/** Nombre total de tâches non programmées. */
	@Input() public totalCount?: number;
	@ObserveProperty<TodoItemComponent>({ sourcePropertyKey: "totalCount" })
	public readonly observableTotalCount = new ObservableProperty<number>();

	/** Nombres de tâches restantes en plus de celles affichées. */
	@Input() public diffCount?: number;
	@ObserveProperty<TodoItemComponent>({ sourcePropertyKey: "diffCount" })
	public readonly observableDiffCount = new ObservableProperty<number>();

	public readonly observableDiffCountLabel = new ObservableProperty<string>();

	/** Tableau des tâches à lister dans l'item toDo. */
	@Input() public tasks: TaskOccurrence[];
	@ObserveArray<TodoItemComponent>("tasks")
	public readonly observableTasks = new ObservableArray<TaskOccurrence>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcRouter: Router,
		private readonly poActivatedRoute: ActivatedRoute
	) {
		super();
	}

	public ngOnInit() {
		this.observableDiffCount.value$.pipe(
			tap((pnDiff: number) => {
				this.observableDiffCountLabel.value = (pnDiff > 0) ? `+${pnDiff} autre${pnDiff > 1 ? "s" : ""}` : "";
			}),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	public navigateToTodoListPageAsync(): Promise<boolean> {
		return this.isvcRouter.navigate(["todo"], { relativeTo: this.poActivatedRoute })
	}

	//#endregion METHODS

}
