import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { FormHelper } from '../../../../../helpers/formHelper';
import { StringHelper } from '../../../../../helpers/stringHelper';
import { EFormEventType } from '../../../../../model/forms/EFormEventType';
import { FieldBase } from '../../../../../model/forms/FieldBase';
import { ITemplateOptions } from '../../../../../model/forms/fieldComponent/ITemplateOptions';
import { IInputField } from '../../../../../model/forms/fieldComponent/specifications/IInputField';
import { EInput } from '../../../../../model/uiMessage/EInput';
import { FormsService } from '../../../../../services/forms.service';

@Component({
	selector: 'calao-input-field',
	templateUrl: './inputField.component.html',
	styleUrls: ['./inputs.component.scss']
})
export class InputFieldComponent extends FieldBase<string> implements IInputField, OnInit { //todo : params.

	//#region FIELDS

	/** Nombre maximum de chiffres pour un numéro de téléphone (10 chiffres, ou un préfixe (+33) et 9 chiffres). */
	private static readonly C_TEL_MAX_DIGITS = 12;

	//#endregion

	//#region PROPERTIES

	@ViewChild("input") public input: ElementRef;

	/** Valeur réellement affichée. */
	private msDisplayValue: string;

	public get displayValue(): string {
		return this.msDisplayValue;
	}
	public set displayValue(psDisplayValue: string) {
		if (this.msDisplayValue !== psDisplayValue) {
			this.msDisplayValue = psDisplayValue;
			this.onModelChanged();
		}
	}

	/** Définit la clavier à afficher pour la navigateur et des critères de gestion supplémentaire sont appliqués si le type est `tel`. */
	public inputType: string = EInput.text;
	/** `true` si la valeur attendue est un numéro de téléphone. */
	public isTel = false;
	/** Taille max de caractères sur le champ. */
	public maxLength: number;
	/** Indique quel type de champ on traite. */
	public type: string;
	/** Paramètres supplémentaires du champ. */
	public templateOptions: ITemplateOptions<never>;

	//#endregion

	//#region METHODS

	constructor(psvcForms: FormsService) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.isvcForms.waitFormEvent(this.model._id, EFormEventType.beforeSubmit)
			.pipe(
				tap(_ => {
					if (this.isTel)
						this.fieldValue = this.getTelValue(); // Créé un effet de clignotement où l'input du num de tel est en erreur, mais fonctionne.
					else if (this.to.uppercase && !StringHelper.isBlank(this.fieldValue))
						this.fieldValue = this.fieldValue.toUpperCase();
				}),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();

		if (this.to.type) {
			this.inputType = this.to.type;
			if (this.to.type === EInput.tel)
				this.initTelValue();
			else
				this.msDisplayValue = this.fieldValue ? this.fieldValue : "";
		}
		else
			this.msDisplayValue = this.fieldValue ? this.fieldValue : "";

		if (this.to.data)
			this.to.disabled = !FormHelper.canBeFilled(this.to.data.oneTimeFilled, this.model, this.fieldValue);

		if (this.input && this.to.focus)
			this.input.nativeElement.focus();
	}

	/** Initialise des attributs de classe si le type de la valeur est `tel`. */
	public initTelValue(): void {
		this.isTel = true;
		this.setTelValue(this.fieldValue);
	}

	/** Convertit un numéro de téléphone au format `+33...`. */
	private getTelValue(): string {
		// Si le champs est défini, on supprime les espaces vides de début et de fin, ensuite on transforme le "0" du début en "+33", enfin on transforme les espaces consécutifs en un seul.
		return StringHelper.isBlank(this.msDisplayValue) ? this.msDisplayValue : this.msDisplayValue.trim().replace(/^0/, "+33").replace(/ +/g, " ");
	}

	/** Récupère un numéro de téléphone dans n'importe quel format, et l'applique à la variable `displayValue` dans un format utilisateur `06...`. */
	private setTelValue(psValue: string): void {
		if (!StringHelper.isBlank(psValue) && psValue.startsWith("+33"))
			this.msDisplayValue = `0${psValue.slice(3, psValue.length)}`;
		else
			this.msDisplayValue = psValue;
	}

	public onModelChanged(): void {
		this.markAsDirty();
	}

	//#endregion
}