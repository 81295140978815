import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { CameraModule } from '../../../modules/camera/camera.module';
import { ColorsModule } from '../../../modules/colors/colors.module';
import { ConversationsModule } from '../../../modules/conversations/conversations.module';
import { DrawingModule } from '../../../modules/drawing/drawing.module';
import { GroupsModule } from '../../../modules/groups/groups.module';
import { HooksModule } from '../../../modules/hooks/hooks.module';
import { SectorsModule } from '../../../modules/sectors/sectors.module';
import { SelectorModule } from '../../../modules/selector/selector.module';
import { ContactsModule } from '../../contacts/contacts.module';
import { DateModule } from '../../date/date.module';
import { FilePickerModule } from '../../filePicker/filePicker.module';
import { GalleryModule } from '../../gallery/gallery.module';
import { LinkModule } from '../../link/link.module';
import { LinkedItemsListModule } from '../../linkedItemsList/linkedItemsList.module';
import { SharedComponentsModule } from '../../sharedComponents.module';
import { DynHostWrapperComponent } from './customFields/dynHostWrapper.component';
import { FormDynHostWrapperComponent } from './customFields/formDynHostWrapper.component';
import { C_FORMLY_CUSTOM, FormlyConfig } from './fields.config';

const modules: Array<Type<any> | ModuleWithProviders<{}>> = [
	IonicModule,
	FormlyModule.forRoot(FormlyConfig),
	SharedComponentsModule,
	LinkModule,
	GalleryModule,
	FilePickerModule,
	ContactsModule,
	DateModule,
	LinkedItemsListModule,
	MatInputModule,
	MatChipsModule,
	ConversationsModule,
	CameraModule,
	ColorsModule,
	SectorsModule,
	SelectorModule,
	DrawingModule,
	HooksModule,
	GroupsModule
];
const components: Array<Type<any>> = [
	FormDynHostWrapperComponent,
	DynHostWrapperComponent
];

/** Module de gestion des formulaires. */
@NgModule({
	imports: modules,
	exports: [...components],
	declarations: [C_FORMLY_CUSTOM, ...components]
})
export class FormModule { }