<calao-sub-header>
	<calao-sub-header-title>
		Répartir les businesses
	</calao-sub-header-title>
</calao-sub-header>

<div class="top-button">
	<ion-button *ngIf="isChanging" slot="start" (click)="switchToComparisonMode()"
		[disabled]="!(changeRows.changes$ | async).length" expand="block">
		Voir le avant/après</ion-button>

	<ion-button *ngIf="!isChanging" slot="start" (click)="switchToChangingMode()"
		[disabled]="!(changeRows.changes$ | async).length" expand="block">
		Faire de nouveaux changements
	</ion-button>

	<ion-button *ngIf="isChanging" slot="start" (click)="optmizeSectorsClicked()" expand="block" color="warning">
		Optimiser</ion-button>
</div>

<br>

<div *ngIf="isChanging" class="biz-list-container">
	<div *ngIf="(changeRows.changes$ | async).length" class="biz-list">

		<ion-breadcrumbs *ngFor="let breadcrumb of (changeRows.changes$ | async)">

			<ion-breadcrumb *ngIf="(breadcrumb.sourceSector?.value$ | async)">
				<ion-icon slot="start" name="extension-puzzle"></ion-icon>
				<ion-button shape="round" color="light">{{(breadcrumb.sourceSector?.value$ | async).title}}
				</ion-button>
				<ion-icon slot="separator" name="arrow-forward-circle"></ion-icon>
			</ion-breadcrumb>

			<ion-breadcrumb>
				<ion-icon slot="start" name="business"></ion-icon>
				<ion-button (click)="onBizSelectorModalClicked(breadcrumb)" shape="round"
					color="light">{{(breadcrumb.business.value$ | async).name}} </ion-button>
				<ion-icon slot="separator" name="arrow-forward-circle"></ion-icon>
			</ion-breadcrumb>

			<ion-breadcrumb>
				<ion-icon slot="start" name="extension-puzzle"></ion-icon>
				<ion-button (click)="onSectorSelectorModalClicked(breadcrumb)" shape="round"
					color="light">{{(breadcrumb.targetSector.value$ | async).title}}
				</ion-button>
				<ion-icon slot="separator" name="remove"></ion-icon>
			</ion-breadcrumb>

			<ion-breadcrumb (click)="deleteChangeRow(breadcrumb)">
				<ion-icon name="trash-bin"></ion-icon>
			</ion-breadcrumb>

		</ion-breadcrumbs>
	</div>

	<div class="changes-button">
		<ion-button (click)="addChange()" expand="block">
			Ajouter un changement
		</ion-button>
	</div>

</div>

<div *ngIf="!isChanging" class="biz-list-container">
	<sectors-group-comparison [titleFirstGroup]="titleFirstGroup.value$ | async"
		[sectorsFirstGroup]="sectorsFirstGroup.value$ | async" [titleSecondGroup]="titleSecondGroup.value$ | async"
		[sectorsSecondGroup]="sectorsSecondGroup.value$ | async">

	</sectors-group-comparison>
</div>

<div class="bottom-container">

	<div class="bottom-buttons">
		<ion-button (click)="close()" expand="block">
			Annuler
		</ion-button>

		<ion-button (click)="closeAndSaveChanges()" expand="block">
			Valider les changements
		</ion-button>

	</div>
</div>