import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'calao-inline-field-layout-content',
	templateUrl: './inline-field-layout-content.component.html',
	styleUrls: ['./inline-field-layout-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineFieldLayoutContentComponent {

	//#region METHODS

	constructor() { }

	//#endregion METHODS

}
