<ng-container *ngIf="observableView.value$ | async as view">
	<ng-container *ngTemplateOutlet="isMobile ? mobileHeader : webappHeader; context { view : view }"></ng-container>

	<div id="agenda" [ngSwitch]="view"
		[ngClass]="(observableToDoItemCount.value$ | async) > 0 ? 'calendar-todo-item container' : 'calendar-full-height container'">
		<ng-container *ngSwitchCase="CalendarView.Month">
			<ng-container *ngIf="(observableToDoItemCount.value$ | async) > 0">
				<ng-container *ngTemplateOutlet="todoItemTemplate"></ng-container>
			</ng-container>
			<mwl-calendar-month-view [viewDate]="observableViewDate.value$ | async" [events]="events.changes$ | async"
				[refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen$ | async" [locale]="locale" [weekStartsOn]="weekStartsOn"
				[weekendDays]="weekendDays" [openDayEventsTemplate]="openDayEventsTemplate" [cellTemplate]="monthCellTemplate"
				[headerTemplate]="monthHeaderTemplate" (dayClicked)="raiseDayClicked($event.day)"
				(eventClicked)="raiseCalendarEventClicked($event.event)" (eventTimesChanged)="raiseEventTimesChanged($event)"
				(beforeViewRender)="raiseViewRenderChanged($event)" class="view" [ngClass]="isMobile ? '' : 'webapp-view'">
			</mwl-calendar-month-view>
		</ng-container>
		<ng-container *ngSwitchCase="CalendarView.Week">
			<ng-container *ngTemplateOutlet="todoItemTemplate"></ng-container>
			<mwl-calendar-week-view [viewDate]="observableViewDate.value$ | async" [events]="events.changes$ | async"
				[refresh]="refresh" [weekStartsOn]="weekStartsOn" [weekendDays]="weekendDays" [locale]="locale"
				[hourSegments]="observableHourSegments.value$ | async" [eventTemplate]="weekViewEventTemplate"
				[headerTemplate]="weekHeaderTemplate" (eventClicked)="raiseCalendarEventClicked($event.event)"
				(eventTimesChanged)="raiseEventTimesChanged($event)" (beforeViewRender)="raiseViewRenderChanged($event)"
				class="view" [ngClass]="isMobile ? '' : 'webapp-view'">
			</mwl-calendar-week-view>
		</ng-container>
		<ng-container *ngSwitchCase="CalendarView.Day">
			<ng-container *ngTemplateOutlet="todoItemTemplate"></ng-container>
			<mwl-calendar-day-view [viewDate]="observableViewDate.value$ | async" [events]="events.changes$ | async"
				[refresh]="refresh" [hourSegments]="observableHourSegments.value$ | async"
				(eventClicked)="raiseCalendarEventClicked($event.event)" [locale]="locale" [eventTemplate]="eventTemplate"
				(eventTimesChanged)="raiseEventTimesChanged($event)" (beforeViewRender)="raiseViewRenderChanged($event)"
				class="view" [ngClass]="isMobile ? '' : 'webapp-view'">
			</mwl-calendar-day-view>
		</ng-container>
		<ng-container *ngSwitchCase="CalendarView.List">
			<div class="list-view-todo-item">
				<ng-container *ngTemplateOutlet="todoItemTemplate"></ng-container>
			</div>
			<ng-container *ngTemplateOutlet="listViewTemplate"></ng-container>
		</ng-container>
	</div>
</ng-container>

<ng-template #defaultPreviousButton>
	<ion-icon name="chevron-back"></ion-icon>
</ng-template>

<ng-template #defaultNextButton>
	<ion-icon name="chevron-forward"></ion-icon>
</ng-template>

<ng-template #previousListButton>
	<ion-segment-button value="previous" (click)="changeListAndDayViewDate(true)">
		<ng-container *ngIf="observablePreviousPeriodButtonLabel.value$ | async as label; else defaultPreviousButton">
			<ion-label>{{ label }}</ion-label>
		</ng-container>
	</ion-segment-button>
</ng-template>

<ng-template #nextListButton>
	<ion-segment-button value="next" (click)="changeListAndDayViewDate()">
		<ng-container *ngIf="observableNextPeriodButtonLabel.value$ | async as label; else defaultNextButton">
			<ion-label>{{ label }}</ion-label>
		</ng-container>
	</ion-segment-button>
</ng-template>

<ng-template #monthHeaderTemplate>
	<div class="cal-cell-row cal-header" role="row">
		<div class="cal-cell" *ngFor="let day of weekDays.changes$ | async" [class.cal-past]="day.isPast"
			[class.cal-today]="day.isToday" [class.cal-future]="day.isFuture" [class.cal-weekend]="day.isWeekend"
			[ngClass]="day.cssClass" tabindex="0" role="columnheader">
			{{ day.date | calendarDate : 'weekViewColumnHeader' : locale }}
		</div>
	</div>
</ng-template>

<ng-template #weekHeaderTemplate>
	<div class="cal-day-headers" role="row">
		<div class="cal-header flex-col" *ngFor="let day of weekDays.changes$ | async" [class.cal-past]="day.isPast"
			[class.cal-today]="day.isToday" [class.cal-future]="day.isFuture" [class.cal-weekend]="day.isWeekend"
			[ngClass]="day.cssClass" tabindex="0" role="columnheader">
			{{ day.date | calendarDate : 'weekViewColumnHeader' : locale }}
			<span>{{
				day.date | calendarDate : 'monthViewDayNumber' : locale
				}}</span>
		</div>
	</div>
</ng-template>

<ng-template #mobileHeader let-view="view">
	<div class="mobile-header">
		<ng-container
			*ngTemplateOutlet="!(observableDisplayMobileHeaderSearch.value$ | async) ? mobileHeaderDate : mobileHeaderSearch; context : { view : view }"></ng-container>
	</div>
</ng-template>

<ng-template #webappHeader let-view="view">
	<div class="segments">
		<div>
			<ion-segment mode="md" [value]="view" class="calendar-view-selector">
				<ion-segment-button [value]="CalendarView.Month" (click)="setView(CalendarView.Month)">
					<ion-label>{{ observableMonthViewButtonLabel.value$ | async }}</ion-label>
				</ion-segment-button>
				<ion-segment-button [value]="CalendarView.Week" (click)="setView(CalendarView.Week)">
					<ion-label>{{ observableWeekViewButtonLabel.value$ | async }}</ion-label>
				</ion-segment-button>
				<ion-segment-button [value]="CalendarView.Day" (click)="setView(CalendarView.Day)"
					[class.active]="view === CalendarView.Day">
					<ion-label>{{ observableDayViewButtonLabel.value$ | async }}</ion-label>
				</ion-segment-button>
				<ion-segment-button [value]="CalendarView.List" (click)="setView(CalendarView.List)">
					<ion-label>{{ observableListViewButtonLabel.value$ | async }}</ion-label>
				</ion-segment-button>
			</ion-segment>
		</div>
		<div>
			<ion-segment mode="md" class="calendar-date-selector">
				<ng-container *ngIf="view !== CalendarView.List; else previousListButton">
					<ion-segment-button value="previous" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
						(click)="changeListAndDayViewDate(true)">
						<ng-container
							*ngIf="observablePreviousPeriodButtonLabel.value$ | async as label; else defaultPreviousButton">
							<ion-label>{{ label }}</ion-label>
						</ng-container>
					</ion-segment-button>
				</ng-container>
				<ion-segment-button value="today" mwlCalendarToday (click)="onChooseDateTime(dateTimePicker)">
					<ion-label>
						<h3>{{ viewDate | calendarDate:(getCalendarDateParam(view)):locale:weekStartsOn }}</h3>
					</ion-label>
					<osapp-date-time #dateTimePicker [params]="datePickerParams" [model]="viewDate"
						(modelChange)="ondDateChanged($event)" class="hidden">
					</osapp-date-time>
				</ion-segment-button>
				<ng-container *ngIf="view !== CalendarView.List; else nextListButton">
					<ion-segment-button value="next" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
						(click)="changeListAndDayViewDate()">
						<ng-container *ngIf="observableNextPeriodButtonLabel.value$ | async as label; else defaultNextButton">
							<ion-label>{{ label }}</ion-label>
						</ng-container>
					</ion-segment-button>
				</ng-container>
			</ion-segment>
		</div>
	</div>
</ng-template>

<ng-template #mobileHeaderDate let-view="view">
	<div class="date-left-part">
		<div class="date" (click)="onChooseDateTime(dateTimePicker)">
			<ion-label>{{ viewDate | calendarDate:(getCalendarDateParam(view)):locale:weekStartsOn }}</ion-label>
			<osapp-date-time #dateTimePicker [params]="datePickerParams" [model]="viewDate"
				(modelChange)="ondDateChanged($event)" class="hidden">
			</osapp-date-time>
		</div>
	</div>
	<div class="date-right-part">
		<div class="current-day-container" (click)="setView(view)">
			<div class="current-day">
				<ion-icon name="calendar-clear"></ion-icon>
				<ion-label>{{ (observableTodayDate.value$ | async) | dateWithLocale : 'fr-FR' : 'dd'
					}}</ion-label>
			</div>
		</div>
		<div class="calendar-icon">
			<ng-container *ngTemplateOutlet="mobileHeaderChangeViewIcon"></ng-container>
		</div>
		<div class="search-icon">
			<ion-icon name="search" (click)="toggleSearch()"></ion-icon>
			<ion-badge *ngIf="observabletotalNbFilters.value$ | async as label" color="warning">{{ label }}</ion-badge>
		</div>
		<div class="option-icon">
			<ion-icon name="ellipsis-vertical" (click)="presentAdvancedOptions($event)"></ion-icon>
		</div>
	</div>
</ng-template>

<ng-template #mobileHeaderSearch>
	<div class="search-left-part">
		<ng-container [ngTemplateOutlet]="filterbarTemplate"></ng-container>
	</div>
	<div class="search-right-part">
		<ion-label (click)="toggleSearch()">Fermer</ion-label>
	</div>
</ng-template>

<ng-template #mobileHeaderChangeViewIcon>
	<ng-container [ngSwitch]="view">
		<ng-container *ngSwitchCase="CalendarView.Month">
			<ion-icon name="calendar" (click)="presentViewSelectorAsync($event)"></ion-icon>
		</ng-container>
		<ng-container *ngSwitchCase="CalendarView.Week">
			<ion-icon name="week" (click)="presentViewSelectorAsync($event)"></ion-icon>
		</ng-container>
		<ng-container *ngSwitchCase="CalendarView.Day">
			<ion-icon name="today" (click)="presentViewSelectorAsync($event)"></ion-icon>
		</ng-container>
		<ng-container *ngSwitchCase="CalendarView.List">
			<ion-icon name="list" (click)="presentViewSelectorAsync($event)"></ion-icon>
		</ng-container>
	</ng-container>

</ng-template>