import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, defer, of, throwError } from 'rxjs';
import { catchError, concatMap, map, mergeMap, reduce } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { MapHelper } from '../../../helpers/mapHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { StoreHelper } from '../../../helpers/storeHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { EPrefix } from '../../../model/EPrefix';
import { IDescriptorVersionRange, Version } from '../../../model/application/Version';
import { ConfigData } from '../../../model/config/ConfigData';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { Store } from '../../../services/store.service';
import { DestroyableServiceBase } from '../../services/models/destroyable-service-base';
import { IDataSourceRemoteChanges } from '../../store/model/IDataSourceRemoteChanges';
import { IGetVersionedDocumentsParams } from '../models/iget-versioned-documents-params';

@Injectable({
	providedIn: 'root'
})
export class VersionedDocumentsService extends DestroyableServiceBase {

	//#region FIELDS

	private static readonly C_LOG_ID = "VERSIONEDDOCUMENTS.S::";

	//#endregion FIELDS

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly ioHttpClient: HttpClient,
	) {
		super();
	}

	/** Récupère les descripteurs de documents les plus récents par rapport à la version actuelle de l'application.
	 * @param poGetParams Objet de configuration pour la récupération des documents.
	 */
	public getVersionedDocumentsByGuid$<T extends IStoreDocument>(poGetParams: IGetVersionedDocumentsParams): Observable<Map<string, T>> {
		const loParams: IDataSourceRemoteChanges = this.getVersionedDocumentsDataSource(poGetParams);

		return combineLatest([
			this.isvcStore.get(loParams),
			this.getLocalVersionedDocuments$(poGetParams)
		]).pipe(
			map(([paVersionedDocuments, paLocalVersionedDocuments]: [T[], T[]]) => {
				const loVersionedDocumentByGuid: Map<string, T> =
					this.indexHighestPriorityVersionedDocumentByGuid(paVersionedDocuments, paLocalVersionedDocuments);

				return loVersionedDocumentByGuid;
			}),
			catchError(poError => {
				console.error(`${VersionedDocumentsService.C_LOG_ID}Error when getting data :`, poError);
				return throwError(() => poError);
			})
		);
	}

	private getVersionedDocumentsDataSource(poGetParams: IGetVersionedDocumentsParams): IDataSourceRemoteChanges {
		const laDatabaseIds: string[] = poGetParams.roles.map((peRole: EDatabaseRole) => this.isvcStore.getDatabasesIdsByRole(peRole)).flat();
		return {
			databasesIds: laDatabaseIds,
			viewParams: {
				include_docs: true,
				startkey: poGetParams.prefix,
				endkey: `${poGetParams.prefix}${Store.C_ANYTHING_CODE_ASCII}`
			},
			remoteChanges: !!poGetParams.activePageManager,
			activePageManager: poGetParams.activePageManager
		};
	}

	private getLocalVersionedDocuments$<T extends IStoreDocument>(poGetParams: IGetVersionedDocumentsParams): Observable<T[]> {
		return defer(() => poGetParams.ids ?? []).pipe(
			concatMap((psId: string) => this.getLocalVersionedDocument$(poGetParams, psId)),
			reduce((paVersionedDocuments: T[], poVersionedDocument?: T) => {
				if (poVersionedDocument)
					paVersionedDocuments.push(poVersionedDocument);

				return paVersionedDocuments;
			}, [])
		);
	}

	private getLocalVersionedDocument$<T extends IStoreDocument>(poGetParams: IGetVersionedDocumentsParams, psFormDescId: string): Observable<T | undefined> {
		const lsPath = `${poGetParams.baseUrl}${IdHelper.buildId(poGetParams.prefix, psFormDescId)}${poGetParams.suffix}`;
		return defer(() => this.checkIfLocalVersionedDocumentExistsAsync(lsPath)).pipe( // Évite de retourner l'index.html à la place d'une 404.
			mergeMap((pbExists: boolean) => {
				if (!pbExists)
					return of(undefined);

				return this.ioHttpClient.get<T>(lsPath).pipe(
					catchError(poError => {
						if (poError.status === 404)
							return of(undefined);
						else
							return throwError(() => poError);
					})
				);
			})
		);
	}

	private checkIfLocalVersionedDocumentExistsAsync(psJsonFilePath: string): Promise<boolean> {
		return this.ioHttpClient.head(psJsonFilePath, { headers: new HttpHeaders({ accept: "application/json" }), observe: "response" }).pipe(
			map((poResponse: HttpResponse<any>) => poResponse.status === 200),
			catchError(poError => of(poError.status === 200))
		).toPromise();
	}

	private indexHighestPriorityVersionedDocumentByGuid<T extends IStoreDocument>(paVersionedDocuments: T[], paLocalVersionedDocuments: T[]): Map<string, T> {
		const loVersionedDocumentByGuid = new Map<string, T>();
		const laWsDatabaseIds: string[] = this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace);
		const loVersionedDocumentsByGuid: Map<string, T[]> = ArrayHelper.groupBy(
			paVersionedDocuments,
			(poVersionedDocument: T) => this.getVersionedDocumentGuidFromVersionedDocumentId(poVersionedDocument._id)
		);
		const loLocalDescriptorByGuid: Map<string, T> = ArrayHelper.groupByUnique(
			paLocalVersionedDocuments,
			(poLocalVersionedDocument: T) => this.getVersionedDocumentGuidFromVersionedDocumentId(poLocalVersionedDocument._id)
		);
		const laKeys: string[] = ArrayHelper.unique(
			[MapHelper.keysToArray(loVersionedDocumentsByGuid), MapHelper.keysToArray(loLocalDescriptorByGuid)].flat()
		);

		if (StringHelper.isBlank(ConfigData.appInfo.appVersion)) {
			console.error(`${VersionedDocumentsService.C_LOG_ID}Form versionedDocument retrieval failed : AppVersion is missing.`);
		} else {
			const lsVersion: string = ConfigData.appInfo.appVersion;
			laKeys.forEach((psKey: string) => {
				const lsId: string | undefined = loLocalDescriptorByGuid.get(psKey)?._id || loVersionedDocumentsByGuid.get(psKey)?.[0]?._id;

				const loVersionedDocument: T | undefined = this.getHighestPriorityVersionedDocument(
					psKey,
					lsVersion,
					loVersionedDocumentsByGuid,
					loLocalDescriptorByGuid,
					laWsDatabaseIds,
					this.getPrefix(lsId)
				);

				if (loVersionedDocument)
					loVersionedDocumentByGuid.set(psKey, loVersionedDocument);
			});
		}
		return loVersionedDocumentByGuid;
	}

	private getPrefix(psId?: string): EPrefix {
		return `${ArrayHelper.getFirstElement(psId?.split("_"))}_` as EPrefix;
	}

	private getVersionedDocumentGuidFromVersionedDocumentId(psEntityDescId?: string): string {
		return psEntityDescId?.split("_")[1] ?? "";
	}

	private getHighestPriorityVersionedDocument<T extends IStoreDocument>(
		psKey: string,
		psVersion: string,
		poVersionedDocumentsByGuid: Map<string, T[]>,
		poLocalVersionedDocumentByGuid: Map<string, T>,
		paWsDatabaseIds: string[],
		pePrefix: EPrefix
	): T | undefined {
		const loRange: IDescriptorVersionRange = Version.getDescriptorVersionRange(IdHelper.buildId(pePrefix, psKey), psVersion);
		const laVersionedDocuments: T[] = poVersionedDocumentsByGuid.get(psKey) ?? [];
		const loLocalVersionedDocument: T | undefined = poLocalVersionedDocumentByGuid.get(psKey);

		const laConfigVersionedDocuments: T[] = [];
		const loWsVersionedDocuments: T[] = [];

		laVersionedDocuments.forEach((poVersionedDocument: T) => {
			if (loRange.minVersion <= poVersionedDocument._id && loRange.maxVersion >= poVersionedDocument._id) {
				if (paWsDatabaseIds.includes(StoreHelper.getDocumentCacheData(poVersionedDocument)?.databaseId ?? ""))
					loWsVersionedDocuments.push(poVersionedDocument);

				else
					laConfigVersionedDocuments.push(poVersionedDocument);
			}
		});

		const loVersionedDocument: T | undefined = this.getHighestPriorityFormVersionedDocument(
			ArrayHelper.getLastElement(laConfigVersionedDocuments),
			ArrayHelper.getLastElement(loWsVersionedDocuments),
			loLocalVersionedDocument
		);

		return loVersionedDocument;
	}

	private getHighestPriorityFormVersionedDocument<T extends IStoreDocument>(
		poConfigVersionedDocument?: T,
		poWsVersionedDocument?: T,
		poLocalVersionedDocument?: T
	): T | undefined {
		console.debug(`${VersionedDocumentsService.C_LOG_ID}ConfigVersionedDocument: "${poConfigVersionedDocument?._id}", WsVersionedDocument: "${poWsVersionedDocument?._id}, LocalVersionedDocument: "${poLocalVersionedDocument?._id}".`);

		const laVersionedDocumentsByPriority: (T | undefined)[] = [poConfigVersionedDocument, poLocalVersionedDocument]; // Tri des documents en fonction de la priorisation.
		const laDefineVersionedDocuments: T[] = laVersionedDocumentsByPriority.filter((poVersionedDocument?: T) => ObjectHelper.isDefined(poVersionedDocument)) as T[];

		const laSortedVersionedDocuments: T[] = laDefineVersionedDocuments.sort((poVersionedDocumentA: T, poVersionedDocumentB: T) => {
			const lnVersionA: Version = Version.fromDescriptorId(poVersionedDocumentA._id);
			const lnVersionB: Version = Version.fromDescriptorId(poVersionedDocumentB._id);

			return lnVersionB.compareTo(lnVersionA);
		});

		// Le descripteur venant du workspace est toujours prioritaire. On le place donc en premier.
		if (ObjectHelper.isDefined(poWsVersionedDocument))
			laSortedVersionedDocuments.unshift(poWsVersionedDocument);

		return ArrayHelper.getFirstElement(laSortedVersionedDocuments);
	}

	//#endregion METHODS

}
