import { Observable, Subject, defer, of, throwError, timer } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { GuidHelper } from '../../helpers/guidHelper';
import { NumberHelper } from '../../helpers/numberHelper';
import { ITaskParams } from '../../model/backgroundTask/taskParams/ITaskParams';
import { ConfigData } from '../../model/config/ConfigData';
import { ObservableProperty } from '../../modules/observable/models/observable-property';
import { TaskDescriptor } from './TaskDescriptor';

export abstract class TaskBase<T extends ITaskParams = ITaskParams> {

	//#region FIELDS

	private static readonly C_TSKLOG_ID = "TSKBS::";

	private readonly moRestartSubject = new Subject<void>();

	protected readonly moObservableProgress = new ObservableProperty<number>(0);

	protected readonly moObservablePaused = new ObservableProperty<boolean>(false);

	protected readonly moObservableRunning = new ObservableProperty<boolean>(false);

	//#endregion FIELDS

	//#region PROPERTIES

	/** Descripteur de la tâche. */
	public descriptor: TaskDescriptor<T>;

	public get progress$(): Observable<number> {
		return this.moObservableProgress.value$;
	}

	public get progressLabel$(): Observable<string> {
		return this.progress$.pipe(map((pnProgress: number) => `${pnProgress * 100}%`));
	}

	public get paused(): boolean {
		return this.moObservablePaused.value;
	}
	public set paused(pbPaused: boolean) {
		this.moObservablePaused.value = pbPaused;
	}

	public get paused$(): Observable<boolean> {
		return this.moObservablePaused.value$;
	}

	public get isRunning(): boolean {
		return this.moObservableRunning.value;
	}

	public get restart$(): Observable<void> {
		return this.moRestartSubject.asObservable();
	}

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		this.descriptor = poDescriptor;
	}

	/** Crée l'observable qui permet d'exécuter la tâche. */
	public run$(): Observable<any> {
		const lsInstanceId = GuidHelper.newGuid();

		return defer(() => {
			this.moObservableRunning.value = true;
			this.moObservableProgress.value = 0;
			return of(undefined);
		}).pipe(
			tap(() => console.debug(`${TaskBase.C_TSKLOG_ID}Starting task "${this.descriptor.name}" with instance id "${lsInstanceId}".`)),
			mergeMap(() => this.execTask$()),
			tap(
				{
					next: () => this.moObservableProgress.value = 1,
					error: () => this.moObservableProgress.value = 0,
					finalize: () => {
						console.debug(`${TaskBase.C_TSKLOG_ID}Ended task "${this.descriptor.name}" with instance id "${lsInstanceId}".`);
						this.moObservableRunning.value = false;
					}
				}
			)
		);
	}

	public sendRestartEvent(): void {
		this.moRestartSubject.next(undefined);
	}

	protected abstract execTask$(): Observable<any>;

	/** Stratégie de recommencement de la tâche.
	 * @param poErrors$ Observable qui fournit chaque erreur de la tâche.
	 */
	public retryStrategy(poErrors$: Observable<any>): Observable<any> {
		return poErrors$ // Observable qui fournit chaque erreur de la tâche.
			.pipe(
				mergeMap((poError: any, pnNbError: number) => {
					// Si le nombre d'échecs est égal au nombre max de répétitions accordées et que ce n'est pas une tâche cyclique.
					if (pnNbError >= ConfigData.backgroundTask.maxRepeatTask && !this.descriptor.intervalRepetition)
						return throwError(() => poError);
					else {
						const lnIntervalRepetition: number | undefined =
							this.descriptor.intervalRepetition instanceof Array ?
								ArrayHelper.getFirstElement(this.descriptor.intervalRepetition) :
								this.descriptor.intervalRepetition;
						const lbHasValidIntervalRepetition: boolean = NumberHelper.isValidStrictPositive(lnIntervalRepetition);

						if (!lbHasValidIntervalRepetition)
							this.descriptor.retryInterval *= this.descriptor.intervalMultiplicator;

						const lnIntervalTime: number =
							lbHasValidIntervalRepetition ? lnIntervalRepetition : this.descriptor.retryInterval;

						this.descriptor.lastExec = new Date();

						return timer(lnIntervalTime);
					}
				})
			);
	}

	//#endregion
}