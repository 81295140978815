import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ObserveProperty } from '../../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../../observable/models/observable-property';
import { FieldBase } from '../../../models/FieldBase';
import { FormsService } from '../../../services/forms.service';
import { IInlineFieldLayoutParams } from './inline-field-layout/models/iinline-field-layout-params';
import { IInlineField } from './inline-field-layout/models/iinlineField';

enum EIconPosition {
	start = "start",
	end = "end"
}

export type ICheckboxFieldComponentParams = IInlineField & {
	label: string;
	icon: string;
	labelClass?: string;
	iconPosition?: EIconPosition;
	iconClass?: string;
}

@Component({
	selector: 'calao-checkbox-field',
	templateUrl: './checkbox-field.component.html',
	styleUrls: ['./checkbox-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxFieldComponent extends FieldBase<boolean> implements OnInit, IInlineField {

	//#region PROPERTIES

	/** `true` si le checkbox est coché, sinon `false`. */
	@Input() public checked?: boolean;
	@ObserveProperty<CheckboxFieldComponent>({ sourcePropertyKey: "checked" })
	public readonly observableChecked = new ObservableProperty<boolean>();

	/** Libellé affiché à coté du checkbox. */
	public readonly observableLabel = new ObservableProperty<string>();

	/** Icône affiché à coté du libellé. */
	public readonly observableIcon = new ObservableProperty<string>();

	/** Placement de l'icône par rapport au libellé. A la fin par défaut. */
	@Input() public iconPosition?: EIconPosition;
	@ObserveProperty<CheckboxFieldComponent>({ sourcePropertyKey: "iconPosition" })
	public readonly observableIconPosition = new ObservableProperty<EIconPosition>(EIconPosition.end);

	/** Classe css à appliquer au libellé pour le styliser. */
	@Input() public labelClass?: string;
	@ObserveProperty<CheckboxFieldComponent>({ sourcePropertyKey: "labelClass" })
	public readonly observableLabelClass = new ObservableProperty<string>();

	/** Classe css à appliquer à l'icône pour le styliser. */
	@Input() public iconClass?: string;
	@ObserveProperty<CheckboxFieldComponent>({ sourcePropertyKey: "iconClass" })
	public readonly observableIconClass = new ObservableProperty<string>();

	public EIconPosition = EIconPosition;

	/** Style de layout à afficher (undefined par défaut). */
	@Input() public layout: "inline";
	@ObserveProperty<CheckboxFieldComponent>({ sourcePropertyKey: "layout" })
	public readonly observableLayout = new ObservableProperty<"inline">();

	/** Paramètres d'affichage à passer au layout. */
	@Input() public layoutParams: IInlineFieldLayoutParams;
	@ObserveProperty<CheckboxFieldComponent>({ sourcePropertyKey: "layoutParams" })
	public readonly observableLayoutParams = new ObservableProperty<IInlineFieldLayoutParams>();

	/** Masque le libellé du champs dans le layout si `true` et que le champs est renseigné. */
	@Input() public hideLabelWhenFilled: boolean;
	@ObserveProperty<CheckboxFieldComponent>({ sourcePropertyKey: "hideLabelWhenFilled" })
	public readonly observableHideWhenFilled = new ObservableProperty<boolean>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(protected override readonly isvcForms: FormsService) {
		super(isvcForms);
	}

	public override ngOnInit(): void {
		const loParams: ICheckboxFieldComponentParams = this.props.params;
		this.observableChecked.value = !!this.model[this.fieldKey];
		this.observableLabel.value = loParams.label;
		this.observableIcon.value = loParams.icon;
		this.observableIconPosition.value = loParams.iconPosition;
		this.observableLabelClass.value = loParams.labelClass;
		this.observableIconClass.value = loParams.iconClass;
		this.observableLayout.value = loParams.layout;
		this.observableLayoutParams.value = loParams.layoutParams;
		this.observableHideWhenFilled.value = loParams.hideLabelWhenFilled;
	}

	public onChanged(poEvent: Event): void {
		this.observableFieldValue.value = (poEvent as CustomEvent).detail.checked;
	}

	public onFieldClicked(): void {
		this.observableChecked.value = !this.observableChecked.value;
	}

	//#endregion METHODS

}
