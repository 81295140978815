import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CheckboxChangeEventDetail, CheckboxCustomEvent, ModalController, RadioGroupChangeEventDetail, RadioGroupCustomEvent } from '@ionic/angular';
import { PlatformService } from '../../../../../../../services/platform.service';
import { ModalComponentBase } from '../../../../../../modal';
import { ObserveArray } from '../../../../../../observable/decorators/observe-array.decorator';
import { ObserveProperty } from '../../../../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../../../../observable/models/observable-array';
import { ObservableProperty } from '../../../../../../observable/models/observable-property';
import { IOption } from '../models/ioption';
import { IRadioAndCheckboxFieldModal } from '../models/iradio-and-checkbox-field-modal';
import { IRadioAndCheckboxFieldModalResult } from '../models/iradio-and-checkbox-field-modal-result';

@Component({
	selector: 'calao-radio-and-checkbox-field-modal',
	templateUrl: './radio-and-checkbox-field-modal.component.html',
	styleUrls: ['./radio-and-checkbox-field-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioAndCheckboxFieldModalComponent extends ModalComponentBase<IRadioAndCheckboxFieldModal> {

	//#region PROPERTIES

	/** Libellé de la modale. */
	@Input() public label?: string;
	@ObserveProperty<RadioAndCheckboxFieldModalComponent>({ sourcePropertyKey: "label" })
	public readonly observableLabel = new ObservableProperty<string>();

	/** Tableau des options pour le sélecteur par bouton radio. */
	@Input() public radioOptions?: IOption[];
	@ObserveArray<RadioAndCheckboxFieldModalComponent>("radioOptions")
	public readonly observableRadioOptions = new ObservableArray<IOption>();

	/** Valeur sélectionnée pour le sélecteur radio. */
	@Input() public radioValue?: string;
	@ObserveProperty<RadioAndCheckboxFieldModalComponent>({ sourcePropertyKey: "radioValue" })
	public readonly observableRadioValue = new ObservableProperty<string>();

	/** tableau des options pour le sélecteur par chexbox. */
	@Input() public checkboxOptions?: IOption[];
	@ObserveArray<RadioAndCheckboxFieldModalComponent>("checkboxOptions")
	public readonly observableCheckboxOptions = new ObservableArray<IOption>();

	/** Valeurs sélectionnées pour le sélecteur par checkbox. */
	public checkboxValues: string[];
	@ObserveArray<RadioAndCheckboxFieldModalComponent>("checkboxValues")
	public readonly observableCheckboxValues = new ObservableArray<string>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
	) {
		super(poModalCtrl, psvcPlatform);
	}

	public cancelAsync(): Promise<boolean> {
		return this.close();
	}

	public validateAsync(): Promise<boolean> {
		return this.close(
			{
				radioValue: this.observableRadioValue.value,
				checkboxValues: this.observableCheckboxValues
			} as IRadioAndCheckboxFieldModalResult
		);
	}

	public isChecked(psValue: string): boolean {
		return this.observableCheckboxValues.some((psCheckboxValue: string) => psCheckboxValue === psValue);
	}

	public onCheckboxChanged(poEvent: Event): void {
		const loEvent: CheckboxChangeEventDetail = (poEvent as CheckboxCustomEvent).detail;
		loEvent.checked ? this.addValueToCheckboxValues(loEvent.value) : this.removeValueFromCheckboxValues(loEvent.value);
	}

	private addValueToCheckboxValues(psValue: string): void {
		this.observableCheckboxValues.push(psValue);
	}

	private removeValueFromCheckboxValues(psValue: string): void {
		this.observableCheckboxValues.remove(psValue);
	}

	public onRadioChange(poEvent: Event): void {
		const loEvent: RadioGroupChangeEventDetail = (poEvent as RadioGroupCustomEvent).detail;
		this.observableRadioValue.value = loEvent.value;
	}

	//#endregion METHODS

}
