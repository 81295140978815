<ion-item class="ion-no-padding" lines="none" (click)="navigateToTodoListPageAsync()">
	<calao-list-item>
		<calao-list-item-start [backgroundColor]="observableItemStartBackgroundColor.value$ | async">
			<ion-icon name="checkbox" slot="start" [style.color]="observableItemStartIconColor.value$ | async"></ion-icon>
			<span class="todo-label">ToDo</span>
			<ng-container *ngIf="observableTotalCount.value$ |async as totalCount">
				<span class="todo">{{ totalCount }}</span>
			</ng-container>
		</calao-list-item-start>
		<calao-list-item-content [backgroundColor]="observableItemBackgroundColor.value$ | async" [detail]="true"
			(onDetailClicked)="navigateToTodoListPageAsync()">
			<div>
				<ng-container *ngFor="let task of observableTasks.changes$ | async">
					<ion-label> - {{ task.title }}</ion-label>
				</ng-container>
			</div>
			<calao-list-item-content-suffix *ngIf="observableDiffCountLabel.value$ | async as label" class="dark-bold">{{
				label }}
			</calao-list-item-content-suffix>
		</calao-list-item-content>
	</calao-list-item>
</ion-item>