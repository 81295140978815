import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'calao-inline-field-layout-tags-content',
  templateUrl: './inline-field-layout-tags-content.component.html',
  styleUrls: ['./inline-field-layout-tags-content.component.scss'],
})
export class InlineFieldLayoutTagsContentComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
